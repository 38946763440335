import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import MuiPhoneNumber from "material-ui-phone-number";
import CountryPicker from "components/CountryPicker";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchClients } from "redux/actions/clients";
import { REGEX } from "../../../utils";
import { fetchSuppliers } from "../../../redux/actions/suppliers";
import { ImageProfil } from "../../Suppliers/Component/ImageProfil";
// import { validateSiret } from "../../Suppliers/Component/StringComponent";
import useStyles from "../style";

const GeneralInformation = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	useEffect(() => {
		if (props.isClient) dispatch(fetchClients());
		else dispatch(fetchSuppliers());
	}, []);

	return (
		<Grid container xs={12} justify="center">
			<Grid container xs={12} alignItems="center" justify="center">
				<Typography variant="subtitle2" align="left" color="black">
					<Grid justify="center" className={classes.ImageProfil}>
						<ImageProfil src={props.supplier.logo} changeValue={props.setLogo} />
					</Grid>
				</Typography>
			</Grid>
			<Grid container xs={12} alignItems="center" justify="center">
				<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.supplierUsername")}
							<span style={{ color: "red" }}>&nbsp;*</span>
							<br />
							<Input
								value={props.supplier.locpt}
								onChange={(event) => props.updateSupplier(event)}
								onBlur={(event) => {
									props.setSupplier({
										...props.supplier,
										[event.target.name]: event.target.value.trim().replace(/\s\s+/g, " "),
									});
								}}
								name="locpt"
								inputRef={props.register({
									required: { value: true, message: t("supplier_required") },
								})}
								disabled={!!props.edit}
								fullWidth
							/>
							<Typography className={classes.validation_error}>
								{props.errors.locpt?.message}
							</Typography>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.country")}
							<br />
							<CountryPicker
								value={props.supplier.countryCode}
								onChange={(newValue) => {
									props.updateSupplier({
										target: {
											name: "countryCode",
											value: newValue,
										},
									});
								}}
								name="countryCode"
								inputRef={props.register({})}
								fullWidth
							/>
							<Typography className={classes.validation_error}>
								{props.errors.thirdPartyAccount?.message}
							</Typography>
						</Typography>
					</Grid>
				</Grid>
				<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.companyName")}
							<span style={{ color: "red" }}>&nbsp;*</span>
							<br />
							<Input
								value={props.supplier.raisonSociale}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									required: { value: true, message: t("companyName_required") },
								})}
								name="raisonSociale"
								placeholder=""
								fullWidth
							/>
							<Typography className={classes.validation_error}>
								{props.errors.raisonSociale?.message}
							</Typography>
						</Typography>
					</Grid>

					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.codeSocietee")}
							<span style={{ color: "red" }}>&nbsp;*</span>
							<br />
							<Input
								value={props.supplier.codeSocietee}
								onChange={(event) => props.updateSupplier(event)}
								inputRef={props.register({
									required: { value: true, message: t("codeSocietee_required") },
									pattern: { value: REGEX.NUMBER, message: t("codeSocietee_not_valid") },
								})}
								name="codeSocietee"
								fullWidth
							/>
							<Typography className={classes.validation_error}>
								{props.errors.codeSocietee?.message}
							</Typography>
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.vat")}
							<br />
							<Input
								value={props.supplier.vatNumber}
								onChange={(event) => props.updateSupplier(event)}
								onBlur={(event) => {
									props.setSupplier({
										...props.supplier,
										[event.target.name]: event.target.value.trim().replace(/\s\s+/g, " "),
									});
								}}
								name="vatNumber"
								fullWidth
							/>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.mobile")}
							<br />

							<Controller
								name="phone"
								control={props.control}
								rules={{
									pattern: {
										value: REGEX.TEL_FORMATTED,
										message: t("phone_notValid"),
									},
								}}
								value={props.supplier.phone}
								defaultValue={props.supplier.phone}
								render={({ name, onChange, value }) => (
									<MuiPhoneNumber
										defaultCountry="fr"
										fullWidth
										name={name}
										value={value}
										inputProps={{
											form: {
												autocomplete: "off",
											},
										}}
										onChange={(v) => {
											onChange(v);
											const event = {
												target: {
													name: "phone",
													value: v,
												},
											};
											props.updateSupplier(event);
										}}
									/>
								)}
							/>
						</Typography>
						<Typography className={classes.validation_error}>
							{props.errors.phone?.message}
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.email")}
							<span style={{ color: "red" }}>&nbsp;*</span>
							<br />
							<Input
								value={props.supplier.email}
								onChange={(event) => props.updateSupplier(event)}
								disabled={props.idSupplier}
								inputRef={props.register({
									required: { value: true, message: t("email_required") },
									pattern: { value: REGEX.EMAIL, message: t("email_notValid") },
								})}
								name="email"
								placeholder="example@me-group.io"
								fullWidth
							/>
							<Typography className={classes.validation_error}>
								{props.errors.email?.message}
							</Typography>
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="subtitle2" align="left" color="black">
							{t("supplier.add.phoneFix")}
							<br />
							<Input
								value={props.supplier.fax}
								onChange={(event) => props.updateSupplier(event)}
								name="fax"
								fullWidth
							/>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default GeneralInformation;
