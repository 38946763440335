/* eslint-disable */
import React, { useState } from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierDeclarationCA } from "redux/actions/suppliers";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import useStyles from "./style";

function DeclarationCAHeaders({ idSupplier }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [sortBy, setSortBy] = useState("");
	const [checked, setChecked] = useState(false);
	const filterDeclarationCA = useSelector(({ filterDeclarationCA: i }) => i);

	const [clickedRow, setClickedRow] = useState({
		mois: true,
		raisonSociale: false,
		dueDate: false,
		total: false,
	});

	const handleOnClickInvoiceRow = (row) => {
		setClickedRow((clickedRow) => ({ [row]: !clickedRow[row] }));
		setSortBy(row);
		dispatch(
			fetchSupplierDeclarationCA(idSupplier, {
				...filterDeclarationCA,
				sortBy: row,
				pageNo: 0,
			})
		);
	};

	const handleChange = () => {
		setChecked(!checked);
	};

	return (
		<Grid container item xs={12}>
			{/* <Grid xs style={{ marginLeft: "-30px" }}></Grid> */}
			<Grid item xs={1}></Grid>
			<Grid item xs={2} onClick={() => handleOnClickInvoiceRow("mois")}>
				<Typography className={classes.headers}>
					{clickedRow["mois"] && <SwapVertIcon />}
					{t("Month")}/{t("Year")}
				</Typography>
			</Grid>

			<Grid item xs={3} onClick={() => handleOnClickInvoiceRow("codeLieu")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["codeLieu"] && <SwapVertIcon />}
					{t("Code lieu")}
				</Typography>
			</Grid>

			<Grid item xs={3} onClick={() => handleOnClickInvoiceRow("nomLieu")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["nomLieu"] && <SwapVertIcon />}
					{t("Nom lieu")}
				</Typography>
			</Grid>

			<Grid item xs={1} onClick={() => handleOnClickInvoiceRow("ca")}>
				<Typography className={classes.headers} style={{ justifyContent: "center" }}>
					{clickedRow["ca"] && <SwapVertIcon />}
					{t("CA")}
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography className={classes.headers} style={{ justifyContent: "center" }}>
					<Checkbox checked={checked} onChange={handleChange} />
				</Typography>
			</Grid>
		</Grid>
	);
}

export default DeclarationCAHeaders;
