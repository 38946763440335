import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TablePagination } from "@material-ui/core";
import { updateDeclarationCAFilter } from "redux/filterDeclarationCA";
import { fetchSupplierDeclarationCA } from "redux/actions/suppliers";
import { useTranslation } from "react-i18next";
import TableElement from "../TableElement";

function DeclarationCATable({ idSupplier, data, classes, setOpenDeletePopup, setSelectedId }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const filterDeclarationCA = useSelector(({ filterDeclarationCA: i }) => i);

	const handleChangeDeclarationCAPage = (event, newPage) => {
		dispatch(updateDeclarationCAFilter({ ...filterDeclarationCA, pageNo: newPage }));
	};

	const handleChangeRowsDeclarationCAPerPage = (event) => {
		dispatch(
			updateDeclarationCAFilter({
				...filterDeclarationCA,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};
	useEffect(() => {
		dispatch(fetchSupplierDeclarationCA(idSupplier, filterDeclarationCA));
	}, [filterDeclarationCA]);

	return (
		<Grid item xs={12}>
			{data?.content?.map((row, i) => (
				<>
					<TableElement
						classes={classes}
						row={row}
						key={i}
						setOpenDeletePopup={setOpenDeletePopup}
						setSelectedId={setSelectedId}
						isDeclarationCATable
					/>
				</>
			))}
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="div"
				count={data?.totalElements}
				rowsPerPage={filterDeclarationCA.pageSize}
				page={filterDeclarationCA.pageNo}
				onChangePage={handleChangeDeclarationCAPage}
				onChangeRowsPerPage={handleChangeRowsDeclarationCAPerPage}
				labelRowsPerPage={t("pagination.rows.per.page")}
			/>
		</Grid>
	);
}

export default DeclarationCATable;
