import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import i18n from "../../../i18n";
import useStyles from "./style";
import { SetConnectedStatut } from "../../../App/appSlice";

function LoginForm() {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
  });
  const [type, setType] = useState("password");
  const [errorAuth, setErrorAuth] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const onSubmit = (data) => {
    if (
      data.username.trim() === "supplychain" &&
      data.password.trim() === "N0velisR0cks!"
    ) {
      dispatch(SetConnectedStatut());
      localStorage.setItem("username", data.username);
      setErrorAuth(false);
      history.push("/");
    } else {
      setErrorAuth(true);
    }
  };
  const showPassword = () => {
     setType(type === "password" ? "text" : "password");
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Grid>
      <MuiDialogActions className={classes.cardActions}>
        <IconButton onClick={() => changeLanguage("gb")}>
          <Typography className="flag-icon flag-icon-gb flag-icon-squared" />
          <Typography
            className={classes.language}
            variant="body2"
            color="primary"
          >
            {t("English")}
          </Typography>
        </IconButton>
        <IconButton onClick={() => changeLanguage("fr")}>
          <Typography className="flag-icon flag-icon-fr flag-icon-squared" />
          <Typography
            className={classes.language}
            variant="body2"
            color="primary"
          >
            {t("French")}
          </Typography>
        </IconButton>
        <IconButton onClick={() => changeLanguage("es")}>
          <Typography className="flag-icon flag-icon-es flag-icon-squared" />
          <Typography
            className={classes.language}
            variant="body2"
            color="primary"
          >
            {t("Spanish")}
          </Typography>
        </IconButton>
      </MuiDialogActions>
      <Card className={classes.card}>
        <MuiDialogContent
          className={classes.DialogContentRoot}
          dividers
         />
        <MuiDialogContent className={classes.DialogContentRoot} dividers>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <Grid container>
              <Grid item xs={1} />

              <Grid item xs={11}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  color="primary"
                >
                  {t("Login")}
                </Typography>
              </Grid>

              <Grid item xs={1} />

              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="username"
                  id="username-text"
                  label={t("Enter your mail")}
                  autoComplete="current-email"
                  inputRef={register({ required: true })}
                />
              </Grid>

              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name="password"
                  inputRef={register({ required: true })}
                  type={type}
                  id="password-text"
                  autoComplete="current-password"
                  label={t("Enter your password")}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={showPassword}
                  className={classes.visibilityButton}
                >
                  <VisibilityIcon />
                </IconButton>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              <Grid item xs={10}>
                {errorAuth && (
                  <Typography className={classes.errorAuth} variant="body2">
                    {t("Login or password incorrect !")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />

              <Grid item xs={10}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  className={classes.button}
                  disabled={!formState.isValid}
                >
                  {t("Login")}
                </Button>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6} />
              <Grid item xs={5}>
                <Typography
                  className={classes.forgotTitle}
                  variant="body2"
                  color="primary"
                >
                  <Link
                    to="#"
                    className={classes.link}
                    variant="body2"
                    onClick={() => {}}
                  >
                    {t("Forgot your password ?")}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </form>
        </MuiDialogContent>
        <MuiDialogContent className={classes.DialogContentRoot} dividers>
          <Typography
            className={classes.footer}
            variant="body1"
            color="primary"
          >
            {t("Copyright © Novy  All rights reserved.")}
          </Typography>
        </MuiDialogContent>
      </Card>
    </Grid>
  );
}

export default LoginForm;
