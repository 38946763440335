import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Badge, Box, withStyles } from "@material-ui/core";

const styles = (theme) => ({

    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(1.7)",
            opacity: 0,
        },
    },
    dot: {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: "10px",
        width: "10px",
        borderRadius: "50%",
    },
    orange: {
        color: "#ff7600",
        backgroundColor: "#ff7600",
    },
    green: {
        backgroundColor: "#31d031",
        color: "#31d031",
    },
    red: {
        color: "#ef2929",
        backgroundColor: "#ef2929",
    },
});

function StatusLegend(props) {
    const { classes } = props;
    const { t } = useTranslation();
    const badgeColor = (level) => {
        switch (level) {
            case "ACTIVE":
                return classes.green;
            case "WAITING":
                return classes.orange;
            case "DISABLE":
                return classes.red;
            default:
                return classes.red;
        }
    };
    return (
        <Grid container xs={12}>
            <Grid container item xs={1} justify="center" alignItems="center" spacing={1}>
                <Grid item >
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                    >
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            variant="dot"
                            classes={{ badge: badgeColor("ACTIVE"), dot: classes.dot }}
                         />                </Box>
                </Grid>
                <Grid item >

                    <Typography variant="caption" color="textSecondary">
                        {t("ACTIVE")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={1} justify="center" alignItems="center" spacing={1}>
                <Grid item >
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        flexDirection="column"
                    >
                        <Badge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            variant="dot"
                            classes={{ badge: badgeColor("DISABLE"), dot: classes.dot }}
                         />                </Box>
                </Grid>
                <Grid item >

                    <Typography variant="caption" color="textSecondary">
                        {t("DISABLE")}
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default withStyles(styles)(StatusLegend);
