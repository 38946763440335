import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import useStyles from "./style";



function Login() {
    const isConnected = useSelector(({ appState }) => appState.isConnected);
    const classes = useStyles();
    return !isConnected && (
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.root}>



            <Grid item xs={4}>
                <LoginForm />
            </Grid>


        </Grid>

    );
}


export default Login;
