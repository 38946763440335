import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { REGEX } from "utils";
import { ReactComponent as EmailIcon } from "./email-icon.svg";
import useStyles from "./style";

const EmailPopup = ({ open, handleConfirm, handleCancel, email, submitButtonDisabled }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [newEmail, setNewEmail] = React.useState("");

	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleCancel}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" className={classes.popupTitle}>
				<div className={classes.emailContainer}>
					<EmailIcon />
				</div>
				{t("supplier.popup.email.title")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.email.message")}
				</DialogContentText>
				<DialogContentText className={classes.text}>{`${t("supplier.popup.email.currentEmail")} ${
					email ?? ""
				}`}</DialogContentText>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.email.newEmail")}
				</DialogContentText>
				<TextField
					autoFocus
					id="email"
					type="email"
					fullWidth
					placeholder="joedon@gmail.com"
					className={classes.inputField}
					value={newEmail}
					onBlur={() => {
						if (!new RegExp(REGEX.EMAIL).test(newEmail)) {
							setNewEmail("");
						}
					}}
					onChange={(e) => {
						setNewEmail(e.target.value);
					}}
					InputProps={{
						classes: {
							input: classes.inputField,
						},
					}}
				/>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button onClick={handleCancel} variant="outlined" color="secondary">
					{t("supplier.popup.email.cancel")}
				</Button>
				<Button
					onClick={(e) => {
						e.preventDefault();
						handleConfirm(newEmail);
					}}
					variant="contained"
					color="secondary"
					disabled={submitButtonDisabled}
				>
					{t("supplier.popup.email.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EmailPopup;
