import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";

export default function SubPagePanel(props) {
	const { children, value, index, classes, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`sub-pagepanel-${index}`}
			aria-labelledby={`sub-page-${index}`}
			className={classes}
			{...other} // eslint-disable-line
		>
			{value === index && <Box style={{ padding: "24px 0" }}>{children}</Box>}
		</div>
	);
}

SubPagePanel.propTypes = {
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
