import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import { ReactComponent as TickIcon } from "./tick-icon.svg";
import { ReactComponent as ErrorIcon } from "./error-icon.svg";
const MessagePopup = ({ open, handleConfirm, message, messageType }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleConfirm}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" className={classes.popupTitle}>
				<div className={classes.emailContainer}>
					{messageType === "success" ? (
						<TickIcon />
					) : messageType === "error" ? (
						<ErrorIcon />
					) : null}
				</div>
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={`${classes.texCenter} ${classes.text}`}>
					{t(message)}
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button
					onClick={() => {
						handleConfirm();
					}}
					variant="contained"
					color="secondary"
				>
					{t("supplier.popup.ok")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MessagePopup;
