import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	supplierByCodeLieuOrNumFacture: "",
	status: [],
	startDate: null,
	endDate: null,
	pageNo: 0,
	pageSize: 10,
	selectAll: false,
	sortBy: "",
};

const filterListSuppliers = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updateFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updateFilter, initFilter } = filterListSuppliers.actions;

export default filterListSuppliers.reducer;
