import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
		marginBottom: "1%",
		cursor: "pointer",
		width: "70%",
		transition: theme.transitions.create("transform", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.short,
		}),
		"&:hover": {
			background: "white !important",
			boxShadow: theme.shadows[25],
			transform: "scale(1.01)",
		},
	},
	content: {
		display: "flex",
		width: "100%",
		flexDirection: "column",
	},
	messageContent: {
		padding: theme.spacing(1, 0, 1, 0),
	},
	fromMessage: {
		borderLeft: `5px solid #EA6E35`,
		backgroundColor: "#f5f5f5",
	},
	toMessage: { borderLeft: `5px solid #117A93` },
}));
