import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {StringComponent} from "../StringComponent";
import useStyles from "../style";
import {PhoneComponent} from "../PhoneComponent";

export const SupplementInformations = (props) => {
    const classes = useStyles();
    const onchangePhone = (value) => {
        props.handleSupplier({ ...props.supplier, phone: value })
    }
    const onchangeFax = (value) => {
        props.handleSupplier({ ...props.supplier, fax: value })
    }
    const onchangeLinkedIn = (value) => {
        props.handleSupplier({ ...props.supplier, linkedIn: value })
    }
    const onchangeEmail = () => {
        // props.handleSupplier({ ...props.supplier, email: value })
    }

    return (
        <Card className={classes.root} >
            <Grid container xs={12} spacing={4}>
                <Grid container item xs={12} className={classes.supplementInfosHeader}>
                    <Typography color="primary" variant="h6" align="left">Informations supplémentaire</Typography>
                </Grid>
                <Grid container item xs={12} spacing={4}>
                    <Grid container item xs={12} spacing={4} alignItems="center">

                        <Grid item xs={6}>
                            <PhoneComponent label="Téléphone" changeValue={onchangePhone} />
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneComponent label="Fax" changeValue={onchangeFax} />
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} spacing={4} alignItems="center">


                        <Grid item xs={6}>
                            <StringComponent label="Page Linkedin" type="linkedin" placeholder="https://www.linkedin.com/example" changeValue={onchangeLinkedIn} />
                        </Grid>
                        <Grid item xs={6}>
                            <StringComponent label="Adresse" type="email" required placeholder="supplychain@novelis.io" changeValue={onchangeEmail} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default SupplementInformations;
