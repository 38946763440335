/* eslint-disable */
import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDownOutlined";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import CardActionArea from "@material-ui/core/CardActionArea";
import AppBar from "@material-ui/core/AppBar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import get from "lodash/get";
import {
	currencyFormat,
	isUserAdminOnly,
	isSupplierOnly,
	isClientOnly,
	isPurchaseOrder,
} from "utils";
import HeaderToolbar from "./HeaderToolbar";
import useStyles from "./style";
import logo from "../../logo.png";
import CardHeader from "./CardHeader";
import {
	fetchCountSuppliers,
	fetchCountInvoices,
	invoicesTotalAmounts,
	logout,
} from "../../../redux/actions/invoices";
import { invoicesVATAmountsBySupplier } from "../../../redux/actions/suppliers";
import { fetchCurrentUser } from "../../../redux/actions/users";

export default () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isSupplier, setIsSupplier] = React.useState(false);
	const [isClient, setIsClient] = React.useState(false);

	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);

	const invoiceTotalAmounts = useSelector(
		({ requests }) =>
			requests.queries.INVOICE_TOTAL_AMOUNT && requests.queries.INVOICE_TOTAL_AMOUNT.data
	);

	const newSuppliers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_COUNT_SUPPLIERS && requests.queries.FETCH_COUNT_SUPPLIERS.data
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const totalInvoices = useSelector(({ requests }) => get(requests, "queries.TOTAL_INVOICE.data"));
	const totalOrders = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_COUNT_ORDERS.data")
	);

	// const invoicesTotal = useSelector(({ requests }) =>
	// 	get(requests, "queries.INVOICE_VAT_AMOUNT_BY_SUPPLIER.data")
	// );

	const invoicesTotal = useSelector(({ requests }) =>
		get(requests, "queries.INVOICE_TOTAL_AMOUNT_BY_SUPPLIER.data")
	);

	const getUserSupplierId = () => {
		if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
			return currentUser.supplierId;
		}
		return undefined;
	};
	useEffect(() => {
		dispatch(fetchCurrentUser());
	}, []);

	useEffect(() => {
		if (!get(currentUser, "roles")) {
			return;
		}
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			dispatch(invoicesVATAmountsBySupplier(getUserSupplierId(currentUser)));
			setIsSupplier(true);
		}

		if (isClientOnly(get(currentUser, "roles", []))) {
			setIsClient(true);
		}

		if (get(currentUser, "roles")) {
			//&& currentUser.roles.includes("invoice_user")) {
			dispatch(fetchCountSuppliers());
			dispatch(invoicesTotalAmounts());
			dispatch(fetchCountInvoices());
		}
	}, [currentUser]);

	useEffect(() => {
		if (isSupplierOnly(get(currentUser, "roles", []))) {
			dispatch(invoicesVATAmountsBySupplier(getUserSupplierId(currentUser)));
			setIsSupplier(true);
		}
	}, [currentUser]);

	const getInvoicesCount = () => totalInvoices;
	const getOrdersCount = () => totalOrders;

	// const getInvoicesTotal = () => {
	// 	if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
	// 		return invoicesTotal;
	// 	}
	// 	return invoiceTotalAmounts;
	// };

	const getInvoiceTotalAmount = () => {
		if (get(currentUser, "roles") && currentUser.roles.includes("invoice_supplier")) {
			return invoicesTotal;
		}
		return invoiceTotalAmounts;
	};

	const getUserInitials = () => {
		const f = get(currentUser, "firstName") ? currentUser.firstName.charAt(0).toUpperCase() : "";
		const l = get(currentUser, "lastName") ? currentUser.lastName.charAt(0).toUpperCase() : "";

		return f + l;
	};

	return (
		<AppBar
			position="relative"
			color="transparent"
			className={clsx(classes.root, !headerExpanded && classes.rootSticky)}
			elevation={24}
		>
			<Grid container className={classes.gridContainer} alignItems="center" justify="space-between">
				<Grid item>
					<img src={logo} alt="Logo" className={classes.logo} />
				</Grid>
				{!isUserAdminOnly(get(currentUser, "roles", [])) &&
					!isClientOnly(get(currentUser, "roles", [])) &&
					!isPurchaseOrder(get(currentUser, "roles", [])) && (
						<Grid item className="d-flex">
							{!isSupplierOnly(get(currentUser, "roles", [])) && (
								<CardHeader path="/" title={newSuppliers || 0} content={t("New clients")} />
							)}
							<CardHeader
								title={totalInvoices || 0}
								content={t("invoice.total_invoice")}
								// path="/redevances"
								isSupplier={isSupplier}
							/>
						</Grid>
					)}
				<Grid item className="text-center">
					<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
						<Badge
							overlap="circle"
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							badgeContent={<ArrowDropDown color="secondary" />}
						>
							<Avatar className={classes.purple}>{getUserInitials()}</Avatar>
						</Badge>
					</IconButton>
					<Typography variant="subtitle2" align="center" className={classes.title}>
						{`${get(currentUser, "firstName", "")} ${get(currentUser, "lastName", "")}`}
					</Typography>
				</Grid>
				<Popover
					id="profile-popover"
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<CardActionArea onClick={() => history.push("/profile")}>
						<ListItemText primary={t("Profile")} className={classes.popupItems} />
					</CardActionArea>
					<CardActionArea onClick={() => dispatch(logout())}>
						<ListItemText primary={t("Logout")} className={classes.popupItems} />
					</CardActionArea>
				</Popover>
			</Grid>
			<HeaderToolbar />
		</AppBar>
	);
};
