import React from 'react'
import CircularProgress  from '@material-ui/core/CircularProgress'
import  Box from '@material-ui/core/Box'

const CircularLoader = () => (
        <Box display="flex" justifyContent="center" width="100%">
            <CircularProgress />
        </Box>
    )

export default CircularLoader