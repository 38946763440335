import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginBottom: "1%",
    cursor: "pointer",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  loginContainer: {
    backgroundColor: theme.palette.background.paper,
    // padding: "100px 100px 100px 100px",
  },
  appBar: {},
  tabs: {},
  selectedTab: {
    color: "#0da8ad",
  },
  tab: {
    width: "25%",
  },
  firstGrid: {
    paddingTop: "1%",
    width: "calc(100% + 48px)",
  },
  firstGridEmail: {
    paddingTop: "3%",
    width: "calc(100% + 48px)",
  }
  ,
  settingsContainer: {
    paddingTop: "4%",
    width: "calc(100% + 48px)",
  },
  header: {
    width: "100%",
    display: "flex",
    background: "transparent",
    boxShadow: "none",
    paddingBottom: "0px",
  },
  content: {
    display: "flex",
    width: "85%",
    flexDirection: "column",
  },
  avatar: {
    alignSelf: "center",
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  downloadIcon: {
    color: theme.palette.success.main,
  },
  cardTable: {
    background: theme.palette.grey[200],
  },
  tableCell: {
    borderBottom: "none",
    fontWeight: "300",
  },
  actions: {
    marginTop: "32px",
  },
  headerTitle: {
    marginTop: "32px",
    marginBottom: "32px",
  },
  checkIcon: {
    fontSize: "3rem",
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  downloadButton: {
    paddingLeft: theme.spacing(4),
  },
  downloadButtonRadio: {
    paddingLeft: theme.spacing(3),
  },
  amountTolerance: {
    marginTop: "15px",
    "& .MuiInputBase-input": {
      width: "100px",
      textAlign: 'center'
    }
  },
  paramSelect: {
    width: 160,
    padding: "15px 0px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ff0539"
    },
    "& .MuiSelect-select": {
      backgroundColor: "#ff053922",
      color: "#ff0539",
    }
  },

  paramSelectImportanct: {
    width: 160,
    padding: "15px 0px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f7925c"
    },
    "& .MuiSelect-select": {
      backgroundColor: "#f7925c22",
      color: "#f7925c",
    }
  },
  paramSelectIgnored: {
    width: 160,
    padding: "15px 0px",
  }

}));
