import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { toast } from "utils";
import clsx from "clsx";
import {
	checkSupplierCode,
	// checkSupplierEmail,
	createSupplier,
	modifySupplier,
	fetchAccountManagers,
	// checkSupplierEmail,
} from "../../redux/actions/suppliers";
import Financial from "./components/Financial";
import Contact from "./components/Contact";
import GeneralInformation from "./components/GeneralInformation";
import useStyles from "./style";
import { fetchSupplier } from "../../redux/actions/invoices";
import ConfirmDialog from "../../components/ConfirmDialog";

const AddSupplier = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { idSupplier } = useParams();
	const getSteps = () => [
		t("supplier.add.step1"),
		t("supplier.add.step2"),
		t("supplier.add.step3"),
	];
	const [activeStep, setActiveStep] = useState(0);
	const [supplier, setSupplier] = React.useState({ contacts: [] });
	const [contact, setContact] = React.useState({});
	const [logo, setLogo] = React.useState(null);
	const [ribAttachment, setRibAttachment] = React.useState(null);
	const steps = getSteps();
	const { errors, handleSubmit, register, control, reset } = useForm();
	const [isEdit, setIsEdit] = React.useState(false);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
	});
	const [checkName, setCheckName] = useState(false);
	// const [checkEmail, setCheckEmail] = useState(false);

	const supplierToUpdate = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER.data")
	);

	const accountManagers = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_ACCOUNT_MANAGERS.data")
	);

	const updateSupplier = (e) => {
		setSupplier({ ...supplier, [e.target.name]: e.target.value });
	};

	const updateSupplierContact = (e) => {
		setContact({ ...contact, [e.target.name]: e.target.value });
	};

	const handleBack = () => {
		if (activeStep === 0) history.goBack();
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	useEffect(() => {
		// if (checkEmail && checkName) setActiveStep((prevActiveStep) => prevActiveStep + 1);
		if (checkName) setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}, [checkName]);

	const onSubmit = () => {
		if (activeStep === 0 && !idSupplier) {
			// check supplier's name in the first step of the form
			dispatch(
				checkSupplierCode(
					supplier.code,
					(rep) => {
						if (!rep.data) {
							setCheckName(true);
						} else {
							toast.error(t("supplier.name.exist.already"));
							setCheckName(false);
						}
					},
					() => {
						toast.error(t("supplier_error"));
					}
				)
			);
			/* 
			dispatch(
				checkSupplierEmail(
					supplier.email,
					(rep) => {
						if (!rep.data) {
							setCheckEmail(true);
						} else {
							toast.error(t("supplier.email.exist.already"));
							setCheckEmail(false);
						}
					},
					() => {
						toast.error(t("supplier_error"));
					}
				)
			); */
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			if (activeStep === 2) {
				setConfirmObject({ message: t("supplier.add.confirm"), openConfirm: true });
			}
		}
	};

	const getStepContent = (stepIndex) => {
		switch (stepIndex) {
			case 0:
				return (
					<GeneralInformation
						register={register}
						control={control}
						errors={errors}
						setSupplier={setSupplier}
						updateSupplier={updateSupplier}
						supplier={supplier}
						edit={isEdit}
						setLogo={setLogo}
						idSupplier={idSupplier}
					/>
				);
			case 1:
				return (
					<Contact
						register={register}
						errors={errors}
						updateSupplier={updateSupplier}
						supplier={supplier}
						contact={contact}
						updateSupplierContact={updateSupplierContact}
						accountManagers={accountManagers}
						control={control}
					/>
				);
			case 2:
				return (
					<Financial
						register={register}
						errors={errors}
						updateSupplier={updateSupplier}
						supplier={supplier}
						setRibAttachment={setRibAttachment}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	};

	useEffect(() => {
		if (idSupplier) dispatch(fetchSupplier(idSupplier));
		dispatch(fetchAccountManagers());
	}, []);

	useEffect(() => {
		if (idSupplier && supplierToUpdate) {
			setIsEdit(true);
			setSupplier(supplierToUpdate);
			reset(supplierToUpdate);

			if (supplierToUpdate.contacts && supplierToUpdate.contacts.length > 0) {
				setContact(supplierToUpdate.contacts[0]);
			}
		}
	}, [supplierToUpdate]);

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		setActiveStep(activeStep - 1);
	};

	const onConfirm = () => {
		supplier.countryCode = supplier.countryCode || "FR";
		supplier.currency = supplier.currency || "EUR";
		if (!isEmpty(contact)) {
			if (idSupplier) {
				supplier.contacts[0] = contact;
			} else {
				supplier.contacts.push(contact);
			}
		}
		setSupplier({ ...supplier });
		if (idSupplier) {
			const accountManagerId = supplier.accountManager.id || supplier.accountManager;
			const tmpSupplier = { ...supplier, accountManager: { id: accountManagerId } };
			setConfirmObject({ message: "", openConfirm: false });
			dispatch(modifySupplier(tmpSupplier, logo, ribAttachment)).then((res) => {
				if (res.action.response?.status) {
					toast.success(t("supplier_updated"));
					history.push(`/`);
				} else {
					toast.error(t("supplier_error"));
				}
			});
		} else {
			const accountManagerId = supplier.accountManager;
			const tmpSupplier = { ...supplier, accountManager: { id: accountManagerId } };
			setConfirmObject({ message: "", openConfirm: false });
			dispatch(createSupplier(tmpSupplier, logo, ribAttachment)).then((res) => {
				if (res.action.response?.status) {
					toast.success(t("supplier_created"));
					history.push(`/`);
				} else {
					toast.error(t("supplier_error"));
				}
			});
		}
	};

	return (
		<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
			<Grid container item xs={12}>
				<Typography variant="caption" color="primary" className={classes.headerBreadCumb}>
					<Grid container direction="row" alignItems="center" className={classes.headerPath}>
						<Grid item style={{ marginRight: "3px" }}>
							<GroupIcon fontSize="small" />
						</Grid>
						<Grid item>
							{t("manageUsers")} {" > "}{" "}
							{idSupplier ? t("supplier.modify") : t("supplier.add2.button")}
						</Grid>
					</Grid>
				</Typography>
			</Grid>
			<Grid container xs={12} justify="center">
				<div className={classes.root}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div>
						{activeStep === steps.length ? (
							<div>
								<Typography className={classes.instructions}>All steps completed</Typography>
								<Button onClick={handleReset}>Reset</Button>
							</div>
						) : (
							<div>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid
										container
										item
										xs={12}
										justify="center"
										className={classes.formContainer}
										style={{ padding: "20px" }}
									>
										{getStepContent(activeStep)}
									</Grid>
									<Grid container justify="flex-end" style={{ marginTop: "20px" }}>
										<Button
											variant="outlined"
											onClick={handleBack}
											className={clsx(classes.backButton, classes.btn)}
										>
											{activeStep === 0 ? t("cancel") : t("supplier.add.previous")}
										</Button>
										<Button
											variant="contained"
											color="secondary"
											type="submit"
											className={classes.btn}
										>
											{activeStep === steps.length - 1
												? t("supplier.add.finish")
												: t("supplier.add.next")}
										</Button>
									</Grid>
								</form>
							</div>
						)}
					</div>
				</div>
			</Grid>
			<ConfirmDialog
				message={confirmObject.message}
				open={confirmObject.openConfirm}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</Grid>
	);
};

export default AddSupplier;
