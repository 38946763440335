import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	invoiceCodeLieu: "",
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
	selectedAll: false,
};
const filterListInvoices = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updateFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updateFilter, initFilter } = filterListInvoices.actions;

export default filterListInvoices.reducer;
