import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {StringComponent} from "../StringComponent";
import useStyles from "../style";
import {CivilityComponent} from "../CivilityComponent";
import {PhoneComponent} from "../PhoneComponent";

export const PrincipalContact = (props) => {
    const classes = useStyles();
    const onchangeContactName = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.lastName = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }
    const onchangeContactFirstName = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.firstName = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }
    const onchangeContactEmail = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.email = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }
    const onchangeContactPhone = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.phone = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }
    const onchangeContactFunction = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.function = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }

    const handleChangeContactCivility = (value) => {
        const items = [...props.supplier.contacts];
        const item = { ...items[0] };
        item.civility = value;
        items[0] = item;
        props.handleSupplier({ ...props.supplier, contacts: items })
    }
    useEffect(() => {
        if (props.supplier) {
            const items = [...props.supplier.contacts];
            const item = { ...items[0] };
            item.principal = true;
            items[0] = item;
            props.handleSupplier({ ...props.supplier, contacts: items })
        }
    }, []);
    return (
        <Card className={classes.root}>
            <Grid container xs={12} spacing={4}>
                <Grid container item xs={12} className={classes.supplementInfosHeader}>
                    <Typography color="primary" variant="h5" align="left">Informations du contact principal</Typography>
                </Grid>
                <Grid container item xs={12} spacing={4}>
                    <Grid container item xs={12} spacing={4} justify="center" alignItems="center">
                        <Grid item xs={4}>
                            <CivilityComponent changeValue={handleChangeContactCivility} />
                        </Grid>
                        <Grid item xs={4}>
                            <StringComponent label="Prenom" type="name" changeValue={onchangeContactFirstName} />
                        </Grid>
                        <Grid item xs={4}>
                            <StringComponent label="Nom" type="name" changeValue={onchangeContactName} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={4} justify="center" alignItems="center">
                        <Grid item xs={4}>
                            <StringComponent label="Email" type="email" placeholder="supplychain@novelis.io" changeValue={onchangeContactEmail} />
                        </Grid>
                        <Grid item xs={4}>
                            <PhoneComponent label="Mobile" changeValue={onchangeContactPhone} />
                        </Grid>
                        <Grid item xs={4}>
                            <StringComponent label="Fonction" type="function" changeValue={onchangeContactFunction} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default PrincipalContact;
