import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {toast} from "utils";
import { useDispatch } from "react-redux";
import {
  deleteSupplier,
  fetchSuppliers,
} from "../../../redux/actions/suppliers";


function ConfirmationDialog({ open, setOpen, id, filterSuppliers }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseDeletePopup = () => {
    setOpen(!open);
  };
  const toDelete = (idParam) => {
    dispatch(
      deleteSupplier(
        idParam,
        () => {
          handleCloseDeletePopup();
          dispatch(fetchSuppliers(filterSuppliers));
        },
        () => toast.error(t(
          "This supplier cannot be deleted because it is linked to an existing invoice"
        ))
      )
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDeletePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("deleteMessage")}</DialogTitle>

      <DialogActions>
        <Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            toDelete(id);
          }}
          type="submit"
          color="secondary"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
