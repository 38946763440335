import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "appState",
  isConnected: false,
  isUserModify:false,
  initialState: {
    menuExpanded: false,
    headerExpanded: true,
  },
  reducers: {
    toogleMenu: (state) => ({ ...state, menuExpanded: !state.menuExpanded }),
    setHeaderExpanded: (state, action) => ({
      ...state,
      headerExpanded: action.payload,
    }),
    SetConnectedStatut: (state) => ({ ...state, isConnected: !state.isConnected }),
    setIsUserModif: (state) => ({ ...state, isUserModify: !state.isUserModify })
  },
});

export const { toogleMenu, setHeaderExpanded, SetConnectedStatut,setIsUserModif } = appSlice.actions;

export default appSlice.reducer;
