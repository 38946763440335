import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	firstGrid: {
		paddingTop: "1%",
		width: "calc(100% + 48px)",
	},
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	input: {
		Width: "224px",
	},
	space: {
		marginTop: "10px",
	},
	tab: {
		fontWeight: "300",
		height: "20px",
	},
	supplierDetails: {
		backgroundColor: theme.palette.background.paper,
	},
	supplierItem: {
		margin: "25px 0",
	},

	large: {
		width: theme.spacing(15),
		height: theme.spacing(15),
	},

	customBadge: {
		backgroundColor: theme.palette.background.paper,
		height: "24px",
	},
	invoicesTable: {
		marginTop: "25px",
	},
	rootContainer: {
		padding: theme.spacing(2, 0),
	},
	appBar: { backgroundColor: "transparent", boxShadow: "none" },
	invoicesDetails: {
		marginTop: theme.spacing(4),
	},
	selectedTab: {
		color: "#0da8ad",
	},
	fullwidth: {
		width: "100%",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		float: "right",
		height: "100%",
		padding: "9px",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	dropzone: {
		background: "transparent",
		maxWidth: "100%",
		minHeight: "80%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#117F9B",
		flexDirection: "column",
		"& .MuiDropzoneArea-icon": {
			color: theme.palette.secondary.main,
			opacity: 0.8,
		},
	},
	buttons: {
		marginRight: "20%",
		marginBottom: "3%",
		margin: "2px",
		padding: "9px",
	},

	buttonsNoMargin: {
		marginRight: "0",
		marginBottom: "0",
	},
	buttonsValidationContainer: {
		padding: theme.spacing(1),
	},
	buttonsValidation: {
		padding: "9px",
	},
	buttonsContainer: {
		padding: theme.spacing(2, 0),
	},
	ibanValidationBox: {
		backgroundColor: "#f5f5f5",
		borderRadius: "8px",
		margin: theme.spacing(0, 1, 2, 0),
		padding: theme.spacing(2, 0),
	},
	ibanValidationButtonBox: {
		margin: theme.spacing(0, 1, 2, 0),
	},
	fields: {},
}));
