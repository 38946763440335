import {
	FETCH_USERS,
	FETCH_USERS_JOBS,
	DELETE_USER,
	CREATE_USER,
	FETCH_USERS_CIVILITIES,
	EDIT_USER,
	FETCH_USER,
	UPDATE_USER,
	UPDATE_USER_STATUS,
	UPDATE_MAIL_CONFIG,
	FETCH_MAIL_CONFIG,
	FETCH_FOLDER_CONFIG,
	UPDATE_FOLDER_CONFIG,
	DELETE_TRAINING_SUPPLIER,
	FETCH_EXTRACTION_LEVEL_PARAMS,
	UPDATE_EXTRACTION_LEVEL_PARAMS,
	FETCH_HOTFOLDER_FTP_CONFIG,
	UPDATE_HOTFOLDER_FTP_CONFIG,
	FETCH_RT_COUNTRIES,
} from "../constants/index";
import {
	URL_FETCH_USERS,
	URL_FETCH_JOBS,
	URL_BASE_USERS,
	URL_FETCH_CIVILITIES,
	URL_UPDATE_USER_STATUS,
	URL_DELETE_TRAINING_SUPPLIER,
	URL_RT_COUNTRIES,
} from "../constants/endpoint";
import { DELETE_LIST_INVOICE } from "../../../redux/constants";

export const fetchCountries = () => ({
	type: `${FETCH_RT_COUNTRIES}`,
	request: {
		url: `${URL_RT_COUNTRIES}`,
	},
});

export const fetchUsers = (filter = {}) => {
	const { userName, status, pageNo, pageSize, sortBy, typeUser } = filter;
	return {
		type: FETCH_USERS,
		request: {
			url: `${URL_FETCH_USERS}`,
			params: {
				userName,
				pageNo,
				pageSize,
				sortBy,
				status: status ? status.map((s) => s.code).join(",") : null,
				typeUser,
			},
		},
	};
};
export const fetchJobs = () => ({
	type: FETCH_USERS_JOBS,
	request: {
		url: `${URL_FETCH_JOBS}`,
	},
});
export const fetchCivilities = () => ({
	type: FETCH_USERS_CIVILITIES,
	request: {
		url: `${URL_FETCH_CIVILITIES}`,
	},
});
export const deleteUser = (id, onSuccess, onError) => ({
	type: DELETE_USER,
	request: {
		url: `${URL_BASE_USERS}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const createUser = (user, onSuccess, onError) => ({
	type: CREATE_USER,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "post",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const updateUser = (idUser, user, onSuccess, onError) => ({
	type: UPDATE_USER,
	request: {
		url: `${URL_BASE_USERS}/${idUser}`,
		method: "put",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const deleteUsers = (idUsers, onSuccess) => ({
	type: DELETE_LIST_INVOICE,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "delete",
		params: { idUsers: idUsers.join(",") },
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) {
				onSuccess();
			}
		},
	},
});

export const editUser = (user, onSuccess, onError) => ({
	type: EDIT_USER,
	request: {
		url: `${URL_BASE_USERS}/`,
		method: "put",
		data: user,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const fetchUser = (id, onSuccess, onError) => ({
	type: FETCH_USER,
	request: {
		url: `${URL_BASE_USERS}/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const enableOrDisableUser = (id, userStatus, onSuccess, onError) => ({
	type: UPDATE_USER_STATUS,
	request: {
		url: `${URL_UPDATE_USER_STATUS}${id}`,
		method: "put",
		data: { status: userStatus },
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: (error) => {
			if (onError) onError();
			if (error.response.status === 400) {
				const message = error.response.data.detail;
				onError(message);
			}
		},
	},
});

export const fetchSettings = (onSuccess, onError) => ({
	type: "FETCH_SETTINGS",
	request: {
		url: `/admin/setting/`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchMailConfig = (id, onSuccess, onError) => ({
	type: FETCH_MAIL_CONFIG,
	request: {
		url: `/import/mail-config/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateSettings = (data, module, onSuccess, onError) => ({
	type: "update_SETTINGS",
	request: {
		url: `/admin/setting/${module}`,
		method: "put",
		data,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateMailConfig = (data, onSuccess, onError) => ({
	type: UPDATE_MAIL_CONFIG,
	request: {
		url: `/import/mail-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchFolderConfig = (id, onSuccess, onError) => ({
	type: FETCH_FOLDER_CONFIG,
	request: {
		url: `/import/folder-config/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateFolderConfig = (data, onSuccess, onError) => ({
	type: UPDATE_FOLDER_CONFIG,
	request: {
		url: `/import/folder-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const deleteTrainingForSupplier = (supplierId, onSuccess, onError) => ({
	type: DELETE_TRAINING_SUPPLIER,
	request: {
		url: `${URL_DELETE_TRAINING_SUPPLIER}${supplierId}`,
		method: "delete",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess("successdeleteTrainingMessage");
			return response;
		},
		onError: (error) => {
			if (error.response.status === 400) {
				onError(error.response);
			} else if (onError) onError(error.response);
		},
	},
});

export const fetchExtractionLevelConfig = (onSuccess, onError) => ({
	type: FETCH_EXTRACTION_LEVEL_PARAMS,
	request: {
		url: `/invoice/extraction-level/extraction-level-params`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateExtractionLevelParam = (data, onSuccess, onError) => ({
	type: UPDATE_EXTRACTION_LEVEL_PARAMS,
	request: {
		url: `/invoice/extraction-level/extraction-level-params`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchHotfolderConfig = (id, onSuccess, onError) => ({
	type: FETCH_HOTFOLDER_FTP_CONFIG,
	request: {
		url: `/hotfolder/ftp-config/${id}`,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess(response.data);
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const updateHotfolderConfig = (data, onSuccess, onError) => ({
	type: UPDATE_HOTFOLDER_FTP_CONFIG,
	request: {
		url: `/hotfolder/ftp-config`,
		method: "put",
		data,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
