/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, TablePagination } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteBill } from "../../../../redux/actions/invoices/index";

import useStyles from "./style";

import { fetchSupplierBills } from "../../../../redux/actions/suppliers";
import TableElement from "./TableElement";
import TableHeaders from "./TableHeaders";
import TableFilters from "./TableFilters";
import { toast } from "react-toastify";
import Table from "./Table";

function InvoicesTable({
	data,
	idSupplier,
	isInvoiceTable,
	isBillTable,
	isPaymentTable,
	isDeclarationCATable,
	companyCode,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [selectedId, setSelectedId] = React.useState(0);
	const [isParentChecked, setIsParentChecked] = React.useState(false);
	const [checkedAll, setCheckedAll] = React.useState([]);
	const [idInvoices, setIdInvoices] = React.useState([]);

	useEffect(() => {
		setIdInvoices(data?.content.map((invoice) => invoice.id));
	}, [data]);

	const changeCheckboxStatus = (e, id) => {
		const myUsers = [...users];
		const { checked } = e.target;
		myUsers.map((user) => {
			if (id === "p1") {
				setIsParentChecked(checked);
				user.isChecked = checked;
			} else {
				if (user.id === id) {
					user.isChecked = checked;
				}
				const isAllChildsChecked = myUsers.every((user) => user.isChecked === true);
			}
			return user;
		});

		setUsers([...myUsers]);
	};
	const handleCheckedAll = (isChecked) => {
		setCheckedAll(isChecked ? idInvoices : []);
	};

	const ConfirmationDialog = ({ open, setOpen, id }) => {
		const handleCloseDeletePopup = () => {
			setOpen(false);
		};

		const toDelete = () => {
			dispatch(
				deleteBill(id, () => {
					toast.success(t("successDeleteBill"));
					dispatch(fetchSupplierBills(idSupplier));
				})
			);
			handleCloseDeletePopup();
		};

		return (
			<Dialog
				open={open}
				onClose={handleCloseDeletePopup}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{t(isBillTable ? "individualBillDeleteMessage" : "individualDeleteMessage")}
				</DialogTitle>

				<DialogActions>
					<Button onClick={handleCloseDeletePopup} color="secondary" autoFocus>
						{t("cancel")}
					</Button>
					<Button onClick={() => toDelete(id)} type="submit" color="secondary">
						{t("confirm")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			<Grid container xs={12} direction="row">
				<Grid item container xs={12} style={{ marginBottom: "4%" }}>
					<TableFilters
						isBillTable={isBillTable}
						isPaymentTable={isPaymentTable}
						isInvoiceTable={isInvoiceTable}
						idSupplier={idSupplier}
					/>
				</Grid>
				<Grid item xs className={classes.listHeaderPadding}>
					{data.totalElements > 0 && (
						<TableHeaders
							idSupplier={idSupplier}
							isBillTable={isBillTable}
							isInvoiceTable={isInvoiceTable}
							isPaymentTable={isPaymentTable}
							isDeclarationCATable={isDeclarationCATable}
							isParentChecked={isParentChecked}
							setIsParentChecked={setIsParentChecked}
							checkedAll={checkedAll}
							setCheckedAll={setCheckedAll}
							handleCheckedAll={handleCheckedAll}
						/>
					)}
				</Grid>

				<Table
					idSupplier={idSupplier}
					data={data}
					setSelectedId={setSelectedId}
					isInvoiceTable={isInvoiceTable}
					isBillTable={isBillTable}
					setOpenDeletePopup={setOpenDeletePopup}
					isPaymentTable={isPaymentTable}
					isDeclarationCATable={isDeclarationCATable}
					companyCode={companyCode}
					checkedAll={checkedAll}
					setCheckedAll={setCheckedAll}
				/>
			</Grid>
			<ConfirmationDialog open={openDeletePopup} setOpen={setOpenDeletePopup} id={selectedId} />
		</>
	);
}
export default InvoicesTable;
