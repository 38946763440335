import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	invoiceCodeLieu: "",
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
};
const filterListDeclarationCAs = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updateDeclarationCAFilter: (state, action) => action.payload,
		initDeclaraionCAFilter: () => initialFilter,
	},
});

export const {
	updateDeclarationCAFilter,
	initDeclaraionCAFilter,
} = filterListDeclarationCAs.actions;

export default filterListDeclarationCAs.reducer;
