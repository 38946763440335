import supllierEn from "../pages/Suppliers/i18n/en";
import administrationEn from "../administration/i18n/en";

export default {
	...supllierEn,
	...administrationEn,
	Copyright: "Copyright",
	Dashboard: "Dashboard",
	"Business management": "Business management",
	Orders: "Orders",
	Invoices: "Invoices",
	Regulations: "Payments",
	Suppliers: "Suppliers",
	Rapports: "Reporting",
	Search: "Search",
	"Add your Invoice": "Add your Invoice or ",
	browse: "browse",
	"Show all": "Show all",
	id: "Identifiant",
	supplier: "Supplier",
	invoiceNo: "Invoice No",
	licencefeeNo: "Commission No",
	paymentNo: "Payment No",
	Redevances: "Commission",
	issueDate: "Issue date",
	dueDate: "Due date",
	"creation.date": "Creation date",
	"payment.date": "Payment date",
	"licencefee.date": "Licence fee date",
	"Price WT": "Pre-Tax Amount",
	"Taxe Rate": "VAT amount",
	Price: "Amount Incl Tax",
	"Purchase order": "Order",
	"Last actions": "Last actions",
	Today: "Today",
	Yesterday: "Yesterday",
	"Last week": "Last week",
	Status: "Status",
	Date: "Date",
	"My tasks": "My invoices",
	"My orders": "My orders",
	"Invoices to verify": "To verify",
	"Invoices to be checked": "To control",
	"Invoices to Payers": "Ready for payment",
	"Invoice available": "Invoice available",
	"search.invoice": "Find by invoice no",
	"search.payment": "Find by payment no",
	"search.licencefee": "Find by licence fee no/Place code",
	"Paid invoices": "Paid",
	"Unpaid invoices": "Unpaid",
	"Total amount of invoices": "Total amount of charges over the last 24 months ",
	"Payment.initiation.message":
		"The payment is initiated and it will be processed in the coming days",
	"Total amount of orders": "Total amount of orders",
	"Total VAT amount": "Total VAT",
	"Statistical overview of purchases": "Invoicing history",
	"Statistical overview of product sales": "Statistical overview of product sales",
	"Total Purchases": "Total purchase orders",
	"Total of expenses": "Charges Total",
	"edit.phonenumber": "Edit phone number",
	"edit.email": "Edit email",
	"Accounting number": "Payment No",
	Late: "Overdue",
	Administrator: "Administrator",
	"New clients": "New partner customers",
	"compagny.id": "Company ID",
	"New suppliers": "Nbr of partner customers",
	FOUNDED_RESULT: "Founded result",
	EndDate: "End Date",
	StartDate: "Start Date",
	"Add an invoice": "Add invoice",
	Upload: "Upload an invoice",
	"edit.iban": "bearbeiten IBAN",
	"upload.invoice": "Upload an invoice",
	Download: "Download",
	Show: "Show",
	Delete: "Delete",
	All: "All",
	SearchInvoice: "Find an invoice...",
	"Delivery address": "Delivery address",
	"Billing address": "Billing address",
	"Payment terms": "Payment terms",
	"Order Details": "Order Details",
	"Invoice Details": "Invoice Details",
	"Reject Invoice": "Reject",
	"Correct Extraction": "Rectify",
	Control: "Control",
	ToPay: "Payment",
	"Total HT": "Total HT",
	"VAT amount": "VAT amount",
	Discount: "Discount",
	"Total amount": "Total amount",
	"Invoice overview": "Invoice overview",
	History: "History",
	verificationDate: "Verification date",
	rejectionDate: "Rejection date",
	controlDate: "Control date",
	dateOfReceipt: "Date of receipt",
	rejectMessage: "Are you sure you want to reject this invoice?",
	controlMessage: "Are you sure you want to send this invoice to control?",
	ErrorMessageToControl: "You cannot control this invoice:",
	topayMessage: "Are you sure you want to send this invoice for payment?",
	deleteMessage: "Are you sure you want to delete the selected invoice(s)?",
	individualDeleteMessage: "Are you sure you want to delete this licence fee?",
	individualBillDeleteMessage: "Are you sure you want to delete this invoice?",
	"document.not.found": "Document not found.",
	rejectionReason: "Rejection reason",
	rejectionDetails: "Rejection details...",
	cancel: "Cancel",
	confirm: "Confirm",
	editForm: "Modification",
	countInvoices: "Total number of charges ",
	tobeprocessed: "to verify",
	None: "------------------",
	save: "Save",
	edit: "Edit",
	Actions: "Actions",
	itemCode: "Code",
	designation: "Item",
	quantity: "Qty",
	unitPrice: "Price",
	deliveryDelay: "Delivery delay",
	Commands: "BILLING LINES",
	Total: "Amount",
	VAT: "VAT Rate",
	"Pre-Tax-Amount": "Pre-Tax-Amount",
	Canceled: "Canceled",
	totalHt: "Total HT",
	vat: "VAT",
	sortBy: "Sort by",
	createdAt: "Created at",
	updatedAt: "Updated at",
	InvoicesCannotBeDeleted: "The following invoices cannot be deleted ",
	InvoicesCannotBeRelaunched: "The following invoices cannot be relaunched ",
	"Integration in progress": "Integration in progress",
	"To verify": "To verify",
	"To control": "To control",
	"To pay": "To pay",
	Payed: "Payed",
	Rejected: "Rejected",
	Exception: "Exception",
	codeArticle: "Item code",
	orderNo: "Purchase order",
	numberOfArticle: "Number of items",
	deliveryMethod: "Delivery method",
	InvoicesNotCreatedMessage: "The following invoices cannot be created :",
	invoice: "invoice",
	order: "order",
	phone: "Phone",
	training: "Training...",
	trained: "Trained",
	"Company Siret": "Company id",
	"VAT Number": "VAT number",
	"Apply Changes": "Apply changes",
	"invoice Number": "Invoice number",
	"Email de Contact": "Contact email",
	"Contact principal": "Main contact",
	"Invoice was already paid": "Invoice was already paid",
	"Goods or Services were never received": "Goods or Services were never received",
	"Supporting documents were not received with the invoice":
		"Supporting documents were not received with the invoice",
	"The purchase order number is missing, is incorrect or has been canceled":
		"The purchase order number is missing, is incorrect or has been canceled",
	"The amount being billed does not match the agreed upon amount on the contract":
		"The amount being billed does not match the agreed upon amount on the contract",
	Other: "Other",
	CREATE_ACTION: "Invoice created",
	VERIFY_ACTION: "Invoice under verification",
	REJECT_ACTION: "Invoice rejected",
	CONTROL_ACTION: "Invoice sent for control",
	END_TRAINING_ACTION: "Learning completed",
	CORRECT_ACTION: "Learning launched",
	TO_PAY_ACTION: "Payment of the invoice",
	DELET_ACTION: "Deletion of the invoice",
	TO_VALIDATE_EXTERNAL_ACTION: "Validation request",
	RELAUNCH_ACTION: "Released extraction",
	EXPORT_ACTION: "Export of the invoice",
	action: "Action",
	details: "Details",
	totalEmptyMessageError: "The total amount of the invoice is not filled",
	supplierEmptyMessageError: "The supplier of the invoice is not filled",
	"invoice.status.external_validation": "Pending approval",
	comment: "Comment",
	incohenrenceAmountsMessageError:
		"The calculation of the amounts is incorrect; The amount of VAT and the amount excluding VAT is different from the total amount",
	reason: "Reason",
	Approve: "Approve",
	email: "Email",
	approvedBy: "Approve by",
	"Nb total des factures": "Nb total des factures",
	"Facture en cours": "Invoices in process",
	"Total Orders": "Total number of orders",
	searchOrder: "Find an order",
	VALIDATED: "Valided",
	PARTIALLY_DELIVERED: "Partially Delivered",
	DELIVERED: "Deliverd",
	PARTIALLY_BILLED: "Partially Billed",
	BILLED: "Billed",
	"purchase_order.status.validated": "Valided",
	"purchase_order.status.partiallyDelivered": "Partially Delivered",
	"purchase_order.status.delivered": "Deliverd",
	"purchase_order.status.PartiallyBilled": "Partially Billed",
	"purchase_order.status.billed": "Billed",
	"order Lines": "Order lines",
	delivered: "Delivered",
	"non delivered": "Non delivered",
	orderDate: "Order date",
	invoiceAccumulation: "Invoice accumulation",
	orderType: "Order Type",
	CLOSED: "Closed",
	OPEN: "Open",
	DeliverySlip: "Delivery slip",
	currency: "Currency",
	deliveryNo: "Delivery No",
	label: "Label",
	deliveredQuantity: "Quantity delivered",
	delivery: "Delivery",
	"Delivery Details": "Delivery Details",
	"Delivery Lines": "Delivery lines",
	deliveryItemDate: "Delivery date",
	export: "Export",
	"invoice.status.exported": "Exported",
	"price TTC": "Price TTC",
	unitePriceHT: "Price HT",
	"invoice.exported": "Exported invoices",
	"invoice.unexported": "Non-exported invoices",
	invoice_charge: "Charges",
	"invoice.total_amoutn_to_verify": "Total amount of invoices to verify",
	"invoice.total_invoice": "Number of royalties",
	"add.contact": "Add a new contact",
	"invoice.duplicated": "Duplicated",
	"extraction.label.fully_extracted": "Fully extracted",
	"extraction.label.partially_extracted": "Partially extracted",
	"extraction.label.not_extracted": "Not extracted",
	"extraction.label.not_invoice": "Other than invoice",
	"this field is mandatory": "this field is mandatory",
	rejectedBy: "Rejected by",
	"invoice.detail.company_siret": "Company id",
	country: "Country",
	duplicated_invoice: "Duplicated invoice",
	"user.roles.user": "User",
	"tootlip.action.edit": "Edit",
	"tooltip.action.delete": "Delete",
	"tooltip.action.relaunch": "Relaunch",
	"tooltip.action.download": "Download",
	"tooltip.action.relaunch_extraction": "Relaunch the extraction",
	"tooltip.action.export_excel": "Export excel",
	"tooltip.action.export_entry_account_excel": "Excel export journal entry",
	"tooltip.action.export_xml": "Export xml",
	"profile.update-password.new-password-required": "New password is required",
	"profile.update-password.password-required": "Password is required",
	"profile.update-password.password-not-matched": "Passwords do not match",
	"profile.update-password.current-password": "Current Password",
	"profile.update-password.new-password": "New Password",
	"profile.update-password.confirm-password": "Confirm Password",
	"profile.update-password.reset-password": "Reset Password",
	"profile.update-password.password-reset": "Password Reset",
	"profile.update-password.password-reset-success": "Your password was changed successfully",
	"profile.update-password.password-email-send": "An email has been sent. please check your mail.",
	"profile.update-password.password-reset-done": "Done",
	"profile.update-password.update-password": "Change password",
	"profile.update-password.email": "Your current Email",
	"profile.update-password.tokenError": "Invalid Token, Password is already updated!",
	"suppliers.add.multiple": "Add Suppliers or ",
	"upload.supplier": "Upload Suppliers",
	"downloqd.template": "download template",
	"supplier.add.button": "Import suppliers",
	"supplier.export.suppliers.excel": "Export suppliers list",
	"supplier.add2.button": "Add a supplier",
	"invoice.status.processing": "Processing",
	"invoice.status.underProcess": "Being processed",
	"invoice.status.InoviceProcessed": "Processed Invoices",
	"invoice.status.InoviceUnderProcess": "Invoices under processing",
	"invoice.status.processed": "Processed",
	Administration: "Administration",
	Logout: "Logout",
	Profile: "Profile",
	"invoice.selected.title0": "All invoices are selected",
	"invoice.selected.title1": "The ",
	"invoice.selected.title2": " invoices on this page are all selected.",
	"invoice.selected.title3": "Select all invoices",
	"supplier.add.step1": "General Informations",
	"supplier.add.step2": "Contact information",
	"supplier.add.step3": "Financial Informations",
	"supplier.add.next": "Next",
	"supplier.add.previous": "Previous",
	"supplier.add.finish": "Finish",
	"supplier.add.country": "Base Country",
	"supplier.add.suppliername": "Supplier Name",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capital",
	"supplier.add.siret": "Company SIRET",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "Email",
	"supplier.add.companyName": "Company name",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civility",
	"supplier.add.phone": "Phone",
	"supplier.add.mobile": "Phone number",
	"supplier.add.vat": "VAT Number",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "Bank",
	"supplier.add.bic": "BIC",
	"supplier.name.exist.already": "Supplier's code already exist",
	"supplier.email.exist.already": "Supplier's email already exist",
	"suppliers.names.exist.already": " Already Exist",
	"suppliers.empty.field.name.row.excel": "There is a line with empty name !!",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.ribAttachment": "RIB in attachment",
	"supplier.add.acc": "Account Reference",
	"supplier.add.curr": "Default Currency",
	"supplier.add.function": "Function",
	"supplier.add.firstName": "First Name",
	"supplier.add.lastName": "Last Name",
	"supplier.add.city": "City",
	"supplier.add.street": "Street",
	"supplier.add.zipCode": "Zip Code",
	"supplier.add.confirm": "Are you sure you want to save the changes?",
	"supplier.delete.confirm": "Are you sure you want to delete the supplier?",
	"supplier.delete.success": "Supplier deleted successfully",
	"supplier.delete.error": "Error occured while deleting supplier",
	"suplier.action.agree": "Agree",
	"suplier.action.disagree": "Disagree",
	"supplier.add.aux": "Auxiliary Account",
	"supplier.add.phoneFix": "Landline",
	ApplyForApproval: "Apply for approval",
	EXPORT_EXCEL: "Export excel",
	EXPORT_EDI: "Export edi",
	EXPORT_XML: "Export xml",
	EXPORT_PDF: "Export pdf",
	EXPORT_EC: "Export JE",
	"order.absent": "Order absent",
	"amount.greate.thang.goods": "Invoice amount greater than the order",
	"delivery.absent": "Delivery absent",
	"Zip Code": "Zip Code",
	Street: "Street",
	City: "City",
	"supplier.delete.training.error.message": "No learning exists for this provider.",
	"invoice.detail.reject.success": "Invoice was rejected  successfully",
	"invoice.detail.reject.failed": "Invoice wasn't rejected",
	"invoice.detail.approved.success": "Invoice was approved successfully",
	"invoice.detail.approved.failed": "Invoice wasn't approved",
	"invoice.detail.export.success": "Invoice was exported  successfully",
	"invoice.detail.export.failed": "Invoice wasn't exported",
	"invoice.detail.message.rectify": "Are you sur you want to save this changes ?",
	"invoice.detail.message.confirmExport": "Are you sur you want to export this invoice ?",
	"token.validation.noteExist": "Change password request already sent",
	"client.delete.msg": "Are you sure you want to delete this client?",
	year: "This year",
	month: "This month",
	week: "This week",
	day: "Today",
	Exported: "Exported",
	Integrated: "Integrated",
	"use.doc.cutter": "Use automatic cutting",
	"List des clients partenaires": "List of partner customers",
	"supplier.popup.email.title": "Change of email",
	"supplier.popup.email.message": "",
	"supplier.popup.email.currentEmail": "My current email:",
	"supplier.popup.email.newEmail": "My new email",
	"supplier.popup.email.cancel": "Cancel",
	"supplier.popup.email.confirm": "Confirm",
	"supplier.popup.otp.requiredField": "OTP is required",
	"supplier.popup.email.requiredField": "Email is required",
	"supplier.popup.email.otp": "Please enter the code received by phone:",
	"supplier.popup.phone.otp": "Please enter the code received by email:",
	"supplier.popup.otp.email.success": "Your email has been modified.",
	"supplier.popup.ok": "Ok",
	"supplier.popup.phoneNumber.title": "Phone number change",
	"supplier.popup.phoneNumber.message": "",
	"supplier.popup.phoneNumber.currentPhoneNumber": "My current phone number",
	"supplier.popup.phoneNumber.newPhoneNumber": "My new phone number",
	"supplier.popup.phoneNumber.cancel": "Cancel",
	"supplier.popup.phoneNumber.confirm": "Confirm",
	"supplier.popup.otp.phone.success": "Your phone number has been modified.",
	"supplier.popup.iban.title": "My bank details",
	"supplier.popup.iban.message": "IBAN change",
	"supplier.popup.iban.currentIban": "My current IBAN:",
	"supplier.popup.iban.newDataTitle": "My new banking details:",
	"supplier.popup.iban.newIban": "IBAN",
	"supplier.popup.iban.newBic": "BIC",
	"supplier.popup.iban.cancel": "Cancel",
	"supplier.popup.iban.confirm": "Confirm",
	"supplier.popup.iban.newRib": "RIB as an attachement",
	"supplier.popup.otp.iban.pending": "Your IBAN is being validated",
	"supplier.popup.iban.upload.placeholder": "Browse a file",
	"supplier.iban.validation.message": "Application pending validation",
	"supplier.popup.iban.requiredFiel": "IBAN, BIC and RIB are required",
	"supplier.popup.phoneNumber.requiredField": "Phone number is required",
	"supplier.iban.refuse": "Refuse",
	"supplier.iban.accept": "Validate",
	"supplier.popup.iban.admin.confirm": "Are you sure you want to validate IBAN?",
	"supplier.popup.iban.admin.confirm.cancel": "Cancel",
	"supplier.popup.iban.admin.confirm.confirm": "Confirm",
	"supplier.popup.iban.admin.reject": "Rejection Reason:",
	"supplier.popup.iban.admin.reject.cancel": "Cancel",
	"supplier.popup.iban.admin.reject.confirm": "Confirm",
	"supplier.financial.bic.notvalid": "Invalid BIC format",
	"supplier.financial.iban.notvalid": "Invalid IBAN format",
	"supplier.iban.validation.ibanLabel": "IBAN: ",
	"supplier.iban.validation.bicLabel": "BIC: ",
	"supplier.iban.validation.ribLabel": "Donwload Rib",
	"supplier.popup.iban.requiredField": "IBAN, BIC and RIB are required",
	"company.thirdPartyAccount": "Third party account",
	"company.companyName": "Company name",
	libPaiement: "Description",
	typeLot: "Type",
	Montant: "Amount",
	Societe: "Company",
	Devise: "Currency",
	"supplier.add.supplierUsername": "Third party account",
	DeclarationCA: "Statement of turnover",
	"Raison sociale": "Company name",
	Month: "Month",
	Year: "Year",
	LOCPT: "Third party account",
	"Code lieu": "Place code",
	"Nom lieu": "Place name",
	EOMAC: "Machine code",
	Machine: "Machine",
	CA: "Gross TO",
	Designation: "Designation",
	"Total redevance": "Total commission",
	numFacture: "Invoice number",
	"suppliersList.headerTitle": "Sites",
	"search.declaration": "Find by place code",
	"supplier.add.codeSocietee": "Company code",
	codeSocietee_required: "Company code  is required",
	codeSocietee_not_valid: "Company code must be a number",
	"payments.total": "Payment",
	Reglement: "Payment",
	Importation: "Importation",
	Extourne: "Reversal",
	Saisie: "Entry",
};
