import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
	Grid,
	Typography,
	Tooltip,
	IconButton,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Divider,
	Switch,
	Button,
	CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "utils";
import { updateSettings } from "administration/redux/actions";
import { fetchSettingsByModuleName } from "redux/actions/users";
import useStyles from "../style";
import ConfirmDialog from "../../../components/ConfirmDialog";

const InvoiceSettings = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { errors, handleSubmit } = useForm();

	const [confirmObject, setConfirmObject] = useState({
		message: "",
		openConfirm: false,
		cancel: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [settingsToSave, setSettingsToSave] = useState(null);
	const [settings, setSettings] = useState(null);

	const [autoExport, setAutoExport] = useState({ loaded: false, changed: false });
	const [exportJE, setExportJE] = useState({ loaded: false, changed: false });
	const [exportPDF, setExportPDF] = useState({ loaded: false, changed: false });
	const [exportXML, setExportXML] = useState({ loaded: false, changed: false });
	const [exportEXCEL, setExportEXCEL] = useState({ loaded: false, changed: false });
	const [showExportPDF, setShowExportPDF] = useState({ loaded: false, changed: false });
	const [showExportXML, setShowExportXML] = useState({ loaded: false, changed: false });
	const [showExportEXCEL, setShowExportEXCEL] = useState({ loaded: false, changed: false });
	const [deleteAction, setDeleteAction] = useState({ loaded: false, changed: false });
	const [showExportExcelAccounting, setShowExportExcelAccounting] = useState({
		loaded: false,
		changed: false,
	});
	const [deleteAllAction, setDeleteAllAction] = useState({ loaded: false, changed: false });
	const [relaunchExtraction, setRelaunchExtraction] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorMyTasks, setIndicatorMyTasks] = useState({ loaded: false, changed: false });
	const [indicatorInvoicesExported, setIndicatorInvoicesExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorInvoicesNotExported, setIndicatorInvoicesNotExported] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLastActions, setIndicatorLastActions] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLoadInvoices, setIndicatorLoadInvoices] = useState({
		loaded: false,
		changed: false,
	});
	const [indicatorLast5Invoices, setIndicatorLast5Invoices] = useState({
		loaded: false,
		changed: false,
	});

	const getDataFromSettings = (data, code) => data.find((e) => e.code === code);

	const intSettings = (data) => {
		setSettings(data);
		if (getDataFromSettings(data, "AUTO_EXPORT"))
			setAutoExport({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "AUTO_EXPORT").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EC"))
			setExportJE({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EC").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_PDF"))
			setExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_PDF").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_XML"))
			setExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "EXPORT_EXCEL"))
			setExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "EXPORT_EXCEL").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_PDF"))
			setShowExportPDF({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_PDF").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_XML"))
			setShowExportXML({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_XML").value === "1",
			});

		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL"))
			setShowExportEXCEL({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION"))
			setRelaunchExtraction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_RELAUNCH_EXTRACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_ACTION"))
			setDeleteAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_DELETE_All_ACTION"))
			setDeleteAllAction({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_DELETE_All_ACTION").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_MY_TASKS"))
			setIndicatorMyTasks({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_MY_TASKS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED"))
			setIndicatorInvoicesExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED"))
			setIndicatorInvoicesNotExported({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_INVOICES_NOT_EXPORTED").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_ACTIONS"))
			setIndicatorLastActions({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_ACTIONS").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LOAD_INVOICES"))
			setIndicatorLoadInvoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LOAD_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES"))
			setIndicatorLast5Invoices({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INDICATOR_LAST_5_INVOICES").value === "1",
			});
		if (getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"))
			setShowExportExcelAccounting({
				loaded: true,
				changed: false,
				value: getDataFromSettings(data, "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").value === "1",
			});
	};

	const formatBooleanValue = (value) => (value ? "1" : "0");

	const onSubmit = (data) => {
		const _settings = JSON.parse(JSON.stringify(settings));
		_settings.find((e) => e.code === "AUTO_EXPORT").value = formatBooleanValue(autoExport.value);
		_settings.find((e) => e.code === "AUTO_EXPORT").changed = autoExport.changed;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").value = data.AMOUNT_TOLERANCE;
		_settings.find((e) => e.code === "AMOUNT_TOLERANCE").changed = true;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").value = data.MAXIMUM_SIZE;
		_settings.find((e) => e.code === "INVOICE_MAXIMUM_SIZE").changed = true;
		_settings.find((e) => e.code === "EXPORT_EC").value = formatBooleanValue(exportJE.value);
		_settings.find((e) => e.code === "EXPORT_EC").changed = exportJE.changed;
		_settings.find((e) => e.code === "EXPORT_PDF").value = formatBooleanValue(exportPDF.value);
		_settings.find((e) => e.code === "EXPORT_PDF").changed = exportPDF.changed;
		_settings.find((e) => e.code === "EXPORT_EXCEL").value = formatBooleanValue(exportEXCEL.value);
		_settings.find((e) => e.code === "EXPORT_EXCEL").changed = exportEXCEL.changed;
		_settings.find((e) => e.code === "EXPORT_XML").value = formatBooleanValue(exportXML.value);
		_settings.find((e) => e.code === "EXPORT_XML").changed = exportXML.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").value = formatBooleanValue(
			showExportPDF.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_PDF").changed = showExportPDF.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").value = formatBooleanValue(
			showExportEXCEL.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL").changed = showExportEXCEL.changed;
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").value = formatBooleanValue(
			showExportXML.value
		);
		_settings.find((e) => e.code === "INVOICE_EXPORT_XML").changed = showExportXML.changed;
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").value = formatBooleanValue(
			relaunchExtraction.value
		);
		_settings.find((e) => e.code === "INVOICE_RELAUNCH_EXTRACTION").changed =
			relaunchExtraction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").value = formatBooleanValue(
			deleteAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_ACTION").changed = deleteAction.changed;
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").value = formatBooleanValue(
			deleteAllAction.value
		);
		_settings.find((e) => e.code === "INVOICE_DELETE_All_ACTION").changed = deleteAllAction.changed;
		_settings.find(
			(e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY"
		).value = formatBooleanValue(showExportExcelAccounting.value);
		_settings.find((e) => e.code === "INVOICE_EXPORT_EXCEL_ACCOUNTING_ENTRY").changed =
			showExportExcelAccounting.changed;
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").value = formatBooleanValue(
			indicatorMyTasks.value
		);
		_settings.find((e) => e.code === "INDICATOR_MY_TASKS").changed = indicatorMyTasks.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_EXPORTED").changed =
			indicatorInvoicesExported.changed;
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").value = formatBooleanValue(
			indicatorInvoicesNotExported.value
		);
		_settings.find((e) => e.code === "INDICATOR_INVOICES_NOT_EXPORTED").changed =
			indicatorInvoicesNotExported.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").value = formatBooleanValue(
			indicatorLastActions.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_ACTIONS").changed =
			indicatorLastActions.changed;
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").value = formatBooleanValue(
			indicatorLoadInvoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LOAD_INVOICES").changed =
			indicatorLoadInvoices.changed;
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").value = formatBooleanValue(
			indicatorLast5Invoices.value
		);
		_settings.find((e) => e.code === "INDICATOR_LAST_5_INVOICES").changed =
			indicatorLast5Invoices.changed;
		const settingsUpdate = _settings.filter((setting) => setting.changed === true);
		setSettingsToSave(settingsUpdate);

		setConfirmObject({ message: t("admin.setting.message.confirm"), openConfirm: true });
	};

	const onConfirm = () => {
		if (!confirmObject.cancel) {
			setIsLoading(true);
			dispatch(
				updateSettings(
					settingsToSave,
					"invoice",
					() => {
						setConfirmObject({ message: "", openConfirm: false });
						toast.success(t("admin.config.update-settings"));
						setIsLoading(false);
						setEdit(false);
					},
					(error) => {
						setConfirmObject({ message: "", openConfirm: false });
						toast.error(error);
						setIsLoading(false);
					}
				)
			);
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			setEdit(false);
		}
	};

	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
		if (confirmObject.cancel) setEdit(true);
	};

	useEffect(() => {
		dispatch(fetchSettingsByModuleName("invoice")).then((res) => {
			if (res.data) intSettings(res.data);
		});
	}, [dispatch]);

	return (
		<>
			<Grid container item xs={12} />
			<Grid
				container
				item
				xs={12}
				className={classes.settingsContainer}
				justify="center"
				alignItems="center"
				alignContent="center"
			>
				{settings && (
					<form onSubmit={handleSubmit(onSubmit)} style={{ width: "60%" }}>
						<Grid container item xs={12} className={classes.loginContainer} spacing={6}>
							<Grid item container xs={12}>
								<Grid container item xs={6}>
									<Typography variant="h6" color="primary">
										{t("admin.settings.invoices")}
									</Typography>
								</Grid>
								<Grid item container xs={6} justify="flex-end">
									<Tooltip placement="top" title={t("tootlip.action.edit")}>
										<IconButton aria-label="edit">
											<EditIcon onClick={() => setEdit(!edit)} />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item xs={6} alignItems="center">
										<Typography variant="subtitle2" align="left" color="primary">
											{t("admin.AUTO_EXPORT")}
											{/* <p>{errors.email?.message}</p> */}
										</Typography>

										<Typography variant="body2" align="left">
											{t("admin.invoice.AUTO_EXPORT-description")}
										</Typography>
									</Grid>

									<Grid item xs={6} align="center" justify="center">
										{autoExport.loaded && (
											<Switch
												defaultChecked={autoExport.value}
												onChange={(e) => {
													setAutoExport({
														...autoExport,
														changed: true,
														value: e.target.checked,
													});
												}}
												name="AUTO_EXPORT"
												color="secondary"
												disabled={!edit}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Typography variant="subtitle2" align="left" color="primary">
										{t("admin.export-buttons")}
										<p>{errors.email?.message}</p>
									</Typography>
									<Typography variant="body2">
										{t("admin.invoice.export-buttons-description")}
									</Typography>
								</Grid>
								<Grid item container xs={12} justify="center" style={{ display: "flex" }}>
									<Grid item xs={1} />
									<Grid itm xs={5}>
										<FormControl component="fieldset" className={classes.formControl}>
											<FormGroup>
												{exportEXCEL.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																checked={exportEXCEL.value}
																onChange={(e) => {
																	setExportEXCEL({
																		...exportEXCEL,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportEXCEL"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.excel")}
													/>
												)}
												{exportXML.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																checked={exportXML.value}
																onChange={(e) => {
																	setExportXML({
																		...exportXML,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportXML"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.xml")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
									<Grid itm xs={5}>
										<FormControl required component="fieldset" className={classes.formControl}>
											<FormGroup>
												{exportXML.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																checked={exportPDF.value}
																onChange={(e) => {
																	setExportPDF({
																		...exportPDF,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportPDF"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.pdf")}
													/>
												)}
											</FormGroup>
											<FormGroup>
												{exportJE.loaded && (
													<FormControlLabel
														control={
															<Checkbox
																checked={exportJE.value}
																onChange={(e) => {
																	setExportJE({
																		...exportJE,
																		changed: true,
																		value: e.target.checked,
																	});
																}}
																name="exportJE"
																disabled={!edit}
															/>
														}
														label={t("admin.download-button.journal-entry")}
													/>
												)}
											</FormGroup>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={2}
							className={classes.actions}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid item>
								<Button
									color="primary"
									autoFocus
									onClick={() => {
										setConfirmObject({
											message: t("CancelActionMessage"),
											openConfirm: true,
											cancel: true,
										});
									}}
									disabled={!edit}
								>
									{t("cancel")}
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									type="submit"
									color="secondary"
									disabled={isLoading || !edit}
								>
									{isLoading && <CircularProgress size={14} />}
									{t("confirm")}
								</Button>
							</Grid>
						</Grid>

						<ConfirmDialog
							message={confirmObject.message}
							open={confirmObject.openConfirm}
							onConfirm={onConfirm}
							onCancel={onCancel}
						/>
					</form>
				)}
			</Grid>
		</>
	);
};

export default InvoiceSettings;
