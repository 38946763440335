import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { electronicFormatIBAN, isValidIBAN, friendlyFormatIBAN, isValidBIC } from "ibantools";
// const ibantools = require('ibantools');

// import { ReactComponent as IbanIcon } from "./iban-icon.svg";
import useStyles from "./style";

const IbanPopup = ({ open, handleConfirm, handleCancel, iban, submitButtonDisabled }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [newIban, setNewIban] = React.useState("");
	const [formattedIban, setFormattedIban] = React.useState("");
	const [newBic, setNewBic] = React.useState("");
	const [newRib, setNewRib] = React.useState();
	const [fileName, setFileName] = React.useState(null);

	const handleIbanChange = (e) => {
		setFormattedIban(friendlyFormatIBAN(e.target.value));
	};

	const handleIbanBlur = (e) => {
		const noFormatIban = electronicFormatIBAN(e.target.value);
		if (isValidIBAN(noFormatIban)) {
			setNewIban(noFormatIban);
		} else {
			setNewIban("");
			setFormattedIban("");
		}
	};

	const handleBicChange = (e) => {
		setNewBic(e.target.value);
	};

	const handleBicBlur = (e) => {
		if (isValidBIC(e.target.value)) {
			setNewBic(e.target.value);
		} else {
			setNewBic("");
		}
	};

	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleCancel}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" className={classes.popupTitle}>
				{t("supplier.popup.iban.title")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.iban.message")}
				</DialogContentText>
				<DialogContentText className={classes.text}>{`${t("supplier.popup.iban.currentIban")} ${
					iban ?? ""
				}`}</DialogContentText>
				<DialogContentText className={`${classes.popupTitle} ${classes.popupTitleLeft} `}>
					{t("supplier.popup.iban.newDataTitle")}
				</DialogContentText>
				<DialogContentText className={classes.fieldLabel}>
					{t("supplier.popup.iban.newIban")}
				</DialogContentText>
				<TextField
					autoFocus
					id="iban"
					type="text"
					fullWidth
					placeholder="FRXX XXXX XXXX XXXX XXXX XXX"
					className={classes.inputFieldContainer}
					onChange={handleIbanChange}
					onBlur={handleIbanBlur}
					value={formattedIban}
					InputProps={{
						classes: {
							input: classes.inputField,
						},
					}}
				/>
				<DialogContentText className={classes.fieldLabel}>
					{t("supplier.popup.iban.newBic")}
				</DialogContentText>
				<TextField
					id="bic"
					type="text"
					fullWidth
					placeholder="XXXXXX"
					className={classes.inputFieldContainer}
					onChange={handleBicChange}
					onBlur={handleBicBlur}
					value={newBic}
					InputProps={{
						classes: {
							input: classes.inputField,
						},
					}}
				/>
				<DialogContentText className={classes.fieldLabel}>
					{t("supplier.popup.iban.newRib")}
				</DialogContentText>
				<div className={classes.ribInput}>
					<label htmlFor="file" className={clsx(classes.ribAttachment)}>
						<Grid container>
							<Grid item xs={10} container alignContent="center">
								<Typography variant="p" color="textSecondary">
									{t("supplier.popup.iban.upload.placeholder")}
								</Typography>
							</Grid>
							<Grid item xs={2} container justify="flex-end">
								<AttachFileIcon className={classes.fileAttachmentIcon} />
							</Grid>
						</Grid>
					</label>
					<Typography variant="p" color="textSecondary" className={clsx(classes.ribFileName)}>
						{fileName}
					</Typography>
					<Input
						type="file"
						id="file"
						inputProps={{ accept: "application/pdf", for: "file-upload" }}
						onChange={(e) => {
							setFileName(e.target.files[0].name);
							setNewRib(e.target.files[0]);
						}}
						name="file"
						// className={clsx(classes.ribAttachment)}
						fullWidth
					/>
				</div>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button onClick={handleCancel} variant="outlined" color="secondary">
					{t("supplier.popup.iban.cancel")}
				</Button>
				<Button
					onClick={(e) => {
						e.preventDefault();
						handleConfirm(newIban, newBic, newRib);
					}}
					variant="contained"
					color="secondary"
					disabled={submitButtonDisabled}
				>
					{t("supplier.popup.iban.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IbanPopup;
