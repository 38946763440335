import React from "react";
// import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import TuneIcon from "@material-ui/icons/Tune";
// import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { List as SuppliersList } from "../pages/Suppliers/List";
import { UsersHome } from "../administration/users";
import Roles from "../redux/constants/roles";
// import Messages from "../pages/messages";

export default [
	{
		name: "Clients partenaires",
		path: "/",
		component: () => <SuppliersList />,
		icon: <PeopleIcon />,
		hasAccess: Roles.user,
	},
	{
		name: "Administration",
		path: "/administration",
		component: () => <UsersHome />,
		icon: <TuneIcon />,
		hasAccess: Roles.admin,
	},
	{
		name: "supplier",
		path: "/",
		icon: <PeopleIcon />,
		component: () => <SuppliersList isSupplier />,

		hasAccess: Roles.supplier,
	},
	/*
	{
		name: "contact",
		path: "/contact",
		component: () => <Messages />,
		icon: <ForumOutlinedIcon />,
		hasAccess: Roles.supplier,
	},
	*/
];
