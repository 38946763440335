/* eslint-disable */
import React from "react";
import { Grid } from "@material-ui/core";
import SubPageContainer from "../../components/SubPageContainer";
import InvoiceSettings from "./InvoiceSettings";

const Settings = () => {
	return (
		<Grid item xs={12} sm={12}>
			<SubPageContainer>
				<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
					<InvoiceSettings />
				</Grid>
			</SubPageContainer>
		</Grid>
	);
};

export default Settings;
