import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	headerPath: {
		marginTop: "15px",
	},
	headerBreadCumb: {
		marginBottom: "15px",
	},
	root: {
		width: "100%",
	},
	backButton: {
		marginRight: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.contrastText,
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	formContainer: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: "3px",
	},
	gridRow: {
		marginTop: "5px",
		marginBottom: "5px",
		maxHeight: "100px",
	},
	validation_error: {
		color: theme.custom.color.errorRed,
	},
	ImageProfil: {
		textAlign: "center",
	},
	btn: {
		width: "160px",
	},
	ribAttachment: {
		border: "1px solid #ccc",
		display: "inline-block",
		padding: "6px 12px",
		cursor: "pointer",
		width: "100%",
		borderRadius: "5px",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
	},
	ribInput: {
		"& input[type='file']": {
			display: "none",
		},
	},
	fileAttachmentIcon: {
		color: theme.palette.success,
	},
}));
