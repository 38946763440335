import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';



function ConfirmDialog(props) {
    const { message, open, onConfirm, onCancel } = props;
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {t('suplier.action.disagree')}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {t('suplier.action.agree')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;