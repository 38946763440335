/* eslint-disable */
import React, { Fragment } from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { get, defaultTo } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

export const DetailsSupplierContact = ({ contact, supplier }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [open, setOpen] = React.useState({
		name: false,
		phone: false,
		email: false,
		accountManager: false,
	});
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};

	const accountManager = `${get(supplier, "accountManager.firstName", "")} ${get(
		supplier,
		"accountManager.lastName",
		""
	)}`;

	const fullname = "".concat(
		t(get(contact, "civility")),
		" ",
		get(contact, "firstName") ?? "",
		" ",
		get(contact, "lastName") ?? ""
	);

	const handleOnClick = () => {
		history.push(`/client/${supplier.id}`);
	};

	return (
		<Grid container xs={12} direction="column" spacing={4}>
			{contact ? (
				<>
					<Grid container item xs={12} spacing={4}>
						<Grid item xs={6}>
							<Typography variant="caption" color="black">
								{t("name")}
								<br />
								<Tooltip
									open={open.name}
									onClose={() => handleClose("name")}
									onOpen={fullname?.length > 32 && (() => handleOpen("name"))}
									title={defaultTo(fullname, "\xa0")}
								>
									<Typography
										variant="subtitle2"
										style={{ overflow: "hidden", textOverflow: "ellipsis" }}
										align="left"
									>
										{defaultTo(fullname, "\xa0")}
										&nbsp;
									</Typography>
								</Tooltip>
							</Typography>

							<Divider />
						</Grid>
						<Grid item xs={6}>
							<Typography variant="caption" color="black">
								{t("phone")}
								<br />
								<Tooltip
									open={open.phone}
									onClose={() => handleClose("phone")}
									onOpen={get(contact, "phone")?.length > 32 && (() => handleOpen("phone"))}
									title={get(contact, "phone")}
								>
									<Typography
										variant="subtitle2"
										style={{ overflow: "hidden", textOverflow: "ellipsis" }}
										align="left"
									>
										{defaultTo(get(contact, "phone"), "\xa0")}&nbsp;
									</Typography>
								</Tooltip>
							</Typography>
							<Divider />
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={4}>
						<Grid item xs={6}>
							<Typography variant="caption" color="black">
								{t("email")}
								<br />
								<Tooltip
									open={open.email}
									onClose={() => handleClose("email")}
									onOpen={get(contact, "email")?.length > 32 && (() => handleOpen("email"))}
									title={get(contact, "email")}
								>
									<Typography
										variant="subtitle2"
										style={{ overflow: "hidden", textOverflow: "ellipsis" }}
										align="left"
									>
										{defaultTo(get(contact, "email"), "\xa0")}&nbsp;
									</Typography>
								</Tooltip>
							</Typography>
							<Divider />
						</Grid>
						<Grid item xs={6}>
							<Typography variant="caption" color="black">
								{t("admin reference")}
								<br />
								<Tooltip
									open={open.accountManager}
									onClose={() => handleClose("accountManager")}
									onOpen={accountManager?.length > 32 && (() => handleOpen("accountManager"))}
									title={accountManager}
								>
									<Typography
										variant="subtitle2"
										style={{ overflow: "hidden", textOverflow: "ellipsis" }}
										align="left"
									>
										{defaultTo(defaultTo(accountManager, "\xa0"))}
										&nbsp;
									</Typography>
								</Tooltip>
							</Typography>
							<Divider />
						</Grid>
					</Grid>
				</>
			) : (
				<Grid item>
					<Typography
						variant="body2"
						color="black"
						style={{ display: "flex", justifyContent: "center" }}
					>
						{/* {t("noPrincipalContact")} */}
						<Button
							style={{ padding: "8px 40px 8px 40px" }}
							variant="contained"
							color="secondary"
							onClick={() => handleOnClick()}
						>
							{" "}
							{t("add.contact")}
						</Button>
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default DetailsSupplierContact;
