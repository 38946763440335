import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import useStyles from "../style";
import {StringComponent} from "../StringComponent";

export const FinancialComponent = (props) => {
    const classes = useStyles();


    const onchangeBank = (value) => {
        props.handleSupplier({ ...props.supplier, bank: value })
    }
    const onchangeBic = (value) => {
        props.handleSupplier({ ...props.supplier, bic: value })
    }
    const onchangeIban = (value) => {
        props.handleSupplier({ ...props.supplier, iban: value })
    }
    const onchangeRib = (value) => {
        props.handleSupplier({ ...props.supplier, rib: value })
    }

    return (
        <Card className={classes.root}>
            <Grid container item xs={12} spacing={4}>
                <Grid container item xs={12} className={classes.supplementInfosHeader}>
                    <Typography color="primary" variant="h5" align="left">Informations financières</Typography>
                </Grid>
                <Grid container item xs={12} spacing={4}>
                    <Grid container item xs={12} spacing={4}>
                        <Grid item xs={6}>
                            <StringComponent label="Banque" changeValue={onchangeBank} />
                        </Grid>
                        <Grid item xs={6}>
                            <StringComponent label="IBAN" changeValue={onchangeIban} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={4}>
                        <Grid item xs={6}>
                            <StringComponent label="RIB" changeValue={onchangeRib} />
                        </Grid>
                        <Grid item xs={6}>
                            <StringComponent label="BIC" changeValue={onchangeBic} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default FinancialComponent;
