import React from "react";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhoneIcon } from "./phone-icon.svg";

import useStyles from "./style";

const PhoneNumberPopup = ({
	open,
	handleConfirm,
	handleCancel,
	phoneNumber,
	submitButtonDisabled,
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [newPhoneNumber, setNewPhoneNumber] = React.useState("");
	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleCancel}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" className={classes.popupTitle}>
				<div className={classes.emailContainer}>
					<PhoneIcon />
				</div>
				{t("supplier.popup.phoneNumber.title")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.phoneNumber.message")}
				</DialogContentText>
				<DialogContentText className={classes.text}>{`${t(
					"supplier.popup.phoneNumber.currentPhoneNumber"
				)} ${phoneNumber ?? ""}`}</DialogContentText>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.phoneNumber.newPhoneNumber")}
				</DialogContentText>

				<MuiPhoneNumber
					defaultCountry="fr"
					autoFocus
					fullWidth
					className={classes.inputField}
					onChange={(value) => {
						setNewPhoneNumber(value);
					}}
					InputProps={{
						classes: {
							input: classes.inputField,
						},
					}}
				/>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button onClick={handleCancel} variant="outlined" color="secondary">
					{t("supplier.popup.phoneNumber.cancel")}
				</Button>
				<Button
					onClick={(e) => {
						e.preventDefault();
						handleConfirm(newPhoneNumber);
					}}
					variant="contained"
					color="secondary"
					disabled={submitButtonDisabled}
				>
					{t("supplier.popup.phoneNumber.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PhoneNumberPopup;
