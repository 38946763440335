// import { URL_BASE_USERS } from "administration/redux/constants/endpoint";

export const URL_BASE_INVOICE = "/invoice";
export const URL_BASE_EXPORT = "/export";
export const URL_BASE_ORDER = "/order";
export const URL_BASE_CLIENT = "/order/client";
export const URL_INVOICE_TOTAL_AMOUNT = `${URL_BASE_INVOICE}/totalAmounts`;
export const URL_INVOICE_TOTAL_VAT_AMOUNT = `${URL_BASE_INVOICE}/totalvatAmounts`;
export const URL_INVOICE = `${URL_BASE_INVOICE}/`;
export const URL_CREATE_INVOICE = `${URL_BASE_INVOICE}/upload`;
export const URL_STATUS = `${URL_BASE_INVOICE}/status`;
export const URL_SUPPLIER = `${URL_BASE_INVOICE}/supplier`;
export const URL_SUPPLIER_CODE_CHECK = `${URL_BASE_INVOICE}/supplierNameCheck`;
export const URL_SUPPLIER_EMAIL_CHECK = `${URL_BASE_INVOICE}/userEmailCheck`;
export const URL_COUNT_SUPPLIER = `${URL_BASE_INVOICE}/supplier/count`;
export const URL_COUNT_CLIENTS = `${URL_BASE_CLIENT}/count`;
export const URL_COUNT_ORDERS = `${URL_BASE_ORDER}/count`;
export const URL_PAYMENT_TERM = `${URL_BASE_INVOICE}/paymentTerm`;
export const URL_DELIVERY_METHOD = `${URL_BASE_INVOICE}/deliveryMethod`;
export const URL_MOTIF = `${URL_BASE_INVOICE}/rejectionMotif`;
export const URL_CREATE_INVOICE_REJECTION = `/reject`;
export const URL_VERIFY_INVOICE = `/verify`;
export const URL_LAST_ACTION_INVOICE = `${URL_BASE_INVOICE}/lastInvoices`;
export const URL_SUPPLIER_INVOICES = `${URL_BASE_INVOICE}/bySupplier`;
export const URL_SUPPLIER_INVOICES_OUTSTANDING = `${URL_BASE_INVOICE}/outstandingInvoice/bySupplier`;
export const URL_COUNTRY = `${URL_BASE_INVOICE}/supplier/address/country`;
export const URL_INVOICE_INDICATORS = `${URL_BASE_INVOICE}/indicators`;
export const URL_INVOICE_TOTAL_OVERDUE_AMOUNT = `${URL_BASE_INVOICE}/overdue`;
export const URL_ORDERS = `${URL_BASE_INVOICE}/po/orders`;
export const URL_TO_PAY_INVOICE = `${URL_BASE_INVOICE}/topay`;
export const URL_INVOICE_HISTORY = `/history`;
export const URL_CREATE_SUPPLIER = `${URL_BASE_INVOICE}/supplier`;
export const URL_LEGAL_FORM = `${URL_BASE_INVOICE}/legalform`;
export const URL_RELAUNCH_EXTRACTION = `${URL_BASE_INVOICE}/relaunch`;
export const URL_CURRENT_USER = `/current-user`;
export const URL_UPDATE_PASSWORD = `/admin/user/userPassword`;
export const URL_CREATE_MULTI_SUPPLIER = `${URL_BASE_INVOICE}/suppliers/upload`;
export const URL_LIST_SETTING_WITH_CODE = `/admin/setting/list`;
export const URL_LIST_SETTING = `/admin/setting/`;
export const URL_LIST_SETTING_MODULE = `/admin/setting/list/module/`;
export const URL_EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL = `${URL_BASE_INVOICE}/export/excel/entryaccount`;
export const URL_DOCUMENTS_INVOICES = `${URL_BASE_INVOICE}/documents`;
export const URL_INVOICE_XML = `/export`;
export const URL_CREATE_ORDER = `${URL_BASE_ORDER}/upload`;
export const URL_ORDER_TOTAL_AMOUNT = `${URL_BASE_ORDER}/totalAmounts`;
export const URL_DELIVERY_METHOD_ORDER = `${URL_BASE_ORDER}/deliveryMethod`;
export const URL_PAYMENT_TERM_ORDER = `${URL_BASE_ORDER}/paymentTerm`;
export const URL_MOTIF_ORDER = `${URL_BASE_ORDER}/rejectionMotif`;
export const URL_ORDER_INDICATORS = `${URL_BASE_ORDER}/indicators`;
export const URL_LAST_ACTION_ORDER = `${URL_BASE_ORDER}/lastOrders`;
export const URL_ORDER_TOTAL_VAT_AMOUNT = `${URL_BASE_ORDER}/totalvatAmounts`;
export const URL_ORDERS_BY_CLIENT = `${URL_BASE_CLIENT}/orders`;
export const URL_ORDER_TOTAL_OVERDUE_AMOUNT = `${URL_BASE_ORDER}/overdue`;
export const URL_CLIENT_CODE_CHECK = `${URL_BASE_CLIENT}/check`;
export const URL_CREATE_CLIENT = `${URL_BASE_CLIENT}/add`;
export const URL_UPDATE_CLIENT = `${URL_BASE_CLIENT}/update`;
export const URL_CREATE_MULTI_CLIENTS = `${URL_BASE_CLIENT}/upload`;
export const URL_ADD_CHANGE_EMAIL_REQUEST = `${URL_BASE_INVOICE}/supplier/changeEmail`;
export const URL_VALIDATE_OTP = `${URL_BASE_INVOICE}/supplier/validateOtp`;
export const URL_ADD_CHANGE_PHONE_NUMBER_REQUEST = `${URL_BASE_INVOICE}/supplier/changePhone`;
export const URL_ADD_CHANGE_IBAN_REQUEST = `${URL_BASE_INVOICE}/supplier/changeIban`;
export const URL_VALID_IBAN = `${URL_BASE_INVOICE}/supplier/validIban`;
export const URL_CANCEL_OTP_VALIDATION = `${URL_BASE_INVOICE}/supplier/cancelOtpValidation`;
export const URL_ACCEPT_CHANGE_IBAN_REQUEST = `${URL_BASE_INVOICE}/supplier/acceptIbanChange`;
export const URL_REFUSE_CHANGE_IBAN_REQUEST = `${URL_BASE_INVOICE}/supplier/refuseIbanChange`;
export const URL_DOWNLOAD_CHANGE_REQUEST_RIB = `${URL_BASE_INVOICE}/supplier/downloadRequestRib`;
