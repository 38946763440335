import {
	FETCH_CLIENTS,
	FETCH_COUNT_CLIENTS,
	ORDER_TOTAL_OVERDUE_AMOUNT_BY_CLIENT,
	FETCH_ORDERS_BY_CLIENT,
	FETCH_CLIENT,
	ORDER_TOTAL_AMOUNT_BY_CLIENT,
	ORDER_VAT_AMOUNT_BY_CLIENT,
	CHECK_CLIENTS_CODE,
	CREATE_MULTI_CLIENTS,
	FETCH_CLIENT_IMPORT_SAMPLE,
} from "../../constants/index";
import {
	URL_BASE_CLIENT,
	URL_BASE_ORDER,
	URL_COUNT_CLIENTS,
	URL_ORDER_TOTAL_OVERDUE_AMOUNT,
	URL_ORDERS_BY_CLIENT,
	URL_CLIENT_CODE_CHECK,
	URL_CREATE_CLIENT,
	URL_CREATE_MULTI_CLIENTS,
	URL_UPDATE_CLIENT,
} from "../../constants/endpoint";

// eslint-disable-next-line import/prefer-default-export
export const fetchClients = (filter = {}) => {
	const { invoiceNoOrSupplierCode, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_CLIENTS,
		request: {
			url: `${URL_BASE_CLIENT}/list`,
			params: {
				invoiceNoOrSupplierCode,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const deleteClient = (id, onSuccess, onError) => ({
	type: FETCH_CLIENTS,
	request: {
		url: `${URL_BASE_CLIENT}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchCountClients = () => ({
	type: `${FETCH_COUNT_CLIENTS}`,
	request: { url: `${URL_COUNT_CLIENTS}` },
});

export const ordersTotalAmountsOverDueByClient = () => ({
	type: `${ORDER_TOTAL_OVERDUE_AMOUNT_BY_CLIENT}`,
	request: {
		url: `${URL_ORDER_TOTAL_OVERDUE_AMOUNT}`,
	},
});

export const fetchOrdersByClient = (idClient) => ({
	type: `${FETCH_ORDERS_BY_CLIENT}`,
	request: { url: `${URL_ORDERS_BY_CLIENT}/${idClient}` },
});

export const fetchClient = (id) => ({
	type: `${FETCH_CLIENT}`,
	request: {
		url: `${URL_BASE_CLIENT}/${id}`,
	},
});

export const ordersTotalAmountsByClient = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${ORDER_TOTAL_AMOUNT_BY_CLIENT}${_status}`,
		request: {
			url: `${URL_BASE_ORDER}/${id}/totalAmounts`,
			params: { status },
		},
	};
};

export const ordersVATAmountsByClient = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${ORDER_VAT_AMOUNT_BY_CLIENT}${_status}`,
		request: {
			url: `${URL_BASE_ORDER}/${id}/totalVAT`,
			params: { status },
		},
	};
};

export const checkClientCode = (code, onSuccess, onError) => ({
	type: CHECK_CLIENTS_CODE,
	request: {
		url: `${URL_CLIENT_CODE_CHECK}/${code}`,
		method: "get",
	},
	meta: {
		onSuccess: (rep) => {
			if (onSuccess) onSuccess(rep);
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const createClient = (client, file) => {
	const data = new FormData();
	data.append("file", file);
	data.append("client", JSON.stringify(client));
	return {
		type: "CREATE_CLIENT",
		request: {
			url: URL_CREATE_CLIENT,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};
export const modifyClient = (client, file) => {
	const data = new FormData();
	data.append("file", file);
	data.append("client", JSON.stringify(client));
	return {
		type: "UPDATE_CLIENT",
		request: {
			url: URL_UPDATE_CLIENT,
			method: "put",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const createMultiClients = (file) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: CREATE_MULTI_CLIENTS,
		request: {
			url: URL_CREATE_MULTI_CLIENTS,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const downloadImportClientSample = () => ({
	type: FETCH_CLIENT_IMPORT_SAMPLE,
	request: {
		url: `${URL_BASE_CLIENT}/sample`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "clients.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});
