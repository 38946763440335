import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
	app: {
		height: "100vh",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: "1 0 auto",
		textAlign: "center",
	},

	form: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "70%",
		flex: "1 1 auto",
	},

	formWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "50%",
		flex: "1 1 auto",
		margin: "0 0",
	},

	formError: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "50%",
		flex: "1 1 auto",
	},
	input: {
		marginButtom: "50px",
	},
}));
