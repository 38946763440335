/* eslint-disable */
import React from "react";
import { Grid, TextField, Button, Typography, Input } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import SubPagePanel from "administration/commonComponents/SubPagePanel";
import { useDispatch, useSelector } from "react-redux";
import { Query } from "@redux-requests/react";
import CircularLoader from "components/Loaders/CircularLoader";
import { FETCH_MESSAGES_HISTORY } from "redux/constants";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Message from "./components/Message";
import { fetchMessagesHistoryWithClient, postReply } from "../../redux/actions/invoices";
import useStyles from "./style";
import { toast } from "react-toastify";

// function a11yProps(index) {
// 	return {
// 		id: `simple-tab-${index}`,
// 		"aria-controls": `simple-tabpanel-${index}`,
// 	};
// }

export default () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [attachmentList, setAttachmentList] = React.useState([]);
	const [fileNameList, setFileNameList] = React.useState([]);
	const { idSupplier } = useParams();
	const [reply, setReply] = React.useState("");
	const [replyButtonDisabled, setReplyButtonDisabled] = React.useState(false);
	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const attachmentMaxSize = process.env.REACT_APP_ATTACHMENT_MAX_SIZE;

	React.useEffect(() => {
		const tmpSupplierId = idSupplier || get(currentUser, "supplierId");
		if (tmpSupplierId) dispatch(fetchMessagesHistoryWithClient(tmpSupplierId));
	}, [idSupplier, currentUser]);

	const handleReplyChange = (e) => {
		setReply(e.target.value);
	};

	const handleSendReply = () => {
		const replyLength = reply.trim().length;
		if (replyLength === 0 && attachmentList.length === 0) return;
		if (attachmentList.length > 0 && replyLength === 0) {
			toast.error(t("missing.comment"));
			return;
		}
		if ((attachmentList.length > 0 && replyLength > 0) || replyLength > 0) {
			setReplyButtonDisabled(true);
			const tmpSupplierId = idSupplier || get(currentUser, "supplierId");
			dispatch(postReply(reply, attachmentList, tmpSupplierId)).then(() => {
				dispatch(fetchMessagesHistoryWithClient(tmpSupplierId));
				setReply("");
				setReplyButtonDisabled(false);
				setFileNameList([]);
				setAttachmentList([]);
			});
		}
	};

	return (
		<>
			<Grid container xs={12} className={classes.root}>
				{/* <Typography variant="h6" color="secondary" className={classes.title}>
					{t("messagerie interne")}
				</Typography> */}
				<Grid container xs={12} justify="center">
					{/* <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
						<Tab label={t("History")} {...a11yProps(0)} />
					</Tabs> */}
				</Grid>
				<Grid container justify="flex-end" className={classes.replyContainer}>
					<TextField
						id="filled-multiline-static"
						label={t("Ajouter un commentaire")}
						onChange={handleReplyChange}
						value={reply}
						multiline
						rows={4}
						fullWidth
						variant="outlined"

						// variant="filled"
					/>
				</Grid>

				<Grid xs={6} className={classes.replyContainer}>
					<div className={classes.ribInput}>
						<label htmlFor="file" className={clsx(classes.ribAttachment)}>
							<Grid container>
								<Grid item xs={10} container alignContent="center">
									<Typography variant="p" color="textSecondary">
										{t("supplier.popup.iban.upload.placeholder")}
									</Typography>
								</Grid>
								<Grid item xs={2} container justify="flex-end">
									<AttachFileIcon className={classes.fileAttachmentIcon} />
								</Grid>
							</Grid>
						</label>
						<Typography variant="p" color="textSecondary" className={clsx(classes.ribFileName)}>
							{fileNameList.map((name) => (
								<div>{name}</div>
							))}
						</Typography>

						<Input
							multiple
							type="file"
							id="file"
							inputProps={{
								accept: "application/pdf, image/png, image/jpg, image/gif ",
								for: "file-upload",
							}}
							onChange={(e) => {
								setFileNameList(
									e.target.files[0]?.size > attachmentMaxSize
										? [...fileNameList]
										: [...fileNameList, e.target.files[0]?.name]
								);
								setAttachmentList(
									e.target.files[0]?.size > attachmentMaxSize
										? toast.error(t("Big.file.message"))
										: [...attachmentList, e.target.files[0]]
								);
							}}
							name="file"
							// className={clsx(classes.ribAttachment)}
							fullWidth
						/>
					</div>
				</Grid>
				<Grid className={classes.replyContainer}>
					<Button
						color="secondary"
						variant="contained"
						className={classes.replyBtn}
						onClick={handleSendReply}
						disabled={replyButtonDisabled}
					>
						{replyButtonDisabled && <CircularLoader />}
						{t("post comment")}
					</Button>
				</Grid>
				<Grid container xs={12} justify="center">
					{/* <SubPagePanel value={value} index={0} style={{ width: "100%" }}> */}
					<Query type={FETCH_MESSAGES_HISTORY} loadingComponent={CircularLoader}>
						{({ data }) =>
							data.map((row, index) => <Message {...row} index={index} currentUser={currentUser} />)
						}
					</Query>
					{/* </SubPagePanel> */}
				</Grid>
			</Grid>
		</>
	);
};
