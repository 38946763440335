import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import MuiPhoneNumber from 'material-ui-phone-number';
import useStyles from "./style";
import { REGEX } from "../../../utils/index";


export const PhoneComponent = ({ label, value, changeValue }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [valueInput] = React.useState(value);
    const [isValid, setIsValid] = React.useState(true);


    const isEmptyOrNull = (v) => v === null || v === "";

    const validateInput = () => {
        setIsValid(REGEX.TEL.test(valueInput));

    };

    return (
        <Grid container xs={12} direction="column">
            <Grid item>
                <Typography
                    variant="subtitle2"
                    align="left"
                    color="primary"
                >
                    {t(label)}
                </Typography>
            </Grid>
            <Grid item>
                <MuiPhoneNumber
                    error={!isValid}
                    onBlur={validateInput}
                    defaultCountry="fr"
                    placeholder="Phone"
                    className={clsx(
                        "classes.resize",
                        isEmptyOrNull(value) && classes.emptyInput
                    )}
                    fullWidth
                    onChange={(e) => changeValue && changeValue(e)}
                    value={value}
                />
            </Grid>
        </Grid>
    );
};

export default PhoneComponent;
