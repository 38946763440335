import React from "react";
import Grid from "@material-ui/core/Grid";
import {SupplementInformations} from "./SupplementInformations";
import {LegalInformations} from "./LegalInformations";

export const PersonnalInformations = (props) =>  <Grid container xs={12}>
            <Grid container item xs={12} style={{ paddingBottom: "64px" }}>
                <LegalInformations supplier={props.supplier} handleSupplier={props.handleSupplier} setLogo={props.setLogo} />
            </Grid>
            <Grid container item xs={12}>
                <SupplementInformations supplier={props.supplier} handleSupplier={props.handleSupplier} />
            </Grid>
        </Grid>

export default PersonnalInformations;
