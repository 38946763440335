import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Input } from "@material-ui/core";
import useStyles from "./style";
import { REGEX } from "../../../utils/index";

export const isEmptyOrNull = (v) => v === null || v.trim() === "";

export const validateSiret = (siret) => {

    if (isEmptyOrNull(siret)) {
        return true;
    }
    siret = siret.replaceAll(" ", "")
    if (siret.length !== 14) {
        return false;
    }
    const arrayDigits = siret.toString(10).split('').map(Number);
    let somme = 0;
    somme = arrayDigits.reduce((_acc, number, index) => index % 2 === 1 ? number * 1 : (number * 2) % 9);
    return somme % 10 === 0;
}

export const StringComponent = ({ label, value, type = "text", required = false, placeholder, changeValue, errorMessage = "incorrect" }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [valueInput, setValueInput] = React.useState(value);
    const [isValid, setIsValid] = React.useState(true);
    
    const validateInput = () => {
        if (type === "email") {
            setIsValid(REGEX.EMAIL.test(valueInput));
        }
        if (type === "linkedin") {
            setIsValid(REGEX.LINKEDIN.test(valueInput));
        }
        if (type === "name") {
            setIsValid(REGEX.NAME.test(valueInput));
        }
        if (type === "function") {
            setIsValid(REGEX.FUNCTION.test(valueInput));
        }
        if (type === "siret") {
            setIsValid(validateSiret(valueInput));
        }
        if (type === "amount") {
            setIsValid(REGEX.AMOUNT.test(valueInput));
        }
    }
    return (
        <Grid container xs={12} direction="column">
            <Grid item>
                <Typography
                    variant="subtitle2"
                    align="left"
                    color="primary"
                >
                    {t(label)}{required && <span style={{ color: "red" }}>*</span>}
                </Typography>
            </Grid>
            <Grid item>
                <Input
                    onBlur={(e) => validateInput(e.target.value)}
                    placeholder={placeholder}
                    required={required}
                    type={type}
                    error={!isValid}
                    className={clsx(
                        "classes.resize",
                        isEmptyOrNull(value) && classes.emptyInput,
                    )}
                    fullWidth
                    onChange={(e) => { setValueInput(e.target.value); if(changeValue) changeValue(e.target.value) }}
                    value={valueInput}
                    helperText={errorMessage}

                />
            </Grid>
        </Grid>
    );
};

export default StringComponent;
