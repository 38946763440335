import supllierDe from "../pages/Suppliers/i18n/de";
import administrationDe from "../administration/i18n/de";

export default {
	...supllierDe,
	...administrationDe,
	Copyright: "Alle Rechte vorbehalten",
	Dashboard: "Dashboard",
	Unternehmensführung: "Kommerzielle Führung",
	Orders: "Bestellungen",
	Invoices: "Rechnungen",
	Regulations: "Sonstige Zahlungen",
	Suppliers: "Lieferanten",
	Rapports: "Berichten",
	Search: "Suchen",
	"Rechnung einfügen": "Rechnungen stellen oder ",
	browse: "durchsuchen",
	"Show all": "Alles anzeigen",
	id: "Identifizierung",
	supplier: "Kunde",
	invoiceNo: "Rechnungsnummer",
	licencefeeNo: "Lizenzgebühr No",
	paymentNo: "Abrechnungsnummer",
	Redevances: "Umsatzbeteiligung",
	issueDate: "Erstellungsdatum",
	dueDate: "Fristdatum",
	"creation.date": "Erstellungsdatum",
	"payment.date": "Zahlungsdatum",
	"licencefee.date": "Lizenzgebühr Datum",
	"Price WT": "Bruttobetrag",
	"Taxe Rate": "Mw-St.",
	Price: "Gesamtbetrag",
	"Purchase order": "Bestellschein",
	"Last actions": "Letzte Handlungen",
	Today: "Heute",
	Yesterday: "Gestern",
	"Last week": "Letzte Woche",
	Status: "Status",
	Date: "Datum",
	"My tasks": "Meine Aufträge",
	"Invoices to verify": "Rechnungen zu prüfen",
	"search.invoice": "Rechnung finden",
	"search.payment": "Eine Zahlung finden",
	"search.licencefee": "finden durch Umsatzbeteiligung/Standort Code",
	"To verify": "Zu prüfen",
	"Invoices to be checked": "Rechnungen zu kontrollieren",
	"Invoices to Payers": "Rechnungen zu bezahlen",
	"Invoice available": "Verfügbare Rechnung",
	"Paid invoices": "Bezahlte Rechnungen",
	"Unpaid invoices": "Unbezahlte Rechnungen",
	"Total amount of invoices": "Gesamtbetrag der Rechnungen in den letzten 24 Monaten ",
	"Payment.initiation.message":
		"Die Zahlung wird eingeleitet und in den kommenden Tagen bearbeitet",
	"Total amount of orders": "Gesamtanzahl der Bestellungen",
	"Total VAT amount": "Gesamtbetrag der Umsatzsteuer",
	"Statistical overview of purchases": "Statistische Übersicht der Einkäufe",
	"Statistical overview of product sales": "Statistische Übersicht der Produktverkäufe",
	"Total Purchases": "Gesamt der Einkäufe",
	"Total of expenses": "Gesambetrag Rechnungen",
	"edit.phonenumber": "Telefon bearbeiten",
	"edit.email": "E-Mail bearbeiten",
	"Accounting number": "Zahlungsnummer",
	"compagny.id": "Firmen-ID",
	Late: "Überfälllig",
	Administrator: "Administrator",
	"New suppliers": "Nbr der Partnerkunden",
	"New clients": "Neuer Kunde",
	FOUNDED_RESULT: "Résultat trouvé",
	EndDate: "Enddatum",
	StartDate: "Startdatum",
	"Add an invoice": "Rechnung einfügen",
	Upload: "Rechnung hochladen",
	"edit.iban": "IBAN bearbeiten",
	"upload.invoice": "Rechnung hochladen",
	Download: "Herunterladen",
	Show: "Anzeigen",
	Delete: "Löschen",
	All: "Alle",
	SearchInvoice: "Rechnung suchen...",
	"Delivery address": "Lieferadresse",
	"Billing address": "Rechnungsadresse",
	"Payment terms": "Zahlungsart",
	"Order Details": "Bestellungs-Details",
	"Invoice Details": "Rechnungs-Details",
	"Reject Invoice": "Ablehnen",
	"Correct Extraction": "Korrigieren",
	Control: "Kontrollieren",
	ToPay: "Auszahlung einrichten",
	"Total HT": "Bruttobetrag",
	"VAT amount": "Betrag der Mw-St.",
	Discount: "Skonto",
	"Total amount": "Gesamtbetrag",
	"Invoice overview": "Rechnungsübersicht",
	History: "Historie",
	verificationDate: "Prüfungsdatum",
	rejectionDate: "Ablehnungsdatum",
	controlDate: "Kontrolldatum",
	dateOfReceipt: "Empfangsdatum",
	rejectMessage: "Sind Sie sicher diese Rechnung ablehnen zu wollen?",
	controlMessage: "Sind Sie sicher diese Rechnung kontrollieren zu wollen?",
	ErrorMessageToControl: "Sie können nicht diese Rechnung kontrollieren:",
	topayMessage: "Sind Sie sicher die Auszahlung dieser Rechnung starten zu wollen?",
	deleteMessage: "Sind Sie sicher diese Rechnungen löschen zu wollen?",
	individualDeleteMessage: "Sind Sie sicher diese Rechnung löschen zu wollen?",
	individualBillDeleteMessage: "Sind Sie sicher diese Rechnung löschen zu wollen?",
	rejectionReason: "Ablehnungsgrund",
	rejectionDetails: "Ablehnungs-Details...",
	cancel: "Abbrechen",
	confirm: "Bestätigen",
	editForm: "Änderung",
	countInvoices: "Gesamtanzahl von den Rechnungen ",
	tobeprocessed: "zu bearbeiten",
	None: "------------------",
	save: "Speichern",
	edit: "Ändern",
	Actions: "Handlungen",
	itemCode: "Referenz",
	designation: "Artikel",
	quantity: "Qtät",
	unitPrice: "Preis",
	deliveryDate: "Lieferungszeit",
	// New added
	Total: "Gesamt",
	VAT: "Taux de TVA",
	"Pre-Tax-Amount": "Bruttobetrag",
	Commands: "BERECHNUNGSZEILEN",
	totalHt: "Bruttobetrag",
	vat: "Mw-St.",
	sortBy: "Sortieren nach",
	createdAt: "Erstellt am",
	updatedAt: "Aktualisierungsdatum",
	InvoicesCannotBeDeleted: "Manche Rechnungen können nicht gelöscht werden",
	InvoicesCannotBeRelaunched: "Manche Rechnungen können nicht neu geschickt werden",
	"Integration in progress": "Im Integrationsprozeβ",
	"To control": "Zu kontrollieren",
	"To pay": "Zu bezahlen",
	Payed: "Bezahlt",
	Rejected: "Abgelehnt",
	Exception: "Ausnahme",
	Canceled: "Storniert",
	codeArticle: "Artikel-Nr.",
	deliveryDelay: "Lieferungszeit",
	orderNo: "Bestellnummer",
	numberOfArticle: "Anzahl der Artikeln",
	deliveryMethod: "Lieferungsart",
	InvoicesNotCreatedMessage: "Folgende Rechnungen können nicht erstellt werden:",
	invoice: "Rechnung",
	order: "Bestellung",
	training: "Im Lernprozeβ...",
	trained: "Gelernt",
	"Company Siret": "Identifizierung",
	"VAT Number": "USt-IdNr",
	"Apply Changes": "Anwenden",
	"invoice Number": "Rechnungsnummer",
	phone: "Telefon",
	"Email de Contact": "EMail des Kontakts",
	"Contact principal": "Ansprechspartner",
	"Invoice was already paid": "Die Rechnung ist schon bezahlt worden",

	"Apply for approval": "Bestätigung anfragen",
	ApplyForApproval: "Bestätigungsanfrage",
	comment: "Kommentar",
	"Goods or Services were never received": "Die Waren oder die Leistungen sind nie erhalten worden",
	"Supporting documents were not received with the invoice":
		"Die Belege sind nicht mit der Rechnung erhalten worden",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Die Bestellnummer ist fehlend, ungültig oder wurde storniert",
	"The amount being billed does not match the agreed upon amount on the contract":
		"Der berechnete Betrag stimmt nicht mit dem im Vertrag vereinbarten Betrag",
	Other: "Sonstiges",
	CREATE_ACTION: "Schaffung von der Rechnung",
	VERIFY_ACTION: "Prüfung von der Rechnung",
	REJECT_ACTION: "Ablehnung von der Rechnung",
	CONTROL_ACTION: "Kontrollieren von der Rechnung",
	END_TRAINING_ACTION: "Lernprozeβ abgeschlossen",
	CORRECT_ACTION: "Lernprozeβ gestartet",
	TO_PAY_ACTION: "Auszahlung von der Rechnung",
	DELET_ACTION: "Löschung von der Rechnung",
	TO_VALIDATE_EXTERNAL_ACTION: "Bestätigungsanfrage",
	RELAUNCH_ACTION: "Extrahierung gestartet",
	EXPORT_ACTION: "Exportieren von der Rechnung",
	action: "Handlung",
	details: "Details",
	supplierEmptyMessageError: "Der Lieferant wurde nicht erkannt",
	incohenrenceAmountsMessageError:
		"Fehler bei den Gesamtbeträge : Der Nettobetrag stimmt nicht mit der Summe von dem Bruttobetrag plus MwSt.",
	"invoice.status.external_validation": " Im Bestätigungsprozeβ",
	reason: "Grund",
	Approve: "Bestätigen",
	email: "E-Mail",
	approvedBy: "Bestätigt bei",
	"Nb total des factures": "Gesamtanzahl der Bestellungen",
	"Facture en cours": "Offene Rechnungen",
	"Total Orders": "Gesamtanzahl der Bestellungen",
	searchOrder: "Bestellung finden",
	VALIDATED: "Bestätigt",
	PARTIALLY_DELIVERED: "Teilweise ausgeliefert",
	DELIVERED: "GELIEFERT",
	PARTIALLY_BILLED: "Teilweise berechnet",
	BILLED: "Berechnet",
	"purchase_order.status.validated": "Bestätigt",
	"purchase_order.status.partiallyDelivered": "Teilweise ausgeliefert",
	"purchase_order.status.delivered": "GELIEFERT",
	"purchase_order.status.PartiallyBilled": "Teilweise berechnet",
	"purchase_order.status.billed": "Berechnet",
	"order Lines": "Die Bestellungszeilen",
	delivered: "Geliefert",
	"non delivered": "Nicht geliefert",
	orderDate: "Bestellungsdatum",
	"this operation is failed": "L'opération a échoué",
	invoiceAccumulation: "Anhäufung von Rechnungen",
	"error.duplicateSuppliers":
		"Die USt-Nr. {{VatNumber}} gehört mehreren Lieferanten. Bitte Lieferant auswählen",
	orderType: "Bestellungsart",
	CLOSED: "Geschlossen",
	OPEN: "Offen",
	DeliverySlip: "Lieferschein",
	currency: "Währung",
	deliveryNo: "Bestellnummer",
	label: "Formulierung",
	deliveredQuantity: "Ausgegebene Anzahl",
	delivery: "Lieferung",
	"Delivery Details": "Empfang",
	"Delivery Lines": "Wareneingänge",
	deliveryItemDate: "Lieferungsdatum",
	export: "Exportieren",
	"invoice.status.exported": "Exportiert",
	"price TTC": "Nettopreis",
	unitePriceHT: "Bruttopreis",
	"invoice.exported": "Exportierte Rechnungen",
	"invoice.unexported": "Nicht exportierte Rechnungen",
	invoice_charge: "Spesen",
	"invoice.total_amoutn_to_verify": "Gesamtbetrag der Rechnungen noch zu prüfen",
	"invoice.total_invoice": "Anzahl Rechnugnen",
	"add.contact": "Neuen Kontakt hinzufügen",
	"invoice.duplicated": "Duplikat",
	"extraction.label.fully_extracted": "Vollständig extrahiert",
	"extraction.label.partially_extracted": "Teilweise extrahiert",
	"extraction.label.not_extracted": "Nicht extrahiert",
	"extraction.label.not_invoice": "Keine Rechnung",
	"this field is mandatory": "Dieses Feld ist erforderlich",
	rejectedBy: "Abgelehnt bei",
	"invoice.detail.company_siret": "Mw-StNr. des Unternehmens",
	country: "Land",
	duplicated_invoice: "Rechnung gedoppelt",
	"user.roles.user": "Benutzer",
	"tootlip.action.edit": "Ändern",
	"tooltip.action.delete": "Löschen",
	"tooltip.action.relaunch": "Neu starten",
	"tooltip.action.download": "Herunterladen",
	"tooltip.action.relaunch_extraction": "Extrahierung neu starten",
	"tooltip.action.export_excel": "Excel exportieren",
	"tooltip.action.export_entry_account_excel": "Excel-EC exportieren",
	"tooltip.action.export_xml": "XML exportieren",
	"profile.update-password.new-password-required": "Neues Passwort erforderlich",
	"profile.update-password.password-required": "Passwort ist erforderlich",
	"profile.update-password.password-not-matched": "Passwörter stimmen nicht überein",
	"profile.update-password.current-password": "Aktuelles Passwort",
	"profile.update-password.new-password": "Neues Passwort",
	"profile.update-password.confirm-password": "Passwort bestätigen",
	"profile.update-password.reset-password": "Passwort zurücksetzen",
	"profile.update-password.password-reset": "Passwort zurücksetzen",
	"profile.update-password.password-reset-success": "Ihr Kennwort wurde erfolgreich geändert",
	"profile.update-password.password-email-send":
		"Eine E-Mail wurde gesendet. Bitte überprüfen Sie Ihre E-Mails.",
	"profile.update-password.password-reset-done": "Fertig",
	"profile.update-password.update-password": "Passwort ändern",
	"profile.update-password.email": "Ihre aktuelle E-Mail",
	"profile.update-password.tokenError": "Ungültiges Token, Passwort wurde bereits aktualisiert!",
	"suppliers.add.multiple": "Ihre Lieferanten stellen oder ",
	"upload.supplier": "Lieferanten aufladen",
	"downloqd.template": "Modell herunterladen",
	"supplier.add.button": "Lieferanten importieren",
	"supplier.add2.button": "Kunden einfügen",
	"supplier.export.suppliers.excel": "Exportieren Sie die Liste der Lieferanten",
	"invoice.status.processing": "Im Bearbeitungsprozeβ",
	"invoice.status.underProcess": "Im Bearbeitungsprozeβ",
	"invoice.status.InoviceProcessed": "Verarbeitete Rechnungen",
	"invoice.status.InoviceUnderProcess": "Rechnungen in Bearbeitung",
	"invoice.status.processed": "Verarbeitet",
	Administration: "Administration",
	Logout: "Ausloggen",
	Profile: "Profil",
	"invoice.selected.title0": "Alle Rechnungen sind ausgewählt",
	"invoice.selected.title1": "Die ",
	"invoice.selected.title2": " Rechnungen von dieser Seite sind alle ausgewählt.",
	"invoice.selected.title3": "Alle Rechnungen auswählen",
	"supplier.add.step1": "Allgemeine Informationen",
	"supplier.add.step2": "Kontaktinformation",
	"supplier.add.step3": "Finanzielle Informationen",
	"supplier.add.next": "Weiter",
	"supplier.add.previous": "Zurück",
	"supplier.add.finish": "Beenden",
	"supplier.add.country": "Land",
	"supplier.add.suppliername": "Lieferantenname",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Kapital",
	"supplier.add.siret": "MwSt-Nr.",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "E-Mail",
	"supplier.add.companyName": "Firmenname",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Anrede",
	"supplier.add.phone": "Telefon",
	"supplier.add.mobile": "Telefonnummer",
	"supplier.add.vat": "USt-IdNr.",
	"supplier.add.naf": "Stellung im Beruf",
	"supplier.add.bank": "Bank",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "Bankkoordinaten",
	"supplier.add.ribAttachment": "IBAN im Anhang.",
	"supplier.add.acc": "Buchungskonto",
	"supplier.add.curr": "Standardmäβige Währung",
	"supplier.add.function": "Funktion",
	"supplier.add.firstName": "Vorname",
	"supplier.add.lastName": "Nachname",
	"supplier.add.city": "Stadt",
	"supplier.add.street": "Strasse",
	"supplier.add.zipCode": "Posteinzahl",
	"supplier.add.confirm": "Sind Sie sicher die Änderungen speichern zu wollen?",
	"supplier.delete.confirm": "Möchten Sie den Anbieter wirklich löschen?",
	"supplier.delete.success": "Erfolgreiches Löschen des Kunden",
	"supplier.delete.error": "Beim Löschen des Kunden ist ein Fehler aufgetreten",
	"suplier.action.agree": "Annehmen",
	"suplier.action.disagree": "Ablehnen",
	"supplier.add.aux": "Zusatzkonto",
	"supplier.add.phoneFix": "Telefonnummer (Festnetz)",
	"supplier.name.exist.already": "Kundencode existiert bereits",
	"supplier.email.exist.already": "E-Mail-Adresse existiert bereits",
	"suppliers.names.exist.already": " existieren bereits",
	"suppliers.empty.field.name.row.excel": "Il y a une ligne sans nom !!",
	EXPORT_EXCEL: "Excel exportieren",
	EXPORT_EDI: "EDI exportieren",
	EXPORT_XML: "XML exportieren",
	EXPORT_PDF: "PDF exportieren",
	EXPORT_EC: "EC exportieren",
	"order.absent": "Abwesendes Befehl",
	"amount.greate.thang.goods": "Rechnungsbetrag höher als die Bestellung",
	"delivery.absent": "Abwesende Lieferung",
	"Zip Code": "Posteinzahl",
	Street: "Straße",
	City: "Stadt",
	"supplier.delete.training.error.message": "Kein Lernprozeβ existiert für diesen Lieferant",
	"invoice.detail.reject.success": "Erfolgreiche Ablehnung von der Rechnung",
	"invoice.detail.reject.failed": "Die Rechnung ist nicht abgelehnt worden",
	"invoice.detail.approved.success": "Erfolgreiches Annehmen von der Rechnung",
	"invoice.detail.approved.failed": "Die Rechnung wurde nicht angenommen",
	"invoice.detail.export.success": "Erfolgreiches Exportieren von der Rechnung",
	"invoice.detail.export.failed": "Die Rechnung ist nicht exportiert worden",
	"invoice.detail.message.rectify": "Sind Sie sicher die Änderungen speichern zu wollen?",
	"invoice.detail.message.confirmExport": "Sind Sie sicher die Rechnung exportieren zu wollen?",
	"token.validation.noteExist": "Kennwortanforderung ändern bereits gesendet",
	"client.delete.msg": "Möchten Sie diesen Client wirklich löschen?",
	year: "Dieses Jahr",
	month: "Diesen Monat",
	week: "In dieser Woche",
	day: "Heute",
	Exported: "Exportiert",
	Integrated: "Integriert",
	"List des clients partenaires": "Kundenliste",
	"supplier.popup.email.title": "E-Mail-Änderung",
	"supplier.popup.email.message": "",
	"supplier.popup.email.currentEmail": "Meine aktuelle E-Mail:",
	"supplier.popup.email.newEmail": "Meine neue E-Mail",
	"supplier.popup.email.cancel": "Abbrechen",
	"supplier.popup.email.confirm": "Bestätigen",
	"supplier.popup.otp.requiredField": "2-Faktor Authentifzierung erforderlich",
	"supplier.popup.email.requiredField": "E-Mail ist erforderlich",
	"supplier.popup.email.otp": "Bitte geben Sie den Code ein, den Sie per Telefon erhalten haben:",
	"supplier.popup.phone.otp": "Bitte geben Sie den per E-Mail erhaltenen Code ein:",
	"supplier.popup.otp.email.success": "Ihre E-Mail wurde geändert",
	"supplier.popup.ok": "Ok",
	"supplier.popup.phoneNumber.title": "Änderung der Telefonnummer",
	"supplier.popup.phoneNumber.message": "",
	"supplier.popup.phoneNumber.currentPhoneNumber": "Meine aktuelle Nummer",
	"supplier.popup.phoneNumber.newPhoneNumber": "Meine neue Telefonnummer",
	"supplier.popup.phoneNumber.cancel": "Abbrechen",
	"supplier.popup.phoneNumber.confirm": "Bestätigen",
	"supplier.popup.otp.phone.success": "Ihre Telefonnummer wurde geändert.",
	"supplier.popup.iban.title": "Meine Bankverbindung",
	"supplier.popup.iban.message": "IBAN-Änderung",
	"supplier.popup.iban.currentIban": "Meine aktuelle IBAN:",
	"supplier.popup.iban.newDataTitle": "Meine neuen Kontaktinformationen:",
	"supplier.popup.iban.newIban": "IBAN",
	"supplier.popup.iban.newBic": "BIC",
	"supplier.popup.iban.cancel": "Abbrechen",
	"supplier.popup.iban.confirm": "Bestätigen",
	"supplier.popup.iban.newRib": "RIB im Anhang",
	"supplier.popup.otp.iban.pending": "Ihre IBAN wird überprüft",
	"supplier.popup.iban.upload.placeholder": "Durchsuchen einer Datei",
	"supplier.iban.validation.message": "Anwendung steht zur Überprüfung an",
	"supplier.popup.iban.requiredFiel": "IBAN, BIC und RIB sind erforderlich",
	"supplier.popup.phoneNumber.requiredField": "Rufnummer ist erforderlich",
	"supplier.iban.refuse": "Ablehnen",
	"supplier.iban.accept": "Bestätigen",
	"supplier.popup.iban.admin.confirm": "Sind Sie sicher, dass Sie die IBAN validieren möchten?",
	"supplier.popup.iban.admin.confirm.cancel": "Abbrechen",
	"supplier.popup.iban.admin.confirm.confirm": "Bestätigen",
	"supplier.popup.iban.admin.reject": "Grund der Ablehnung:",
	"supplier.popup.iban.admin.reject.cancel": "Abbrechen",
	"supplier.popup.iban.admin.reject.confirm": "Bestätigen",
	"supplier.financial.bic.notvalid": "Ungültiges BIC-Format",
	"supplier.financial.iban.notvalid": "Ungültiges IBAN-Format",
	"supplier.iban.validation.ibanLabel": "IBAN: ",
	"supplier.iban.validation.bicLabel": "BIC: ",
	"supplier.iban.validation.ribLabel": "Donwload Rib",
	"supplier.popup.iban.requiredField": "IBAN und BIC sind erforderlich",
	"company.thirdPartyAccount": "Kundencode",
	"company.companyName": "Firmenname",
	libPaiement: "Rechnungsnummer",
	typeLot: "Status",
	Montant: "Betrag",
	Societe: "Gesellschaft",
	Devise: "Währung",
	"supplier.add.supplierUsername": "Kundencode",
	DeclarationCA: "Umsatzübersicht",
	"Raison sociale": "Firmenname",
	Month: "Monat",
	Year: "Jahr",
	LOCPT: "Kundencode",
	"Code lieu": "Standort Code",
	"Nom lieu": "Standort Name",
	EOMAC: "Automaten Nummer",
	Machine: "Automat",
	CA: "Brutto-Umsatz",
	Designation: "Bezeichnung",
	"Total redevance": "Umsatzbeteiligung",
	numFacture: "Rechnungsnummer",
	"suppliersList.headerTitle": "Seiten",
	"search.declaration": "Suche nach Umsatzübersicht",
	"supplier.add.codeSocietee": "Buchungskreis",
	codeSocietee_required: "Buchungskreis ist erforderlich",
	codeSocietee_not_valid: "Der Buchungskreis muss eine Nummer sein",
	"Add your Invoice": "Fügen Sie eine Rechnung hinzu",
	"payments.total": "Auszahlungsbetrag",
	Reglement: "Bezahlung",
	Importation: "Import",
	Extourne: "auflösen",
	Saisie: "Eingabe",
};
