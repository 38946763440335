/* eslint-disable */
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "components/Filter";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
	fetchSupplierBills,
	fetchSupplierDeclarationCA,
	fetchSupplierInvoices,
} from "redux/actions/suppliers";

const useQuery = () => new URLSearchParams(useLocation().search);

function TableFilters({ isBillTable, isPaymentTable, isInvoiceTable, idSupplier }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const query = useQuery();
	const filterInvoices = useSelector(({ filterInvoices: i }) => i);
	const filterBills = useSelector(({ filterBills: i }) => i);
	const filterDeclarationCA = useSelector(({ filterDeclarationCA: i }) => i);

	const handleFilterInvoiceValueChange = (e) => {
		const filter = { ...filterInvoices, invoiceNoOrSupplierCode: e.target.value };
		dispatch(fetchSupplierInvoices(idSupplier, filter));
	};

	const handleFilterBillValueChange = () => {
		const filter = { ...filterBills };
		// dispatch(updateBillFilter(filter));
		dispatch(fetchSupplierBills(idSupplier, filter));
	};

	const handleFilterByCodeLieu = (e) => {
		const filter = { ...filterDeclarationCA, invoiceCodeLieu: e.target.value };
		dispatch(fetchSupplierDeclarationCA(idSupplier, filter));
	};

	useEffect(() => {
		query.get("");
	}, []);

	return (
		<>
			<Grid item xs={3}>
				<SearchFilter
					callback={
						isBillTable
							? handleFilterBillValueChange
							: isInvoiceTable
							? handleFilterInvoiceValueChange
							: handleFilterByCodeLieu
					}
					placeHolder={
						isBillTable
							? t("search.invoice")
							: isInvoiceTable
							? t("search.licencefee")
							: isPaymentTable
							? t("search.payment")
							: t("search.declaration")
					}
					// style={classes.searchIcon}
					value={query.get("q")}
				/>
			</Grid>
		</>
	);
}

export default TableFilters;
