import appSlice from "../../App/appSlice";
import filterListSuppliers from "../supplierFilterReducer";
import filterListInvoices from "../filterReducer";
import filterListBill from "../filterBills";
import filterListPayments from "../filterPayments";
import filterListDeclarationCAs from "../filterDeclarationCA";
import formulaireReducer from "../formulaireReducer";
import orderFormulaireReducer from "../orderFormulaireReducer";
import filterListUsers from "../../administration/redux/reducers/UserFilterReducer";

export default {
	appState: appSlice,
	filterInvoices: filterListInvoices,
	filterPayments: filterListPayments,
	filterSuppliers: filterListSuppliers,
	// filterOrders: filterListInvoices,
	filterDeclarationCA: filterListDeclarationCAs,
	filterBills: filterListBill,
	filterClients: filterListSuppliers,
	filterUsers: filterListUsers,
	formulaire: formulaireReducer,
	orderFormulaire: orderFormulaireReducer,
};
