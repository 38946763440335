import React, { Fragment, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Button, Input, NativeSelect } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast, REGEX } from "utils";

import { createUser, fetchCivilities, fetchJobs, fetchUser, updateUser } from "../redux/actions";
import { fetchSuppliers } from "../../redux/actions/suppliers";
import useStyles from "../style";
import ConfirmDialog from "../../components/ConfirmDialog";

export const AddSuplierAccount = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const suppliers = useSelector(
		({ requests }) =>
			requests.queries.FETCH_SUPPLIERS &&
			requests.queries.FETCH_SUPPLIERS.data &&
			requests.queries.FETCH_SUPPLIERS.data.content
	);
	const jobs = useSelector(
		({ requests }) => requests.queries.FETCH_USERS_JOBS && requests.queries.FETCH_USERS_JOBS.data
	);
	const civilities = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS_CIVILITIES && requests.queries.FETCH_USERS_CIVILITIES.data
	)?.filter((civ) => civ.label !== "Ms"); // TODO: remove filter after 1.0 release;
	const userTomodify = useSelector(
		({ requests }) => requests.queries.FETCH_USER && requests.queries.FETCH_USER.data
	);
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, handleSubmit, register, setValue } = useForm();
	const [supplier, setSupplier] = React.useState("");
	const [job, setJob] = React.useState(t("supplier"));
	const [civility, setCivility] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);

	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
	});
	const [userToSave, setUserToSave] = React.useState(null);
	const handleSupplierFieldChange = (event) => {
		const { target } = event;
		setSupplier(target.value);
	};
	const handleJobFieldChange = (event) => {
		const { target } = event;
		setJob(target.value);
	};
	const handleCivilityFieldChange = (event) => {
		const { target } = event;
		setCivility(target.value);
	};

	useEffect(() => {
		dispatch(fetchSuppliers());
		dispatch(fetchJobs());
		dispatch(fetchCivilities());
	}, []);
	useEffect(() => {
		if (props.match.params.idUser) dispatch(fetchUser(props.match.params.idUser));
	}, [props.match.params.idUser]);
	useEffect(() => {
		if (props.match.params.idUser && userTomodify) {
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setValue("email", userTomodify.email);
			setSupplier(get(userTomodify, "supplier.label"));
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setJob(t(get(userTomodify, "jobTitle.label")));
			setCivility(t(get(userTomodify, "civility.label")));
		}
	}, [userTomodify]);
	const onSubmit = (data) => {
		data.jobTitle = t("supplier");
		const selectedSupplier = suppliers.find((s) => data.supplierId === t(s.label));
		const tempValue = data.jobTitle === t("supplier") ? t("supplier") : t("user");
		const selectedJob = jobs.find((s) => tempValue === t(s.label));
		const selectedCivility = civilities.find((s) => data.civility === t(s.label));
		data = {
			...data,
			jobTitle: selectedJob,
			civility: selectedCivility,
			supplierId: get(selectedSupplier, "id"),
		};
		setUserToSave(data);
		setConfirmObject({
			message: props.match.params.idUser
				? t("admin.supplier.confirm.message.edit")
				: t("admin.supplier.confirm.message.add"),
			openConfirm: true,
		});
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			let data = userToSave;
			if (props.match.params.idUser) {
				// update mode
				data = { ...data, id: props.match.params.idUser };
				setIsLoading(true);
				dispatch(
					updateUser(
						props.match.params.idUser,
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.success(t("invoice.user.updated.success"));
							setIsLoading(false);
							setTimeout(() => {
								history.push("/administration");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.error(error);
							setIsLoading(false);
						}
					)
				);
			} else {
				setIsLoading(true);
				dispatch(
					createUser(
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.success(t("sendEmailCreationUserMessage"));
							setIsLoading(false);
							setTimeout(() => {
								history.push("/administration");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.error(error);
							setIsLoading(false);
						}
					)
				);
			}
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			history.push("/administration");
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	return (
		<>
			<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
				<Grid container item xs={12}>
					<Typography variant="caption" color="primary">
						<GroupIcon fontSize="small" /> {t("manageUsers")} {t(" > ")}{" "}
						{props.match.params.idUser && userTomodify ? t("edit_supplier") : t("add_supplier")}
					</Typography>
				</Grid>
				<Grid container item xs={12}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid
							container
							item
							xs={12}
							sm={12}
							className={classes.firstGrid}
							spacing={6}
							justify="center"
							alignItems="center"
							alignContent="center"
						>
							<Grid
								container
								item
								xs={12}
								className={classes.headerTitle}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Typography variant="h6" color="primary">
									{userTomodify && props.match.params.idUser
										? t("edit_supplier")
										: t("add_supplier")}
								</Typography>
							</Grid>
							<Grid
								container
								item
								xs={8}
								className={classes.loginContainer}
								spacing={6}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid container item xs={12} spacing={6}>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("supplier_name")}
											<span style={{ color: "red" }}>*</span>

											<br />
											<NativeSelect
												value={supplier}
												onChange={handleSupplierFieldChange}
												inputRef={register({
													required: { value: true, message: t("supplier_required") },
												})}
												name="supplierId"
												fullWidth
											>
												{suppliers &&
													suppliers.map((elt, i) => (
														<option key={i} value={t(elt.label)}>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.supplier?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("email")}
											<span style={{ color: "red" }}>*</span>

											<Input
												inputRef={register({
													required: { value: true, message: t("email_required") },
												})}
												name="email"
												type="email"
												disabled={props.match.params.idUser}
												fullWidth
											/>
											<p>{errors.email?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("userFunction")}
											<span style={{ color: "red" }}>*</span>

											<br />

											<NativeSelect
												value={job}
												onChange={handleJobFieldChange}
												inputRef={register({
													required: { value: true, message: t("job_required") },
												})}
												disabled
												name="jobTitle"
												fullWidth
											>
												{jobs &&
													jobs.map((elt, i) => (
														<option
															key={i}
															selected={
																props.match.params.idUser &&
																userTomodify &&
																elt.label === userTomodify.civility.label
															}
															value={t(elt.label)}
														>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.jobTitle?.message}</p>
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={12} spacing={6}>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("userCivility")}

											<br />
											<NativeSelect
												value={civility}
												onChange={handleCivilityFieldChange}
												inputRef={register()}
												name="civility"
												fullWidth
											>
												{/* eslint-disable-next-line */}
												<option value="" />
												{civilities &&
													civilities.map((elt, i) => (
														<option key={i} value={t(elt.label)}>
															{t(elt.label)}
														</option>
													))}
											</NativeSelect>
											<p>{errors.civility?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("lastName")}

											<br />

											<Input
												inputRef={register({
													pattern: { value: REGEX.FORM_NAMES, message: t("lastName_notValid") },
												})}
												defaultValue={
													props.match.params.idUser && userTomodify && userTomodify.lastName
												}
												name="lastName"
												fullWidth
											/>
											<p>{errors.lastName?.message}</p>
										</Typography>
									</Grid>
									<Grid item xs={4}>
										<Typography variant="subtitle2" align="left" color="primary">
											{t("firstName")}

											<br />
											<Input
												inputRef={register({
													pattern: { value: REGEX.FORM_NAMES, message: t("firstName_notValid") },
												})}
												name="firstName"
												fullWidth
											/>
										</Typography>
										<p>{errors.firstName?.message}</p>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs={12}
								spacing={2}
								className={classes.actions}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid item>
									<Button
										color="primary"
										autoFocus
										onClick={() =>
											setConfirmObject({
												message: t("CancelActionMessage"),
												openConfirm: true,
												cancel: true,
											})
										}
									>
										{t("cancel")}
									</Button>
								</Grid>
								<Grid item>
									<Button variant="contained" type="submit" color="secondary" disabled={isLoading}>
										{isLoading && <CircularProgress size={14} />}
										{t("confirm")}
									</Button>
								</Grid>
							</Grid>

							<ConfirmDialog
								message={confirmObject.message}
								open={confirmObject.openConfirm}
								onConfirm={onConfirm}
								onCancel={onCancel}
							/>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</>
	);
};

export default AddSuplierAccount;
