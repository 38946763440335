import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Input from "@material-ui/core/Input";
import { friendlyFormatIBAN, electronicFormatIBAN, isValidIBAN, isValidBIC } from "ibantools";
import clsx from "clsx";
import useStyles from "../style";

const Financial = ({ supplier, updateSupplier, setRibAttachment, register, errors }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [fileName, setFileName] = React.useState(null);

	const onChange = () => {
		const tmpFile =
			document.getElementById("rib_upload") && document.getElementById("rib_upload").files[0];
		setFileName(tmpFile?.name);
		const reader = new FileReader();
		reader.readAsDataURL(tmpFile);
		reader.onload = () => {
			setRibAttachment(reader.result);
		};
	};
	const validateIban = (value) => {
		const noFormatIban = electronicFormatIBAN(value);
		return isValidIBAN(noFormatIban);
	};

	const validateBic = (value) => isValidBIC(value);
	return (
		<Grid container xs={12} justify="center">
			<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.bank")}
						<br />
						<Input
							value={supplier.bank}
							onChange={(event) => updateSupplier(event)}
							name="bank"
							fullWidth
						/>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.bic")}
						<Input
							value={supplier.bic}
							onChange={(event) => updateSupplier(event)}
							name="bic"
							fullWidth
							inputRef={register({
								validate: validateBic,
							})}
						/>
						<Typography className={classes.validation_error}>
							{errors.bic && errors.bic.type === "validate" && t("supplier.financial.bic.notvalid")}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.iban")}
						<br />
						<Input
							value={supplier.iban}
							onChange={(event) => {
								const e = {
									target: {
										name: event.target.name,
										value: friendlyFormatIBAN(event.target.value),
									},
								};
								updateSupplier(e);
							}}
							inputRef={register({
								validate: validateIban,
							})}
							name="iban"
							fullWidth
						/>
						<Typography className={classes.validation_error}>
							{errors.iban &&
								errors.iban.type === "validate" &&
								t("supplier.financial.iban.notvalid")}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.acc")}
						<br />
						<Input
							value={supplier.accountJournalNumber}
							onChange={(event) => updateSupplier(event)}
							name="accountJournalNumber"
							fullWidth
						/>
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={8} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={8}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.ribAttachment")}
						<br />
						<div className={classes.ribInput}>
							<label htmlFor="rib_upload" className={clsx(classes.ribAttachment)}>
								<AttachFileIcon className={classes.fileAttachmentIcon} /> {t("upload")}
							</label>
							<Typography>{fileName}</Typography>
							<Input
								type="file"
								id="rib_upload"
								inputProps={{ accept: "application/pdf", for: "file-upload" }}
								value={supplier.accountJournalAuxiliary}
								onChange={(event) => {
									updateSupplier(event);
									onChange();
								}}
								name="ribFile"
								// className={clsx(classes.ribAttachment)}
								fullWidth
							/>
						</div>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Financial;
