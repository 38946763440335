import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	dialogWrapper: {
		// boxShadow: "0 1px 15px 1px rgba(69,65,78,.08)",
		// backgroundColor: theme.palette.background.paper,
		// padding: "24px ",
		// borderRadius: "0",
		padding: theme.spacing(0),
	},
	popupTitle: {
		padding: theme.spacing(2, 17),
		textAlign: "center",
		color: theme.palette.secondary.main,
	},
	popupTitleLeft: {
		textAlign: "left",
		padding: theme.spacing(0, 0),
		margin: theme.spacing(0, 0),
	},
	emailContainer: {
		padding: theme.spacing(1, 0),
	},
	text: {
		marginBottom: theme.spacing(1, 0),
		fontSize: "0.95em",
	},
	texCenter: {
		textAlign: "center",
	},
	fieldLabel: {
		marginBottom: theme.spacing(0),
		marginTop: theme.spacing(1),
		fontSize: "0.95em",
	},
	inputFieldContainer: {
		marginBottom: theme.spacing(1),
	},
	inputField: {
		fontSize: "0.83125em",
	},
	actionBar: {
		padding: theme.spacing(2, 3),
	},
	ribAttachment: {
		border: "1px solid #ccc",
		display: "inline-block",
		padding: "6px 12px",
		cursor: "pointer",
		width: "100%",
		borderRadius: "5px",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
	},
	ribInput: {
		margin: theme.spacing(1, 0),
		"&::before": {
			border: "none",
		},
		"& input[type='file']": {
			display: "none",
		},
	},
	fileAttachmentIcon: {
		color: theme.palette.success,
	},
	ribFileName: {
		display: "block",
		marginTop: theme.spacing(1),
	},
}));
