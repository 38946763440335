import React from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

export const CivilityComponent = ({ value, changeValue }) =>  <FormControl component="fieldset">
            <FormLabel component="legend">Civilité</FormLabel>
            <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={(e) => changeValue && changeValue(e.target.value)}
            >
                <FormControlLabel value="Mrs" control={<Radio color="primary" />} label="Mme" />
                <FormControlLabel value="Ms" control={<Radio color="primary" />} label="Mlle" />
                <FormControlLabel value="Mr" control={<Radio color="primary" />} label="M" />
            </RadioGroup>
        </FormControl>

export default CivilityComponent;
