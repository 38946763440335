import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";
import es from "./es";
import ar from "./ar";
import de from "./de";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
	es: {
		translation: es,
	},
	ar: {
		translation: ar,
	},
	de: {
		translation: de,
	},
};

const navigatorLanguages = {
	"fr-FR": "fr",
	fr: "fr",
	"en-US": "en",
	en: "en",
	"es-ES": "es",
	es: "es",
	"de-DE": "de",
	de: "de",
};

i18n.use(initReactI18next).init({
	resources,
	lng: localStorage.getItem("lgn")
		? localStorage.getItem("lgn")
		: navigatorLanguages[navigator.language]
		? navigatorLanguages[navigator.language]
		: "fr",
	keySeparator: false,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
