import React from "react";

import { Checkbox, Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { defaultTo } from "lodash";
import { getCurrencySymbol, currencyFormat } from "utils";

function InvoiceElements({ row, checkedAll, setCheckedAll }) {
	const [open, setOpen] = React.useState({
		raisonSociale: false,
		nomLieu: false,
	});
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};

	return (
		<>
			<Grid item xs={1}>
				<Typography color="primary">
					{row.mois && row.annee
						? `${row.mois}/${row.annee}`
						: row.mois
						? row.mois
						: row.annee
						? row.annee
						: "--"}
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography color="primary">{row.numFacture || row.numFactRfa}</Typography>
			</Grid>

			<Grid item xs={1}>
				<Typography color="primary">{row.codeLieu}</Typography>
			</Grid>
			<Grid item xs={3}>
				<Tooltip
					open={open.nomLieu}
					onClose={() => handleClose("nomLieu")}
					onOpen={row?.nomLieu?.length > 20 && (() => handleOpen("nomLieu"))}
					title={defaultTo(row?.nomLieu, "\xa0")}
				>
					<Typography color="primary" style={{ width: "95%" }} noWrap={row?.nomLieu?.length > 20}>
						{row.nomLieu}
					</Typography>
				</Tooltip>
			</Grid>

			<Grid item xs={1}>
				<Typography color="primary" style={{ textAlign: "center" }}>{`${currencyFormat(
					row.ca ?? "0"
				)} ${getCurrencySymbol(row?.codeSociete)}`}</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography color="primary" style={{ textAlign: "center" }}>{`${currencyFormat(
					row.totalRed ?? "0"
				)} ${getCurrencySymbol(row?.codeSociete)}`}</Typography>
			</Grid>

			<Grid item xs={1}>
				<div>
					<Checkbox
						id={`invoice-list-action-check-${row?.id}`}
						value={parseInt(row?.id, 10)}
						checked={checkedAll.includes(parseInt(row?.id, 10))}
						onChange={(e) => {
							const list = [...checkedAll];

							if (e.target.checked) list.push(parseInt(e.target.value, 10));
							else list.splice(list.indexOf(parseInt(e.target.value, 10)), 1);

							setCheckedAll(list);
						}}
					/>
				</div>
			</Grid>

			<Grid item xs={1} />
		</>
	);
}

export default InvoiceElements;
