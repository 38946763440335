/* eslint-disable */
import React, { useState } from "react";
import {
	Grid,
	Typography,
	Checkbox,
	Card,
	CardContent,
	Button,
	Tooltip,
	IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { downloadInvoices, fetchSupplierInvoices } from "redux/actions/suppliers";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import useStyles from "./style";
import { updateFilter } from "redux/filterReducer";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import LoadingButton from "../../LoadingButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useQuery } from "components/Filter";

function InvoiceHeaders({ idSupplier, checkedAll, setCheckedAll, handleCheckedAll }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const filterInvoices = useSelector(({ filterInvoices: i }) => i);
	const [clickedRow, setClickedRow] = useState({
		mois: true,
		raisonSociale: false,
		dueDate: false,
		total: false,
	});
	const [isParentChecked, setIsParentChecked] = useState(false);
	const [selectedAll, setSelectedAll] = useState(false);
	const invoices = useSelector(
		({ requests }) =>
			requests.queries.FETCH_SUPPLIER_INVOICES &&
			requests.queries.FETCH_SUPPLIER_INVOICES.data &&
			requests.queries.FETCH_SUPPLIER_INVOICES.data.content
	);
	const query = useQuery();
	useEffect(() => {
		dispatch(updateFilter({ ...filterInvoices, invoiceNoOrSupplierCode: query.get("q") }));
	}, [query.get("q")]);
	const handleOnClickInvoiceRow = (row) => {
		setClickedRow((clickedRow) => ({ [row]: !clickedRow[row] }));
		dispatch(
			fetchSupplierInvoices(idSupplier, {
				...filterInvoices,
				sortBy: row,
				pageNo: 0,
			})
		);
	};
	const selectAllItems = () => {
		setSelectedAll(true);

		dispatch(updateFilter({ ...filterInvoices, selectAll: true }));
	};
	const handleChange = () => {
		setChecked(!checked);
	};

	const handleChange1 = (event) => {
		setChecked([event.target.checked, event.target.checked]);
	};
	useEffect(() => {
		setIsParentChecked(
			invoices && checkedAll && checkedAll.length === invoices.length && invoices.length !== 0
		);
	}, [checkedAll]);

	return (
		<Grid container item xs={12}>
			<Grid item xs={1} />
			<Grid item xs={1} onClick={() => handleOnClickInvoiceRow("mois")}>
				<Typography className={classes.headers}>
					{clickedRow["mois"] && <SwapVertIcon />}
					{t("Month")}/{t("Year")}
				</Typography>
			</Grid>
			<Grid item xs={2} onClick={() => handleOnClickInvoiceRow("numFacture")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["numFacture"] && <SwapVertIcon />}
					{t("numFacture")}
				</Typography>
			</Grid>

			<Grid item xs={1} onClick={() => handleOnClickInvoiceRow("codeLieu")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["codeLieu"] && <SwapVertIcon />}
					{t("Code lieu")}
				</Typography>
			</Grid>

			<Grid item xs={3} onClick={() => handleOnClickInvoiceRow("nomLieu")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["nomLieu"] && <SwapVertIcon />}
					{t("Nom lieu")}
				</Typography>
			</Grid>

			<Grid item xs={1} onClick={() => handleOnClickInvoiceRow("ca")}>
				<Typography className={classes.headers} style={{ justifyContent: "center" }}>
					{clickedRow["ca"] && <SwapVertIcon />}
					{t("CA")}
				</Typography>
			</Grid>

			<Grid item xs={2} onClick={() => handleOnClickInvoiceRow("totalRed")}>
				<Typography className={classes.headers} noWrap style={{ justifyContent: "center" }}>
					{clickedRow["totalRed"] && <SwapVertIcon />}
					{t("Total redevance")}
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<Checkbox
					checked={isParentChecked}
					onChange={(e) => {
						handleCheckedAll(e.target.checked);
						setSelectedAll(false);
					}}
				/>
				<Tooltip placement="top" title={t("tooltip.action.download")}>
					<IconButton
						aria-label="download"
						disabled={isEmpty(checkedAll)}
						onClick={() => {
							setIsLoading(true);

							dispatch(downloadInvoices(idSupplier, checkedAll, selectedAll, filterInvoices)).then(
								() => {
									setCheckedAll([]);
									setIsLoading(false);
								}
							);
						}}
					>
						<LoadingButton isLoading={isLoading}>
							<GetAppIcon />
						</LoadingButton>
					</IconButton>
				</Tooltip>
			</Grid>

			{isParentChecked && (
				<Card className={classes.selectAll}>
					<CardContent>
						<Grid container direction="row" justify="center" alignItems="center">
							{selectedAll ? (
								<Grid item xs>
									<Typography className={classes.title} gutterBottom align="center">
										{t("invoice.selected.title0")}
									</Typography>
								</Grid>
							) : (
								<>
									<Grid item xs={3}>
										<Typography
											color="textSecondary"
											align="right"
											// gutterBottom
										>
											{t("invoice.selected.title1")}
											{checkedAll.length}

											{t("invoice.selected.title2")}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Button onClick={() => selectAllItems()}>
											<Typography
												className={classes.title}
												align="center"
												justify="center"
												color="secondary"
											>
												{t("invoice.selected.title3")}
											</Typography>
										</Button>
									</Grid>
								</>
							)}
						</Grid>
					</CardContent>
				</Card>
			)}
		</Grid>
	);
}

export default InvoiceHeaders;
