export const FETCH_INVOICE = "FETCH_INVOICE";
export const FETCH_BILL = "FETCH_BILL";
export const DELETE_LIST_INVOICE = "DELETE_LIST_INVOICE";
export const DOWNLOAD_LIST_INVOICE = "DOWNLOAD_LIST_INVOICE";
export const LIST_FETCH_INVOICE = "LIST_FETCH_INVOICE";
export const FETCH_STATUS = "FETCH_STATUS";
export const FETCH_STATUS_HISTORY = "STATUS_HISTORY";
export const FETCH_PAYMENT_TERM = "FETCH_PAYMENT_TERM";
export const FETCH_PAYMENT_TERMS = "FETCH_PAYMENT_TERMS";
export const FETCH_SUPPLIER = "FETCH_SUPPLIER";
export const CHECK_SUPPLIERS_NAME = "CHECK_SUPPLIERS_NAME";
export const CHECK_SUPPLIERS_EMAIL = "CHECK_SUPPLIERS_EMAIL";
export const FETCH_SUPPLIERS = "FETCH_SUPPLIERS";
export const FETCH_DOCUMENT_INVOICE = "FETCH_DOCUMENT_INVOICE";
export const FETCH_DOCUMENT_BILL = "FETCH_DOCUMENT_BILL";
export const FETCH_REJECTION_MOTIFS = "FETCH_REJECTION_MOTIFS";
export const CREATE_INVOICE_REJECTION = "CREATE_INVOICE_REJECTION";
export const VERIFY_INVOICE = "VERIFY_INVOICE";
export const TO_CONTROL = "TO_CONTROL";
export const TO_VERIFY = "TO_VERIFY";
export const INTEGRATION = "INTEGRATION";
export const INVOICE_TOTAL_AMOUNT = "INVOICE_TOTAL_AMOUNT";
export const SUPPLIERS_NOT_DELETED = "SUPPLIERS_NOT_DELETED";
export const DELETE_SUPPLIERS = "DELETE_SUPPLIERS";
export const FETCH_SUPPLIER_INVOICES = "FETCH_SUPPLIER_INVOICES";
export const FETCH_SUPPLIER_BILLS = "FETCH_SUPPLIER_BILLS";
export const FETCH_SUPPLIER_PAYMENTS = "FETCH_SUPPLIER_PAYMENTS";
export const FETCH_SUPPLIER_DECLARATION_CA = "FETCH_SUPPLIER_DECLARATION_CA";
export const SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT =
	"SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const PATCH_COMMAND_INVOICE = "PATCH_COMMAND_INVOICE";
export const LIST_FETCH_DELIVERY_METHOD = "LIST_FETCH_DELIVERY_METHOD";
export const FETCH_LAST_ACTIONS_INVOICE = "FETCH_LAST_ACTIONS_INVOICE";
export const INVOICE_TOTAL_VAT_AMOUNT = "INVOICE_TOTAL_VAT_AMOUNT";
export const FETCH_COUNT_SUPPLIERS = "FETCH_COUNT_SUPPLIERS";
export const FETCH_COUNT_INVOICE_INDICATOR = "FETCH_COUNT_INVOICE_INDICATOR";
export const INVOICE_TOTAL_OVERDUE_AMOUNT = "INVOICE_TOTAL_OVERDUE_AMOUNT";
export const INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER = "INVOICE_TOTAL_OVERDUE_AMOUNT_BY_SUPPLIER";
export const FETCH_XML_INVOICE = "FETCH_XML_INVOICE";
export const INVOICE_TOTAL_AMOUNT_BY_SUPPLIER = "INVOICE_TOTAL_AMOUNT_BY_SUPPLIER";
export const INVOICE_VAT_AMOUNT_BY_SUPPLIER = "INVOICE_VAT_AMOUNT_BY_SUPPLIER";
export const TO_PAY_INVOICE = "TO_PAY_INVOICE";
export const FETCH_INVOICE_HISTORY_LIST = "FETCH_INVOICE_HISTORY_LIST";
export const RELAUNCH_EXTRACTION = "RELAUNCH_EXTRACTION";
export const INVOICES_COUNT_BY_SUPPLIER = "INVOICES_COUNT_BY_SUPPLIER";
export const INVOICES_TOTAL_BY_SUPPLIER = "INVOICES_TOTAL_BY_SUPPLIER";
export const EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL = "EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL";
export const EXPORT_SUPPLIERS_EXCEL = "EXPORT_SUPPLIERS_EXCEL";
export const ADD_CHANGE_EMAIL_REQUEST = "ADD_CHANGE_EMAIL_REQUEST";
export const VALIDATE_OTP = "VALIDATE_OTP";
export const ADD_CHANGE_PHONE_NUMBER_REQUEST = "ADD_CHANGE_PHONE_NUMBER_REQUEST";
export const ADD_CHANGE_IBAN_REQUEST = "ADD_CHANGE_IBAN_REQUEST";
export const VALID_IBAN = "VALID_IBAN";
export const CANCEL_OTP_VALIDATION = "CANCEL_OTP_VALIDATION";
export const FETCH_ACCOUNT_MANAGERS = "FETCH_ACCOUNT_MANAGERS";
export const FETCH_MESSAGES_HISTORY = "FETCH_MESSAGES_HISTORY";
export const ACCEPT_CHANGE_IBAN_REQUEST = "ACCEPT_CHANGE_IBAN_REQUEST";
export const REFUSE_CHANGE_IBAN_REQUEST = "ACCEPT_CHANGE_IBAN_REQUEST";
export const DOWNLOAD_CHANGE_REQUEST_RIB = "DOWNLOAD_CHANGE_REQUEST_RIB";

// invoice approval
export const FETCH_INVOICE_TO_APPROVE = "FETCH_INVOICE_TO_APPROVE";
export const CREATE_INVOICE_APPROVAL_REJECTION = "CREATE_INVOICE_APPROVAL_REJECTION";

// orders
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDER = "FETCH_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const FETCH_ORDER_STATUS = "FETCH_ORDER_STATUS";
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const FETCH_LEGAL_FORM = "FETCH_LEGAL_FORM";
export const FETCH_ORDER_BY_SUPPLIER = "FETCH_ORDER_BY_SUPPLIER";
export const FETCH_ORDERS_BY_CLIENT = "FETCH_ORDERS_BY_CLIENT";
export const FETCH_SUPPLIER_IMPORT_SAMPLE = "FETCH_SUPPLIER_IMPORT_SAMPLE";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENT = "FETCH_CLIENT";
export const FETCH_COUNT_CLIENTS = "FETCH_COUNT_CLIENTS";
export const ORDER_TOTAL_AMOUNT = "ORDER_TOTAL_AMOUNT";
export const LIST_FETCH_ORDER = "LIST_FETCH_ORDER";
export const FETCH_COUNT_ORDERS = "FETCH_COUNT_ORDERS";
export const LIST_FETCH_DELIVERY_METHOD_ORDERS = "LIST_FETCH_DELIVERY_METHOD_ORDERS";
export const LIST_FETCH_BILLING_LINES_ORDER = "LIST_FETCH_BILLING_LINES_ORDER";
export const FETCH_DOCUMENT_ORDER = "FETCH_DOCUMENT_ORDER";
export const FETCH_PAYMENT_TERMS_ORDER = "FETCH_PAYMENT_TERMS_ORDER";
export const FETCH_REJECTION_MOTIFS_ORDER = "FETCH_REJECTION_MOTIFS_ORDER";
export const FETCH_COUNT_ORDER_INDICATOR = "FETCH_COUNT_ORDER_INDICATOR";
export const FETCH_LAST_ACTIONS_ORDER = "FETCH_LAST_ACTIONS_ORDER";
export const ORDER_TOTAL_VAT_AMOUNT = "ORDER_TOTAL_VAT_AMOUNT";
export const CHECK_CLIENTS_CODE = "CHECK_CLIENTS_CODE";
export const CREATE_MULTI_CLIENTS = "CREATE_MULTI_CLIENTS";
export const ORDER_TOTAL_AMOUNT_BY_CLIENT = "ORDER_TOTAL_AMOUNT_BY_CLIENT";
export const ORDER_TOTAL_OVERDUE_AMOUNT_BY_CLIENT = "ORDER_TOTAL_OVERDUE_AMOUNT_BY_CLIENT";
export const ORDER_VAT_AMOUNT_BY_CLIENT = "ORDER_VAT_AMOUNT_BY_CLIENT";
export const FETCH_CLIENT_IMPORT_SAMPLE = "FETCH_CLIENT_IMPORT_SAMPLE";
export const FETCH_ORDER_HISTORY_LIST = "FETCH_ORDER_HISTORY_LIST";

// delivery
export const FETCH_DELIVERY_ITEMS_BY_SUPPLIER = "FETCH_DELIVERY_ITEMS_BY_SUPPLIER";
export const TOTAL_INVOICE = "TOTAL_INVOICE";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const CREATE_MULTI_SUPPLIER = "CREATE_MULTI_SUPPLIER";
export const TREATED = ["EXPORTED"];
export const REJECTED = ["REJECTED"];
export const TO_VERIFY_LIST = ["INTEGRATION", "TO_VERIFY", "TO_CONTROL", "TO_VALIDATE_EXTERNAL"];
export const FETCH_DOCUMENT_DECLARATION = "FETCH_DOCUMENT_DECLARATION";
