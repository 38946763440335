import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	billNoOrSupplierCode: "",
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
};
const filterListBills = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updateBillFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updateBillFilter, initFilter } = filterListBills.actions;

export default filterListBills.reducer;
