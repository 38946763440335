/* eslint-disable */
import { URL_BASE_USERS } from "administration/redux/constants/endpoint";

import {
	FETCH_SUPPLIER,
	FETCH_SUPPLIERS,
	SUPPLIERS_NOT_DELETED,
	DELETE_SUPPLIERS,
	FETCH_SUPPLIER_INVOICES,
	SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT,
	FETCH_COUNTRIES,
	INVOICE_TOTAL_AMOUNT_BY_SUPPLIER,
	INVOICE_VAT_AMOUNT_BY_SUPPLIER,
	FETCH_LEGAL_FORM,
	FETCH_SUPPLIER_IMPORT_SAMPLE,
	CHECK_SUPPLIERS_NAME,
	EXPORT_SUPPLIERS_EXCEL,
	ADD_CHANGE_EMAIL_REQUEST,
	ADD_CHANGE_PHONE_NUMBER_REQUEST,
	ADD_CHANGE_IBAN_REQUEST,
	VALIDATE_OTP,
	FETCH_SUPPLIER_BILLS,
	VALID_IBAN,
	CANCEL_OTP_VALIDATION,
	FETCH_SUPPLIER_PAYMENTS,
	FETCH_ACCOUNT_MANAGERS,
	CHECK_SUPPLIERS_EMAIL,
	ACCEPT_CHANGE_IBAN_REQUEST,
	REFUSE_CHANGE_IBAN_REQUEST,
	DOWNLOAD_CHANGE_REQUEST_RIB,
	FETCH_SUPPLIER_DECLARATION_CA,
	DOWNLOAD_LIST_INVOICE,
} from "../../constants";
import {
	URL_SUPPLIER,
	URL_SUPPLIER_INVOICES_OUTSTANDING,
	URL_COUNTRY,
	URL_CREATE_SUPPLIER,
	URL_LEGAL_FORM,
	URL_BASE_INVOICE,
	URL_CREATE_MULTI_SUPPLIER,
	URL_SUPPLIER_CODE_CHECK,
	URL_BASE_EXPORT,
	URL_ADD_CHANGE_EMAIL_REQUEST,
	URL_ADD_CHANGE_PHONE_NUMBER_REQUEST,
	URL_ADD_CHANGE_IBAN_REQUEST,
	URL_VALIDATE_OTP,
	URL_VALID_IBAN,
	URL_CANCEL_OTP_VALIDATION,
	URL_SUPPLIER_EMAIL_CHECK,
	URL_ACCEPT_CHANGE_IBAN_REQUEST,
	URL_REFUSE_CHANGE_IBAN_REQUEST,
	URL_DOWNLOAD_CHANGE_REQUEST_RIB,
} from "../../constants/endpoint";

export const checkSupplierEmail = (email, onSuccess, onError) => {
	const data = new FormData();
	data.append("email", email);
	return {
		type: CHECK_SUPPLIERS_EMAIL,
		request: {
			url: `${URL_SUPPLIER_EMAIL_CHECK}`,
			data,
			method: "post",
		},
		meta: {
			onSuccess: (rep) => {
				if (onSuccess) onSuccess(rep);
			},
			onError: () => {
				if (onError) onError();
			},
		},
	};
};

export const checkSupplierCode = (name, onSuccess, onError) => {
	const data = new FormData();
	data.append("name", name);
	return {
		type: CHECK_SUPPLIERS_NAME,
		request: {
			url: `${URL_SUPPLIER_CODE_CHECK}`,
			data,
			method: "post",
		},
		meta: {
			onSuccess: (rep) => {
				if (onSuccess) onSuccess(rep);
			},
			onError: () => {
				if (onError) onError();
			},
		},
	};
};
// export const fetchSupplierDeclarationCA = (id, filter = {}) => {
// 	const { invoiceCodeLieu, pageNo, pageSize, sortBy } = filter;

// 	return {
// 		type: FETCH_SUPPLIER_DECLARATION_CA,
// 		request: {
// 			url: `/invoice/declarationCA/${id}`,
// 			params: {
// 				invoiceCodeLieu,
// 				pageNo,
// 				pageSize,
// 				sortBy,
// 			},
// 		},
// 	};
// };

export const fetchSuppliers = (filter = {}) => {
	const { supplierByCodeLieuOrNumFacture, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_SUPPLIERS,
		request: {
			url: `${URL_SUPPLIER}`,
			params: {
				supplierByCodeLieuOrNumFacture,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const deleteSupplier = (id, onSuccess, onError) => ({
	type: FETCH_SUPPLIER,
	request: {
		url: `${URL_SUPPLIER}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const suppliersNotDeleted = (data) => ({
	type: SUPPLIERS_NOT_DELETED,
	payload: data,
});

export const deleteSuppliers = (idSuppliers, onSuccess) => ({
	type: DELETE_SUPPLIERS,
	request: {
		url: `${URL_SUPPLIER}`,
		method: "delete",
		params: { idSuppliers: idSuppliers.join(",") },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) {
				onSuccess();
			}
			store.dispatch(fetchSuppliers());
			return store.dispatch(suppliersNotDeleted(response.data));
		},
	},
});

export const supplierInvoicesTotalAmounts = (id) => ({
	type: SUPPLIER_INVOICES_OUTSTANDING_TOTAL_AMOUNT,
	request: {
		url: `${URL_SUPPLIER_INVOICES_OUTSTANDING}/${id}`,
		method: "get",
	},
});

export const fetchSupplierInvoices = (id, filter = {}) => {
	const { invoiceNoOrSupplierCode, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_SUPPLIER_INVOICES,
		request: {
			url: `/invoice/invoices/${id}`,
			params: {
				invoiceNoOrSupplierCode,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const fetchSupplierBills = (id, filter = {}) => {
	const { billNoOrSupplierCode, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_SUPPLIER_BILLS,
		request: {
			url: `/invoice/bill/${id}`,
			params: {
				billNoOrSupplierCode,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const fetchSupplierPayments = (id, filter = {}) => {
	const { paymentNoOrSupplierCode, pageNo, pageSize, sortBy } = filter;
	return {
		type: FETCH_SUPPLIER_PAYMENTS,
		request: {
			url: `/invoice/payment/${id}`,
			params: {
				paymentNoOrSupplierCode,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const fetchSupplierDeclarationCA = (id, filter = {}) => {
	const { invoiceCodeLieu, pageNo, pageSize, sortBy } = filter;

	return {
		type: FETCH_SUPPLIER_DECLARATION_CA,
		request: {
			url: `/invoice/declarationCA/${id}`,
			params: {
				invoiceCodeLieu,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const fetchSupplier = (id) => ({
	type: FETCH_SUPPLIER,
	request: { url: `${URL_SUPPLIER}/${id}` },
});

export const fetchCountries = () => ({
	type: `${FETCH_COUNTRIES}`,
	request: {
		url: `${URL_COUNTRY}`,
	},
});

export const invoicesTotalAmountsBySupplier = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${INVOICE_TOTAL_AMOUNT_BY_SUPPLIER}${_status}`,
		request: {
			url: `${URL_BASE_INVOICE}/invoices/${id}/totalAmounts`,
			params: { status },
		},
	};
};

// // eslint-disable-next-line arrow-body-style
// export const invoicesCountBySupplier = (id, status) => {
//   return {
//     type: `${INVOICES_COUNT_BY_SUPPLIER}`,
//     request: {
//       url: `${URL_BASE_INVOICE}/invoices/${id}/count`,
//       params: { status },
//     },
//   };
// };

export const invoicesVATAmountsBySupplier = (id, status) => {
	const _status = status ? `_${status}` : "";
	return {
		type: `${INVOICE_VAT_AMOUNT_BY_SUPPLIER}${_status}`,
		request: {
			url: `${URL_BASE_INVOICE}/invoices/${id}/totalVAT`,
			params: { status },
		},
	};
};

export const createSupplier = (supplier, logo, ribAttachment) => {
	const data = new FormData();
	data.append("logo", logo);
	data.append("rib", ribAttachment);
	data.append("supplier", JSON.stringify(supplier));
	return {
		type: "CREATE_SUPPLIER",
		request: {
			url: URL_CREATE_SUPPLIER,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const modifySupplier = (supplier, logo, ribAttachment) => {
	const data = new FormData();
	data.append("logo", logo);
	data.append("rib", ribAttachment);
	data.append("supplier", JSON.stringify(supplier));
	return {
		type: "UPDATE_SUPPLIER",
		request: {
			url: URL_CREATE_SUPPLIER,
			method: "put",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const fetchLegalForms = () => ({
	type: FETCH_LEGAL_FORM,
	request: {
		url: URL_LEGAL_FORM,
	},
});

export const createMultiSupplier = (file) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: "CREATE_MULTI_SUPPLIER",
		request: {
			url: URL_CREATE_MULTI_SUPPLIER,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
	};
};

export const downloadImportSample = () => ({
	type: FETCH_SUPPLIER_IMPORT_SAMPLE,
	request: {
		url: `${URL_BASE_INVOICE}/supplier/sample`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const exportSuppliersExcel = () => ({
	type: EXPORT_SUPPLIERS_EXCEL,
	request: {
		url: `${URL_BASE_EXPORT}/suppliers/export`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "suppliers.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const addChangeEmailRequest = (supplierId, email, onSuccess) => ({
	type: ADD_CHANGE_EMAIL_REQUEST,
	request: {
		url: URL_ADD_CHANGE_EMAIL_REQUEST,
		method: "post",
		data: JSON.stringify({ email, supplierId }),
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) {
				onSuccess(response);
			}
		},
	},
});

export const addChangePhoneNumberRequest = (supplierId, phoneNumber, onSuccess) => ({
	type: ADD_CHANGE_PHONE_NUMBER_REQUEST,
	request: {
		url: URL_ADD_CHANGE_PHONE_NUMBER_REQUEST,
		method: "post",
		data: JSON.stringify({ phoneNumber, supplierId }),
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) {
				onSuccess(response);
			}
		},
	},
});

export const addChangeIbanRequest = (supplierId, iban, bic, file, onSuccess) => {
	const data = new FormData();
	data.append("file", file);
	data.append("supplierId", supplierId);
	data.append("iban", iban);
	data.append("bic", bic);
	return {
		type: ADD_CHANGE_IBAN_REQUEST,
		request: {
			url: URL_ADD_CHANGE_IBAN_REQUEST,
			method: "post",
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => {
				if (onSuccess) {
					onSuccess(response);
				}
			},
		},
	};
};

export const validateOtp = (id, otp, operationType, onSuccess) => ({
	type: VALIDATE_OTP,
	request: {
		url: URL_VALIDATE_OTP,
		method: "post",
		data: JSON.stringify({ id, otp, operationType }),
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) {
				onSuccess(response);
			}
		},
	},
});

export const cancelOtpValidationRequest = (id) => ({
	type: CANCEL_OTP_VALIDATION,
	request: {
		url: URL_CANCEL_OTP_VALIDATION,
		method: "post",
		data: JSON.stringify({ id }),
		headers: { "Content-Type": "application/json" },
	},
});

export const fetchValidIban = (supplierId) => ({
	type: VALID_IBAN,
	request: {
		url: `${URL_VALID_IBAN}/${supplierId}`,
		method: "get",
	},
});
export const fetchAccountManagers = () => ({
	type: FETCH_ACCOUNT_MANAGERS,
	request: {
		url: `${URL_BASE_USERS}/accountManager`,
		method: "get",
	},
});

export const acceptChangeIbanRequest = (supplierId, onSuccess) => {
	const data = new FormData();
	data.append("supplierId", supplierId);

	return {
		type: ACCEPT_CHANGE_IBAN_REQUEST,
		request: {
			url: URL_ACCEPT_CHANGE_IBAN_REQUEST,
			method: "post",
			data,
		},
		meta: {
			onSuccess: () => {
				if (onSuccess) {
					onSuccess();
				}
			},
		},
	};
};

export const refuseChangeIbanRequest = (supplierId, motive, onSuccess) => {
	const data = new FormData();
	data.append("supplierId", supplierId);
	data.append("motive", motive);
	return {
		type: REFUSE_CHANGE_IBAN_REQUEST,
		request: {
			url: URL_REFUSE_CHANGE_IBAN_REQUEST,
			method: "post",
			data,
		},
		meta: {
			onSuccess: (response) => {
				if (onSuccess) {
					onSuccess();
				}
			},
		},
	};
};

export const downloadIbanRequestRib = (transactionId) => ({
	type: DOWNLOAD_CHANGE_REQUEST_RIB,
	request: {
		url: `${URL_DOWNLOAD_CHANGE_REQUEST_RIB}/${transactionId}`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				link.setAttribute("download", "rib.pdf");
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const downloadInvoices = (idSupplier, idInvoices, selectedAll, filterInvoices) => ({
	type: DOWNLOAD_LIST_INVOICE,

	request: {
		url: `${URL_BASE_INVOICE}/download`,

		params: {
			supplierId: idSupplier,
			idInvoices: idInvoices.join(","),
			isSelectedAll: selectedAll,
			filterKey: filterInvoices.invoiceNoOrSupplierCode,
		},

		responseType: "blob",
	},

	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");

			link.href = window.URL.createObjectURL(new Blob([response.data]));

			link.setAttribute("download", "invoices.zip");

			document.body.appendChild(link);

			link.click();

			link.remove();

			return response;
		},
	},
});
