import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Input, ListItemText, MenuItem, Select } from "@material-ui/core";
import useStyles from "./style";

export const SelectComponent = ({ label, value, items, required = false, changeValue }) => {
    const { t } = useTranslation();
    const classes = useStyles();


    const itemSelected = (e) => {
        const selectedItem = items.find(
            (s) => e.target.value === t(s.label)
        );
        changeValue(selectedItem);
    }

    return (
        <Grid container xs={12} direction="column">
            <Grid item>
                <Typography
                    variant="subtitle2"
                    align="left"
                    color="primary"
                >
                    {t(label)}{required && <span style={{ color: "red" }}>*</span>}
                </Typography>
            </Grid>
            <Grid item>
                <Select
                    labelId="select"
                    id="select"
                    value={value}
                    onChange={(e) => { itemSelected(e); }}
                    fullWidth
                    displayEmpty
                    style={{ height: "32px", maxWidth: "100%" }}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                    }}
                    input={<Input
                        name="select" id="select" />}
                >
                    <MenuItem >
                        <ListItemText primary="" />
                    </MenuItem>
                    {items && items.map((item) => (
                            <MenuItem value={t(item.label)}>
                                <ListItemText primary={t(item.label)} />
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
        </Grid >
    );
};

export default SelectComponent;



