import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 2),
		margin: theme.spacing(0, 1),
		backgroundColor: "rgba(255,255,255,0.6)",
	},
	content: {
		fontWeight: "bold",
	},
}));
