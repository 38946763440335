import supllierFr from "../pages/Suppliers/i18n/fr";
import administrationFr from "../administration/i18n/fr";

export default {
	...supllierFr,
	...administrationFr,
	Copyright: "Tous droits réservés",
	Dashboard: "Tableau de Bord",
	"Business management": "Gestion Commerciale",
	Orders: "Commandes",
	Invoices: "Factures",
	Regulations: "Paiements",
	Suppliers: "Clients",
	Rapports: "Rapports",
	Search: "Rechercher",
	"Add your Invoice": "Déposer votre facture ",
	browse: "Parcourir",
	"Show all": "Afficher tout",
	id: "Identifiant",
	supplier: "Client",
	paymentNo: "Reglement N°",
	Reglement: "Règlement",
	Redevances: "Redevances",
	invoiceNo: "N° Redevance",
	licencefeeNo: "N° Redevance",
	issueDate: "Date d'émission",
	dueDate: "Date d'échéance",
	"creation.date": "Date de création",
	"payment.date": "Date de création",
	"licencefee.date": "Date de création",
	"Price WT": "Montant HT",
	"Taxe Rate": "TVA",
	Price: "Parcourir",
	"Purchase order": "Bon de Commande",
	"Last actions": "Dernières actions",
	Today: "Aujourd'hui",
	Yesterday: "Hier",
	"Last week": "Semaine dernière",
	Status: "Statut",
	Date: "Date",
	"My tasks": "Mes tâches",
	"Invoices to verify": "A vérifier",
	"To verify": "A vérifier",
	"search.invoice": "Chercher par n° facture",
	"search.payment": "Chercher par n° de paiement",
	"search.licencefee": "Chercher par n° redevance/Code lieu",
	"Invoices to be checked": "A contrôler",
	"Invoices to Payers": "A payer",
	"Invoice available": "Facture disponible",
	"Paid invoices": "Factures payées",
	"Unpaid invoices": "Factures non payées",
	"Total amount of invoices": "Total des redevances sur les derniers 24 mois ",
	"Total amount of orders": "Montant total des commandes",
	"Total VAT amount": "Montant total de TVA",
	"Payment.initiation.message":
		"Le paiement est initié et il sera traité dans les jours qui viennent",
	"Statistical overview of purchases": "Aperçu statistique des achats",
	"Statistical overview of product sales": "Aperçu statistique des ventes des produits",
	"Total Purchases": "Total des achats",
	"Total of expenses": "Total des redevances",
	"edit.phonenumber": "Mettre à jour votre téléphone",
	"edit.email": "Mettre à jour votre e-mail",
	"Accounting number": "N° Paiement",
	Late: "En retard",
	Administrator: "Administrateur",
	"New clients": "Nbr de clients partenaires",
	"New suppliers": "Nbr de clients partenaires",
	FOUNDED_RESULT: "Résultat trouvé",
	EndDate: "Date Fin",
	StartDate: "Date Début",
	"Add an invoice": "Ajouter une redevance",
	Upload: "Importer une facture",
	"edit.iban": "Mettre à jour votre IBAN",
	"upload.invoice": "Charger la facture",
	Download: "Télécharger",
	Show: "Afficher",
	Delete: "Supprimer",
	All: "Tous",
	SearchInvoice: "Rechecher une redevance...",
	"Delivery address": "Adresse de livraison",
	"Billing address": "Adresse de facturation",
	"Payment terms": "Moyen de paiement",
	"Order Details": "Détails Commande",
	"Invoice Details": "Détails redevance",
	"Reject Invoice": "Rejeter",
	"Correct Extraction": "Corriger",
	Control: "Contrôler",
	ToPay: "Mettre en paiment",
	"Total HT": "Total HT",
	"VAT amount": "Montant de TVA",
	"compagny.id": "ID entreprise",
	Discount: "Remise",
	"Total amount": "Montant Total",
	"Invoice overview": "Aperçu de la Redevance",
	History: "Historique",
	verificationDate: "Date de vérification",
	rejectionDate: "Date de rejet",
	controlDate: "Date de mise en contrôle",
	dateOfReceipt: "Date de réception",
	rejectMessage: "Voulez-vous vraiment rejeter cette redevance?",
	controlMessage: "Voulez-vous vraiment mettre cette redevance en contrôle?",
	ErrorMessageToControl: "Vous ne pouvez pas mettre cette redevance en contrôle:",
	topayMessage: "Voulez-vous vraiment mettre cette redevance en paiement?",
	deleteMessage: "Êtes-vous sûr de vouloir supprimer ces redevances?",
	individualDeleteMessage: "Êtes-vous sûr de vouloir supprimer cette redevance?",
	individualBillDeleteMessage: "Êtes-vous sûr de vouloir supprimer cette facture?",
	"document.not.found": "Document introuvable",
	rejectionReason: "Motif de rejection",
	rejectionDetails: "Détails du Rejet...",
	cancel: "Annuler",
	confirm: "Confirmer",
	editForm: "Modification",
	countInvoices: "Nombre total de redevances",
	tobeprocessed: "à traiter ",
	None: "------------------",
	save: "Enregistrer",
	edit: "Modifier",
	Actions: "Actions",
	itemCode: "Code",
	designation: "Article",
	quantity: "Qté",
	unitPrice: "Prix",
	deliveryDate: "Délai de livraison",
	// New added
	Total: "Montant (TTC)",
	VAT: "Taux de TVA",
	"Pre-Tax-Amount": "Montant HT",
	Commands: "LIGNES DE FACTURATION",
	totalHt: "Total HT",
	vat: "TVA",
	sortBy: "Trier par",
	createdAt: "Date de création",
	updatedAt: "Date de mise à jour",
	InvoicesCannotBeDeleted: "Certaines redevances ne peuvent pas être supprimées",
	InvoicesCannotBeRelaunched: "Certaines redevances ne peuvent pas être relancées",
	"Integration in progress": "Intégration en cours",
	"To control": "A contrôler",
	"To pay": "A payer",
	Payed: "Payée",
	Rejected: "Rejetée",
	Exception: "Exception",
	Canceled: "Annulée",
	codeArticle: "Code Article",
	deliveryDelay: "Délai de livraison",
	orderNo: "N ° de commande",
	numberOfArticle: "Nombre d'articles",
	deliveryMethod: "Méthode de livraison",
	InvoicesNotCreatedMessage: "Les redevances suivantes ne peuvent pas être créées :",
	invoice: "redevance",
	order: "commande",
	training: "Apprentissage en cours...",
	trained: "Appris",
	"Company Siret": "Identifiant",
	"VAT Number": "Numéro de TVA",
	"Apply Changes": "Appliquer",
	"invoice Number": "N° Redevance",
	"Email de Contact": "Email du contact",
	"Contact principal": "Contact principal",
	"Invoice was already paid": "La redevance a déjà été payée",

	"Apply for approval": "Demander approbation",
	ApplyForApproval: "Demande d'approbation",
	comment: "Commentaire",
	"Goods or Services were never received": "Les biens ou services n'ont jamais été reçus",
	"Supporting documents were not received with the invoice":
		"Les pièces justificatives n'ont pas été reçues avec la redevance",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Le numéro du bon de commande est manquant, est incorrect ou a été annulé",
	"The amount being billed does not match the agreed upon amount on the contract":
		"Le montant facturé ne correspond pas au montant convenu sur le contrat",
	Other: "Autre",
	CREATE_ACTION: "Création de la redevance",
	VERIFY_ACTION: "Vérification de la facutre",
	REJECT_ACTION: "Rejet de la redevance",
	CONTROL_ACTION: "Mise en contrôle de la redevance",
	END_TRAINING_ACTION: "Apprentissage effectué",
	CORRECT_ACTION: "Lancement d'apprentissage",
	TO_PAY_ACTION: "Mise en paiment de la redevance",
	DELET_ACTION: "Suppression de la redevance",
	TO_VALIDATE_EXTERNAL_ACTION: "Demande d'approbation",
	RELAUNCH_ACTION: "Extraction relancée",
	EXPORT_ACTION: "Export de la redevance",
	action: "Action",
	details: "Détails",
	supplierEmptyMessageError: "Le Client de la redevance n'est pas reconnu",
	incohenrenceAmountsMessageError:
		"Erreur sur les cumuls des montants : Le montant TTC est différent de la somme des montants HT et TVA",
	"invoice.status.external_validation": " En cours d'approbation",
	reason: "Motif",
	Approve: "Approuver",
	email: "Email",
	approvedBy: "Approuvé par",
	"Nb total des redevances": "Nb total des redevances",
	"Redevance en cours": "Redevance en cours",
	"Total Orders": "Nombre total de commandes",
	searchOrder: "Trouver une commande",
	VALIDATED: "Validée",
	PARTIALLY_DELIVERED: "Partiellement Délivrée",
	DELIVERED: "LIVRÉ",
	PARTIALLY_BILLED: "Partiellement facturé",
	BILLED: "Facturé",
	"purchase_order.status.validated": "Validé",
	"purchase_order.status.partiallyDelivered": "Partiellement Délivrée",
	"purchase_order.status.delivered": "LIVRÉ",
	"purchase_order.status.PartiallyBilled": "Partiellement facturé",
	"purchase_order.status.billed": "Facturé",
	"order Lines": "Les lignes de commande du référentiel",
	delivered: "Livrée",
	phone: "Téléphone",
	"non delivered": "Non livrée",
	orderDate: "Date de la commande",
	"this operation is failed": "L'opération a échoué",
	invoiceAccumulation: "Accumulation de redevances",
	"error.duplicateSuppliers":
		"Le numéro de TVA {{VatNumber}} correspond à plusieurs clients. Veuillez choisir le clients",
	orderType: "Type de commande",
	CLOSED: "Fermée",
	OPEN: "Ouverte",
	DeliverySlip: "Bordereau de livraison",
	currency: "Devise",
	deliveryNo: "N ° de livraison",
	label: "Libéllé",
	deliveredQuantity: "Quantité livrée",
	delivery: "Livraison",
	"Delivery Details": "Réception",
	"Delivery Lines": "Entrées marchandises",
	deliveryItemDate: "Date de livraison",
	export: "Exporter",
	"invoice.status.exported": "Exporté",
	"price TTC": "Prix TTC",
	unitePriceHT: "Prix HT",
	"invoice.exported": "Redevance exportées",
	"invoice.unexported": "Redevance non exportées",
	invoice_charge: "Frais",
	"invoice.total_amoutn_to_verify": "Montant total des redevances à vérifier",
	"invoice.total_invoice": "Nombre des redevances",
	"add.contact": "Ajouter un nouveau contact",
	"invoice.duplicated": "Doublon",
	"extraction.label.fully_extracted": "Totalement extraite",
	"extraction.label.partially_extracted": "Partiellement extraite",
	"extraction.label.not_extracted": "Non extraite",
	"extraction.label.not_invoice": "Autre que redevance",
	"this field is mandatory": "ce champ est obligatoire",
	rejectedBy: "Rejeté par",
	"invoice.detail.company_siret": "Identifiant Entreprise",
	country: "Pays",
	duplicated_invoice: "Redevance dupliquée",
	"user.roles.user": "Utilisateur",
	"tootlip.action.edit": "Modifier",
	"tooltip.action.delete": "Supprimer",
	"tooltip.action.relaunch": "Relancer",
	"tooltip.action.download": "Télécharger",
	"tooltip.action.relaunch_extraction": "Relancer l'extraction",
	"tooltip.action.export_excel": "Exporter excel",
	"tooltip.action.export_entry_account_excel": "Export Excel écriture Comptable",
	"tooltip.action.export_xml": "Exporter xml",
	"profile.update-password.current-password-required": "Le mot de passe actuel est requis",
	"profile.update-password.new-password-required": "Un nouveau mot de passe est requis",
	"profile.update-password.password-required": "Mot de passe requis",
	"profile.update-password.password-not-matched": "Les mots de passe ne sont pas identiques.",
	"profile.update-password.current-password": "Mot de passe actuel",
	"profile.update-password.new-password": "Nouveau mot de passe",
	"profile.update-password.confirm-password": "Confirmer le mot de passe",
	"profile.update-password.reset-password": "Réinitialiser le mot de passe",
	"profile.update-password.password-reset": "Réinitialiser le mot de passe",
	"profile.update-password.password-reset-success": "Votre mot de passe à été changé avec succès",
	"profile.update-password.password-reset-done": "Terminer",
	"profile.update-password.update-password": "Modifier le mot de passe",
	"profile.update-password.email": "Votre email actuel",
	"profile.update-password.tokenError": "Jeton non valide, le mot de passe est déjà mis à jour!",
	"profile.update-password.password-email-send":
		"Un e-mail a été envoyé. veuillez vérifier votre e-mail.",
	"suppliers.add.multiple": "Déposer votre clients ou",
	"upload.supplier": "Charger les clients",
	"downloqd.template": "télécharger le modèle",
	"supplier.add.button": "Importer des clients",
	"supplier.export.suppliers.excel": "Exporter la liste des clients",
	"supplier.add2.button": "Ajouter un client",
	"invoice.status.processing": "En traitement",
	"invoice.status.underProcess": "En cours de traitement",
	"invoice.status.InoviceProcessed": "Redevance Traitées",
	"invoice.status.InoviceUnderProcess": "Redevances en cours de traitement",
	"invoice.status.processed": "Traitée",
	Administration: "Administration",
	Logout: "Se déconnecter",
	Profile: "Profil",
	"invoice.selected.title0": "Toutes les redevances sont sélectionnées",
	"invoice.selected.title1": "Les ",
	"invoice.selected.title2": " redevance de cette page sont toutes sélectionnées.",
	"invoice.selected.title3": "Sélectionnez toutes les redevances",
	"supplier.add.step1": "Informations générales",
	"supplier.add.step2": "Informations de contact",
	"supplier.add.step3": "Informations financières",
	"supplier.add.next": "Suivant",
	"supplier.add.previous": "Précédent",
	"supplier.add.finish": "Finir",
	"supplier.name.exist.already": "Le nom du client existe déjà",
	"supplier.email.exist.already": "L'adresse e-mail existe déjà",
	"suppliers.names.exist.already": " existent Déjà",
	"suppliers.empty.field.name.row.excel": "Il y a une ligne sans nom !!",
	"supplier.add.country": "Pays de base",
	"supplier.add.suppliername": "Nom du client",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capitale",
	"supplier.add.siret": "Société SIRET",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "E-mail",
	"supplier.add.companyName": "Raison sociale",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civilité",
	"supplier.add.phone": "Téléphone",
	"supplier.add.mobile": "Numéro de téléphone",
	"supplier.add.vat": "Numéro de TVA",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "Banque",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.ribAttachment": "RIB en pièce jointe",
	"supplier.add.acc": "Compte comptable",
	"supplier.add.curr": "Devise par défaut",
	"supplier.add.function": "Fonction",
	"supplier.add.firstName": "Prénom",
	"supplier.add.lastName": "Nom",
	"supplier.add.city": "Ville",
	"supplier.add.street": "Rue",
	"supplier.add.zipCode": "Code postal",
	"supplier.add.confirm": "Voulez-vous vraiment enregistrer les modifications?",
	"supplier.delete.confirm": "Voulez-vous vraiment supprimer le client?",
	"supplier.delete.success": "Client supprimé avec succès",
	"supplier.delete.error": "Une erreur s'est produite lors de la suppression du client",
	"suplier.action.agree": "Accepter",
	"suplier.action.disagree": "Refuser",
	"supplier.add.aux": "Compte auxiliaire",
	"supplier.add.phoneFix": "Téléphone fixe",
	EXPORT_EXCEL: "Exporter excel",
	EXPORT_EDI: "Exporter edi",
	EXPORT_XML: "Exporter xml",
	EXPORT_PDF: "Exporter pdf",
	EXPORT_EC: "Exporter EC",
	"order.absent": "Commande absente",
	"amount.greate.thang.goods": "Montant de la redevance supérieur à la commande",
	"delivery.absent": "Absence de livraison",
	"Zip Code": "Code postal",
	Street: "Rue",
	City: "Ville",
	"supplier.delete.training.error.message": "Aucun apprentissage n'existe pour ce client.",
	"invoice.detail.reject.success": "La redevance a été rejetée avec succès",
	"invoice.detail.reject.failed": "La redevance n'a pas été rejetée",
	"invoice.detail.approved.success": "La redevance a été approuvée avec succès",
	"invoice.detail.approved.failed": "La redevance n'a pas été approuvée",
	"invoice.detail.export.success": "La redevance a été exportée avec succès",
	"invoice.detail.export.failed": "La redevance n'a pas été exportée",
	"invoice.detail.message.rectify": "Êtes-vous sûr de vouloir enregistrer ces modifications?",
	"invoice.detail.message.confirmExport": "Êtes-vous sûr de vouloir exporter cette redevance?",
	"token.validation.noteExist": "Demande de changement de mot de passe déjà envoyée",
	year: "Cette année",
	month: "Ce mois",
	week: "Cette semaine",
	day: "Aujourd'hui",
	Exported: "Exportées",
	Integrated: "Integrées",
	"use.doc.cutter": "Utiliser la découpe automatique",
	"List des clients partenaires": "Liste des clients partenaires",
	"supplier.popup.email.title": "Mettre à jour votre E-mail",
	"supplier.popup.email.message": "",
	"supplier.popup.email.currentEmail": "Mon email actuel:",
	"supplier.popup.email.newEmail": "Mon nouvel email",
	"supplier.popup.email.cancel": "Annuler",
	"supplier.popup.email.confirm": "Valider",
	"supplier.popup.otp.requiredField": "OTP est requis",
	"supplier.popup.email.requiredField": "Email est requis",
	"supplier.popup.email.otp": "Veuillez entrer le code reçu par téléphone:",
	"supplier.popup.phone.otp": "Veuillez entrer le code reçu par email:",
	"supplier.popup.otp.email.success": "Votre email à bien été modifié.",
	"supplier.popup.ok": "Ok",
	"supplier.popup.phoneNumber.title": "Mettre à jour votre numéro de téléphone",
	"supplier.popup.phoneNumber.message": "",
	"supplier.popup.phoneNumber.currentPhoneNumber": "Mon numéro actuel",
	"supplier.popup.phoneNumber.newPhoneNumber": "Mon nouveau numéro",
	"supplier.popup.phoneNumber.cancel": "Annuler",
	"supplier.popup.phoneNumber.confirm": "Valider",
	"supplier.popup.otp.phone.success": "Votre numéro de téléphone a bien été modifié.",
	"supplier.popup.iban.title": "Mes coordonnées Bancaires",
	"supplier.popup.iban.message": "Mettre à jour votre IBAN",
	"supplier.popup.iban.currentIban": "Mon IBAN actuel:",
	"supplier.popup.iban.newDataTitle": "Mes nouvelles coordonnées:",
	"supplier.popup.iban.newIban": "IBAN",
	"supplier.popup.iban.newBic": "BIC",
	"supplier.popup.iban.cancel": "Annuler",
	"supplier.popup.iban.confirm": "Valider",
	"supplier.popup.iban.newRib": "RIB en pièces jointe",
	"supplier.popup.otp.iban.pending": "Votre IBAN est en cours de validation",
	"supplier.popup.iban.upload.placeholder": "Parcourir un ficher",
	"supplier.iban.validation.message": "Données en attente de validation",
	"supplier.popup.iban.requiredFiel": "IBAN, BIC et RIB sont requis",
	"supplier.popup.phoneNumber.requiredField": "Numéro de téléphone est requis",
	"supplier.iban.refuse": "Rejeter",
	"supplier.iban.accept": "Valider",
	"supplier.popup.iban.admin.confirm": "Êtes-vous sur de vouloir valider ?",
	"supplier.popup.iban.admin.confirm.cancel": "Annuler",
	"supplier.popup.iban.admin.confirm.confirm": "Valider",
	"supplier.popup.iban.admin.reject": "Motif de rejet:",
	"supplier.popup.iban.admin.reject.cancel": "Annuler",
	"supplier.popup.iban.admin.reject.confirm": "Valider",
	"supplier.financial.bic.notvalid": "Format BIC non valide",
	"supplier.financial.iban.notvalid": "Format IBAN non valide",
	"supplier.iban.validation.ibanLabel": "IBAN: ",
	"supplier.iban.validation.bicLabel": "BIC: ",
	"supplier.iban.validation.ribLabel": "Télécharger le Rib ",
	"supplier.popup.iban.requiredField": "IBAN, BIC et RIB sont requis",
	"company.thirdPartyAccount": "Compte tiers comptable",
	"company.companyName": "Raison sociale",
	libPaiement: "Libellé",
	typeLot: "Type",
	Montant: "Montant",
	societe: "Société",
	Devise: "Devise",
	"supplier.add.supplierUsername": "Compte tiers comptable",
	DeclarationCA: "Declaration CA",
	"Raison sociale": "Raison sociale",
	Month: "Mois",
	Year: "Année",
	LOCPT: "Compte tier compatble",
	"Code lieu": "Code lieu",
	"Nom lieu": "Nom lieu",
	EOMAC: "EOMAC",
	Machine: "Machine",
	CA: "CA",
	Designation: "Désignation",
	"Total redevance": "Total redevance (TTC)",
	numFacture: "Numéro de redevance",
	"suppliersList.headerTitle": "Sites",
	"search.declaration": "Chercher par code lieu",
	"supplier.add.codeSocietee": "Code Societée",
	codeSocietee_required: "Le Code Societée est obligatoire",
	codeSocietee_not_valid: "Le Code Societée doit être un nombre",
	"payments.total": "Paiement",
};
