import { lighten, makeStyles, darken } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	wrapper: {
		display: "flex",
	},
	content: {
		flexGrow: 1,
		paddingLeft: theme.spacing(9),
		transition: theme.transitions.create("padding", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	smallContent: {
		paddingLeft: theme.custom.variables.menuLeftWidth,
	},
	headerExpanded: {
		marginTop: theme.custom.variables.headerHeight,
	},
	container: {
		backgroundColor: "#f8f9fa",
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(5),
	},
	toast: {
		minWidth: 320,
		width: "inherit",
		"& .Toastify__toast": {
			minHeight: "inherit",
			padding: 4,
		},
		"& .Toastify__toast-body": {
			width: "100%",
		},
		"& .nov-toastify-icon": {
			paddingTop: 7,
		},
		"& .Toastify__close-button": {
			marginTop: 15,
			marginRight: 5,
			"& svg": {
				width: 20,
				height: 20,
			},
		},
		"& .Toastify__toast--error": {
			color: darken(theme.palette.error.main, 0.6),
			backgroundColor: lighten(theme.palette.error.main, 0.9),
			"& .Toastify__close-button": {
				color: darken(theme.palette.error.main, 0.6),
			},
			"& .nov-toastify-icon": {
				color: theme.palette.error.main,
			},
		},
		"& .Toastify__toast--success": {
			color: darken(theme.palette.success.main, 0.6),
			backgroundColor: lighten(theme.palette.success.main, 0.9),
			"& .Toastify__close-button": {
				color: darken(theme.palette.success.main, 0.6),
			},
			"& .nov-toastify-icon": {
				color: theme.palette.success.main,
			},
		},
		"& .Toastify__toast--warning": {
			color: darken(theme.palette.warning.main, 0.6),
			backgroundColor: lighten(theme.palette.warning.main, 0.9),
			"& .Toastify__close-button": {
				color: darken(theme.palette.warning.main, 0.6),
			},
			"& .nov-toastify-icon": {
				color: theme.palette.warning.main,
			},
		},
		"& .Toastify__toast--info": {
			color: darken(theme.palette.info.main, 0.6),
			backgroundColor: lighten(theme.palette.info.main, 0.9),
			"& .Toastify__close-button": {
				color: darken(theme.palette.info.main, 0.6),
			},
			"& .nov-toastify-icon": {
				color: theme.palette.info.main,
			},
		},
	},
}));
