/* eslint-disable */
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useHistory } from "react-router-dom";
import {
	CardActions,
	IconButton,
	CardContent,
	Card,
	Typography,
	Grid,
	Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { toast } from "utils";
import useStyles from "./style";
import InfoItem from "./components/InfoItem";
import { deleteSupplier } from "../../redux/actions/suppliers/index";
import UserAvatar from "./components/UserAvatar";
import get from "lodash/get";

function TableCae({
	index,
	onClickRow,
	row,
	infoItems,
	withLogo,
	refreshSuppliers,
	isPaymentTable,
	isSupplier,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const onSuccessDelete = () => {
		toast.success(t("supplier.delete.success"));
		refreshSuppliers();
	};

	const onErrorDelete = () => {
		toast.error(t("supplier.delete.error"));
	};

	// const confirmDeleteSupplier = () => {
	// 	setConfirmObject({ message: t("supplier.delete.confirm"), openConfirm: true });
	// };

	const handleDeleteSupplier = () => {
		dispatch(deleteSupplier(row.id, onSuccessDelete, onErrorDelete));
	};

	const colors = ["#8408C3", "#0922FE", "#128CA5", "#00D4FF", "#12A560 "];

	const colorTheme = colors[index % colors.length] || colors[0];

	return (
		<Card
			key={index}
			className={classes.root}
			elevation={1}
			style={{ borderLeft: `5px solid ${colorTheme}` }}
			style={
				withLogo ? { borderLeft: `5px solid ${colorTheme}` } : { borderRight: `5px solid #54C5B8` }
			}
		>
			<Grid container xs={12} display="flex">
				<CardContent className={classes.content}>
					<ButtonBase focusRipple onClick={onClickRow && (() => onClickRow(row.id))}>
						<Grid container spacing={6} display="flex" alignItems="center">
							{withLogo && (
								<Grid item xs={2}>
									<Grid container item direction="column">
										<>
											<Grid item className={classes.avatar}>
												<UserAvatar row={row} color={colorTheme} />
											</Grid>
											<Grid item>
												<Typography variant="subtitle2" align="center">
													{row.code}
												</Typography>
											</Grid>
										</>
									</Grid>
								</Grid>
							)}
							<Grid item xs>
								<Grid
									container
									style={{ height: "100%", marginLeft: `${!withLogo ? "5%" : ""}` }}
									display="flex"
									alignItems="center"
								>
									{infoItems &&
										infoItems.map((item) => (
											<>
												<Grid container xs direction="row" justify="center" alignItems="center">
													<InfoItem
														label={item.label}
														value={item.value}
														justify={item.justify}
														marginLeft={item.marginLeft}
														color={item.color ? item.color : ""}
													/>
												</Grid>
											</>
										))}
								</Grid>
							</Grid>
						</Grid>
					</ButtonBase>
				</CardContent>
				{!isPaymentTable && !isSupplier && (
					<CardActions>
						<Grid container xs={12} justify="flex-end">
							<Grid item>
								<Tooltip placement="top" title={t("tootlip.action.edit")}>
									<IconButton
										aria-label="download"
										onClick={() => history.push(`/client/${get(row, "id")}`)}
									>
										<EditIcon color="secondary" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</CardActions>
				)}
			</Grid>
		</Card>
	);
}

export default TableCae;
