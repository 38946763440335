export default {
	lastName: "الاسم",
	firstName: "الاسم الاول",
	email: "البريد الإلكتروني",
	Username: "اسم المستخدم",
	userFunction: "وظيفة",
	job: "وظيفة",
	manageUsers: "إدارةالمستخدم",
	manageSuppliers: "منطقة الموردين",
	manageClients: "منطقة العملاء",
	supplier_name: "المورد",
	client_name: "اسم العميل",
	manageGroups: "إدارة المجموعات",
	manageRoles: "إدارة الأدوار",
	manageWorkflow: "إدارة سير العمل",
	manageLicenseAddOn: "إدارة التراخيص والوظائف الإضافية",
	status: "الحالة",
	ACTIVE: "نشط",
	WAITING: "في الانتظار",
	DISABLE: "تعطيل",
	add_user: "إضافة مستخدم",
	add_user_button: "إضافة مستخدم",
	add_supplier_button: "إضافة مورد",
	add_client_button: "إضافة الزبون",
	add_supplier: "إضافة مورد",
	edit_supplier: "تعديل المورد",
	add_client: "إضافة عميل",
	sendEmailCreationUserMessage:
		"تم إرسال بريد إلكتروني به اسم المستخدم وكلمة المرور إلى المستخدم ليتمكن من الاتصال",
	CancelActionMessage: "هل أنت متأكد أنك تريد إلغاء هذا الإجراء؟",
	searchUser: "بحث",
	userCivility: "الكياسة",
	administrator: "المسؤول",
	user: "وكيل",
	Mr: "سيدي",
	Ms: "الانسة",
	Mrs: "سيدة",
	enableDisableActionMessage: "هل أنت متأكد أنك تريد تمكين / تعطيل هذا المستخدم",
	edit_user: "تعديل المستخدم",
	// validation messages
	lastName_required: "مطلوب اسم",
	lastName_notValid: "الاسم غير صالح",
	supplierName_notValid: "اسم المورد غير صالح",
	phone_notValid: "الهاتف غير صالح",
	mobile_required: "المحمول مطلوب",
	firstName_required: "الاسم الأول مطلوب",
	firstName_notValid: "الاسم الأول غير صالح",
	email_required: "البريد الالكتروني مطلوب",
	companyName_required: "اسم الشركة مطلوب",
	supplier_required: "اسم المورد مطلوب",
	email_notValid: "البريد الإلكتروني غير صالح",
	siret_notValid: "شركة SIRET غير صالحة",
	url_notValid: "رابط غير صالح",
	supplier_error: "حدث خطأ أثناء تسجيل الموفر",
	supplier_created: "تم إنشاء المورد بنجاح!",
	supplier_updated: "تم تحديث المورد بنجاح!",
	supplier_update_error: "فشل تحديث تفاصيل المورد!",
	job_required: "الكياسة إلزامية",
	civility_required: "الكياسة إلزامية",
	deleteUserActionMessage: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
	deleteUsersActionMessage: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
	"admin.settings": "إعدادات",
	"admin.email-config": "تكوين البريد الإلكتروني",
	"admin.ftp-config": "تكوين بروتوكول نقل الملفات",
	"admin.import-config": "تكوين الاستيراد",
	Host: "مضيف",
	Port: "منفذ",
	Email: "البريد الإلكتروني",
	Password: "كلمه السر",
	successDeleteBill: "تم حذف الفاتورة بنجاح.",
	"Read Folder": "مجلد القراءة",
	"Processing folder": "مجلد المعالجة",
	"Success folder": "مجلد النجاح",
	"Exception folder": "مجلد الاستثناءات",
	"admin.config.update-settings": "تم تحديث المعلمات بنجاح",
	"admin.config.update-message": "تم تحديث تكوين البريد الإلكتروني بنجاح",
	"admin.invoice.AUTO_EXPORT": "تصدير تلقائي",
	"admin.AMOUNT_TOLERANCE": "هامش الخطأ",
	"admin.MAXIMUM_SIZE": "أكبر مقاس",
	supplierTraining: "تعلم المورد",
	deleteTraining: "حذف التدريب",
	deleteTrainingMessage: "هل أنت متأكد أنك تريد حذف التدريب من هذا المزود",
	successdeleteTrainingMessage: "تم حذف التدريب بنجاح",
	"admin.download-buttons": "الإجراءات على الفواتير",
	"admin.download-buttons-description":
		"اختر الإجراءات التي تريد السماح بها في فواتيرك في البوابة.",
	"admin.download-button.excel": "Excel تنزيل بتنسيق",
	"admin.download-button.xml": " XML تنزيل بتنسيق",
	"admin.download-button.pdf": "PDF تنزيل بتنسيق",
	"admin.download-button.edi": "EDI تنزيل بتنسيق",
	"admin.invoice.AUTO_EXPORT-description":
		"تعمل هذه المعلمة على تنشيط التصدير التلقائي للفواتير المستخرجة بالكامل والتي لا تحتوي على رسالة خطأ.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"يتيح لك هذا الحقل تكوين تفاوت في حسابات مجاميع الفاتورة. سيتم عرض رسائل الخطأ على أساس هذا التفاوت.",
	"admin.MAXIMUM_SIZE-description": "الحد الأقصى لحجم المستند المراد تنزيله بالميغابايت",
	"admin.display-indicators": "عرض المؤشرات في لوحة القيادة",
	"admin.display-indicators-description":
		"اختر المؤشرات التي تريد تصورها في Supply Chain Dashboard.",
	"admin.setting.my_tasks": "مهامي",
	"admin.setting.invoices-exported": "الفواتير  المصدرة",
	"admin.setting.invoices-not-exported": "الفواتير غير المصدرة",
	"admin.setting.last-actions": "آخر الإجراءات",
	"admin.setting.load-invoices": "تحميل الفواتير",
	"admin.setting.last-5-invoices": "آخر 5 فواتير",
	"admin.setting.download-pdf": "تحميل PDF",
	"admin.setting.delete": "حذف",
	"admin.setting.delete-all": "حذف جميع الفواتير",
	"admin.setting.AMOUNT_TOLERANCE.required": "مطلوب هامش الخطأ",
	"admin.setting.relaunch-extraction": "أعد تشغيل الاستخراج",
	"admin.export-buttons": "أزرار التنزيل",
	"admin.invoice.export-buttons-description":
		"قم بإعداد عرض اختصارات التنزيلات الممكنة بعد تصدير فاتورة. ستظهر الأزرار بعد التصدير في تفاصيل الفاتورة.",
	"admin.setting.export_entry_account_excel": "تصدير إدخال محاسبة Excel",
	"admin.extraction-level-settings": "أهمية الحقول المستخرجة (مستوى الاستخراج)",
	"admin.extraction-level-settings-desciption":
		"حدد أهمية المجالات التي تبدو أكثر أهمية بالنسبة لك والمجالات التي تعتبرها ضرورية. سيؤخذ ذلك في الاعتبار عند حساب مستوى الاستخراج",
	"admin.level-settings": "إعدادات مستوى الاستخراج",
	"admin.extraction-level.champs": "مجالات",
	"admin.extraction-level.status": "حالة",
	"admin.extraction-level.mandatory": "أساسي",
	"admin.extraction-level.ignored": "مهمل",
	"admin.extraction-level.important": "مهم",
	"admin.setting.message.confirm": "?هل أنت متأكد أنك تريد تحديث هذه الإعدادات",
	"admin.user.confirm.message.edit": "هل أنت متأكد أنك تريد تحديث هذا المستخدم?",
	"admin.user.confirm.message.add": "هل أنت متأكد أنك تريد إضافة هذا المستخدم?",
	"admin.supplier.confirm.message.add": "?هل أنت متأكد أنك تريد إضافة هذا المستخدم",
	"admin.client.confirm.message.add": "?هل أنت متأكد أنك تريد إضافة هذا المستخدم",
	"admin.supplier.confirm.message.edit": "هل أنت متأكد أنك تريد تحديث هذا المستخدم?",
	"admin.config.message.confirm": "هل أنت متأكد أنك تريد تحديث إعدادات البريد الإلكتروني ؟",
	"admin.extraction.message.confirm": " هل أنت متأكد أنك تريد تحديث مستويات الاستخراج هذه ؟",
	"invoice.user.updated.success": "تم تحديث المستخدم بنجاح",
	"admin.config.ftp.message.confirm": "هل أنت متأكد أنك تريد تعديل هذا التكوين لخادم FTP؟",
	"admin.config.ftp.update-message": "تم تحديث تكوين خادم FTP بنجاح",
	agent_accounting: "وكيل محاسبة",
	agent_purchase: "وكيل شراء",
	"admin.settings.invoice": "فاتورة",
	"admin.settings.order": "أمر",
	"admin.settings.invoices": "إعدادات الفواتير",
	"admin.settings.orders": "إعدادات الطلبات",
	"admin.order.download-buttons": "الإجراءات على الطلبات",
	"admin.order.download-buttons-description":
		"اختر الإجراءات التي تريد السماح بها لأوامرك في البوابة.",
	"admin.order.AUTO_EXPORT-description":
		"تعمل هذه المعلمة على تنشيط التصدير التلقائي للأمر المستخرج بالكامل وليس به رسائل خطأ.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"يسمح لك هذا الحقل بتعيين تفاوت في حساب مجاميع الطلب. سيتم عرض رسائل الخطأ على أساس هذا التفاوت.",
	"admin.setting.last-5-orders": "آخر 5 طلبات",
	"admin.order.setting.delete-all": "احذف جميع الطلبات",
	"admin.order.export-buttons-description":
		"قم بإعداد عرض اختصارات التنزيلات الممكنة بعد تصدير الطلب. ستظهر الأزرار بعد التصدير في تفاصيل الطلب.",
	"admin.settings.order.upload": "تحميل الطلبات",
	"order.exported": "أوامر التصدير",
	"order.unexported": "الطلبات غير المصدرة",
	"admin.AUTO_EXPORT": "تصدير تلقائي",
	"admin.download-button.journal-entry": "قم بتنزيل Excel Journal Entry",
	"pagination.rows.per.page": "عدد الصفوف في الصفحة:",
};
