export default {
	lastName: "Nom",
	firstName: "Prénom",
	email: "E-mail",
	Username: "Le nom d'utilisateur",
	userFunction: "Fonction",
	supplier_name: "Client",
	client_name: "Nom du client",
	job: "Intitulé de poste",
	manageUsers: " Gestion des utilisateurs",
	manageSuppliers: "Espace Clients",
	manageClients: "Espace Clients",
	manageGroups: "Gestion des groupes",
	manageRoles: "Gestion des rôles",
	manageWorkflow: "Gestion du workflow",
	manageLicenseAddOn: "Gestion licences & Add-ons",
	status: "Statut",
	ACTIVE: "Actif",
	WAITING: "En attente",
	DISABLE: "Inactif",
	add_user: "Ajouter utilisateur",
	add_user_button: "AJOUTER UTILISATEUR ",
	add_supplier_button: "AJOUTER Client",
	add_client_button: "AJOUTER CLIENT",
	sendEmailCreationUserMessage:
		"Un mail avec l'identifiant et le mot de passe a été envoyé à l’utilisateur pour pouvoir se connecter",
	CancelActionMessage: "Êtes-vous sûr de vouloir annuler cette action ?",
	searchUser: "Recherche",
	userCivility: "Civilité",
	administrator: "Administrateur",
	user: "Agent",
	Mr: "Monsieur",
	Ms: "Mademoiselle",
	Mrs: "Madame",
	enableDisableActionMessage: "Êtes-vous sûr de vouloir activer / désactiver cet utilisateur?",
	edit_user: "Modifier Utilisateur",
	add_supplier: "Ajouter client",
	edit_supplier: "Modifier client",
	add_client: "Ajouter un client partenaire",
	// validation messages
	lastName_required: "Nom obligatoire",
	lastName_notValid: "Nom invalide",
	supplierName_notValid: "Le nom du client n'est pas valide",
	phone_notValid: "Le téléphone n'est pas valide",
	mobile_required: "Mobile est obligatoire",
	supplier_required: "Le nom du client est obligatoire",
	firstName_required: "Le prènom est obligatoire",
	firstName_notValid: "Le prènom n'est pas valide",
	email_required: "L'e-mail est obligatoire",
	companyName_required: "La raison sociale est obligatoire",
	email_notValid: "L'e-mail n'est pas valide",
	siret_notValid: "Numéro SIRET invalide",
	url_notValid: "L'URL n'est pas valide",
	supplier_created: "Client créé avec succès !",
	supplier_updated: "Client mis à jour avec succès !",
	supplier_error: "Une erreur s'est produite lors de l'enregistrement du client",
	supplier_update_error: "Échec de la mise à jour des détails du client !",
	job_required: "Intitulé du poste obligatoire",
	civility_required: "Civilité obligatoire",
	deleteUserActionMessage: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
	deleteUsersActionMessage: "Êtes-vous sûr de vouloir supprimer ces utilisateurs ?",
	"admin.settings": "Paramètres",
	"admin.config.update-settings": "Les paramètres ont été modifiés avec succès",
	"admin.AUTO_EXPORT": "Export Automatique",
	"admin.email-config": "Configuration d'e-mail",
	"admin.ftp-config": "Configuration FTP",
	"admin.import-config": "Configuration d'import",
	"admin.folder-config": "Configuration des dossiers",
	"admin.config.update-message": "La configuration d'e-mail a été mise à jour avec succès",
	"admin.AMOUNT_TOLERANCE": "Tolérance des Totaux",
	"admin.MAXIMUM_SIZE": "Taille maximum",
	field_required: "Obligatoire",
	Host: "Hôte",
	Port: "Port",
	Email: "E-mail",
	Password: "Mot de passe",
	"Read folder": "Dossier de lecture",
	successDeleteBill: "La facture a été supprimé avec succès.",
	"Processing folder": "Dossier de traitement",
	"Success folder": "Dossier de réussite",
	"Exception folder": "Dossier d'exceptions",
	supplierTraining: "Apprentissage client",
	deleteTraining: "Supprimer apprentissage",
	deleteTrainingMessage: "Êtes-vous sûr de vouloir supprimer le training de ce client?",
	successdeleteTrainingMessage: "L'apprentissage a été supprimé avec succès",
	"admin.download-buttons": "Actions sur les factures",
	"admin.download-buttons-description":
		"Choisissez les actions que vous voudriez permettre sur vos factures dans le portail.",
	"admin.download-button.excel": "Télécharger au Format Excel",
	"admin.download-button.journal-entry": "Télécharger Excel écriture comptable",
	"admin.download-button.xml": "Télecharger au Format XML",
	"admin.download-button.pdf": "Télécharger au Format PDF",
	"admin.download-button.edi": "Télécharger au Format EDI",
	"admin.invoice.AUTO_EXPORT-description":
		"Ce paramètre active l'export automatique des redevances.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Ce champs vous permet de paramétrer une tolérance dans les calculs des totaux d’une facture. C’est sur la base de cette tolérance que les messages d’erreur seront affichés.",
	"admin.MAXIMUM_SIZE-description": "Taille maximale d'un document à télécharger en Mo",
	"admin.display-indicators": "Affichage des indicateurs dans le tableau de bord",
	"admin.display-indicators-description":
		"Choisissez les indicateurs vous voulez visualiser dans votre Dashboard Supply Chain.",
	"admin.setting.my_tasks": "Mes tâches",
	"admin.setting.invoices-exported": "Factures exportées",
	"admin.setting.invoices-not-exported": "Factures non exportées",
	"admin.setting.last-actions": "Dernières actions",
	"admin.setting.load-invoices": "Charger des factures",
	"admin.setting.last-5-invoices": "Dernières 5 Factures",
	"admin.setting.download-pdf": "Télécharger le PDF",
	"admin.setting.delete": "Supprimer",
	"admin.setting.delete-all": "Supprimer toutes les factures",
	"admin.setting.AMOUNT_TOLERANCE.required": "Tolérance des totaux requise",
	"admin.setting.relaunch-extraction": "Relancer l'extraction",
	"admin.setting.export_entry_account_excel": "Exporter Excel écriture comptable",
	"admin.export-buttons": "Boutons de téléchargement à l’export",
	"admin.invoice.export-buttons-description":
		"Paramétrez l’affichage des raccourcis de téléchargements possibles après l’export d’une redevance. Les boutons seront visibles après l’export dans le détail des factures.",
	"admin.extraction-level-settings": "L'importance des champs extraits (Niveau d'extraction)",
	"admin.extraction-level-settings-desciption":
		"Paramétrer l'importance des champs qui vous semblent les plus importants et des champs, qui vous sont indispensables. Cela sera pris en compte dans le calcul du niveau d'extraction.",
	"admin.level-settings": "Paramètres de Niveau d'extraction",
	"admin.extraction-level.champs": "Champs",
	"admin.extraction-level.status": "Statut",
	"admin.extraction-level.mandatory": "Indispensable",
	"admin.extraction-level.ignored": "Ignoré",
	"admin.extraction-level.important": "Important",
	"admin.setting.message.confirm": "Voulez-vous vraiment modifier ces paramètres ?",
	"admin.user.confirm.message.edit": "Voulez-vous vraiment modifier cet utilisateur ?",
	"admin.user.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.supplier.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.client.confirm.message.add": "Voulez-vous vraiment ajouter cet utilisateur ?",
	"admin.supplier.confirm.message.edit": "Voulez-vous vraiment modifier cet utilisateur ?",
	"admin.config.message.confirm":
		"Voulez-vous vraiment modifier cette configuration de messagerie ?",
	"admin.extraction.message.confirm":
		"Voulez-vous vraiment mettre à jour ces niveaux d'extraction ?",
	"invoice.user.updated.success": "L'utilisateur a été modifié  avec succès",
	"admin.config.ftp.message.confirm":
		"Voulez-vous vraiment modifier cette configuration du serveur FTP ?",
	"admin.config.ftp.update-message":
		"La configuration du serveur FTP a été mise à jour avec succès",
	agent_accounting: "Agent comptable",
	agent_purchase: "Agent d'achat",
	"admin.settings.invoice": "Facture",
	"admin.settings.order": "Commande",
	"admin.settings.invoices": "Paramètres des redevances",
	"admin.settings.orders": "Paramètres des commandes",
	"admin.order.download-buttons": "Actions sur les commandes",
	"admin.order.download-buttons-description":
		"Choisissez les actions que vous souhaitez autoriser sur vos commandes dans le portail.",
	"admin.order.AUTO_EXPORT-description":
		"Ce paramètre active l'exportation automatique des commandes entièrement extraites et sans message d'erreur.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"Ce champ vous permet de définir une tolérance dans le calcul des totaux d'une commande. C'est sur la base de cette tolérance que les messages d'erreur seront affichés.",
	"admin.setting.last-5-orders": "5 dernières commandes",
	"admin.order.setting.delete-all": "Supprimer toutes les commandes",
	"admin.order.export-buttons-description":
		"Configurer l'affichage des raccourcis de téléchargement possibles après l'exportation d'une commande. Les boutons seront visibles après l'export dans les détails de la commande.",
	"admin.settings.order.upload": "Télécharger les commandes",
	"order.exported": "Commandes exportées",
	"order.unexported": "Commandes non exportées",
	"pagination.rows.per.page": "Lignes par page:",
};
