import React from "react";

import InvoiceHeaders from "./InvoiceHeaders";
import BillHeaders from "./BillHeaders";
import DeclarationCAHeaders from "./DeclarationCAHeaders";
import PaymentHeaders from "./PaymentHeaders";

function TableHeaders({
	isInvoiceTable,
	idSupplier,
	isPaymentTable,
	isDeclarationCATable,
	isParentChecked,
	setIsParentChecked,
	checkedAll,
	setCheckedAll,
	handleCheckedAll,
}) {
	return isInvoiceTable ? (
		<InvoiceHeaders
			idSupplier={idSupplier}
			isParentChecked={isParentChecked}
			setIsParentChecked={setIsParentChecked}
			checkedAll={checkedAll}
			setCheckedAll={setCheckedAll}
			handleCheckedAll={handleCheckedAll}
		/>
	) : isPaymentTable ? (
		<PaymentHeaders idSupplier={idSupplier} />
	) : isDeclarationCATable ? (
		<DeclarationCAHeaders idSupplier={idSupplier} />
	) : (
		<BillHeaders idSupplier={idSupplier} />
	);
}

export default TableHeaders;
