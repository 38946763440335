import React from "react";

import { Grid, Tooltip } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import { defaultTo } from "lodash";
import { currencyFormat, getCurrencySymbol } from "utils";
import { useTranslation } from "react-i18next";

function PaymentElements({ row }) {
	const [open, setOpen] = React.useState({
		libPaiement: false,
		codeLettrage: false,
	});
	const { t } = useTranslation();
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};

	return (
		<>
			<Grid item xs={1}>
				<Typography color="primary">
					{row.month && row.annee
						? `${row.month}/${row.annee}`
						: row.month
						? row.month
						: row.annee
						? row.annee
						: "--"}
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography color="primary">{row.societe}</Typography>
			</Grid>
			<Grid item xs={3}>
				<Tooltip
					open={open.libPaiement}
					onClose={() => handleClose("libPaiement")}
					onOpen={row?.libPaiement?.length > 20 && (() => handleOpen("libPaiement"))}
					title={defaultTo(row?.libPaiement, "\xa0")}
				>
					<Typography
						color="primary"
						style={{ width: "95%" }}
						noWrap={row?.libPaiement?.length > 20}
					>
						{row.libPaiement}
					</Typography>
				</Tooltip>
			</Grid>
			<Grid item xs={1}>
				<Typography color="primary">{row.devise}</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography color="primary" style={{ textAlign: "center" }}>{`${currencyFormat(
					row.montant ?? "0"
				)} ${getCurrencySymbol(row?.codeSociete)}`}</Typography>
			</Grid>
			<Grid item xs={1} />

			<Grid item xs={2}>
				<Typography color="primary">{t(row.typeLot)}</Typography>
			</Grid>
		</>
	);
}

export default PaymentElements;
