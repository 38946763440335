import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TablePagination } from "@material-ui/core";
import { fetchSupplierBills } from "redux/actions/suppliers";
import { updateBillFilter } from "redux/filterBills";
import { useTranslation } from "react-i18next";
import TableElement from "../TableElement";

function BillTable({
	idSupplier,
	data,
	classes,
	setOpenDeletePopup,
	setSelectedId,
	companyCode,
	isParentChecked,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const filterBills = useSelector(({ filterBills: i }) => i);
	const handleChangeBillsPage = (event, newPage) => {
		dispatch(updateBillFilter({ ...filterBills, pageNo: newPage }));
	};
	const handleChangeRowsBillsPerPage = (event) => {
		dispatch(
			updateBillFilter({
				...filterBills,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};

	useEffect(() => {
		dispatch(fetchSupplierBills(idSupplier, filterBills));
	}, [filterBills]);
	return (
		<Grid item xs={12}>
			{data?.content?.map((row, i) => (
				<>
					<TableElement
						companyCode={companyCode}
						classes={classes}
						row={row}
						key={i}
						setOpenDeletePopup={setOpenDeletePopup}
						setSelectedId={setSelectedId}
						isBillTable
						isParentChecked={isParentChecked}
					/>
				</>
			))}
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="div"
				count={data.totalElements}
				rowsPerPage={filterBills.pageSize}
				page={filterBills.pageNo}
				onChangePage={handleChangeBillsPage}
				onChangeRowsPerPage={handleChangeRowsBillsPerPage}
				labelRowsPerPage={t("pagination.rows.per.page")}
			/>
		</Grid>
	);
}

export default BillTable;
