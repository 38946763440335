/* eslint-disable */
import { format, parse } from "date-fns";
import {
	DELETE_LIST_INVOICE,
	FETCH_STATUS,
	FETCH_SUPPLIER,
	FETCH_SUPPLIERS,
	FETCH_PAYMENT_TERM,
	FETCH_PAYMENT_TERMS,
	FETCH_DOCUMENT_INVOICE,
	FETCH_DOCUMENT_BILL,
	FETCH_INVOICE,
	FETCH_BILL,
	FETCH_REJECTION_MOTIFS,
	CREATE_INVOICE_REJECTION,
	VERIFY_INVOICE,
	LIST_FETCH_INVOICE,
	DOWNLOAD_LIST_INVOICE,
	INVOICE_TOTAL_AMOUNT,
	PATCH_COMMAND_INVOICE,
	LIST_FETCH_DELIVERY_METHOD,
	FETCH_COUNT_SUPPLIERS,
	INVOICE_TOTAL_VAT_AMOUNT,
	FETCH_LAST_ACTIONS_INVOICE,
	FETCH_COUNT_INVOICE_INDICATOR,
	INVOICE_TOTAL_OVERDUE_AMOUNT,
	FETCH_INVOICE_HISTORY_LIST,
	TOTAL_INVOICE,
	EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL,
	FETCH_MESSAGES_HISTORY,
	FETCH_DOCUMENT_DECLARATION,
} from "../../constants";
import {
	URL_STATUS,
	URL_SUPPLIER,
	URL_PAYMENT_TERM,
	URL_INVOICE,
	URL_MOTIF,
	URL_CREATE_INVOICE_REJECTION,
	URL_VERIFY_INVOICE,
	URL_INVOICE_TOTAL_AMOUNT,
	URL_DELIVERY_METHOD,
	URL_COUNT_SUPPLIER,
	URL_INVOICE_TOTAL_VAT_AMOUNT,
	URL_LAST_ACTION_INVOICE,
	URL_INVOICE_INDICATORS,
	URL_INVOICE_TOTAL_OVERDUE_AMOUNT,
	URL_TO_PAY_INVOICE,
	URL_INVOICE_HISTORY,
	URL_BASE_INVOICE,
	URL_RELAUNCH_EXTRACTION,
	URL_BASE_EXPORT,
} from "../../constants/endpoint";

import { LIST_FETCH_STATUS_HISTORY, LIST_FETCH_BILLING_LINES } from "../../actionType/index";

export const fetchStatus = (id) => ({
	type: FETCH_STATUS,
	request: { url: `${URL_STATUS}/${id}` },
});

export const fetchListStatus = () => ({
	type: `LIST_${FETCH_STATUS}`,
	request: { url: `${URL_STATUS}` },
});

export const fetchListHistory = (id) => ({
	type: LIST_FETCH_STATUS_HISTORY,
	request: {
		url: `${URL_BASE_INVOICE}/${id}/history/old`,
	},
});

export const fetchBillingLines = (id) => ({
	type: LIST_FETCH_BILLING_LINES,
	request: {
		url: `${URL_BASE_INVOICE}/${id}/billing`,
	},
});
export const invoicesNotDeleted = (data) => ({
	type: "INVOICES_NOT_DELETED",
	payload: data,
});
export const invoicesTotalAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${INVOICE_TOTAL_AMOUNT}${categorie}`,
		request: {
			url: URL_INVOICE_TOTAL_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};

export const invoicesTotalVATAmounts = (status, categorie) => {
	categorie = categorie || "";
	return {
		type: `${INVOICE_TOTAL_VAT_AMOUNT}${categorie}`,
		request: {
			url: URL_INVOICE_TOTAL_VAT_AMOUNT,
			params: { status: status && status.join(",") },
		},
	};
};
export const fetchSupplier = (id) => ({
	type: FETCH_SUPPLIER,
	request: { url: `${URL_SUPPLIER}/${id}` },
});

export const fetchCountSuppliers = () => ({
	type: `${FETCH_COUNT_SUPPLIERS}`,
	request: { url: `${URL_COUNT_SUPPLIER}` },
});

export const fetchCountInvoices = () => ({
	type: TOTAL_INVOICE,
	request: { url: `${URL_BASE_INVOICE}/supplier/invoice/count` },
});

export const fetchSuppliers = () => ({
	type: `${FETCH_SUPPLIERS}`,
	request: { url: `${URL_SUPPLIER}` },
});

export const fetchPaymentTerm = (id) => ({
	type: FETCH_PAYMENT_TERM,
	request: { url: `${URL_PAYMENT_TERM}/${id}` },
});

export const fetchPaymentTerms = () => ({
	type: `${FETCH_PAYMENT_TERMS}`,
	request: { url: `${URL_PAYMENT_TERM}` },
});

export const fetchDeliveryMethods = () => ({
	type: LIST_FETCH_DELIVERY_METHOD,
	request: { url: URL_DELIVERY_METHOD },
});

export const fetchDocumentDeclaration = (idInvoice, onSuccess, onError) => ({
	type: FETCH_DOCUMENT_DECLARATION,
	request: {
		url: `/invoice/declarationCA/${idInvoice}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			onSuccess("application/pdf");
			return response;
		},
		onError,
	},
});

export const fetchDocumentInvoice = (idInvoice, onSuccess, onError) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${idInvoice}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					const extension = filename.split(".").pop();
					if (extension === "pdf") {
						onSuccess("application/pdf");
					} else {
						onSuccess(`image/${extension}`);
					}
				}
			}
			return response;
		},
		onError,
	},
});

export const fetchDocumentBill = (idBill, onSuccess, onError) => ({
	type: FETCH_DOCUMENT_BILL,
	request: {
		url: `${URL_BASE_INVOICE}/bill/${idBill}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			onSuccess("application/pdf");
			return response;
		},
		onError,
	},
});

export const downloadDocumentBill = (idBill, onError) => ({
	type: FETCH_DOCUMENT_BILL,
	request: {
		url: `${URL_BASE_INVOICE}/bill/${idBill}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
		onError: onError,
	},
});

export const fetchRejectionMotifs = () => ({
	type: `${FETCH_REJECTION_MOTIFS}`,
	request: {
		url: `${URL_MOTIF}`,
	},
});

export const downloadDeclaration = (idInvoice, onError) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `/invoice/declarationCA/${idInvoice}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "declaration.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
		onError: onError,
	},
});

export const downloadInvoice = (idInvoice, onError) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${idInvoice}/pdf`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.pdf");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
		onError: onError,
	},
});

export const downloadInvoiceExcel = (idInvoice) => ({
	type: "FETCH_EXCEL_INVOICE",
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/excel`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "invoice.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const downloadInvoices = (idInvoices, type = "inv") => ({
	type: DOWNLOAD_LIST_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/pdf`,
		params: { idInvoices: idInvoices.join(","), type },
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			link.setAttribute("download", "invoices.zip");
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const fetchInvoice = (id) => ({
	type: FETCH_INVOICE,
	request: { url: `${URL_BASE_INVOICE}/${id}` },
	meta: {
		onSuccess: (response) => {
			if (response.status === 200) {
				let { data } = response;
				const issueDate = response.data.issueDate
					? parse(response.data.issueDate, "dd/MM/yyyy", new Date())
					: null;
				const dueDate = response.data.dueDate
					? parse(response.data.dueDate, "dd/MM/yyyy", new Date())
					: null;
				data = {
					...data,
					issueDate: issueDate ? format(issueDate, "yyyy-MM-dd") : null,
					dueDate: dueDate ? format(dueDate, "yyyy-MM-dd") : null,
				};
				response = { ...response, data };
			}
			return response;
		},
	},
});

export const deleteInvoice = (id, onSuccess, onError) => ({
	type: FETCH_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const deleteBill = (id, onSuccess) => ({
	type: FETCH_BILL,
	request: {
		url: `${URL_BASE_INVOICE}/bill/${id}`,
		method: "delete",
	},
	meta: {
		onSuccess: () => {
			if (onSuccess) onSuccess();
		},
	},
});

export const fetchInvoices = (filter = {}) => {
	const { invoiceNoOrSupplierCode, startDate, endDate, pageNo, pageSize, sortBy } = filter;
	return {
		type: LIST_FETCH_INVOICE,
		request: {
			url: `${URL_INVOICE}`,
			params: {
				invoiceNoOrSupplierCode,
				startDate: startDate ? format(new Date(startDate), "yyyy/MM/dd") : null,
				endDate: endDate ? format(new Date(endDate), "yyyy/MM/dd") : null,
				pageNo,
				pageSize,
				sortBy,
			},
		},
	};
};

export const deleteInvoices = (idInvoices, all, deleteAll, onSuccess) => ({
	type: DELETE_LIST_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/`,
		method: "delete",
		params: { idInvoices: idInvoices.join(","), all, deleteAll },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) {
				onSuccess();
			}
			store.dispatch(fetchInvoices());
			return store.dispatch(invoicesNotDeleted(response.data));
		},
	},
});

export const updateInvoice = (id, invoice) => {
	if (invoice && invoice.issueDate) {
		invoice = {
			...invoice,
			issueDate: format(new Date(invoice.issueDate), "dd/MM/yyyy"),
		};
	}
	if (invoice && invoice.dueDate) {
		invoice = {
			...invoice,
			dueDate: format(new Date(invoice.dueDate), "dd/MM/yyyy"),
		};
	}
	return {
		type: "UPDATE_INVOICE",
		request: {
			url: `${URL_BASE_INVOICE}/${id}`,
			method: "put",
			data: invoice,
		},
	};
};
export const updateCommand = (idInvoice, command) => ({
	type: PATCH_COMMAND_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${idInvoice}`,
		method: "patch",
		data: command,
	},
});

export const createInvoice = (file, idClient) => {
	const data = new FormData();
	data.append("file", file);
	return {
		type: "CREATE_INVOICE",
		request: {
			url: `${URL_BASE_INVOICE}/bill/upload`,
			method: "post",
			params: { idClient: idClient },
			data,
			headers: { "Content-Type": "multipart/form-data" },
		},
		meta: {
			onSuccess: (response) => response,
		},
	};
};
export const createInvoiceRejection = (id, invoiceRejection, onSuccess, onError) => ({
	type: CREATE_INVOICE_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_CREATE_INVOICE_REJECTION}`,
		method: "post",
		data: invoiceRejection,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchInvoice(id));
		},
	},
});

export const createInvoiceApproval = (id, invoiceApproval, onSuccess, onError) => ({
	type: CREATE_INVOICE_REJECTION,
	request: {
		url: `${URL_BASE_INVOICE}/protected/${id}/tovalidate`,
		method: "put",
		data: invoiceApproval,
		headers: { "Content-Type": "application/json" },
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: (error, requestAction, store) => {
			if (onError) onError();
			return store.dispatch(fetchInvoice(id));
		},
	},
});

export const verifyInvoice = (id, onSuccess, onError) => ({
	type: VERIFY_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_VERIFY_INVOICE}`,
		method: "put",
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOcrData = (id, onSuccess, onError) => ({
	type: "FETCH_OCR_DATA",
	request: { url: `${URL_BASE_INVOICE}/${id}/ocr/data` },
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess(response.data);
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchOcrImage = (imageId) => ({
	type: "FETCH_OCR_IMAGE",
	request: { url: `${URL_BASE_INVOICE}/${imageId}/ocr/data` },
});

export const rectifyInvoice = (id, invoiceDto) => ({
	type: "APPLY_RECTIFY_INVOICE",
	request: {
		url: `${URL_BASE_INVOICE}/${id}/ocr/correct/apply`,
		method: "put",
		data: invoiceDto,
	},
	meta: {
		onSuccess: (response) => {
			window.location.reload(false);
			return response;
		},
		onError: () => {},
	},
});

export const fetchLastThreeInvoices = () => ({
	type: FETCH_LAST_ACTIONS_INVOICE,
	request: {
		url: `${URL_LAST_ACTION_INVOICE}`,
	},
});

export const fetchInvoiceIndicators = () => ({
	type: FETCH_COUNT_INVOICE_INDICATOR,
	request: {
		url: `${URL_INVOICE_INDICATORS}`,
	},
});

export const invoicesTotalAmountsOverDue = () => ({
	type: `${INVOICE_TOTAL_OVERDUE_AMOUNT}`,
	request: {
		url: URL_INVOICE_TOTAL_OVERDUE_AMOUNT,
	},
});

export const downloadXMLInvoice = (idInvoice) => ({
	type: FETCH_DOCUMENT_INVOICE,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/xmlpivot`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "export.xml");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const topayInvoice = (id, onSuccess, onError) => ({
	type: URL_TO_PAY_INVOICE,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_TO_PAY_INVOICE}`,
		method: "put",
	},
	meta: {
		onSuccess: (response, requestAction, store) => {
			if (onSuccess) onSuccess();
			return store.dispatch(fetchInvoice(id));
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchInvoiceHistory = (id) => ({
	type: FETCH_INVOICE_HISTORY_LIST,
	request: {
		url: `${URL_BASE_INVOICE}/${id}${URL_INVOICE_HISTORY}`,
	},
});

export const exportEdiFile = (id, onSuccess, onError) => ({
	type: "EXPORT_EDI_FILE",
	request: {
		url: `${URL_BASE_EXPORT}/${id}/edi`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const exportXMLFileToServer = (id, onSuccess, onError) => ({
	type: "EXPORT_XML_FILE",
	request: {
		url: `${URL_BASE_EXPORT}/${id}/xml`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const downloadInvoicesXML = (idInvoices, filter = {}) => {
	const { selectAll } = filter;
	return {
		type: "DOWNLOAD_LIST_INVOICE_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/xmlpivot`,
			params: { selectAll, idInvoices: idInvoices.join(",") },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "invoicesXML.zip");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const relaunchInvoice = (id, onSuccess, onError) => ({
	type: "UPDATE_INVOICE",
	request: {
		url: `${URL_RELAUNCH_EXTRACTION}/${id}`,
		method: "put",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const relaunchInvoices = (idInvoices, onSuccess, onError) => ({
	type: "UPDATE_INVOICE",
	request: {
		url: `${URL_BASE_INVOICE}/relaunch`,
		params: { idInvoices: idInvoices.join(",") },
		method: "get",
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});
export const downloadInvoicesExcel = (idInvoices, filter = {}) => {
	const { selectAll } = filter;
	return {
		type: "DOWNLOAD_LIST_INVOICE_EXCEL",
		request: {
			url: `${URL_BASE_EXPORT}/excel`,
			params: { idInvoices: idInvoices.join(","), selectAll },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "invoices.xlsx");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const logout = () => ({
	type: "LOGOUT",
	request: {
		url: `${process.env.REACT_APP_GATEWAY}/logout`,
	},
	meta: {
		onSuccess: (response) => {
			const currectUrl = window.location.origin;
			window.location.href = `${process.env.REACT_APP_GATEWAY}/login/sso?redirect_url=${encodeURI(
				currectUrl
			)}`;
			return response;
		},
	},
});
export const exportSingleAccountingEntryExcel = (idInvoice) => ({
	type: `${EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL}_LIST`,
	request: {
		url: `${URL_BASE_EXPORT}/${idInvoice}/excel/journal`,
		responseType: "blob",
	},
	meta: {
		onSuccess: (response) => {
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(new Blob([response.data]));
			const disposition = response.headers["content-disposition"];
			if (disposition && disposition.indexOf("attachment") !== -1) {
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(disposition);
				if (matches !== null && matches[1]) {
					const filename = matches[1].replace(/['"]/g, "");
					link.setAttribute("download", filename);
				} else {
					link.setAttribute("download", "écriturecomptable_multiple_invoices.xlsx");
				}
			}
			document.body.appendChild(link);
			link.click();
			link.remove();
			return response;
		},
	},
});

export const exportAccountingEntriesExcel = (idInvoices, filter = {}) => {
	const { selectAll } = filter;
	return {
		type: `${EXPORT_INVOICE_ENTRY_ACCOUNT_EXCEL}_LIST`,
		request: {
			url: `${URL_BASE_EXPORT}/excel/journal`,
			params: { idInvoices: idInvoices.join(","), selectAll },
			responseType: "blob",
		},
		meta: {
			onSuccess: (response) => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.setAttribute("download", "écriturecomptable_multiple_invoices.xlsx");
				document.body.appendChild(link);
				link.click();
				link.remove();
				return response;
			},
		},
	};
};

export const fetchMessagesHistoryWithClient = (supplierId) => ({
	type: FETCH_MESSAGES_HISTORY,
	request: {
		url: `${URL_BASE_INVOICE}/message/history`,
		params: { supplierId },
		method: "get",
	},
});

export const postReply = (replyText, files = [], supplierId) => {
	const data = new FormData();

	files.forEach((file) => {
		data.append("file", file);
	});

	data.append("replyText", replyText);
	return {
		type: "POST_USER_REPLY",
		request: {
			url: `${URL_BASE_INVOICE}/message/reply/send`,
			method: "post",
			data,
			params: { supplierId },
			headers: { "Content-Type": "application/json" },
		},
	};
};
