export default {
	countSuppliers: "Gesamtzahl der Partnerkunden",
	searchSupplier: "Lieferant finden",
	contact: "Kontakt",
	principalContact: "Ansprechspartner",
	outstandingInvoices: "Unbezahlte Rechnungen",
	addSupplier: "Lieferant einfügen",
	"limited.liability.company": "GmbH",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "AG",
	"general.partnership": "G.b.R.",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Sonstiges",
	companySiret: "MwSt-Nr.",
	vatNumber: "USt-IdNr.",
	legalForm: "Rechtsform",
	codeNaf: "Stellung im Beruf",
	email: "EMail",
	address: "Adresse",
	fax: "Fax",
	socialCapital: "Aktienkapital",
	name: "Name",
	function: "Funktion",
	phone: "Telefon",
	bank: "Bank",
	iban: "IBAN",
	bic: "BIC",
	rib: "Bankkoordinaten",
	account_journal_auxiliary: "Zusatzbuchungsjournal",
	account_journal_number: "Buchführungskonto",
	noPrincipalContact: "Keiner Hauptansprechspartner",
	financialInformation: "Finanzielle Informationen",
	associatedContact: "Zugehörige Kontakte",
	return: "Zurück",
	invoice_uploaded_error: "Die Rechnung wurde nicht hinzugefügt",
	invoice_uploaded_success: "Erfolgreiche Zufügung von der Rechnung.",
	"upload.instructions": "Anleitungen",
	"upload.instructions1": "Füllen Sie die Excel-Tabelle mit den Lieferanten-Informationen",
	"upload.instructions2":
		"Füllen Sie die «Logo»-Spalte mit dem Dateiname des Logos. (z.B.: logofilename.jpg)",
	"upload.instructions3": "Alle Felder müssen im TEXT-Format sein",
	"upload.instructions4":
		"Stellen Sie die gefüllte Excel-Tabelle und die Lieferanten-Logos im gleichen Ordner",
	"upload.instructions5": "Komprimieren Sie diesen Ordner:",
	"upload.instructions51":
		'Windows: Rechten Klick auf dem Ordner, dann wählen Sie "Schicken nach " dann klicken Sie auf "komprimierter Ordner "',
	"upload.instructions52":
		"MAC: Klicken Sie darauf und halten sie die Taste gedrückt oder drücken Sie einfach mit zwei Finger, dann wählen Sie Komprimieren",
	"upload.instructions6":
		"Laden Sie den komprimierten Ordner auf dem Portal oder verschieben und stellen Sie ihn im dafür vorgesehenen Bereich",
	"upload.instructions7": 'Klicken Sie auf "Lieferanten aufladen"',
	"supplier.modify": " Kunden ändern",
	"Nb Total des clients": "Gesamtzahl der Kunden",
	"Nb Total des redevances": "Gesamtzahl der Umsatzbeteiligungen",
	"admin reference": "Ansprechpartner ME Group",
	"Ajouter un commentaire": "Einen Kommentar hinzufügen",
	"post comment": "Kommentar hinzufügen",
	"messagerie interne": "messagerie interne",
	"Big.file.message": "Bitte wählen Sie eine Datei mit maximal 2 MB.",
	"missing.comment": "Bitte fügen Sie einen Kommentar ein.",
};
