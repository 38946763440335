/* eslint-disable */
import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierBills } from "redux/actions/suppliers";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import useStyles from "./style";

function BillHeaders({ idSupplier }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [sortBy, setSortBy] = useState("");
	const filterBills = useSelector(({ filterBills: i }) => i);
	const [clickedRow, setClickedRow] = useState({
		createdAt: true,
		invoiceNo: false,
		dueDate: false,
		total: false,
	});
	const handleOnClickBillRow = (row) => {
		setClickedRow((clickedRow) => ({ [row]: !clickedRow[row] }));
		setSortBy(row);
		dispatch(
			fetchSupplierBills(idSupplier, {
				...filterBills,
				sortBy: row,
				pageNo: 0,
			})
		);
	};
	return (
		<Grid container item xs={12}>
			<Grid item xs={1}>
				<Typography>&nbsp;</Typography>
			</Grid>
			<Grid item xs={2} onClick={() => handleOnClickBillRow("createdAt")}>
				<Typography className={classes.headers}>
					{clickedRow["createdAt"] && <SwapVertIcon />} {t("creation.date")}
				</Typography>
			</Grid>
			<Grid item xs={8} />
			<Grid item xs={1}>
				<Typography>&nbsp;</Typography>
			</Grid>
		</Grid>
	);
}

export default BillHeaders;
