import { createSlice } from "@reduxjs/toolkit";

export const initialFilter = {
	paymentNoOrSupplierCode: "",
	pageNo: 0,
	pageSize: 10,
	sortBy: "",
};
const filterListPayments = createSlice({
	name: "filter",
	initialState: initialFilter,
	reducers: {
		updatePaymentFilter: (state, action) => action.payload,
		initFilter: () => initialFilter,
	},
});

export const { updatePaymentFilter, initFilter } = filterListPayments.actions;

export default filterListPayments.reducer;
