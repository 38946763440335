import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	appBar: {},
	tabs: {},
	selectedTab: {
		color: "#0da8ad",
	},
	tab: {
		width: "50%",
	},
	settingsContainer: {
		paddingTop: "4%",
		marginLeft: "24px",
		width: "calc(100%)",
	},
	loginContainer: {
		backgroundColor: theme.palette.background.paper,
		marginLeft: "4px",
	},
	actions: {
		marginTop: "32px",
	},
}));
