import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Typography, Divider } from "@material-ui/core";
import { get, defaultTo } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

export const DetailsLegalInformation = ({ style, supplier }) => {
	const classes = style;
	const [open, setOpen] = React.useState({
		thirdPartyAccount: false,
		companySiret: false,
		raisonSociale: false,
		codeNaf: false,
		vatNumber: false,
		email: false,
		fax: false,
		phone: false,
	});
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};

	const { t } = useTranslation();
	return (
		<Grid container xs={12} spacing={4}>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4}>
					<Typography variant="caption" color="black">
						{t("company.thirdPartyAccount")}
						<br />
						<Tooltip
							open={open.thirdPartyAccount}
							onClose={() => handleClose("thirdPartyAccount")}
							onOpen={
								get(supplier, "locpt")?.length > 21 && (() => handleOpen("thirdPartyAccount"))
							}
							title={get(supplier, "locpt")}
						>
							<Typography
								variant="subtitle2"
								align="left"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
							>
								{defaultTo(get(supplier, "locpt"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={4} spacing={4}>
					<Typography variant="caption" color="black">
						{t("company.companyName")}
						<br />
						<Tooltip
							open={open.raisonSociale}
							onClose={() => handleClose("raisonSociale")}
							onOpen={
								get(supplier, "raisonSociale")?.length > 21 && (() => handleOpen("raisonSociale"))
							}
							title={get(supplier, "raisonSociale")}
						>
							<Typography
								variant="subtitle2"
								align="left"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
							>
								{defaultTo(get(supplier, "raisonSociale"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>

				<Grid item xs={4} spacing={4} className={classes.fields}>
					<Typography variant="caption" color="black">
						{t("vatNumber")}
						<br />
						<Tooltip
							tooltipPlacementRight
							open={open.vatNumber}
							onClose={() => handleClose("vatNumber")}
							onOpen={get(supplier, "vatNumber")?.length > 21 && (() => handleOpen("vatNumber"))}
							title={get(supplier, "vatNumber")}
						>
							<Typography
								variant="subtitle2"
								align="left"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
							>
								{defaultTo(get(supplier, "vatNumber"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={4} alignItems="flex-end">
				<Grid item xs={4} spacing={4} className={classes.fields}>
					<Typography variant="caption" color="black">
						{t("email")}
						<br />
						<Tooltip
							tooltipPlacementTop
							open={open.email}
							onClose={() => handleClose("email")}
							onOpen={get(supplier, "email")?.length > 21 && (() => handleOpen("email"))}
							title={get(supplier, "email")}
						>
							<Typography
								variant="subtitle2"
								align="left"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
							>
								{defaultTo(get(supplier, "email"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>

					<Divider />
				</Grid>
				<Grid item xs={4} spacing={4} className={classes.fields}>
					<Typography variant="caption" color="black">
						{t("supplier.add.phoneFix")}
						<br />
						<Tooltip
							open={open.fax}
							onClose={() => handleClose("fax")}
							onOpen={get(supplier, "fax")?.length > 21 && (() => handleOpen("fax"))}
							title={get(supplier, "fax")}
						>
							<Typography
								variant="subtitle2"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
								align="left"
							>
								{defaultTo(get(supplier, "fax"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={4} spacing={4} className={classes.fields}>
					<Typography variant="caption" color="black">
						{t("phone")}
						<br />
						<Tooltip
							open={open.phone}
							onClose={() => handleClose("phone")}
							onOpen={get(supplier, "phone")?.length > 21 && (() => handleOpen("phone"))}
							title={get(supplier, "phone")}
						>
							<Typography
								variant="subtitle2"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
								align="left"
							>
								{defaultTo(get(supplier, "phone"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsLegalInformation;
