import supllierAr from "../pages/Suppliers/i18n/ar";
import administrationAr from "../administration/i18n/ar";

export default {
	...supllierAr,
	...administrationAr,
	ltl: "ltl",
	Copyright: "حقوق النشر",
	Dashboard: "لوحة القيادة",
	"Business management": "ادارة اعمال",
	Orders: " الطلب ",
	Invoices: "الفواتير",
	Regulations: "اللوائح",
	Suppliers: "الموردون",
	Rapports: "التقارير",
	Search: "بحث",
	"Add your Invoice": " أضف فاتورتك",
	browse: "تصفح",
	"Show all": "عرض الكل",
	id: "اسم المستخدم",
	supplier: "المورد",
	invoiceNo: "رقم الفاتورة",
	licencefeeNo: "رقم رسوم الملكية ",
	paymentNo: "رقم التسوية",
	Redevances: "العائدات",
	issueDate: "يوم الاصدار",
	dueDate: "تاريخ الاستحقاق",
	"creation.date": "تاريخ الإنشاء",
	"payment.date": "تاريخ الدفع",
	"licencefee.date": "تاريخ رسوم الترخيص",
	"Price WT": "السعر بالوزن",
	"Taxe Rate": "معدل الضريبة",
	Price: "السعر",
	"Purchase order": "أمر شراء",
	"Last actions": "الإجراءات الأخيرة",
	Today: "اليوم",
	Yesterday: "في الامس",
	"Last week": "الاسبوع الماضي",
	Status: "الحالة",
	Date: "تاريخ",
	"My tasks": "مهامي",
	"Invoices to verify": "فواتير للتحقق",
	"search.invoice": "ابحث عن رقم فاتورة",
	"search.payment": "ابحث عن رقم الدفع",
	"search.licencefee": "ابحث عن رقم رسوم الترخيص",
	"Invoices to be checked": "الفواتير المراد فحصها",
	"Invoices to Payers": "فواتير للدافعين",
	"Paid invoices": "الفواتير المدفوعة",
	"Unpaid invoices": "فواتير غير مدفوعة",
	"Total amount of invoices": "إجمالي مبلغ الفواتير على مدار الـ 24 شهرًا الماضية ",
	"Payment.initiation.message": "بدأ الدفع وستتم معالجتها في الأيام القادمة",
	"Total amount of orders": "إجمالي مبلغ الطلبات",
	"Total VAT amount": "إجمالي مبلغ ضريبة القيمة المضافة",
	"Statistical overview of purchases": "نظرة إحصائية عامة على المشتريات",
	"Statistical overview of product sales": "نظرة عامة إحصائية على مبيعات المنتج",
	"Total Purchases": "إجمالي المشتريات",
	"Total of expenses": "اجمالي المصاريف",
	"edit.phonenumber": "تغيير رقم الهاتف",
	"edit.email": "تغيير البريد الإلكتروني",
	"Accounting number": "رقم الدفع",
	Late: "متأخر",
	Administrator: "مدير",
	"New suppliers": "عدد العملاء الشريكة",
	"compagny.id": "",
	"New clients": "الموردون الجدد",
	FOUNDED_RESULT: "نتيجة مؤسسية",
	EndDate: "تاريخ الانتهاء",
	StartDate: "تاريخ البدء",
	"Add an invoice": "إضافة فاتورة",
	Download: "تحميل",
	Show: "تبين",
	Delete: "حذف",
	All: "الكل",
	SearchInvoice: "ابحث على فاتورة",
	"Delivery address": "عنوان التسليم",
	"Billing address": "عنوان وصول الفواتير",
	"Payment terms": "شروط الدفع",
	"Order Details": "تفاصيل الطلب",
	"Invoice Details": "تفاصيل الفاتورة",
	"Reject Invoice": "رفض الفاتورة",
	"Correct Extraction": "تصحيح الفاتورة",
	Control: "مراقبة",
	ToPay: "دفع",
	"Total HT": "إجمالي HT",
	"VAT amount": "قيمة الضريبة",
	Discount: "خصم",
	"Total amount": "المبلغ الإجمالي",
	"Invoice overview": "نظرة عامة على الفاتورة",
	History: "التاريخ",
	verificationDate: "تاريخ التحقق",
	rejectionDate: "تاريخ الرفض",
	controlDate: "تاريخ التحكم",
	dateOfReceipt: "تاريخ استلام",
	rejectMessage: "رفض الرسالة",
	controlMessage: "هل أنت متأكد أنك تريد وضع هذه الفاتورة قيد التحكم؟ ",
	ErrorMessageToControl: "لا يمكنك التحكم في هذه الفاتورة:",
	deleteMessage: "هل أنت متأكد أنك تريد حذف هذه الفاتورة (الفواتير)",
	individualDeleteMessage: "هل أنت متأكد أنك تريد حذف هذه الفاتورة؟",
	individualBillDeleteMessage: "هل أنت متأكد أنك تريد حذف هذه الفاتورة؟",
	rejectionReason: "سبب الرفض",
	rejectionDetails: "تفاصيل الرفض",
	cancel: "إلغاء",
	confirm: "تؤكد",
	editForm: "تحرير النموذج",
	countInvoices: "حساب الفواتير ",
	tobeprocessed: "لتتم معالجتها ",
	None: "------------------",
	save: "حفظ",
	edit: "تعديل",
	Actions: "أجراءات",
	itemCode: "الرمز",
	designation: "الصنف",
	totalHt: "المجموع بدون ضرائب",
	quantity: "الكمية",
	unitPrice: "سعر الوحدة",
	deliveryDate: "تاريخ التسليم او الوصول",
	Commands: " الفواتير",
	Total: "مجموع",
	VAT: "قيمة الضريبة",
	"Pre-Tax-Amount": "المبلغ قبل الضريبة",
	sortBy: "صنف حسب",
	createdAt: "أنشئت في",
	updatedAt: "تحديث في",
	Upload: "تحميل الفواتير",
	"edit.iban": "تعديل IBAN",
	"upload.invoice": "تحميل الفواتير",
	InvoicesCannotBeDeleted: "لا يمكن حذف بعض الفواتير",
	InvoicesCannotBeRelaunched: "لا يمكن مطالبة بعض الفواتير",
	"Integration in progress": "قيد التقدم",
	"To verify": "للتحقق",
	"To control": "للتحكم",
	"To pay": "للدفع",
	Payed: "المدفوع",
	Rejected: "مرفوض",
	Canceled: "ألغيت",
	Exception: "استثناء",
	codeArticle: "رمز الصنف",
	deliveryDelay: "أجل التسليم",
	vat: "ضريبة القيمة المضافة",
	orderNo: "رقم الطلب",
	numberOfArticle: "عدد الصنف",
	deliveryMethod: "طريقة التوصيل",
	InvoicesNotCreatedMessage: ": لا يمكن إنشاء الفواتير التالية",
	invoice: "فاتورة",
	order: "طلب",
	training: "...في التدريب",
	trained: "متدرب",
	"VAT Number": "ضريبة الشراء",
	"invoice Number": "رقم الفاتورة",
	"Apply Changes": "تطبيق",
	"Company Siret": "Siret",
	"Invoice was already paid": "تم دفع الفاتورة بالفعل",
	"Goods or Services were never received": " لم يتم استلام السلع أو الخدمات مطلقًا",
	"Supporting documents were not received with the invoice":
		"لم يتم استلام المستندات الداعمة مع الفاتورة",
	"The purchase order number is missing, is incorrect or has been canceled":
		"رقم طلب الشراء مفقود أو غير صحيح أو تم إلغاؤه",
	"The amount being billed does not match the agreed upon amount on the contract":
		"المبلغ الذي يتم إصدار فاتورة به لا يتطابق مع المبلغ المتفق عليه في العقد",
	Other: "أخرى",
	CREATE_ACTION: "إنشاء الفاتورة",
	VERIFY_ACTION: "فحص الكلية",
	REJECT_ACTION: "رفض الفاتورة",
	CONTROL_ACTION: "السيطرة على الفاتورة",
	END_TRAINING_ACTION: "اكتمل التعلم",
	CORRECT_ACTION: "إطلاق التعلم",
	TO_PAY_ACTION: "دفع الفاتورة",
	DELET_ACTION: "شطب الفاتورة",
	RELAUNCH_ACTION: "إعادة إنشاء الفاتورة",
	EXPORT_ACTION: "تصدير الفاتورة",

	action: "عمل",
	details: "تفاصيل",
	totalEmptyMessageError: "لم يتم ملء المبلغ الإجمالي للفاتورة",
	supplierEmptyMessageError: "لم يتم ملء مورد الفاتورة",
	incohenrenceAmountsMessageError:
		"حساب المبالغ غير صحيح ؛ يختلف مبلغ ضريبة القيمة المضافة والمبلغ باستثناء ضريبة القيمة المضافة عن المبلغ الإجمالي",
	invoice_charge: "التكاليف",
	total_extract: "فاتورة مستخرجة بالكامل",
	partial_extract: "فاتورة مستخرجة جزئياً",
	not_extract: "فاتورة غير مستخرجة",
	country: "بلد",
	duplicated_invoice: "فاتورة مكررة",
	"invoice.total_invoice": "إجمالي الفواتير",
	"add.contact": "إضافة جهة اتصال جديدة",
	currency: "عملة",
	phone: "هاتف",
	DeliverySlip: "وثيقة التوصيل",
	"invoice.detail.company_siret": "هوية الشركة",
	reason: "السبب",
	"invoice.exported": "الفواتير المصدرة",
	"invoice.unexported": "الفواتير غير المصدرة",
	"invoice.status.underProcess": "يتم معالجتها",
	"invoice.status.processed": "معالجة",
	"invoice.status.InoviceProcessed": "الفواتير المعالجة",
	"invoice.status.InoviceUnderProcess": "الفواتير قيد المعالجة",
	"invoice.status.external_validation": "في انتظار التأكيد",
	comment: "تعليق",
	"invoice.status.exported": "مصدر",
	"Invoice available": "الفاتورة متاحة",
	"invoice.duplicated": "مكرر",
	"extraction.label.fully_extracted": "مستخرجة بالكامل",
	"extraction.label.partially_extracted": "مستخرجة جزئياً",
	"extraction.label.not_extracted": "غير مستخرجة",
	"extraction.label.not_invoice": "ليست فاتورة",
	"tootlip.action.edit": "تعديل",
	"tooltip.action.delete": "حذف",
	"tooltip.action.relaunch": "إعادة التشغيل",
	"tooltip.action.download": "تحميل",
	"tooltip.action.relaunch_extraction": "أعد تشغيل الاستخراج",
	"tooltip.action.export_excel": "excel تصدير",
	"tooltip.action.export_entry_account_excel": "قيد محاسبة تصدير Excel",
	"tooltip.action.export_xml": "xml تصدير",
	"supplier.add.button": "موردي الاستيراد",
	"supplier.export.suppliers.excel": "تصدير قائمة الموردين",
	"supplier.add2.button": "إضافة مورد",
	"suppliers.add.multiple": "أضف الموردين أو ",
	"upload.supplier": "تحميل الموردين",
	"downloqd.template": "تحميل النموذج",
	"invoice.total_amoutn_to_verify": "إجمالي مبلغ الفواتير للتحقق",
	Administration: "الادارة",
	Logout: "تسجيل خروج",
	Profile: "الملف الشخصي",
	"invoice.selected.title0": "تم تحديد جميع الفواتير",
	"invoice.selected.title1": " تم تحديد جميع الفواتير",
	"invoice.selected.title2": " الموجودة في هذه الصفحة",
	"invoice.selected.title3": "حدد جميع الفواتير",
	"supplier.add.step1": "المعلومات العامة",
	"supplier.add.step2": "معلومات للتواصل",
	"supplier.add.step3": "المعلومات المالية",
	"supplier.add.next": "التالي",
	"supplier.add.previous": "سابق",
	"supplier.add.finish": "ينهي",
	"supplier.add.country": "بلد القاعدة",
	"supplier.add.suppliername": "اسم المورد",
	"supplier.add.logo": "شعار",
	"supplier.add.capital": "رأس مال",
	"supplier.add.siret": "شركة SIRET",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "بريد الالكتروني",
	"supplier.add.companyName": "اسم الشركة",
	"supplier.add.fax": "فاكس",
	"supplier.add.civility": "الكياسة",
	"supplier.add.phone": "هاتف",
	"supplier.add.mobile": "رقم الهاتف",
	"supplier.add.vat": "ظريبه الشراء",
	"supplier.add.naf": "Code NAF",
	"supplier.add.bank": "مصرف",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.ribAttachment": "الضلع في المرفق",
	"supplier.add.acc": "حساب محاسبة",
	"supplier.add.curr": "العملة الافتراضية",
	"supplier.add.function": "وظيفة",
	"supplier.add.firstName": "الاسم الاول",
	"supplier.add.lastName": "الكنية",
	"supplier.add.city": "مدينة",
	"supplier.add.street": "شارع",
	"supplier.add.zipCode": "الرمز البريدي",
	"supplier.add.confirm": "هل أنت متأكد أنك تريد حفظ التغييرات؟",
	"supplier.delete.confirm": "هل أنت متأكد أنك تريد حذف المزود؟",
	"supplier.delete.success": "تم حذف المورد بنجاح",
	"supplier.delete.error": "حدث خطأ أثناء حذف المورد",
	"suplier.action.agree": "قبول",
	"suplier.action.disagree": "رفض",
	"supplier.add.aux": "حساب مساعد",
	"supplier.add.phoneFix": "هاتف",
	"supplier.name.exist.already": "اسم المورد موجود سابقا",
	"supplier.email.exist.already": "عنوان البريد الإلكتروني موجود سابقا",
	"suppliers.names.exist.already": " موجودون سابقا",
	"suppliers.empty.field.name.row.excel": "هناك صف بدون اسم !!",
	ApplyForApproval: "طلب الموافقة",
	TO_VALIDATE_EXTERNAL_ACTION: "طلب الموافقة",
	EXPORT_EXCEL: "excel تصدير",
	EXPORT_EDI: "edi تصدير",
	EXPORT_XML: "xml تصدير",
	EXPORT_PDF: "pdf تصدير",
	EXPORT_EC: "JE تصدير",
	"order.absent": "طلب غائب",
	"amount.greate.thang.goods": "مبلغ الفاتورة أكبر من الطلب",
	"delivery.absent": "عدم التسليم",
	delivery: "تسليم",
	"user.roles.user": "مستخدم",
	"profile.update-password.new-password-required": "كلمة مرور جديدة مطلوبة",
	"profile.update-password.password-required": "كلمة المرور مطلوبة",
	"profile.update-password.password-not-matched": "كلمات المرور غير متطابقة",
	"profile.update-password.current-password": "كلمة المرور الحالية",
	"profile.update-password.new-password": "كلمة مرور جديدة",
	"profile.update-password.confirm-password": "تأكيد كلمة المرور",
	"profile.update-password.reset-password": "إعادة تعيين كلمة المرور",
	"profile.update-password.password-reset": "إعادة تعيين كلمة المرور",
	"profile.update-password.password-reset-success": "تم تغيير كلمة المرور الخاصة بك بنجاح",
	"profile.update-password.password-email-send": "تم إرسال بريد إلكتروني. يرجى التحقق من بريدك.",
	"profile.update-password.password-reset-done": "تم",
	"profile.update-password.update-password": "تغيير كلمة المرور",
	"profile.update-password.email": "بريدك الإلكتروني الحالي",
	"profile.update-password.tokenError": "رمز غير صالح ، تم تحديث كلمة المرور بالفعل!",
	"Zip Code": "الرمز البريدي",
	Street: "شارع",
	City: "مدينة",
	"supplier.delete.training.error.message": "لا يوجد تدريب لهذا المورد.",
	"invoice.detail.reject.success": "تم رفض الفاتورة بنجاح",
	"invoice.detail.reject.failed": "لم يتم رفض الفاتورة",
	"invoice.detail.approved.success": "تمت الموافقة على الفاتورة بنجاح",
	"invoice.detail.approved.failed": "لم تتم الموافقة على الفاتورة",
	"invoice.detail.export.success": "تم تصدير الفاتورة بنجاح",
	"invoice.detail.export.failed": "لم يتم تصدير الفاتورة",
	"invoice.detail.message.rectify": "هل أنت متأكد أنك تريد حفظ هذه التغييرات؟",
	"invoice.detail.message.confirmExport": "هل أنت متأكد أنك تريد تصدير هذه الفاتورة؟",
	"token.validation.noteExist": "تم إرسال طلب تغيير كلمة المرور بالفعل",
	"client.delete.msg": "هل أنت متأكد أنك تريد حذف هذا العميل؟",
	year: "هذه السنة",
	month: "هذا الشهر",
	week: "هذا الاسبوع",
	day: "اليوم",
	Exported: "المصدرة",
	Integrated: "المدمجة",
	"List des clients partenaires": "قائمة عملاء الشريك",

	"supplier.popup.email.title": "تغيير البريد الإلكتروني",
	"supplier.popup.email.message": "",
	"supplier.popup.email.currentEmail": "ريدي الإلكتروني الحالي:",
	"supplier.popup.email.newEmail": "بريدي الإلكتروني الجديد",
	"supplier.popup.email.cancel": "إلغاء",
	"supplier.popup.email.confirm": "تأكيد",
	"supplier.popup.otp.requiredField": "حقل إلزامي",
	"supplier.popup.email.requiredField": "حقل إلزامي",
	"supplier.popup.email.otp": "الرجاء إدخال الرمز الذي تلقيته عبر الهاتف:",
	"supplier.popup.phone.otp": "الرجاء إدخال الرمز الذي تلقيته عبر البريد الإلكتروني:",
	"supplier.popup.otp.email.success": "تم تعديل بريدك الإلكتروني.",
	"supplier.popup.ok": "تأكيد",
	"supplier.popup.phoneNumber.title": "تغيير رقم الهاتف",
	"supplier.popup.phoneNumber.message": "",
	"supplier.popup.phoneNumber.currentPhoneNumber": "رقمي الحالي",
	"supplier.popup.phoneNumber.newPhoneNumber": "رقم هاتفي جديد",
	"supplier.popup.phoneNumber.cancel": "إلغاء",
	"supplier.popup.phoneNumber.confirm": "تأكيد",
	"supplier.popup.otp.phone.success": "تم تغيير رقم هاتفك.",
	"supplier.popup.iban.title": "تفاصيل حسابي المصرفي",
	"supplier.popup.iban.message": "تغيير رقم الحساب المصرفي الدولي (IBAN)",
	"supplier.popup.iban.currentIban": "رقم IBAN الحالي الخاص بي:",
	"supplier.popup.iban.newDataTitle": "تفاصيل حسابي المصرفي الجديدة:",
	"supplier.popup.iban.newIban": "IBAN",
	"supplier.popup.iban.newBic": "BIC",
	"supplier.popup.iban.cancel": "إلغاء",
	"supplier.popup.iban.confirm": "تأكيد",
	"supplier.popup.iban.newRib": "RIB",
	"supplier.popup.otp.iban.pending": "يتم التحقق من صحة رقم الحساب  IBAN الخاص بك",
	"supplier.popup.iban.upload.placeholder": "تحميل ملف",
	"supplier.iban.validation.message": "طلب تغيير IBAN قيد المعالجة",
	"supplier.popup.iban.requiredFiel": "حقل إلزامي",
	"supplier.popup.phoneNumber.requiredField": "حقل إلزامي",
	"supplier.iban.refuse": "رفض التعديل",
	"supplier.iban.accept": "تأكيد",
	"supplier.popup.iban.admin.confirm": "هل تريد تعديل حقل  IBAN الخاص بالمستخدم؟",
	"supplier.popup.iban.admin.confirm.cancel": "إلغاء",
	"supplier.popup.iban.admin.confirm.confirm": "تأكيد",
	"supplier.popup.iban.admin.reject": "سبب الرفض:",
	"supplier.popup.iban.admin.reject.cancel": "إلغاء",
	"supplier.popup.iban.admin.reject.confirm": "تأكيد",
	"supplier.financial.bic.notvalid": "BIC غير صالح",
	"supplier.financial.iban.notvalid": "IBAN غير صالح",
	"supplier.iban.validation.ibanLabel": "IBAN: ",
	"supplier.iban.validation.bicLabel": "BIC: ",
	"supplier.iban.validation.ribLabel": "تحميل Rib",
	"supplier.popup.iban.requiredField": "حقل إلزامي",
	"company.thirdPartyAccount": "حساب طرف ثالث",
	"company.companyName": "اسم الشركة",
	libPaiement: "صيغة",
	typeLot: "نوع",
	Montant: "مبلغ",
	Societe: "الشركة",
	Devise: "عملة",
	"supplier.add.supplierUsername": "حساب طرف ثالث",
	DeclarationCA: "تصاريح",
	"Raison sociale": "اسم الشركة",
	Month: "شهر",
	Year: "السنة",
	LOCPT: "حساب طرف ثالث",
	"Code lieu": "رمز الموقع",
	"Nom lieu": "اسم المكان",
	EOMAC: "EOMAC",
	Machine: "آلة",
	CA: "CA",
	Designation: "التسمية",
	"Total redevance": "مجموع الرسوم",
	numFacture: "رقم الإتاوة",
	"suppliersList.headerTitle": "المواقع",
	"search.declaration": "ابحث عن رقم التصريح",
	"supplier.add.codeSocietee": "رمز الشركة",
	codeSocietee_required: "حقل إلزامي",
	codeSocietee_not_valid: "رمز الشركة يجب أن يكون رقماً",
};
