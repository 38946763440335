import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TablePagination } from "@material-ui/core";
import { fetchSupplierInvoices } from "redux/actions/suppliers";
import { updateFilter } from "redux/filterReducer";
import { useTranslation } from "react-i18next";
import TableElement from "../TableElement";
function InvoiceTable({
	idSupplier,
	data,
	classes,
	setOpenDeletePopup,
	setSelectedId,
	companyCode,
	checkedAll,
	setCheckedAll,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const filterInvoices = useSelector(({ filterInvoices: i }) => i);

	const handleChangeInvoicePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterInvoices, pageNo: newPage }));
	};

	const handleChangeRowsInvoicePerPage = (event) => {
		dispatch(
			updateFilter({
				...filterInvoices,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};
	useEffect(() => {
		dispatch(fetchSupplierInvoices(idSupplier, filterInvoices));
	}, [filterInvoices]);

	return (
		<Grid item xs={12}>
			{data?.content?.map((row, i) => (
				<>
					<TableElement
						classes={classes}
						row={row}
						key={i}
						setOpenDeletePopup={setOpenDeletePopup}
						setSelectedId={setSelectedId}
						isInvoiceTable
						companyCode={companyCode}
						checkedAll={checkedAll}
						setCheckedAll={setCheckedAll}
					/>
				</>
			))}
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="div"
				count={data?.totalElements}
				rowsPerPage={filterInvoices.pageSize}
				page={filterInvoices.pageNo}
				onChangePage={handleChangeInvoicePage}
				onChangeRowsPerPage={handleChangeRowsInvoicePerPage}
				labelRowsPerPage={t("pagination.rows.per.page")}
			/>
		</Grid>
	);
}

export default InvoiceTable;
