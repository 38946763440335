import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    overflowX: "hidden",
    overflowY: "hidden",
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  DialogContentRoot: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardHeader: {
    textAlign: "center"
  },

  span: {
    backgroundColor: theme.palette.grey[200],

    width: "30%"
  },
  content: {
    justifyContent: "center",
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),

  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[700],
    fontWeight: "bold"
  },
  footer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  forgotTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    textAlign: "right",
    fontWeight: "bold"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tableCell: {
    minWidth: 300,
    fontSize: 15
  },
  resetButton: {
    marginLeft: theme.spacing(1)
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(12),
  },
  visibilityButton: {
    marginTop: theme.spacing(2)
  },
  link: {
    color: 'primary',
    textDecoration: 'none',
  },
  cardActions: {
    justifyContent: "center",
    margin: 0,
    padding: theme.spacing(1),
  },
  language: {
    color: theme.palette.common.white,
    fontWeight: "bold",
    marginLeft: theme.spacing(1)
  },
  card: {
    backgroundColor: theme.palette.common.white,
    width: '100%'
  },
  errorAuth: {
    color: 'red'
  }

}));
