import { createSlice } from "@reduxjs/toolkit";

const initialFormulaire = {
  selectedItem: "",
  invoiceDto: {
    id: {
      fieldFullName: null,
    },
    supplier: {
      code: {
        fieldFullName: null,
      },
      label: {
        fieldFullName: "Invoice Layout\\Vendor",
      },
      vatNumber: {},
    },
    invoiceNo: {
      fieldFullName: "Invoice Layout\\InvoiceNumber",
    },
    issueDate: {
      fieldFullName: "Invoice Layout\\InvoiceDate",
    },
    dueDate: {
      fieldFullName: "Invoice Layout\\InvoiceData",
    },
    vat: {
      fieldFullName: null,
    },
    additionalAmountsHT: [
      {
        amount: {
          fieldFullName: "Invoice Layout\\TOTALHT",
        },
      },
    ],
    additionalAmountsTVA: [
      {
        amount: {
          fieldFullName: "Invoice Layout\\TVA",
        },
      },
    ],
    additionalAmountsCharge: [
      {
        amount: {
          fieldFullName: "Invoice Layout\\Charge",
        },
      },
    ],
    total: {
      fieldFullName: "Invoice Layout\\Total",
    },
    purchaseOrderNo: {
      fieldFullName: null,
    },
    status: {
      code: {
        value: null,
        fieldFullName: null,
        location: null,
      },
      label: {
        value: null,
        fieldFullName: null,
        location: null,
      },
    },
    deliveryAddress: {
      fieldFullName: null,
    },
    billingAddress: {
      fieldFullName: "Invoice Layout\\InvoiceData",
    },
    currency: {
      fieldFullName: "Invoice Layout\\Currency",
    },
    paymentTerm: {
      code: {
        fieldFullName: null,
      },
      label: {
        fieldFullName: "Invoice Layout\\PaymentTerms",
      },
    },
    billingLinesDtos: [],
    order: {
      orderNo: {
        fieldFullName: "Invoice Layout\\PurchaseOrder",
      },
      nbArticle: {
        fieldFullName: null,
      },
      deliveryDelay: {
        fieldFullName: null,
      },
      deliveryMethod: {
        code: {
          fieldFullName: null,
        },
        label: {
          fieldFullName: null,
        },
      },
      invoiceId: {
        fieldFullName: null,
      },
    },
    discount: {
      fieldFullName: "Invoice Layout\\Discount",
    },
    deliverySlip: {
      fieldFullName: "Invoice Layout\\DeliverySlip",
    },
    country: {
      fieldFullName: "Invoice Layout\\InvoiceData\\Country",
    },
  },
};

// TODO-JAL Fix ESLINT
const formulaireListInvoices = createSlice({
  name: "formulaire",
  initialState: initialFormulaire,
  reducers: {
    updateFormulaire: (state, action) => (state = action.payload), // eslint-disable-line
    setItem: () => { },
    initFormulaire: (state) => (state = initialFormulaire), // eslint-disable-line
  },
});

export const {
  updateFormulaire,
  initFormulaire,
  setItem,
} = formulaireListInvoices.actions;

export default formulaireListInvoices.reducer;
