import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: "5px",
	},
	replyContainer: {
		margin: theme.spacing(3, 10),
	},
	replyBtn: {
		marginTop: theme.spacing(3),
	},
	title: {
		paddingTop: "1%",
	},
	ribAttachment: {
		border: "1px solid #ccc",
		display: "inline-block",
		padding: "6px 12px",
		cursor: "pointer",
		width: "100%",
		borderRadius: "5px",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
	},
	ribFileName: {
		display: "block",
		marginTop: theme.spacing(1),
	},
	fileAttachmentIcon: {
		color: theme.palette.success,
	},
	ribInput: {
		margin: theme.spacing(1, 0),
		"&::before": {
			border: "none",
		},
		"& input[type='file']": {
			display: "none",
		},
	},
}));
