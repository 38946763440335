import { setHeaderExpanded } from "App/appSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import View from "./View";

export default () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const throttleScrollEvent = () => {
			const st = window.pageYOffset || document.documentElement.scrollTop;
			dispatch(setHeaderExpanded(st <= 160));
		};

		window.addEventListener("scroll", throttleScrollEvent);

		return () => {
			window.removeEventListener("scroll", throttleScrollEvent);
		};
	}, []);

	return <View />;
};
