import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Card, Grid, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import { get } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
	downloadDocumentBill,
	downloadInvoice,
	downloadDeclaration,
	fetchDocumentBill,
	fetchDocumentInvoice,
	fetchDocumentDeclaration,
} from "../../../../../redux/actions/invoices/index";
import PDFViewer from "../../../../../components/PDFViewer";
import PDFJSBackend from "../../../../../components/pdfjs";

import BillElements from "./BillElements";
import InvoiceElements from "./InvoiceElements";
import DeclarationCAElements from "./DeclarationCAElements";
import PaymentElements from "./PaymentElements";

function TableElement({
	classes,
	row,
	key,
	isBillTable,
	isDeclarationCATable,
	isInvoiceTable,
	setOpenDeletePopup,
	setSelectedId,
	isPaymentTable,
	companyCode,
	checkedAll,
	setCheckedAll,
}) {
	const dispatch = useDispatch();
	const [showDocument, setShowDocument] = useState(false);
	const [isFileLoading, setIsFileLoading] = useState(false);
	const [mimeType, setMimeType] = useState(null);
	const [fileURL, setFileURL] = useState(null);
	const { t } = useTranslation();

	const showToastError = () => {
		toast.error(t("document.not.found"));
		setShowDocument(false);
		setIsFileLoading(false);
	};

	const showElement = () => {
		setShowDocument(!showDocument);
		setIsFileLoading(false);
	};

	const getDocument = (id) => {
		setIsFileLoading(true);
		// eslint-disable-next-line no-unused-expressions
		isBillTable
			? dispatch(fetchDocumentBill(id, setMimeType, showToastError)).then(() => showElement())
			: isInvoiceTable
			? dispatch(fetchDocumentInvoice(id, setMimeType, showToastError)).then(() => showElement())
			: isDeclarationCATable
			? dispatch(fetchDocumentDeclaration(id, setMimeType, showToastError)).then(() =>
					showElement()
			  )
			: null;
	};

	const onClickShowElement = (id) =>
		!showDocument ? getDocument(id) : setShowDocument(!showDocument);

	const documentPDF = isBillTable
		? // eslint-disable-next-line react-hooks/rules-of-hooks
		  useSelector(({ requests }) => get(requests, "queries.FETCH_DOCUMENT_BILL.data"))
		: isInvoiceTable
		? // eslint-disable-next-line react-hooks/rules-of-hooks
		  useSelector(({ requests }) => get(requests, "queries.FETCH_DOCUMENT_INVOICE.data"))
		: isDeclarationCATable
		? // eslint-disable-next-line react-hooks/rules-of-hooks
		  useSelector(({ requests }) => get(requests, "queries.FETCH_DOCUMENT_DECLARATION.data"))
		: null;

	useEffect(() => {
		const blob = new Blob([documentPDF], { type: mimeType });
		setFileURL(URL.createObjectURL(blob));
	}, [documentPDF]);

	const downloadDocumentFile = () => {
		dispatch(
			isBillTable
				? downloadDocumentBill(row.id, showToastError)
				: isInvoiceTable
				? downloadInvoice(row.id, showToastError)
				: isDeclarationCATable
				? downloadDeclaration(row.id, showToastError)
				: null
		);
	};

	return (
		<Grid>
			<Card
				key={key}
				className={classes.root}
				elevation={1}
				style={{ borderRight: "7px solid #1293B8" }}
				color="primary"
			>
				<CardContent className={classes.content}>
					{/* <ButtonBase focusRipple></ButtonBase> */}
					<Grid container>
						{!isPaymentTable ? (
							<Grid item xs={1}>
								{row.hasDocument ? (
									isFileLoading ? (
										<CircularProgress color="secondary" />
									) : showDocument ? (
										<ArrowDropDownIcon
											fontSize="large"
											onClick={() => onClickShowElement(row.id)}
										/>
									) : (
										<ArrowRightIcon fontSize="large" onClick={() => onClickShowElement(row.id)} />
									)
								) : (
									<ArrowRightIcon style={{ color: "red" }} fontSize="large" />
								)}
							</Grid>
						) : (
							<Grid item xs={1} />
						)}
						{isBillTable ? (
							<BillElements row={row} companyCode={companyCode} />
						) : isDeclarationCATable ? (
							<DeclarationCAElements row={row} companyCode={companyCode} />
						) : isInvoiceTable ? (
							<InvoiceElements
								row={row}
								companyCode={companyCode}
								checkedAll={checkedAll}
								setCheckedAll={setCheckedAll}
							/>
						) : (
							<PaymentElements row={row} companyCode={companyCode} />
						)}
					</Grid>
				</CardContent>
				<CardActions className={classes.cardActions} style={{ width: isBillTable ? "" : 0 }}>
					<Grid container display="flex" justify="flex-end">
						{row.hasDocument && (
							<IconButton
								aria-label="download"
								onClick={() => {
									downloadDocumentFile();
								}}
								style={{ color: "#17A7D0", marginLeft: "auto" }}
								color="secondary"
							>
								<GetAppIcon />
							</IconButton>
						)}

						{isBillTable && (
							<IconButton
								aria-label="delete"
								onClick={() => {
									setOpenDeletePopup(true);
									setSelectedId(row.id);
								}}
								style={{ color: "red" }}
							>
								<DeleteIcon />
							</IconButton>
						)}

						{isPaymentTable && row.toolTip && (
							<Tooltip title={t("Payment.initiation.message")} placement="left-start">
								<IconButton aria-label="info" style={{ color: "#12A560 " }}>
									<InfoIcon />
								</IconButton>
							</Tooltip>
						)}
					</Grid>
				</CardActions>
			</Card>

			<Grid item>
				{fileURL && showDocument && (
					<PDFViewer
						backend={PDFJSBackend}
						src={fileURL}
						style={{
							height: "800px",
						}}
					/>
				)}
			</Grid>
		</Grid>
	);
}

export default TableElement;
