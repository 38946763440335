import React from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { friendlyFormatIBAN } from "ibantools";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as IbanValidationIcon } from "./iban-validation-icon.svg";
import { downloadIbanRequestRib } from "../../../../redux/actions/suppliers";
import useStyles from "./style";

export default ({ ibanRequest, children }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const handleDownload = () => {
		dispatch(downloadIbanRequestRib(ibanRequest.id));
		// downloadDocumentBill
	};
	return (
		<Grid className={classes.ibanBox} container>
			<Grid
				item
				container
				xs={12}
				justify="center"
				alignContent="center"
				className={classes.ibanValidationBox}
			>
				<Grid xs={12} item container justify="center" alignContent="center">
					<IbanValidationIcon className={classes.waitingIcon} />
				</Grid>
				<Grid xs={12} item container justify="center" alignContent="center">
					<Typography variant="p" color="secondary" align="center">
						{t("supplier.iban.validation.message")}
					</Typography>
				</Grid>
				<Grid xs={12} container className={classes.dataContainer}>
					<Grid xs={2} item container className={classes.dataRow}>
						<Typography variant="p" color="textSecondary">
							{t("supplier.iban.validation.ibanLabel")}
						</Typography>
					</Grid>
					<Grid xs={10} item container className={classes.dataRow}>
						<Typography variant="p" color="textSecondary">
							{friendlyFormatIBAN(ibanRequest.iban)}
						</Typography>
					</Grid>
					<Grid xs={2} item container className={classes.dataRow}>
						<Typography variant="p" color="textSecondary">
							{t("supplier.iban.validation.bicLabel")}
						</Typography>
					</Grid>
					<Grid xs={10} item container className={classes.dataRow}>
						<Typography variant="p" color="textSecondary">
							{ibanRequest.bic}
						</Typography>
					</Grid>
					<Grid xs={12} item container>
						<Grid xs={10} item container alignContent="center">
							<Typography variant="p" color="textSecondary">
								{t("supplier.iban.validation.ribLabel")}
							</Typography>
						</Grid>
						<Grid xs={2} item container alignContent="center">
							<IconButton
								aria-label="download"
								onClick={handleDownload}
								className={classes.downloadButton}
							>
								<GetAppIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} container>
				{children}
			</Grid>
		</Grid>
	);
};
