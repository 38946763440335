import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	ibanBox: {
		padding: theme.spacing(3),
	},
	ibanValidationBox: {
		backgroundColor: "#f5f5f5",
		borderRadius: "8px",
		margin: theme.spacing(0, 0, 2, 0),
		padding: theme.spacing(2, 0),
	},
	waitingIcon: {
		width: "50px",
		height: "50px",
		marginBottom: theme.spacing(1),
	},
	dataContainer: {
		padding: theme.spacing(2, 3),
	},
	dataRow: {
		padding: theme.spacing(1, 0),
	},
	downloadButton: {
		padding: theme.spacing(1),
	},
}));
