import React from "react";

import { Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

function BillElements({ row }) {
	return (
		<>
			<Grid item xs={2}>
				<Typography color="primary">{row.createdAt?.split(" ")[0]}</Typography>
			</Grid>
		</>
	);
}

export default BillElements;
