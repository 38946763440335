import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentFilter } from "redux/filterPayments";
import { Grid, TablePagination } from "@material-ui/core";
import { fetchSupplierPayments } from "redux/actions/suppliers";
import { useTranslation } from "react-i18next";
import TableElement from "../TableElement";

function PaymentTable({ idSupplier, data, classes, setOpenDeletePopup, setSelectedId }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const filterPayments = useSelector(({ filterPayments: i }) => i);

	const handleChangeInvoicePage = (event, newPage) => {
		dispatch(updatePaymentFilter({ ...filterPayments, pageNo: newPage }));
	};

	const handleChangeRowsInvoicePerPage = (event) => {
		dispatch(
			updatePaymentFilter({
				...filterPayments,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};
	useEffect(() => {
		dispatch(fetchSupplierPayments(idSupplier, filterPayments));
	}, [filterPayments]);

	return (
		<Grid item xs={12}>
			{data?.content?.map((row, i) => (
				<>
					<TableElement
						classes={classes}
						row={row}
						key={i}
						setOpenDeletePopup={setOpenDeletePopup}
						setSelectedId={setSelectedId}
						isPaymentTable
					/>
				</>
			))}
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100]}
				component="div"
				count={data?.totalElements}
				rowsPerPage={filterPayments.pageSize}
				page={filterPayments.pageNo}
				onChangePage={handleChangeInvoicePage}
				onChangeRowsPerPage={handleChangeRowsInvoicePerPage}
				labelRowsPerPage={t("pagination.rows.per.page")}
			/>
		</Grid>
	);
}

export default PaymentTable;
