export default {
	lastName: "Apellido",
	firstName: "Primer nombre",
	email: "Correo electrónico",
	Username: "Nombre de usuario",
	userFunction: "Función",
	supplier_name: "Proveedor",
	client_name: "Nombre del cliente",
	job: "Función",
	manageUsers: " Gestión de usuarios",
	manageSuppliers: "Área de proveedores",
	manageClients: "Área de clientes",
	manageGroups: "Administrar grupos",
	manageRoles: "Administrar roles",
	manageWorkflow: "Gestión del flujo de trabajo",
	manageLicenseAddOn: "Administrar licencias y complementos",
	status: "Estado",
	ACTIVE: "Activo",
	WAITING: "A la espera",
	DISABLE: "Desactivar",
	add_user: "Agregar usuario",
	add_user_button: "AÑADIR USUARIO",
	add_supplier_button: "AGREGAR PROVEEDOR",
	add_client_button: "AGREGAR CLIENTE",
	add_supplier: "Agregar proveedor",
	add_client: "Agregar cliente",
	edit_supplier: "Modificar proveedor",
	sendEmailCreationUserMessage:
		"Se ha enviado un correo electrónico con el nombre de usuario y la contraseña al usuario para que pueda conectarse",
	CancelActionMessage: "¿Está seguro de que desea cancelar esta acción?",
	searchUser: "Buscar",
	userCivility: "Civilidad",
	administrator: "Administrador",
	user: "Agente",
	Mr: "Señor",
	Ms: "Mademoiselle",
	Mrs: "Señora",
	enableDisableActionMessage: "¿Está seguro de que desea habilitar / deshabilitar este usuario?",
	edit_user: "Editar Usuario",
	// validation messages
	lastName_required: " Se requiere el nombre",
	lastName_notValid: "El nombre no es valido",
	supplierName_notValid: "El nombre del proveedor no es válido",
	phone_notValid: "El teléfono no es válido",
	mobile_required: "Se requiere móvil",
	supplier_required: "El nombre del proveedor es obligatorio",
	firstName_required: "Se requiere el nombre",
	firstName_notValid: "El nombre no es válido",
	email_required: "Correo electronico es requerido",
	companyName_required: "Razón social es requerido",
	email_notValid: "El correo no es válido",
	siret_notValid: "La empresa SIRET no es válida",
	url_notValid: "La URL no es válida",
	supplier_created: "Proveedor creado con éxito!",
	supplier_updated: "Proveedor actualizado correctamente!",
	supplier_error: "Ocurrió un error al guardar el proveedor",
	supplier_update_error: "¡No se pudieron actualizar los detalles del proveedor!",
	job_required: "La función es obligatoria",
	civility_required: "El civismo es obligatorio",
	deleteUsersActionMessage: "¿Está seguro de que desea eliminar este usuario?",
	"admin.settings": "Configuraciones",
	"admin.config.update-settings": "La configuración se ha actualizado con éxito",
	"admin.config.update-message":
		"La configuración del correo electrónico se ha actualizado correctamente",
	"admin.AUTO_EXPORT": "Exportación automática",
	"admin.email-config": "Configuración de correo electrónico",
	"admin.ftp-config": "Configuración de FTP",
	"admin.import-config": "Importar configuración",
	Host: "Anfitrión",
	Port: "Puerto",
	Email: "Email",
	Password: "Contraseña",
	"Read folder": "Leer carpeta",
	successDeleteBill: "La factura se ha eliminado correctamente.",
	"Processing folder": "Carpeta de procesamientor",
	"Success folder": "Carpeta de éxito",
	"Exception folder": "Carpeta de excepciones",
	"admin.AMOUNT_TOLERANCE": "Tolerancia de los totales",
	"admin.MAXIMUM_SIZE": "Talla máxima",
	supplierTraining: "Aprendizaje proveedores",
	deleteTraining: "Eliminar entrenamiento",
	deleteTrainingMessage: "¿Estás seguro de que deseas eliminar la capacitación de este proveedor?",
	successdeleteTrainingMessage: "El aprendizaje se ha eliminado correctamente",
	"admin.download-buttons": "Acciones sobre facturas",
	"admin.download-buttons-description":
		"Elija qué acciones le gustaría permitir en sus facturas en el portal.",
	"admin.download-button.excel": "Descargar en formato Excel",
	"admin.download-button.xml": "Descarga en formato XML",
	"admin.download-button.pdf": "Descarga en formato PDF",
	"admin.download-button.edi": "Descarga en formato EDI",
	"admin.invoice.AUTO_EXPORT-description":
		"Este parámetro activa la exportación automática de facturas.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Este campo le permite configurar una tolerancia en los cálculos de los totales de una factura. Sobre la base de esta tolerancia se mostrarán los mensajes de error.",
	"admin.MAXIMUM_SIZE-description": "Tamaño máximo de un documento para cargar en MBs",
	"admin.display-indicators": "Visualización de indicadores en el tablero",
	"admin.display-indicators-description":
		"Elija qué indicadores desea visualizar en su Panel de control de la cadena de suministro.",
	"admin.setting.my_tasks": "Mis tareas",
	"admin.setting.invoices-exported": "Facturas exportadas",
	"admin.setting.invoices-not-exported": "Facturas no exportadas",
	"admin.setting.last-actions": "Últimas acciones",
	"admin.setting.load-invoices": "Cargar facturas",
	"admin.setting.last-5-invoices": "Últimas 5 facturas",
	"admin.setting.download-pdf": "Descargar PDF",
	"admin.setting.delete": "Borrar",
	"admin.setting.delete-all": "Eliminar todas las facturas",
	"admin.setting.AMOUNT_TOLERANCE.required": "Se requiere tolerancia de totales",
	"admin.setting.relaunch-extraction": "Relanzar la extracción",
	"admin.export-buttons": "Exportar botones de descarga",
	"admin.invoice.export-buttons-description":
		"Configure la visualización de posibles accesos directos a descargas después de exportar una factura. Los botones serán visibles después de la exportación en los detalles de la factura.",
	"admin.setting.export_entry_account_excel": "Exportar entrada de contabilidad de Excel",
	"admin.extraction-level-settings": "La importancia de los campos extraídos (nivel de extracción)",
	"admin.extraction-level-settings-desciption":
		"Establezca la importancia de los campos que le parecen más importantes y los campos que es esencial. Esto se tendrá en cuenta en el cálculo del Nivel de extracción.",
	"admin.level-settings": "Configuración del nivel de extracción",
	"admin.extraction-level.champs": "Campos",
	"admin.extraction-level.status": "Estado",
	"admin.extraction-level.mandatory": "Obligatorio",
	"admin.extraction-level.ignored": "Ignorado",
	"admin.extraction-level.important": "Importante",
	"admin.setting.message.confirm": "¿Está seguro de que desea actualizar esta configuración?",
	"admin.user.confirm.message.edit": "¿Está seguro de que desea actualizar este usuario?",
	"admin.user.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.supplier.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.client.confirm.message.add": "¿Estás seguro de que quieres guardar a este usuario?",
	"admin.supplier.confirm.message.edit": "¿Está seguro de que desea actualizar este usuario?",
	"admin.config.message.confirm":
		"¿Está seguro de que desea actualizar esta configuración de correo electrónico?",
	"admin.extraction.message.confirm":
		"¿Está seguro de que desea actualizar estos niveles de extracciones?",
	"invoice.user.updated.success": "El usuario se actualizó correctamente",
	"admin.config.ftp.message.confirm":
		"¿Está seguro de que desea modificar esta configuración del servidor FTP?",
	"admin.config.ftp.update-message":
		"La configuración del servidor FTP se ha actualizado correctamente",
	agent_accounting: "Agente contable",
	agent_purchase: "Agente De Compras",
	"admin.settings.invoice": "Factura",
	"admin.settings.order": "Ordenado",
	"admin.settings.invoices": "Configuración de facturas",
	"admin.settings.orders": "Configuración de pedidos",
	"admin.order.download-buttons": "Acciones sobre pedidos",
	"admin.order.download-buttons-description":
		"Elija qué acciones le gustaría permitir en sus pedidos en el portal.",
	"admin.order.AUTO_EXPORT-description":
		"Este parámetro activa la exportación automática de pedidos que están totalmente extraídos y no tienen mensajes de error.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"Este campo le permite establecer una tolerancia en el cálculo de los totales de un pedido. Sobre la base de esta tolerancia se mostrarán los mensajes de error.",
	"admin.setting.last-5-orders": "Últimos 5 pedidos",
	"admin.order.setting.delete-all": "Eliminar todos los pedidos",
	"admin.order.export-buttons-description":
		"Configure la visualización de posibles accesos directos a descargas después de exportar un pedido. Los botones serán visibles después de la exportación en los detalles del pedido.",
	"admin.settings.order.upload": "Subir pedidos",
	"order.exported": "Pedidos exportados",
	"order.unexported": "Pedidos no exportados",
	"admin.download-button.journal-entry": "Descargar entrada de diario de Excel",
	"pagination.rows.per.page": "Filas por página:",
	Reglement: "Normas",
	Redevances: "Tarifa de regalías",
	"search.licencefee": "Encontrar por Tarifa de regalías/código de lugar",
};
