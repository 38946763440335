import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import MuiPhoneNumber from "material-ui-phone-number";
import { Controller } from "react-hook-form";
import get from "lodash/get";
import { REGEX } from "../../../utils";
import useStyles from "../style";

const Contact = ({
	register,
	control,
	errors,
	contact,
	updateSupplierContact,
	accountManagers,
	updateSupplier,
	supplier,
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleSupplierContactChange = (e) => updateSupplierContact(e);
	const handleUpdateSupplier = (e) => updateSupplier(e);
	return (
		<Grid container item xs={12} justify="center">
			<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.firstName")}
						<Input
							value={contact.firstName}
							onChange={handleSupplierContactChange}
							inputRef={register({
								pattern: { value: REGEX.FORM_NAMES, message: t("firstName_notValid") },
								required: { value: true, message: t("field_required") },
							})}
							name="firstName"
							fullWidth
						/>
						<Typography className={classes.validation_error}>
							{errors.firstName?.message}
						</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.lastName")}
						<Input
							value={contact.lastName}
							onChange={handleSupplierContactChange}
							inputRef={register({
								pattern: { value: REGEX.FORM_NAMES, message: t("lastName_notValid") },
								required: { value: true, message: t("field_required") },
							})}
							name="lastName"
							fullWidth
						/>
						<Typography className={classes.validation_error}>{errors.lastName?.message}</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("supplier.add.mobile")}

						<Controller
							name="phone1"
							id="phone1"
							control={control}
							rules={{
								pattern: {
									value: REGEX.TEL_FORMATTED,
									message: t("phone_notValid"),
								},
							}}
							value={contact.phone}
							defaultValue={contact.phone}
							render={({ name, onChange, value }) => (
								<MuiPhoneNumber
									defaultCountry="fr"
									fullWidth
									name={name}
									value={value}
									inputProps={{
										form: {
											autocomplete: "off",
										},
									}}
									onChange={(v) => {
										onChange(v);
										const event = {
											target: {
												name: "phone",
												value: v.length <= 4 ? "" : v,
											},
										};

										handleSupplierContactChange(event);
									}}
								/>
							)}
						/>
						<Typography className={classes.validation_error}>{errors.phone1?.message}</Typography>
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("email")}
						<Input
							value={contact.email}
							onChange={(e) => {
								const event = {
									target: {
										name: "email",
										value: e.target.value,
									},
								};
								handleSupplierContactChange(event);
							}}
							inputRef={register({
								pattern: { value: REGEX.EMAIL, message: t("email_notValid") },
							})}
							name="email1"
							placeholder="example@me-group.io"
							fullWidth
						/>
						<Typography className={classes.validation_error}>{errors.email1?.message}</Typography>
					</Typography>
				</Grid>
			</Grid>
			<Grid container xs={12} justify="center" spacing={6} className={classes.gridRow}>
				<Grid item xs={4}>
					<Typography variant="subtitle2" align="left" color="black">
						{t("admin reference")}
						<NativeSelect
							value={supplier.accountManager?.id || supplier.accountManager}
							onChange={handleUpdateSupplier}
							inputRef={register({
								required: { value: true, message: t("field_required") },
							})}
							name="accountManager"
							fullWidth
							style={{ marginTop: "15px" }}
						>
							{/* eslint-disable-next-line */}
							<option value="" />
							{accountManagers &&
								accountManagers.map((elt, i) => (
									<option key={i} value={t(elt.id)}>
										{`${get(elt, "firstName", "")} ${get(elt, "lastName", "")}`}
									</option>
								))}
						</NativeSelect>
						<Typography className={classes.validation_error}>
							{errors.accountManager?.message}
						</Typography>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Contact;
