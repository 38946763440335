export default {
	countSuppliers: "Total number of partner clients",
	searchSupplier: "Find a supplier by place code",
	contact: "Contact",
	principalContact: "Main contact",
	outstandingInvoices: "Outstanding invoices",
	addSupplier: "Add supplier",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Other",
	companySiret: "SIRET number",
	vatNumber: "VAT number",
	legalForm: "Legal form",
	codeNaf: "NAF Code",
	email: "Email",
	address: "Address",
	fax: "Fax",
	socialCapital: "Share capital",
	name: "Name",
	function: "Position",
	phone: "Phone",
	bank: "Bank",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	account_journal_auxiliary: "Auxiliary",
	account_journal_number: "Account number",
	noPrincipalContact: "No main contact available",
	details: "Details",
	financialInformation: "Financial information",
	associatedContact: "Associated contacts",
	return: "Back",
	invoice_uploaded_error: "The invoice has not been added",
	invoice_uploaded_success: "The invoice has been successfully added.",
	"upload.instructions": "Instructions",
	"upload.instructions1": "Fill the excel sheet with the suppliers' information",
	"upload.instructions2":
		"Fill the column “Logo” with the logo file name. (Example: logofilename.jpg)",
	"upload.instructions3": "Make sure all the fields are of type TEXT",
	"upload.instructions4":
		"Place the filled excel sheet and the suppliers' logos in the same folder",
	"upload.instructions5": "Compress that folder : ",
	"upload.instructions51":
		'Windows: Right Click on the Folder then choose  "Send to"  then click on "Compressed Folder"',
	"upload.instructions52":
		"MAC: Control-click it or tap it using two fingers, then choose Compress from the shortcut menu.",
	"upload.instructions6":
		"Upload the compressed folder to the portal or drag and drop it to the dedicated zone",
	"upload.instructions7": 'Click on "Upload Suppliers"',
	"supplier.modify": "Update supplier",
	"Nb Total des clients": "Total number of customers",
	"Nb Total des redevances": "Total number of invoices",
	"admin reference": "Referent Me Group",
	"Ajouter un commentaire": "Add a comment",
	"post comment": "post comment",
	"messagerie interne": "Secure internal messaging",
	"Big.file.message": "Please select a file that does not exceed 2 MB.",
	"missing.comment": "Please add a comment.",
};
