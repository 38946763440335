import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Query } from "@redux-requests/react";
import get from "lodash/get";
import TablePagination from "@material-ui/core/TablePagination";
import Filter from "./Filter";
import useStyles from "../Invoices/List/style";
import { fetchSuppliers } from "../../redux/actions/suppliers";
import { FETCH_SUPPLIERS } from "../../redux/constants";
import TableCardItem from "../../components/TableCardItem";
import ConfirmationDialog from "./Component/ConfirmationDialog";
import { updateFilter, initFilter } from "../../redux/filterReducer";
import CircularLoader from "../../components/Loaders/CircularLoader";

export const List = ({ isSupplier }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selected, setSelected] = React.useState([]);
	const suppliers = useSelector(({ requests }) => get(requests, "queries.FETCH_SUPPLIERS.data"));
	const filterSuppliers = useSelector(({ filterSuppliers: i }) => i);
	const idSuppliers = "";

	const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
	const [selectedId, setSelectedId] = React.useState(0);

	const handleSelectedAll = (checked) => {
		setSelected(checked ? idSuppliers : []);
	};

	const handleChangePage = (event, newPage) => {
		dispatch(updateFilter({ ...filterSuppliers, pageNo: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(
			updateFilter({
				...filterSuppliers,
				pageSize: +event.target.value,
				pageNo: 0,
			})
		);
	};

	const reloadSuppliers = () => dispatch(fetchSuppliers(filterSuppliers));
	useEffect(() => {
		reloadSuppliers();
	}, [filterSuppliers, dispatch]);

	useEffect(() => {
		dispatch(initFilter());
	}, [dispatch]);

	const onClickRow = (id) => {
		dispatch(initFilter());
		history.push(`/supplier/details/${id}`);
	};

	const onDeleteItem = (id) => {
		setOpenDeletePopup(true);
		setSelectedId(id);
	};

	return (
		<>
			<Grid spacing={2} direction="column" xs={12} container className={classes.firstGrid}>
				<Grid container item xs={12} direction="row-reverse">
					<Grid container item xs={3} justify="flex-end">
						{!isSupplier ? (
							<Grid item style={{ marginRight: "20px" }}>
								<Button
									fullWidth
									startIcon={<AddIcon />}
									variant="contained"
									color="secondary"
									className={classes.addClientButton}
									onClick={() => {
										history.push("/client");
									}}
								>
									{t("add_client")}
								</Button>
							</Grid>
						) : null}
					</Grid>
					<Grid container item direction="column" xs={9}>
						<Grid item>
							<Typography variant="h6" color="secondary">
								{isSupplier ? t("suppliersList.headerTitle") : t("List des clients partenaires")}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="textSecondary">
								{t("countSuppliers")} :
								<Query type={FETCH_SUPPLIERS}>{({ data }) => ` ${data.totalElements}`}</Query>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container>
					<Filter
						selected={selected}
						setSelected={setSelected}
						handleSelectedAll={handleSelectedAll}
					/>
				</Grid>
				<Grid container xs={11} direction="row" className={classes.listHeader}>
					<Grid container xs spacing={2}>
						<Grid item>
							<Typography>&nbsp;</Typography>
						</Grid>
					</Grid>
					<Grid container xs spacing={2} justify="center">
						<Grid item>
							<Typography>{t("company.thirdPartyAccount")}</Typography>
						</Grid>
					</Grid>
					<Grid container xs spacing={2} justify="center">
						<Grid item>
							<Typography>{t("")}</Typography>
						</Grid>
					</Grid>

					<Grid container xs spacing={2} justify="center">
						<Grid item>
							<Typography>{t("Raison sociale")}</Typography>
						</Grid>
					</Grid>
					<Grid container xs spacing={2} justify="center">
						<Grid item>
							<Typography>{t("Nb Total des redevances")}</Typography>
						</Grid>
					</Grid>
					<Grid container xs={1} spacing={2}>
						<Grid item>
							<Typography>&nbsp;</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					style={{
						margin: "30px 0px",
					}}
				>
					<Grid item xs={12}>
						{get(suppliers, "content") ? (
							suppliers.content.map((row, i) => {
								const infoItems = [
									{ value: row.locpt, justify: "center" },
									{
										value:
											row.contacts?.length > 0 &&
											`${row.contacts[0].firstName} ${row.contacts[0].lastName}`,
										justify: "center",
									},
									{ value: row.raisonSociale, justify: "center" },
									{ value: get(row, "invoiceCount", 0), justify: "center" },
								];
								return (
									<TableCardItem
										index={i}
										t={t}
										row={row}
										contactDetails
										infoItems={infoItems}
										onClickRow={onClickRow}
										onDeleteItem={onDeleteItem}
										refreshSuppliers={() => reloadSuppliers()}
										withLogo
									/>
								);
							})
						) : (
							<CircularLoader />
						)}
					</Grid>
					<Grid item xs={12}>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={get(suppliers, "totalElements")}
							rowsPerPage={filterSuppliers.pageSize}
							page={filterSuppliers.pageNo}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							labelRowsPerPage={t("pagination.rows.per.page")}
						/>
					</Grid>
				</Grid>
			</Grid>

			<ConfirmationDialog
				open={openDeletePopup}
				setOpen={setOpenDeletePopup}
				id={selectedId}
				filterSuppliers={filterSuppliers}
			/>
		</>
	);
};

export default List;
