import i18n from "../i18n";
import Roles from "../redux/constants/roles";

export { default as toast } from "./toast";

export const scrollToTop = () => {
	window.scrollTo(0, 0);
};

export const invoiceStatusColors = {
	EXCEPTION: "#e17055",
	INTEGRATION: "#fdcb6e",
	TO_VERIFY: "#6c5ce7",
	TO_CONTROL: "#00cec9",
	TO_PAY: "#74b9ff",
	PAYED: "#00b894",
	REJECTED: "#d63031",
	CANCELED: "#636e72",
	VALIDATED: "#00b894",
	PARTIALLY_DELIVERED: "#fdcb6e",
	DELIVERED: "#6c5ce7",
	BILLED: "#00cec9",
	PARTIALLY_BILLED: "#74b9ff",
	EXPORTED: "#636e72",
};

export function currencyFormat(num, arrondissement = 2) {
	num = parseFloat(num);
	if (Number.isNaN(num)) {
		return Number.NaN;
	}
	if (i18n.language === "ar") {
		return num || num === 0
			? num
					.toFixed(arrondissement) // always two decimal digits
					.replace(".", ",") // replace decimal point character with ,
			: num; // use " " as a separator
	}

	return num || num === 0
		? num
				.toFixed(arrondissement) // always two decimal digits
				.replace(".", ",") // replace decimal point character with ,
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$& ")
		: num; // use " " as a separator
}

export const scallIn = (orgSize, canvasSize) => orgSize / canvasSize;

export const scallOut = (scallSize, canvasSize) => scallSize * canvasSize;

export const REGEX = {
	NAME: /^[a-zA-ZÀ-ÿ'.[`~!@#$%^&*()_\-|+=÷¿?;:'",<>{}/\\\]\s]{0,25}$/,
	EMAIL: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-_][a-zA-Z0-9-_.]*\.[a-zA-Z]{2,}$/,
	TEL: /^\+{1}[0-9]{6,18}$/,
	LINKEDIN: /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!-]))?/,
	FUNCTION: /.{0,150}/,
	AMOUNT: /^([0-9 ]{0,12}(.,?[0-9]{0,2})?)$/,
	FAX: /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/,
	FORM_NAMES: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
	TEL_FORMATTED: /^|(?:\+{1}[- ()0-9]{8,20}|\+{1}[- ()0-9]{0,4})$/,
	NUMBER: /^([0-9 ]+)$/,
};

export const isSupplierOnly = (roles) =>
	roles.includes(Roles.supplier) && !roles.includes(Roles.user);
export const isUserAdminOnly = (roles) =>
	roles.includes(Roles.admin) && !roles.includes(Roles.user);
export const isSimpleUserOnly = (roles) =>
	roles.includes(Roles.user) && !roles.includes(Roles.admin);
export const isPurchaseOrder = (roles) =>
	roles.includes(Roles.purchase_user) && !roles.includes(Roles.admin);
export const isClientOnly = (roles) => roles.includes(Roles.client) && !roles.includes(Roles.user);

export const getCurrencySymbol = (code) => {
	switch (code) {
		case 7:
			return "£";
		case 10:
			return "CHF";
		default:
			return "€";
	}
};
