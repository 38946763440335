export default {
	lastName: "Last name",
	firstName: "First name",
	email: "Email",
	Username: "Username",
	userFunction: "Position",
	supplier_name: "Supplier",
	client_name: "Client name",
	job: "Function",
	manageUsers: " Manage users",
	manageSuppliers: "Manage suppliers",
	manageClients: "Manage clients",
	manageGroups: "Manage groups",
	manageRoles: "Manage roles",
	manageWorkflow: "Workflow management",
	manageLicenseAddOn: "Manage licenses & Add-ons",
	status: "Status",
	ACTIVE: "Active",
	WAITING: "Waiting",
	DISABLE: "Disable",
	add_user: "Add user",
	add_user_button: "ADD USER",
	add_supplier_button: "ADD SUPPLIER",
	add_client_button: "ADD CLIENT",
	add_supplier: "Add supplier",
	add_client: "Add client",
	edit_supplier: "Edit supplier",
	sendEmailCreationUserMessage:
		"An email with the username and password was sent to the user to to enable connection",
	CancelActionMessage: "Are you sure you want to cancel this action?",
	searchUser: "Search",
	userCivility: "Civility",
	administrator: "Administrator",
	user: "Agent",
	supplier: "Supplier",
	Mr: "Sir",
	Ms: "Miss",
	Mrs: "Madam",
	enableDisableActionMessage: "Are you sure you want to enable / disable this user",
	edit_user: "Edit User",
	// validation messages
	lastName_required: "Last name is required",
	lastName_notValid: "Last name is not valid",
	supplierName_notValid: "Supplier name is not valid",
	firstName_required: "First name is required",
	firstName_notValid: "First name is not valid",
	phone_notValid: "Phone is not valid",
	mobile_required: "Mobile is required",
	supplier_required: "Supplier name is required",
	email_required: "Email is required",
	companyName_required: "Company name is required",
	email_notValid: "Email is  not valid",
	siret_notValid: "Company tax registration number is  not valid",
	url_notValid: "URL is  not valid",
	supplier_created: "Supplier created successfully!",
	supplier_updated: "Supplier updated successfully!",
	supplier_error: "Error occurred while saving supplier",
	supplier_update_error: "Failed to update supplier details!",
	job_required: "The function is required",
	civility_required: "Civility is required",
	deleteUserActionMessage: "Are you sure you want to delete this user?",
	deleteUsersActionMessage: "Are you sure you want to delete these users?",
	"admin.settings": "Settings",
	"admin.settings.invoices": "Charges Settings",
	"admin.settings.orders": "Orders Settings",
	"admin.config.update-settings": "Settings have been successfully updated",
	"admin.config.update-message": "Email configuration has been successfully updated",
	"admin.AUTO_EXPORT": "Automatic Export",
	"admin.AMOUNT_TOLERANCE": "Tolerance of totals",
	"admin.MAXIMUM_SIZE": "Maximum size",
	"admin.email-config": "Email configuration",
	"admin.ftp-config": "FTP configuration",
	"admin.import-config": "Import configuration",
	Host: "Host",
	Port: "Port",
	Email: "Email",
	Password: "Password",
	successDeleteBill: "Invoice has been deleted successfully.",
	"Read folder": "Read folder",
	"Processing folder": "Processing folder",
	"Success folder": "Success folder",
	"Exception folder": "Exception folder",
	supplierTraining: "Supplier training",
	deleteTraining: "Delete training",
	deleteTrainingMessage: "Are you sure you want to delete training from this provider",
	successdeleteTrainingMessage: "Learning has been deleted successfully",
	"admin.download-buttons": "Actions on invoices",
	"admin.order.download-buttons": "Actions on orders",
	"admin.download-buttons-description":
		"Choose which actions you would like to allow on your invoices in the portal.",
	"admin.order.download-buttons-description":
		"Choose which actions you would like to allow on your orders in the portal.",
	"admin.download-button.excel": "Download in Excel Format",
	"admin.download-button.xml": "Download in XML Format",
	"admin.download-button.pdf": "Download in PDF Format",
	"admin.download-button.edi": "Download in EDI Format",
	"admin.download-button.journal-entry": "Download Excel Journal Entry",
	"admin.invoice.AUTO_EXPORT-description":
		"This parameter activates the automatic export of invoices.",
	"admin.order.AUTO_EXPORT-description":
		"This parameter activates the automatic export of order that are fully extracted and have no error messages.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"This field allows you to set a tolerance in the calculation of the totals of an invoice. It is on the basis of this tolerance that error messages will be displayed.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"This field allows you to set a tolerance in the calculation of the totals of an order. It is on the basis of this tolerance that error messages will be displayed.",
	"admin.MAXIMUM_SIZE-description": "Maximum size of a document to upload in MBs",
	"admin.display-indicators": "Indicators display in the dashboard",
	"admin.display-indicators-description":
		"Choose which indicators you want to visualize in your Supply Chain Dashboard.",
	"admin.setting.my_tasks": "My tasks",
	"admin.setting.invoices-exported": "Invoices exported",
	"admin.setting.invoices-not-exported": "Invoices not exported",
	"admin.setting.last-actions": "Last actions",
	"admin.setting.load-invoices": "Load invoices",
	"admin.setting.last-5-invoices": "Last 5 invoices",
	"admin.setting.last-5-orders": "Last 5 orders",
	"admin.setting.download-pdf": "Download PDF",
	"admin.setting.delete": "Delete",
	"admin.setting.delete-all": "Delete all invoices",
	"admin.order.setting.delete-all": "Delete all orders",
	"admin.setting.relaunch-extraction": "Relaunch the extraction",
	"admin.setting.AMOUNT_TOLERANCE.required": "Tolerance of totals is required",
	"admin.export-buttons": "Export download buttons",
	"admin.invoice.export-buttons-description":
		"Set up the display of possible downloads shortcuts after exporting an invoice. The buttons will be visible after the export in the invoice details.",
	"admin.order.export-buttons-description":
		"Set up the display of possible downloads shortcuts after exporting an order. The buttons will be visible after the export in the order details.",
	"admin.setting.export_entry_account_excel": "Export Excel Journal Entry",
	"admin.extraction-level-settings": "Importance of the extracted fields (Extraction level)",
	"admin.extraction-level-settings-desciption":
		"Set the importance of the fields which seem the most important to you and of those which you are essential. This will be taken into account in the calculation of the Extraction Level.",
	"admin.level-settings": "Extraction Level Settings",
	"admin.extraction-level.champs": "Fields",
	"admin.extraction-level.status": "Status",
	"admin.extraction-level.mandatory": "Essential",
	"admin.extraction-level.ignored": "Ignored",
	"admin.extraction-level.important": "Important",
	"admin.setting.message.confirm": "Are you sure you want to update these settings?",
	"admin.user.confirm.message.edit": "Are you sure you want to update this user?",
	"admin.user.confirm.message.add": "Are you sure you want to save this user?",
	"admin.supplier.confirm.message.add": "Are you sure you want to save this user?",
	"admin.supplier.confirm.message.edit": "Are you sure you want to update this user?",
	"admin.config.message.confirm": "Are you sure you want to update this email configuration?",
	"admin.extraction.message.confirm": "Are you sure you want to update these extractions levels?",
	"invoice.user.updated.success": "User was updated  successfully",
	"admin.config.ftp.message.confirm":
		"Are you sure you want to modify this configuration of the FTP server?",
	"admin.config.ftp.update-message":
		"The configuration of the FTP server has been updated successfully",
	agent_accounting: "Accounting Agent",
	agent_purchase: "Purchasing Agent",
	"admin.settings.invoice": "Invoice",
	"admin.settings.order": "Order",
	"admin.settings.order.upload": "Upload orders",
	"order.exported": "Exported orders",
	"order.unexported": "Non-exported orders",
	"admin.client.confirm.message.add": "Are you sure you want to save this user?",
	"pagination.rows.per.page": "Rows per page:",
};
