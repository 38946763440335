import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { get, defaultTo } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

export const DetailsFinancialInformations = ({ supplier }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState({
		vatNumber: false,
		bic: false,
	});
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};
	return (
		<Grid container xs={12} spacing={4}>
			<Grid container item xs={12} spacing={4}>
				<Grid item xs={6}>
					<Typography variant="caption" color="black">
						{t("vatNumber")}
						<br />
						<Tooltip
							open={open.vatNumber}
							onClose={() => handleClose("vatNumber")}
							onOpen={get(supplier, "vatNumber")?.length > 32 && (() => handleOpen("vatNumber"))}
							title={get(supplier, "vatNumber")}
						>
							<Typography
								variant="subtitle2"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
								align="left"
							>
								{defaultTo(get(supplier, "vatNumber"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={6}>
					<Typography variant="caption" color="black">
						{t("bic")}
						<br />
						<Tooltip
							open={open.bic}
							onClose={() => handleClose("bic")}
							onOpen={get(supplier, "bic")?.length > 32 && (() => handleOpen("bic"))}
							title={get(supplier, "bic")}
						>
							<Typography
								variant="subtitle2"
								style={{ overflow: "hidden", textOverflow: "ellipsis" }}
								align="left"
							>
								{defaultTo(get(supplier, "bic"), "\xa0")}&nbsp;
							</Typography>
						</Tooltip>
					</Typography>
					<Divider />
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={4}>
				<Grid item xs={12}>
					<Typography variant="caption" color="black">
						{t("iban")}
						<br />

						<Typography variant="subtitle2" color="black">
							{defaultTo(get(supplier, "iban"), "\xa0")}&nbsp;
						</Typography>
					</Typography>
					<Divider />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DetailsFinancialInformations;
