import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Collapse from "@material-ui/core/Collapse";
import { DropzoneArea } from "material-ui-dropzone";
import { toast, currencyFormat, isSupplierOnly, getCurrencySymbol } from "utils";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import useStyles from "./style";
import {
	fetchSupplier,
	fetchSupplierBills,
	fetchSupplierInvoices,
	invoicesTotalAmountsBySupplier,
	addChangeEmailRequest,
	addChangePhoneNumberRequest,
	addChangeIbanRequest,
	validateOtp,
	fetchValidIban,
	cancelOtpValidationRequest,
	acceptChangeIbanRequest,
	refuseChangeIbanRequest,
	fetchSupplierPayments,
	fetchSupplierDeclarationCA,
} from "../../redux/actions/suppliers";
import {
	fetchCountInvoices,
	fetchCountSuppliers,
	invoicesTotalAmounts,
	createInvoice,
} from "../../redux/actions/invoices";
import InvoicesTable from "../Home/components/InvoicesTable";
import Messages from "../messages";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
// import PaymentTable from "./Component/PaymentTable";
import { DetailsLegalInformation } from "./Component/DetailsSupplier/DetailsLegalInformation";
import { DetailsSupplierContact } from "./Component/DetailsSupplier/DetailsSupplierContact";
import { DetailsFinancialInformations } from "./Component/DetailsSupplier/DetailsFinancialInformations";
import CircularLoader from "../../components/Loaders/CircularLoader";
import EmailPopup from "./Component/popups/EmailPopup";
import PhoneNumberPopup from "./Component/popups/PhoneNumberPopup";
import IbanPopup from "./Component/popups/IbanPopup";
import OtpPopup from "./Component/popups/OtpPopup";
import MessagePopup from "./Component/popups/MessagePopup";
import IbanRequestBox from "./Component/IbanRequestBox";
import AcceptIbanPopup from "./Component/popups/AcceptIbanPopup";
import CancelIbanPopup from "./Component/popups/CancelIbanPopup";

function a11yProps(index) {
	return {
		id: `supplier-detail-tab-${index}`,
		"aria-controls": `supplier-detail-tabpanel-${index}`,
	};
}

// const useQuery = () => new URLSearchParams(useLocation().search);

function SupplierDetails(props) {
	// const query = useQuery();
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [value, setValue] = React.useState(0);
	const [valueI, setValueI] = React.useState(0);
	const [expanded, setExpanded] = React.useState(false);
	const supplier = useSelector(({ requests }) => get(requests.queries, "FETCH_SUPPLIER.data"));
	const ibanRequest = useSelector(({ requests }) => get(requests.queries, "VALID_IBAN.data"));

	const history = useHistory();
	// useEffect(() => {
	// 	query.get("");
	// }, []);

	const isContact = new URLSearchParams(useLocation().search).get("contact");

	const supplierInvoices = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER_INVOICES.data")
	);
	// const [filtredData, setFiltredData] = React.useState({});
	// useEffect(() => {
	// 	console.log(supplierInvoices?.content[0]?.codeLieu.includes(query.get("q")));
	// 	const data = supplierInvoices?.content.filter((d) => d.codeLieu.includes(query.get("q")));
	// 	setFiltredData({ ...supplierInvoices, content: data });
	// 	console.log(filtredData);
	// }, [query.get("q")]);
	const supplierPayments = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER_PAYMENTS.data")
	);

	const supplierDeclarationCA = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER_DECLARATION_CA.data")
	);

	const supplierBills = useSelector(({ requests }) =>
		get(requests.queries, "FETCH_SUPPLIER_BILLS.data")
	);

	const totalInvoicesAmount = useSelector(({ requests }) =>
		get(requests.queries, "INVOICE_TOTAL_AMOUNT_BY_SUPPLIER.data")
	);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	const isSupplier = isSupplierOnly(get(currentUser, "roles", []));

	const [files, setFiles] = React.useState([]);
	const [filesNotPersisted, setFilesNotPersisted] = React.useState([]);
	const onloadFiles = (filesParam) => {
		setFiles(filesParam);
	};
	const onDeleteFile = (file) => {
		files.splice(files.indexOf(file), 1);
	};
	// const acceptedInvoices = ["image/*", "application/pdf", "application/xml", ".xml"];

	// eslint-disable-next-line no-shadow
	// const filterInvoices = useSelector(({ filterInvoices }) => filterInvoices);

	const onclickUploadInvoices = () => {
		files.forEach((element) => {
			dispatch(createInvoice(element, supplier.id)).then((response) => {
				if (response.status === 201) {
					toast.success(t("invoice_uploaded_success"));
				} else {
					toast.error(t("invoice_uploaded_error"));
				}
				dispatch(fetchSupplierBills(props.match.params.idSupplier));
				dispatch(invoicesTotalAmounts([]));
				if (response.data && !isEmpty(response.data.nameFile)) {
					setFilesNotPersisted([...filesNotPersisted, response.data.nameFile]);
				}
			});
		});
	};

	const [requestId, setRequestId] = React.useState(0);
	const [requestOperationType, setRequestOperationType] = React.useState("");
	// Email Popup
	const [emailPopupOpen, setEmailPopupOpen] = React.useState(false);
	const [emailFormSubmitting, setEmailFormSubmitting] = React.useState(false);
	const handleChangeEmail = () => {
		setEmailPopupOpen(true);
	};
	// Otp popup - generic use. Only one needed for al validations
	const [otpPopupOpen, setOtpPopupOpen] = React.useState(false);

	const confirmChangeEmail = (newEmail) => {
		if (newEmail === null || newEmail === "") {
			toast.error(t("supplier.popup.email.requiredField"));
			return;
		}
		setEmailFormSubmitting(true);
		dispatch(
			addChangeEmailRequest(supplier.id, newEmail, (response) => {
				if (response.data) {
					setEmailPopupOpen(false);
					setOtpPopupOpen(true);
					setRequestId(response.data);
					setRequestOperationType("EMAIL");
				}
			})
		).then((resp) => {
			setEmailFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};

	const cancelChangeEmail = () => {
		setEmailPopupOpen(false);
	};

	// PhoneNumber Popup
	const [phoneNumberPopupOpen, setPhoneNumberPopupOpen] = React.useState(false);
	const [phoneFormSubmitting, setPhoneFormSubmitting] = React.useState(false);

	const handleChangePhoneNumber = () => {
		setPhoneNumberPopupOpen(true);
	};
	const confirmChangePhoneNumber = (newPhoneNumber) => {
		if (newPhoneNumber === null || newPhoneNumber === "") {
			toast.error(t("supplier.popup.phoneNumber.requiredField"));
			return;
		}
		setPhoneFormSubmitting(true);
		dispatch(
			addChangePhoneNumberRequest(supplier.id, newPhoneNumber, (response) => {
				if (response.data) {
					setPhoneNumberPopupOpen(false);
					setOtpPopupOpen(true);
					setRequestId(response.data);
					setRequestOperationType("PHONE_NUMBER");
				}
			})
		).then((resp) => {
			setPhoneFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};

	const cancelChangePhoneNumber = () => {
		setPhoneNumberPopupOpen(false);
	};

	// Iban Popup
	const [ibanPopupOpen, setIbanPopupOpen] = React.useState(false);
	const [ibanFormSubmitting, setIbanFormSubmitting] = React.useState(false);

	const handleChangeIban = () => {
		setIbanPopupOpen(true);
	};

	const confirmChangeIban = (newIban, newBic, newRib) => {
		if (newIban === null || newIban === "") {
			toast.error(t("supplier.popup.iban.requiredField"));
			return;
		}
		if (newBic === null || newBic === "") {
			toast.error(t("supplier.popup.iban.requiredField"));
			return;
		}

		if (newRib === null) {
			toast.error(t("supplier.popup.iban.requiredField"));
			return;
		}

		setIbanFormSubmitting(true);

		dispatch(
			addChangeIbanRequest(supplier.id, newIban, newBic, newRib, (response) => {
				if (response.data) {
					setIbanPopupOpen(false);
					setOtpPopupOpen(true);
					setRequestId(response.data);
					setRequestOperationType("IBAN");
				}
			})
		).then((resp) => {
			setIbanFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};

	const cancelChangeIban = () => {
		setIbanPopupOpen(false);
	};

	// Otp popup - generic use. Only one needed for al validations
	const [messagePopupOpen, setMessagePopupOpen] = React.useState(false);
	const [messagePopupText, setMessagePopupText] = React.useState("");
	const [messagePopupType, setMessagePopupType] = React.useState("");
	const handleMessagePopupOk = () => {
		setMessagePopupOpen(false);
		setMessagePopupText("");
		setMessagePopupType("");
	};

	const [otpFormSubmitting, setOtpFormSubmitting] = React.useState(false);
	const handleOtpValidation = (otp) => {
		if (otp === null || otp === "") {
			toast.error(t("supplier.popup.otp.requiredField"));
			return;
		}
		setOtpFormSubmitting(true);
		dispatch(
			validateOtp(requestId, otp, requestOperationType, (response) => {
				if (response.data && response.data === true) {
					if (requestOperationType === "EMAIL") {
						setOtpPopupOpen(false);
						setMessagePopupOpen(true);
						setMessagePopupText("supplier.popup.otp.email.success");
						setMessagePopupType("success");
						history.push("/");
						// dispatch(fetchSupplier(props.match.params.idSupplier));
					} else if (requestOperationType === "PHONE_NUMBER") {
						setOtpPopupOpen(false);
						setMessagePopupOpen(true);
						setMessagePopupText("supplier.popup.otp.phone.success");
						setMessagePopupType("success");
						dispatch(fetchSupplier(props.match.params.idSupplier));
					} else if (requestOperationType === "IBAN") {
						setOtpPopupOpen(false);
						setMessagePopupOpen(true);
						setMessagePopupText("supplier.popup.otp.iban.pending");
						setMessagePopupType("success");
						dispatch(fetchSupplier(props.match.params.idSupplier));
						dispatch(fetchValidIban(props.match.params.idSupplier));
					}
				}
			})
		).then((resp) => {
			setOtpFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};

	const cancelOtpValidation = () => {
		// TODO - update DB
		dispatch(cancelOtpValidationRequest(requestId));
		setRequestId(-1);
		setRequestOperationType("");
		setOtpPopupOpen(false);
	};

	const [adminIbanPopupOpen, setAdminIbanPopupOpen] = React.useState(false);
	const [adminIbanFormSubmitting, setAdminIbanFormSubmitting] = React.useState(false);

	const adminHandleChangeIban = () => {
		setAdminIbanPopupOpen(true);
	};

	const adminCancelChangeIban = () => {
		setAdminIbanPopupOpen(false);
	};

	const adminConfirmChangeIban = () => {
		setAdminIbanFormSubmitting(true);

		dispatch(
			acceptChangeIbanRequest(supplier.id, () => {
				setAdminIbanPopupOpen(false);
				dispatch(fetchSupplier(props.match.params.idSupplier));
				dispatch(fetchValidIban(props.match.params.idSupplier));
			})
		).then((resp) => {
			setAdminIbanFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};

	const [adminRefuseIbanPopupOpen, setAdminRefuseIbanPopupOpen] = React.useState(false);
	const [adminRefuseIbanFormSubmitting, setAdminRefuseIbanFormSubmitting] = React.useState(false);

	const adminRefuseHandleChangeIban = () => {
		setAdminRefuseIbanPopupOpen(true);
	};

	const adminRefuseCancelChangeIban = () => {
		setAdminRefuseIbanPopupOpen(false);
	};

	const adminRefuseConfirmChangeIban = (motive) => {
		setAdminRefuseIbanFormSubmitting(true);

		dispatch(
			refuseChangeIbanRequest(supplier.id, motive, () => {
				setAdminRefuseIbanPopupOpen(false);
				dispatch(fetchSupplier(props.match.params.idSupplier));
				dispatch(fetchValidIban(props.match.params.idSupplier));
			})
		).then((resp) => {
			setIbanFormSubmitting(false);
			if (resp.error && resp.error.response.data.message) {
				toast.error(resp.error.response.data.message);
			}
		});
	};
	const filterInvoices = useSelector(({ filterInvoices: i }) => i);

	useEffect(() => {
		// dispatch(invoicesTotalAmounts());
		dispatch(fetchCountSuppliers());
		dispatch(fetchCountInvoices());
		dispatch(invoicesTotalAmountsBySupplier(props.match.params.idSupplier));
		dispatch(fetchSupplier(props.match.params.idSupplier));
		dispatch(fetchSupplierInvoices(props.match.params.idSupplier, filterInvoices));
		dispatch(fetchSupplierBills(props.match.params.idSupplier));
		dispatch(fetchSupplierPayments(props.match.params.idSupplier));
		dispatch(fetchSupplierDeclarationCA(props.match.params.idSupplier));
		dispatch(fetchValidIban(props.match.params.idSupplier));
	}, [dispatch, props.match.params.idSupplier]);

	useEffect(() => {
		if (isContact === "1") setValue(4);
	}, [isContact]);

	const handleChange = (event, newValue) => {
		if (newValue !== 2) setExpanded(false);
		setValue(newValue);
	};
	const handleChangeInformtionTab = (event, newValue) => {
		setValueI(newValue);
	};

	return (
		<>
			{supplier ? (
				<Grid container xs={12} className={classes.rootContainer}>
					<Grid container item xs={12}>
						<Card style={{ width: "100%" }}>
							<Grid item container xs={12} justify="space-around">
								<Grid
									container
									item
									className={classes.supplierItem}
									direction="column"
									spacing={4}
									justify="center"
									alignItems="center"
									xs={3}
								>
									<Grid item container justify="center" alignItems="center">
										<Badge
											overlap="circle"
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											classes={{ badge: classes.customBadge }}
										>
											<Avatar
												alt="Remy Sharp"
												src={
													supplier && supplier.logo
														? `${process.env.REACT_APP_DOMAIN}/invoice${supplier.logo}`
														: null
												}
												className={classes.large}
											/>
										</Badge>
									</Grid>
									<Grid item>
										<Typography variant="h6" color="primary">
											{get(supplier, "label")}
										</Typography>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={isSupplier ? 6 : valueI === 2 && ibanRequest.id !== null ? 6 : 9}
									justify="center"
								>
									<Paper position="static" className={classes.appBar} alignItems="center">
										<Tabs
											value={valueI}
											onChange={handleChangeInformtionTab}
											aria-label="Assets"
											className={classes.tabs}
										>
											<Tab
												label={t("details")}
												{...a11yProps(0)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("associatedContact")}
												{...a11yProps(1)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
											<Tab
												label={t("financialInformation")}
												{...a11yProps(2)} // eslint-disable-line
												classes={{ selected: classes.selectedTab }}
											/>
										</Tabs>
									</Paper>
									<SubPagePanel value={valueI} index={0} className={classes.fullwidth}>
										<DetailsLegalInformation supplier={supplier} style={classes} />
									</SubPagePanel>
									<SubPagePanel value={valueI} index={1} className={classes.fullwidth}>
										<DetailsSupplierContact
											supplier={supplier}
											contact={supplier && supplier.contacts && supplier.contacts[0]}
										/>
									</SubPagePanel>
									<SubPagePanel value={valueI} index={2} className={classes.fullwidth}>
										<DetailsFinancialInformations supplier={supplier} />
									</SubPagePanel>
								</Grid>
								{isSupplier ? (
									<Grid
										container
										item
										xs={3}
										display="flex"
										direction="column"
										spacing={2}
										justify="center"
										className={classes.buttonsContainer}
									>
										{valueI === 0 ? (
											<>
												<Grid container>
													<Button
														size="small"
														className={classes.buttons}
														variant="contained"
														color="success"
														fullWidth
														onClick={handleChangePhoneNumber}
													>
														{t("edit.phonenumber")}
													</Button>
													<PhoneNumberPopup
														open={phoneNumberPopupOpen}
														handleConfirm={confirmChangePhoneNumber}
														handleCancel={cancelChangePhoneNumber}
														phoneNumber={get(supplier, "phone")}
														submitButtonDisabled={phoneFormSubmitting}
													/>
													<Button
														size="small"
														className={classes.buttons}
														variant="contained"
														color="secondary"
														fullWidth
														onClick={handleChangeEmail}
													>
														{t("edit.email")}
													</Button>
													<EmailPopup
														open={emailPopupOpen}
														handleConfirm={confirmChangeEmail}
														handleCancel={cancelChangeEmail}
														email={get(supplier, "email")}
														submitButtonDisabled={emailFormSubmitting}
													/>
												</Grid>
											</>
										) : valueI === 2 ? (
											<>
												{ibanRequest.id !== null ? (
													<IbanRequestBox ibanRequest={ibanRequest}>
														<Button
															size="small"
															className={`${classes.buttons} ${classes.buttonsNoMargin}`}
															variant="contained"
															color="secondary"
															onClick={handleChangeIban}
															disabled
															fullWidth
														>
															{t("edit.iban")}
														</Button>
													</IbanRequestBox>
												) : (
													<Grid container>
														<Button
															size="small"
															className={classes.buttons}
															variant="contained"
															color="secondary"
															onClick={handleChangeIban}
															fullWidth
														>
															{t("edit.iban")}
														</Button>
														<IbanPopup
															open={ibanPopupOpen}
															handleConfirm={confirmChangeIban}
															handleCancel={cancelChangeIban}
															iban={get(supplier, "iban")}
															submitButtonDisabled={ibanFormSubmitting}
														/>
													</Grid>
												)}
											</>
										) : (
											<></>
										)}
									</Grid>
								) : valueI === 2 ? (
									<Grid
										container
										item
										xs={3}
										display="flex"
										direction="column"
										spacing={2}
										justify="center"
										className={classes.buttonsContainer}
									>
										{ibanRequest.id !== null ? (
											<IbanRequestBox ibanRequest={ibanRequest}>
												<Grid item xs={12} container>
													<Grid
														item
														xs={6}
														container
														className={classes.buttonsValidationContainer}
													>
														<Button
															size="small"
															className={classes.buttonsValidation}
															variant="outlined"
															color="secondary"
															onClick={adminRefuseHandleChangeIban}
															fullWidth
														>
															{t("supplier.iban.refuse")}
														</Button>
														<CancelIbanPopup
															open={adminRefuseIbanPopupOpen}
															handleConfirm={adminRefuseConfirmChangeIban}
															handleCancel={adminRefuseCancelChangeIban}
															submitButtonDisabled={adminRefuseIbanFormSubmitting}
														/>
													</Grid>
													<Grid
														item
														xs={6}
														container
														className={classes.buttonsValidationContainer}
													>
														<Button
															size="small"
															className={classes.buttonsValidation}
															variant="contained"
															color="secondary"
															onClick={adminHandleChangeIban}
															fullWidth
														>
															{t("supplier.iban.accept")}
														</Button>
														<AcceptIbanPopup
															open={adminIbanPopupOpen}
															handleConfirm={adminConfirmChangeIban}
															handleCancel={adminCancelChangeIban}
															submitButtonDisabled={adminIbanFormSubmitting}
														/>
													</Grid>
												</Grid>
											</IbanRequestBox>
										) : null}
									</Grid>
								) : (
									<></>
								)}
							</Grid>
						</Card>
					</Grid>
				</Grid>
			) : (
				<CircularLoader />
			)}
			<Grid container xs={12} className={classes.invoicesDetails}>
				<Grid container direction="column" item xs={12}>
					<Grid item>
						<Typography variant="h6" color="secondary">
							{t("Total amount of invoices")} :
							{totalInvoicesAmount >= 0 ? (
								`${currencyFormat(totalInvoicesAmount)} ${getCurrencySymbol(
									supplier?.codeSocietee
								)}`
							) : (
								<CircularLoader />
							)}
						</Typography>
					</Grid>
					<Grid item />
				</Grid>
			</Grid>

			<Grid container spacing={4} className={classes.invoicesTable}>
				<Grid item xs={12} sm={12}>
					<SubPageContainer>
						<Paper position="static" className={classes.appBar}>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
							>
								<Tab
									label={t("Redevances")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>

								<Tab
									label={t("Invoices")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("Regulations")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("DeclarationCA")}
									{...a11yProps(3)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
								<Tab
									label={t("contact")}
									{...a11yProps(4)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
								/>
							</Tabs>
							{value === 1 && (
								<Grid container item xs={12} justify="flex-end">
									<Grid item>
										<Button
											size="small"
											onClick={() => {
												setExpanded(!expanded);
											}}
											variant="contained"
											className={classes.expand}
											startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
											style={{ width: "250px", backgroundColor: "#54c5b8", color: "white" }}
										>
											{t("Upload")}
										</Button>
									</Grid>
								</Grid>
							)}
							<Grid spacing={2} container item xs={12} justify="center" alignItems="stretch">
								<Grid item xs={6}>
									<Collapse direction="row" in={expanded} timeout="auto" unmountOnExit>
										<DropzoneArea
											filesLimit={30}
											className={classes.dropzone}
											showFileNames
											useChipsForPreview
											dropzoneText={
												<>
													<Typography variant="subtitle1" color="textSecondary">
														{t("Add your Invoice")}
														<u>{` ${t("browse")}`}</u>
													</Typography>
												</>
											}
											dropzoneClass={classes.dropzone}
											dropzoneParagraphClass={classes.text}
											onChange={onloadFiles}
											onDelete={onDeleteFile}
											acceptedFiles={null}
										/>
									</Collapse>
								</Grid>
							</Grid>
							{expanded && (
								<Grid container item xs={12} justify="flex-end">
									<Grid item>
										<Button
											size="small"
											onClick={() => {
												onclickUploadInvoices();
												setExpanded(!expanded);
											}}
											variant="contained"
											style={{ color: "white", backgroundColor: "#128CA5", width: "250px" }}
											className={classes.expand}
										>
											{t("upload.invoice")}
										</Button>
									</Grid>
								</Grid>
							)}
						</Paper>
						<SubPagePanel value={value} index={0}>
							{get(supplierInvoices, "totalElements") >= 0 ? (
								<InvoicesTable
									companyCode={supplier?.codeSocietee}
									data={supplierInvoices}
									idSupplier={props.match.params.idSupplier}
									isInvoiceTable
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={1}>
							{get(supplierBills, "totalElements") >= 0 ? (
								<InvoicesTable
									companyCode={supplier?.codeSocietee}
									data={supplierBills}
									idSupplier={props.match.params.idSupplier}
									isBillTable
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={2}>
							{get(supplierPayments, "totalElements") >= 0 ? (
								// <PaymentTable idSupplier={props.match.params.idSupplier} data={supplierPayments} />
								<InvoicesTable
									companyCode={supplier?.codeSocietee}
									data={supplierPayments}
									idSupplier={props.match.params.idSupplier}
									isPaymentTable
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={3}>
							{get(supplierDeclarationCA, "totalElements") >= 0 ? (
								// <PaymentTable idSupplier={props.match.params.idSupplier} data={supplierPayments} />
								<InvoicesTable
									companyCode={supplier?.codeSocietee}
									data={supplierDeclarationCA}
									idSupplier={props.match.params.idSupplier}
									isDeclarationCATable
								/>
							) : (
								<CircularLoader />
							)}
						</SubPagePanel>
						<SubPagePanel value={value} index={4}>
							<Messages />
						</SubPagePanel>
					</SubPageContainer>
					<OtpPopup
						open={otpPopupOpen}
						handleConfirm={handleOtpValidation}
						handleCancel={cancelOtpValidation}
						message={
							requestOperationType === "PHONE_NUMBER"
								? "supplier.popup.phone.otp"
								: "supplier.popup.email.otp"
						}
						submitButtonDisabled={otpFormSubmitting}
					/>
					<MessagePopup
						open={messagePopupOpen}
						handleConfirm={handleMessagePopupOk}
						message={messagePopupText}
						messageType={messagePopupType}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export default SupplierDetails;
