import React from "react";
import Avatar from "@material-ui/core/Avatar";
import useStyles from "../style";

export default ({ row, color }) => {
	const classes = useStyles();
	const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}/invoice${path}`;

	return row ? (
		row?.logo ? (
			<Avatar className={classes.userAvatar} alt="Supplier logo" src={getImageURL(row?.logo)} />
		) : (
			<Avatar
				className={classes.userAvatar}
				alt="Supplier logo"
				style={{ color: "#fff", backgroundColor: color }}
			>
				{row?.contacts && row.contacts.length > 0
					? row.contacts[0].firstName.charAt(0) || row.contacts[0].lastName.charAt(0)
					: null}
			</Avatar>
		)
	) : null;
};
