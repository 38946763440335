export default {
	countSuppliers: "Número total de clientes socios",
	searchSupplier: "",
	name: "Nombre",
	contact: "Contacto",
	principalContact: "Contacto principal",
	outstandingInvoices: "Facturas pendientes",
	addSupplier: "Agregar proveedor",
	"": "",
	associatedContact: "Contactos asociados",
	financialInformation: "Información financiera",
	legalForm: "Forma jurídica",
	return: "Espalda",
	account_journal_auxiliary: "Auxiliar",
	account_journal_number: "Cuenta contable",
	invoice_uploaded_error: "No se ha agregado la factura.",
	invoice_uploaded_success: "La factura se ha agregado correctamente.",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Otra",
	companySiret: "Número SIRET",
	vatNumber: "Número de valor agregado",
	codeNaf: "Código NAF",
	email: "Correo electrónico",
	address: "Dirección",
	fax: "Fax",
	socialCapital: "Capital social",
	function: "Posición",
	phone: "Teléfono",
	bank: "Banco",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	noPrincipalContact: "No hay contacto principal disponible",
	details: "Detalles",
	"upload.instructions": "Instrucciones",
	"upload.instructions1": "Complete la hoja de Excel con la información del proveedor",
	"upload.instructions2":
		'Complete la columna "Logotipo" con el nombre del archivo del logotipo. (Ejemplo: logofilename.jpg)',
	"upload.instructions3": "Asegúrese de que todos los campos sean de tipo TEXTO",
	"upload.instructions4":
		"Coloque la hoja de Excel completa y los logotipos de proveedores en la misma carpeta",
	"upload.instructions5": "Comprime esta carpeta:",
	"upload.instructions51":
		'Windows: haga clic con el botón derecho en la carpeta, luego elija "Enviar a " y luego haga clic en "Carpeta comprimida "',
	"upload.instructions52":
		"MAC: haz Control-clic en él o tócalo con dos dedos, luego elige Comprimir en el menú emergente.",
	"upload.instructions6":
		"Cargue la carpeta comprimida en el portal o arrástrela y suéltela en el área dedicada",
	"upload.instructions7": 'Haga clic en "Descargar proveedores"',
	"supplier.modify": " Modificar proveedor",
	"Nb Total des clients": "Número total de clientes",
	"Nb Total des redevances": "Número total de regalías",
	"admin reference": "Me Group referente",
	"Ajouter un commentaire": "Añadir un comentario",
	"post comment": "publicar comentario",
	"messagerie interne": "Mensajería interna segura",
	"Big.file.message": "Por favor seleccione un archivo que no exceda de 2 MB.",
	"missing.comment": "Por favor, añada un comentario.",
};
