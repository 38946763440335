import React from "react";
import { toast as toastify } from "react-toastify";

import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const Message = ({ message, Icon }) => (
	<Grid container alignItems="center" spacing={1}>
		<Grid item>
			<Typography variant="h5" className="nov-toastify-icon">
				{Icon}
			</Typography>
		</Grid>
		<Grid item xs={10}>
			{message}
		</Grid>
	</Grid>
);

const toast = (message, ...args) => message && toastify(message, ...args);

toast.info = (message, ...args) => {
	if (message && typeof message === "string") toastify.info(<Message message={message} Icon={<InfoOutlined />} />, ...args);
};

toast.warn = (message, ...args) => {
	if (message && typeof message === "string") toastify.warn(<Message message={message} Icon={<ReportProblemOutlined />} />, ...args);
};

toast.error = (message, ...args) => {
	if (message && typeof message === "string") toastify.error(<Message message={message} Icon={<ErrorOutline />} />, ...args);
};

toast.success = (message, ...args) => {
	if (message && typeof message === "string") toastify.success(<Message message={message} Icon={<CheckCircleOutline />} />, ...args);
};

export default toast;
