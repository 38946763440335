export default {
	lastName: "Nachname",
	firstName: "Vorname",
	email: "E-Mail",
	Username: "Nutzername",
	userFunction: "Funktion",
	supplier_name: "Kunde",
	client_name: "Kundenname",
	job: "Stelle",
	manageUsers: "Management User",
	manageSuppliers: "Lieferantenbereich",
	manageClients: "Kunden verwalten",
	manageGroups: "Gruppen Management",
	manageRoles: "Rollen Management",
	manageWorkflow: "Workflow Management",
	manageLicenseAddOn: "Lizenz & Add-On Management",
	status: "Status",
	ACTIVE: "Aktiv",
	WAITING: "Warteschlange",
	DISABLE: "Nicht aktiv",
	add_user: "Benutzer einfügen",
	add_user_button: "BENUTZER EINFÜGEN ",
	add_supplier_button: "LIEFERANT EINFÜGEN ",
	add_client_button: "KUNDEN HINZUFUGEN",
	sendEmailCreationUserMessage:
		"Eine Mail mit Benutznamen und Passwort wurde dem Benutzer geschickt, um die Verbindung zu ermöglichen",
	searchUser: "Suche",
	userCivility: "Anrede",
	administrator: "Administrator",
	user: "Benutzer",
	Mr: "Herr",
	Ms: "Fraulein",
	Mrs: "Frau",
	enableDisableActionMessage: "Sind Sie sicher dieser Benutzer aktivieren/deaktivieren zu wollen?",
	edit_user: "Benutzer ändern",
	add_supplier: "Lieferant hinzufügem",
	edit_supplier: "Lieferant ändern",
	add_client: "Kunden hinzufügen",
	// validation messages
	lastName_required: "Der Nachname ist erforderlich",
	lastName_notValid: "Der Nachname ist ungültig",
	supplierName_notValid: "Der Lieferantenname ist ungültig",
	phone_notValid: "Die Telefonnummer ist ungültig",
	mobile_required: "Mobile ist erforderlich",
	supplier_required: "Der Lieferantenname ist erforderlich",
	firstName_required: "Der Vorname ist erforderlich",
	firstName_notValid: "Der Vorname ist ungültig",
	email_required: "Die E-Mail-Adresse ist erforderlich",
	companyName_required: "Firmenname ist erforderlich",
	email_notValid: "Die E-Mail-Adresse ist ungültig",
	siret_notValid: "Die MwSt-Nr. ist ungültig",
	url_notValid: "Die URL ist ungültig",
	supplier_created: "Erfolgreiche Erstellung des Kunden!",
	supplier_updated: "Erfolgreiche Aktualisierung des Kunden!",
	supplier_error: "Ein Fehler ist während der Kunden-Erstellung aufgetreten",
	supplier_update_error: "Fehlerhafte Aktualisierung von den Lieferanteninformationen",
	job_required: "Die Angabe der Position ist erforderlich",
	civility_required: "Die Anrede ist erforderlich",
	deleteUserActionMessage: "Sind Sie sicher diesen Benutzer löschen zu wollen?",
	deleteUsersActionMessage: "Sind Sie sicher diese Benutzer löschen zu wollen?",
	"admin.settings": "Einstellungen",
	"admin.config.update-settings": "Erfolgreiche Änderung der Einstellungen!",
	"admin.AUTO_EXPORT": "Automatisches Exportieren",
	"admin.email-config": "EMail konfigurieren",
	"admin.ftp-config": "FTP-Konfiguration",
	"admin.import-config": "Konfiguration importieren",
	"admin.folder-config": "Dateien konfigurieren",
	"admin.config.update-message": "Erfolgreiche Aktualisierung von der EMail-Konfiguration!",
	"admin.AMOUNT_TOLERANCE": "Toleranz der Gesamtsummen",
	"admin.MAXIMUM_SIZE": "Maximale Gröβe",
	field_required: "erforderlich",
	Host: "Host",
	Port: "Port",
	Email: "EMail",
	Password: "Passwort",
	"Read folder": "Lese-Ordner",
	successDeleteBill: "Rechnung wurde erfolgreich gelöscht.",
	"Processing folder": "Protokol-Ordner",
	"Success folder": "Erfolgsordner",
	"Exception folder": "Ausnahme-Ordner",
	supplierTraining: "Lieferanten-Lernprozeβ",
	deleteTraining: "Lernprozeβ löschen",
	deleteTrainingMessage: "Sind Sie sicher das Training von diesem Kunden stornieren zu wollen?",
	successdeleteTrainingMessage: "Erfolgreiches Löschen vom Lernprozeβ",
	"admin.download-buttons": "Handlungen an Rechnungen",
	"admin.download-buttons-description":
		"Wählen Sie welche Handlungen Sie auf Ihren Rechnungen ermöglichen möchten.",
	"admin.download-button.excel": "Als Excel herunterladen",
	"admin.download-button.journal-entry": "Als Excel-EC herunterladen",
	"admin.download-button.xml": "Als XML herunterladen",
	"admin.download-button.pdf": "Als PDF herunterladen",
	"admin.download-button.edi": "Im EDI herunterladen",
	"admin.invoice.AUTO_EXPORT-description":
		"Die Einstellung aktiviert das automatische Exportieren der Rechnungen.",
	"admin.invoice.AMOUNT_TOLERANCE-description":
		"Dieses Feld ermöglicht die Einstellung einer Toleranz für die Berechnung der Summen. Die Fehlernotizien werden auf der Basis dieser Toleranz angezeigt.",
	"admin.MAXIMUM_SIZE-description": "Maximale Gröβe in MB von einer Datei zum Herunterladen",
	"admin.display-indicators": "Darstellung der Indikatoren im Dashboard",
	"admin.display-indicators-description":
		"Wählen Sie welche Indikatoren Sie auf Ihrem Dashboard ansehen möchten.",
	"admin.setting.my_tasks": "Meine Aufträge",
	"admin.setting.invoices-exported": "Exportierte Rechnungen",
	"admin.setting.invoices-not-exported": "Nicht exportierte Rechnungen",
	"admin.setting.last-actions": "Letzte Handlungen",
	"admin.setting.load-invoices": "Rechnungen aufladen",
	"admin.setting.last-5-invoices": "Letzte 5 Rechnungen",
	"admin.setting.download-pdf": "PDF herunterladen",
	"admin.setting.delete": "Löschen",
	"admin.setting.delete-all": "Alle Rechnungen löschen",
	"admin.setting.AMOUNT_TOLERANCE.required": "Toleranz der Gesamtsummen erforderlich",
	"admin.setting.relaunch-extraction": "Extraktion neu starten",
	"admin.setting.export_entry_account_excel": "Excel-EC exportieren",
	"admin.export-buttons": "Verknüfungen für Downloads zum Exportieren",
	"admin.invoice.export-buttons-description":
		"Stellen Sie das Anzeigen der möglichen Download-Verknüpfungen nach dem Exportieren einer Rechnung ein. Die Verknüpfungen werden nach dem Exportieren in den Informationen der Rechnungen sichtbar.",
	"admin.extraction-level-settings": "Wichtigkeit der extrahierten Felder (Extraktionsstuf)",
	"admin.extraction-level-settings-desciption":
		"Stellen Sie ein die Wichtigkeit der Felder, die für Sie wichtiger sind und, die wesentlich sind. Diese Informationen werden in der Berechnung der Extraktionsstufen berücksichtigt.",
	"admin.level-settings": "Einstellung der Extraktionsstufen",
	"admin.extraction-level.champs": "Felder",
	"admin.extraction-level.status": "Status",
	"admin.extraction-level.mandatory": "Wesentlich",
	"admin.extraction-level.ignored": "Ignoriert",
	"admin.extraction-level.important": "Wichtig",
	"admin.setting.message.confirm": "Sind Sie sicher diese Einstellungen ändern zu wollen?",
	"admin.user.confirm.message.edit": "Sind Sie sicher dieser Benutzer ändern zu wollen?",
	"admin.user.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.supplier.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.client.confirm.message.add": "Sind Sie sicher dieser Benutzer einfügen zu wollen?",
	"admin.supplier.confirm.message.edit": "Sind Sie sicher dieser Benutzer ändern zu wollen?",
	"admin.config.message.confirm": "Sind Sie sicher diese EMail-Konfiguration ändern zu wollen?",
	"admin.extraction.message.confirm":
		"Sind Sie sicher diese Extraktionsstufen aktualisieren zu wollen?",
	"invoice.user.updated.success": "Erfolgreiche Änderung des Benutzers!",
	"admin.config.ftp.message.confirm":
		"Möchten Sie diese Konfiguration des FTP-Servers wirklich ändern?",
	"admin.config.ftp.update-message":
		"Die Konfiguration des FTP-Servers wurde erfolgreich aktualisiert",
	agent_accounting: "Buchhalter",
	agent_purchase: "Einkäufer",
	"admin.settings.invoice": "Rechnung",
	"admin.settings.order": "Bestellt",
	"admin.settings.invoices": "Rechnungseinstellungen",
	"admin.settings.orders": "Auftragseinstellungen",
	"admin.order.download-buttons": "Aktionen auf Bestellungen",
	"admin.order.download-buttons-description":
		"Wählen Sie im Portal aus, welche Aktionen Sie für Ihre Bestellungen zulassen möchten.",
	"admin.order.AUTO_EXPORT-description":
		"Dieser Parameter aktiviert den automatischen Export von Bestellungen, die vollständig extrahiert wurden und keine Fehlermeldungen aufweisen.",
	"admin.order.AMOUNT_TOLERANCE-description":
		"In diesem Feld können Sie eine Toleranz für die Berechnung der Gesamtsummen einer Bestellung festlegen. Aufgrund dieser Toleranz werden Fehlermeldungen angezeigt.",
	"admin.setting.last-5-orders": "Letzte 5 Bestellungen",
	"admin.order.setting.delete-all": "Alle Bestellungen löschen",
	"admin.order.export-buttons-description":
		"Richten Sie die Anzeige möglicher Download-Verknüpfungen nach dem Exportieren einer Bestellung ein. Die Schaltflächen werden nach dem Export in den Bestelldetails angezeigt.",
	"admin.settings.order.upload": "Bestellungen hochladen",
	"order.exported": "Exportierte Bestellungen",
	"order.unexported": "Nicht exportierte Bestellungen",
	"pagination.rows.per.page": "Zeilen pro Seite:",
};
