import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const AcceptIbanPopup = ({ open, handleConfirm, handleCancel, submitButtonDisabled }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleCancel}
			aria-labelledby="form-dialog-title"
		>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.iban.admin.confirm")}
				</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button onClick={handleCancel} variant="outlined" color="secondary">
					{t("supplier.popup.iban.admin.confirm.cancel")}
				</Button>
				<Button
					onClick={(e) => {
						e.preventDefault();
						handleConfirm();
					}}
					variant="contained"
					color="secondary"
					disabled={submitButtonDisabled}
				>
					{t("supplier.popup.iban.admin.confirm.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AcceptIbanPopup;
