import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";
import get from "lodash/get";
import { Flip, ToastContainer } from "react-toastify";
import ChangePasswordRequest from "pages/profile/ChangePasswordRequest";
import FormPasswordReset from "pages/profile/FormPasswordReset";
// import { CircularProgress } from "@material-ui/core";

import menu from "./menu";
import AppBar from "./components/AppBar";
import MenuLeft from "./components/MenuLeft";
import useStyles from "./style";
import "./polyfills";
import Login from "../pages/Login";
import CustomizedSteppers from "../pages/Suppliers/Component/Stepper";
import AddSupplier from "../pages/supplier/index";
import FormSupplier from "../pages/Suppliers/Details";
import { isUserAdminOnly, isSupplierOnly } from "../utils";

import "react-toastify/dist/ReactToastify.css";

const Copyright = () => {
	const { t } = useTranslation();
	return (
		<Box pt={4}>
			<Typography variant="body2" color="textSecondary" align="center">
				{t("Copyright")} ©
				<Link color="inherit" href="https://photo-me.com/" target="_blank">
					Me Group
				</Link>{" "}
				{new Date().getFullYear()}
				&nbsp;{"  "}&nbsp;
				{process.env.REACT_APP_RELEASE_VERSION || "1.0.0.0"}
			</Typography>
		</Box>
	);
};

const useHasAccess = (currentUser, hasAccess) => {
	if (get(currentUser, "roles")) {
		return currentUser.roles.includes(hasAccess);
	}
	return false;
};

const getTransformedMenu = (currentUser) => {
	const transformedMenu = [];
	menu.forEach(({ path, component, items, hasAccess }) => {
		if (useHasAccess(currentUser, hasAccess)) {
			if (items) {
				items.forEach(({ path: pathChild, component: componentChild }) => {
					transformedMenu.push({ pathChild, componentChild });
				});
			} else transformedMenu.push({ path, component });
		}
	});
	return transformedMenu;
};

function App() {
	const classes = useStyles();
	const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
	const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);

	const currentUser = useSelector(({ requests }) =>
		get(requests, "queries.FETCH_CURRENT_USER.data")
	);

	return (
		<Switch>
			<Route path="/login" exact component={Login} />
			<div>
				<CssBaseline />
				<AppBar currentUser={currentUser} />

				<div className={classes.wrapper}>
					<MenuLeft menu={menu} />
					<main
						className={clsx(
							classes.content,
							menuExpanded && classes.smallContent,
							!headerExpanded && classes.headerExpanded
						)}
					>
						<Container maxWidth={false} className={classes.container}>
							<Switch>
								<Redirect from="/client/details/invoices/details/:idInvoice" to="/redevances" />
								{isUserAdminOnly(get(currentUser, "roles", [])) && (
									<Redirect from="/" to="/administration" exact />
								)}
								{currentUser &&
									isSupplierOnly(get(currentUser, "roles", [])) &&
									currentUser.supplierIds?.length === 1 && (
										<Redirect from="/" to={`/client/details/${currentUser.supplierIds[0]}`} exact />
									)}
								<Route path="/client/details/:idSupplier" exact component={FormSupplier} />
								<Route
									path="/client/details/:idSupplier?contact=1"
									exact
									component={FormSupplier}
								/>

								<Route path="/supplier/add" exact component={CustomizedSteppers} />
								<Route path="/client" exact component={AddSupplier} />
								<Route path="/client/:idSupplier" exact component={AddSupplier} />

								<Route path="/supplier/:idSupplier" exact component={AddSupplier} />
								<Route path="/supplier/details/:idSupplier" exact component={FormSupplier} />

								<Route path="/profile" exact component={ChangePasswordRequest} />
								<Route path="/protected/user/profile/:userId" exact component={FormPasswordReset} />

								{getTransformedMenu(currentUser).map(({ path, component }, index) => (
									<Route key={index} exact path={path} component={component} />
								))}
							</Switch>
							<Copyright />
						</Container>
					</main>
				</div>
				<ToastContainer
					className={classes.toast}
					limit={3}
					hideProgressBar
					position="bottom-center"
					transition={Flip}
					autoClose={15000}
				/>
			</div>
		</Switch>
	);
}

export default App;
