import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const CancelIbanPopup = ({ open, handleConfirm, handleCancel, submitButtonDisabled }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [motive, setMotive] = React.useState("");

	return (
		<Dialog
			PaperProps={{ className: classes.dialogWrapper, square: true }}
			open={open}
			onClose={handleCancel}
			aria-labelledby="form-dialog-title"
		>
			<DialogContent>
				<DialogContentText className={classes.text}>
					{t("supplier.popup.iban.admin.reject")}
				</DialogContentText>
				<TextField
					autoFocus
					id="motive"
					type="motive"
					name="motive"
					fullWidth
					className={classes.inputField}
					onChange={(e) => {
						setMotive(e.target.value);
					}}
					InputProps={{
						classes: {
							input: classes.inputField,
						},
					}}
				/>
			</DialogContent>
			<DialogActions className={classes.actionBar}>
				<Button onClick={handleCancel} variant="outlined" color="secondary">
					{t("supplier.popup.iban.admin.reject.cancel")}
				</Button>
				<Button
					onClick={(e) => {
						e.preventDefault();
						handleConfirm(motive);
					}}
					variant="contained"
					color="secondary"
					disabled={submitButtonDisabled}
				>
					{t("supplier.popup.iban.admin.reject.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CancelIbanPopup;
