/* eslint-disable */
import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierPayments } from "redux/actions/suppliers";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import useStyles from "./style";

function PaymentHeaders({ idSupplier }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [sortBy, setSortBy] = useState("");
	const filterPayments = useSelector(({ filterPayments: i }) => i);
	const [clickedRow, setClickedRow] = useState({
		year: true,
		raisonSociale: false,
		dueDate: false,
		total: false,
	});

	const handleOnClickPaymentRow = (row) => {
		setClickedRow((clickedRow) => ({ [row]: !clickedRow[row] }));
		dispatch(
			fetchSupplierPayments(idSupplier, {
				...filterPayments,
				sortBy: row,
				pageNo: 0,
			})
		);
	};

	return (
		<Grid container item xs={12}>
			<Grid item xs={1}></Grid>
			<Grid item xs={1} onClick={() => handleOnClickPaymentRow("year")}>
				<Typography className={classes.headers}>
					{clickedRow["year"] && <SwapVertIcon />}
					{t("Month")}/{t("Year")}
				</Typography>
			</Grid>
			<Grid item xs={2} onClick={() => handleOnClickPaymentRow("societe")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["societe"] && <SwapVertIcon />}
					{t("Societe")}
				</Typography>
			</Grid>
			<Grid item xs={3} onClick={() => handleOnClickPaymentRow("libPaiement")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["libPaiement"] && <SwapVertIcon />}
					{t("libPaiement")}
				</Typography>
			</Grid>
			<Grid item xs={1} onClick={() => handleOnClickPaymentRow("devise")}>
				<Typography className={classes.headers}>
					{clickedRow["devise"] && <SwapVertIcon />}
					{t("Devise")}
				</Typography>
			</Grid>
			<Grid item xs={1} onClick={() => handleOnClickPaymentRow("montant")}>
				<Typography className={classes.headers} noWrap style={{ justifyContent: "center" }}>
					{clickedRow["montant"] && <SwapVertIcon />}
					{t("payments.total")}
				</Typography>
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={2} onClick={() => handleOnClickPaymentRow("typeLot")}>
				<Typography className={classes.headers} noWrap>
					{clickedRow["typeLot"] && <SwapVertIcon />}
					{t("typeLot")}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default PaymentHeaders;
