import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";

import useStyles from "../style";
import SubPageContainer from "../../components/SubPageContainer";
import SubPagePanel from "../../components/SubPagePanel";
import { fetchUsers } from "../redux/actions";
import { Add } from "./Add";

import { List } from "./List";
import { ListSuppliers } from "./ListSuppliers";
// import { ListClients } from "./ListClients";
import { AddSuplierAccount } from "./AddSupplierAccount";
// import { AddClientAccount } from "./AddClientAccount";
import Settings from "../settingsPage";

function a11yProps(index) {
	return {
		id: `supplier-detail-tab-${index}`,
		"aria-controls": `supplier-detail-tabpanel-${index}`,
	};
}

export const UsersHome = () => {
	const isUserModify = useSelector(({ appState }) => appState.isUserModify);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();

	const [value, setValue] = React.useState(0);
	const [tempFlag] = React.useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		dispatch(fetchUsers());
	}, []);
	return (
		<Grid item xs={12} sm={12} className={classes.firstGrid}>
			<BrowserRouter>
				<SubPageContainer>
					<Paper position="static" className={classes.appBar}>
						{!isUserModify && (
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Assets"
								className={classes.tabs}
								scrollButtons="auto"
								variant="scrollable"
							>
								<Tab
									label={t("manageUsers")}
									{...a11yProps(0)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								<Tab
									label={t("manageSuppliers")}
									{...a11yProps(1)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								{/* <Tab
									label={t("manageClients")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/> */}
								<Tab
									label={t("admin.settings")}
									{...a11yProps(2)} // eslint-disable-line
									classes={{ selected: classes.selectedTab }}
									className={classes.tab}
								/>
								{tempFlag && (
									<>
										<Tab
											label={t("manageGroups")}
											{...a11yProps(1)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageRoles")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageWorkflow")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
										<Tab
											label={t("manageLicenseAddOn")}
											{...a11yProps(2)} // eslint-disable-line
											classes={{ selected: classes.selectedTab }}
											className={classes.tab}
										/>
									</>
								)}
							</Tabs>
						)}
					</Paper>
					<SubPagePanel value={value} index={0}>
						<Grid container xs={12} className={classes.firstGrid}>
							<Route path="/administration/addUser" component={Add} />
							<Route path="/administration/editUser/:idUser" component={Add} />
							<Route path="/administration" exact component={List} />
						</Grid>
					</SubPagePanel>

					<SubPagePanel value={value} index={1}>
						<Grid container xs={12} className={classes.firstGrid}>
							<Route path="/administration/addUser" component={AddSuplierAccount} />
							<Route path="/administration/editUser/:idUser" component={AddSuplierAccount} />
							<Route path="/administration" exact component={ListSuppliers} />
						</Grid>
					</SubPagePanel>

					{/* <SubPagePanel value={value} index={2}>
						<Grid container xs={12} className={classes.firstGrid}>
							<Route path="/administration/addUser" component={AddClientAccount} />
							<Route path="/administration/editUser/:idUser" component={AddClientAccount} />
							<Route path="/administration" exact component={ListClients} />
						</Grid>
					</SubPagePanel> */}

					<SubPagePanel value={value} index={2}>
						<Grid container xs={12} className={classes.firstGrid}>
							<Settings />
						</Grid>
					</SubPagePanel>
				</SubPageContainer>
			</BrowserRouter>
		</Grid>
	);
};

export default UsersHome;
