import React from "react";

import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(4),
        margin: theme.spacing(0, 1),
        backgroundColor: "rgba(255,255,255,0.8)",
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(1.7)",
            opacity: 0,
        },
    },
    dot: {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    orange: {
        color: "#ff7600",
        backgroundColor: "#ff7600",
    },
    green: {
        backgroundColor: "#31d031",
        color: "#31d031",
    },
    red: {
        color: "#ef2929",
        backgroundColor: "#ef2929",
    },
});

function UserStatusBadge(props) {
    // const classes = useStyles();
    const { level, classes } = props;
    const badgeColor = () => {
        switch (level) {
            case "ACTIVE":
                return classes.green;
            case "WAITING":
                return classes.orange;
            case "DISABLE":
                return classes.red;
            default:
                return classes.red;
        }
    };
    return (
        <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
        >
            <Badge
                overlap="circle"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
                classes={{ badge: badgeColor(level), dot: classes.dot }}
             />
        </Box>
    );
}

export default withStyles(styles)(UserStatusBadge);
