export default {
	countSuppliers: "Nombre total de clients partenaires",
	searchSupplier: "Trouver un client par code lieu",
	contact: "Contact",
	principalContact: "Contact principal",
	outstandingInvoices: "Factures impayées",
	addSupplier: "Ajouter un client",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "Autre",
	companySiret: "Siret",
	vatNumber: "Numéro TVA",
	legalForm: "Forme juridique",
	codeNaf: "Code NAF",
	email: "Email",
	address: "Adresse",
	fax: "Fax",
	socialCapital: "Capital social",
	name: "Nom",
	function: "Fontion",
	phone: "Téléphone",
	bank: "Banque",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	account_journal_auxiliary: "Auxiliaire",
	account_journal_number: "Compte comptable",
	noPrincipalContact: "Pas de contact principal",
	details: "Détails",
	financialInformation: "Informations financières",
	associatedContact: "Contacts associés",
	return: "Revenir",
	invoice_uploaded_error: "La facture n'a pas été ajoutée",
	invoice_uploaded_success: "La facture a été ajoutée avec succès.",
	"upload.instructions": "Instructions",
	"upload.instructions1": "Remplissez la feuille Excel avec les informations des clients",
	"upload.instructions2":
		"Remplissez la colonne «Logo» avec le nom du fichier du logo. (Exemple: logofilename.jpg)",
	"upload.instructions3": "Assurez-vous que tous les champs sont de type TEXT",
	"upload.instructions4":
		"Placez la feuille Excel remplie et les logos des clients dans le même dossier",
	"upload.instructions5": "Compressez ce dossier:",
	"upload.instructions51":
		'Windows: Faites un clic droit sur le dossier puis choisissez "Envoyer vers " puis cliquez sur "Dossier compressé "',
	"upload.instructions52":
		"MAC: cliquez dessus tout en maintenant la touche Contrôle enfoncée ou appuyez dessus avec deux doigts, puis choisissez Compresser dans le menu contextuel.",
	"upload.instructions6":
		"Téléchargez le dossier compressé sur le portail ou faites-le glisser et déposez-le dans la zone dédiée",
	"upload.instructions7": 'Cliquez sur "Télécharger les clients"',
	"supplier.modify": " Modifier client",
	"Nb Total des clients": "Nb Total des clients",
	"Nb Total des redevances": "Nb Total des redevances",
	"admin reference": "Référent Me Group ",
	"Ajouter un commentaire": "Ajouter un commentaire",
	"post comment": "Poster le commentaire",
	"messagerie interne": "Messagerie interne sécurisée",
	"Big.file.message": "Veuillez choisir un fichier qui ne dépasse pas 2 Mo.",
	"missing.comment": "Veuillez ajouter un commentaire.",
};
