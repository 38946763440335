import React, { useState } from "react";

import { Checkbox, Grid } from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { defaultTo } from "lodash";
import { getCurrencySymbol } from "utils";

function DeclarationCAElements({ row }) {
	const [open, setOpen] = React.useState({
		raisonSociale: false,
		nomLieu: false,
	});
	const handleClose = (property) => {
		setOpen((o) => ({ ...o, [property]: false }));
	};

	const handleOpen = (property) => {
		setOpen((o) => ({ ...o, [property]: true }));
	};
	const [checked, setChecked] = useState(false);
	const handleChange = () => {
		setChecked(!checked);
	};

	return (
		<>
			<Grid item xs={2}>
				<Typography color="primary">
					{row.mois && row.annee
						? `${row.mois}/${row.annee}`
						: row.mois
						? row.mois
						: row.annee
						? row.annee
						: "--"}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography color="primary">{row.codeLieu}</Typography>
			</Grid>
			<Grid item xs={3}>
				<Tooltip
					open={open.nomLieu}
					onClose={() => handleClose("nomLieu")}
					onOpen={row?.nomLieu?.length > 10 && (() => handleOpen("nomLieu"))}
					title={defaultTo(row?.nomLieu, "\xa0")}
				>
					<Typography style={{ width: "95%" }} color="primary" noWrap>
						{row.nomLieu}
					</Typography>
				</Tooltip>
			</Grid>
			<Grid item xs={1}>
				<Typography color="primary" style={{ textAlign: "center" }}>{`${
					row.ca ?? "0"
				} ${getCurrencySymbol(row?.codeSociete)}`}</Typography>
			</Grid>
			<Grid item xs={1}>
				<Typography color="primary" style={{ textAlign: "center" }}>
					<Checkbox checked={checked} onChange={handleChange} />
				</Typography>
			</Grid>
		</>
	);
}

export default DeclarationCAElements;
