import React from "react";
import InvoiceTable from "../InvoiceTable";
import BillTable from "../BillTable";
import DeclarationCATable from "../DeclarationCATable";
import PaymentTable from "../PaymentTable";
import useStyles from "../style";

function Table({
	data,
	setOpenDeletePopup,
	idSupplier,
	setSelectedId,
	isBillTable,
	isInvoiceTable,
	isPaymentTable,
	isDeclarationCATable,
	companyCode,
	checkedAll,
	setCheckedAll,
}) {
	const classes = useStyles();
	// const { t } = useTranslation();

	return isBillTable ? (
		<BillTable
			idSupplier={idSupplier}
			data={data}
			classes={classes}
			setOpenDeletePopup={setOpenDeletePopup}
			setSelectedId={setSelectedId}
			isBillTable={isBillTable}
			companyCode={companyCode}
		/>
	) : isPaymentTable ? (
		<PaymentTable
			idSupplier={idSupplier}
			data={data}
			classes={classes}
			setOpenDeletePopup={setOpenDeletePopup}
			setSelectedId={setSelectedId}
			isPaymentTable={isPaymentTable}
			companyCode={companyCode}
		/>
	) : isInvoiceTable ? (
		<InvoiceTable
			idSupplier={idSupplier}
			data={data}
			classes={classes}
			setOpenDeletePopup={setOpenDeletePopup}
			setSelectedId={setSelectedId}
			isInvoiceTable={isInvoiceTable}
			companyCode={companyCode}
			checkedAll={checkedAll}
			setCheckedAll={setCheckedAll}
		/>
	) : (
		<DeclarationCATable
			idSupplier={idSupplier}
			data={data}
			classes={classes}
			setOpenDeletePopup={setOpenDeletePopup}
			setSelectedId={setSelectedId}
			isDeclarationCATable={isDeclarationCATable}
			companyCode={companyCode}
		/>
	);
}

export default Table;
