/* eslint-disable */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function InfoItem({ label, value, justify, color, marginLeft }) {
	return (
		<Grid container spacing={1} justify={justify} style={{ marginLeft: marginLeft }}>
			{/* <Grid item>
				<Typography variant="subtitle2" color="textSecondary">
					{label}
				</Typography>
			</Grid> */}
			<Grid item>
				<Typography color={color ? color : "primary"}>{value}</Typography>
			</Grid>
		</Grid>
	);
}

export default InfoItem;
