import supllierEs from "../pages/Suppliers/i18n/es";
import administrationEs from "../administration/i18n/es";

export default {
	...supllierEs,
	...administrationEs,
	Copyright: "Copyright",
	Dashboard: "Tablero de mandos",
	Orders: "Ordenes de Compra",
	Invoices: "Facturas",
	Regulations: "Pagos",
	Suppliers: "Proveedores",
	Rapports: "Reporting",
	Search: "Search",
	"Add your Invoice": "Agrega tu factura o ",
	browse: "recorre",
	"Show all": "Mostrar todo",
	id: "identificador",
	supplier: "Proveedor",
	"search.payment": "Encuentra un pago",
	invoiceNo: "Factura N°",
	licencefeeNo: "Tasa de regalías N°",
	paymentNo: "Número de asentamiento",
	Redevances: "Regalías",
	issueDate: "Fecha de emision",
	dueDate: "Fecha de vencimiento",
	"creation.date": "Fecha de creación",
	"licencefee.date": "Fecha de regalías",
	"search.licencefee": "Encuentra un número de licencia",
	"search.invoice": "Encuentra un número de licencia",
	"payment.date": "Fecha de pago",
	"Price WT": "Importe sin IVA",
	"Taxe Rate": "IVA",
	Price: "Importe con IVA",
	"Purchase order": "Orden de Compra",
	"Last actions": "Ultimas acciones",
	Today: "Hoy",
	Yesterday: "Ayer",
	"Last week": "Ultima semana",
	Status: "Estado",
	Date: "Fecha",
	"My tasks": "Mis facturas",
	"Invoices to verify": "A Verificar",
	"Invoices to be checked": "A Validar",
	"Invoices to Payers": "Lista para Pago",
	"Paid invoices": "Pagadas",
	"Unpaid invoices": "No Pagadas",
	"Total amount of invoices": "Monto total de facturas durante los últimos 24 meses ",
	"Payment.initiation.message": "Se inicia el pago y se tramitará en los próximos días",
	"Total amount of orders": "Cantidad total de pedidos",
	"Total VAT amount": "Total IVA",
	"Statistical overview of purchases": "Historial de Facturacion",
	"Total Purchases": "Total Ordenes",
	"Total of expenses": "Facturas Total",
	"edit.phonenumber": "Editar teléfono",
	"edit.email": "Editar correo electrónico",
	Late: "Con Retraso",
	Administrator: "Administrador",
	"New clients": "Nuevos proveedores",
	"New suppliers": "Nbr de clientes socios",
	FOUNDED_RESULT: "Resultado encontrado",
	EndDate: "fecha final",
	StartDate: "fecha de inicio",
	"Add an invoice": "Agregar una factura",
	"Accounting number": "Pago número",
	Upload: "Subir facturas",
	"upload.invoice": "Subir facturas",
	"edit.iban": "editar IBAN",
	Download: "Descargar",
	Show: "monitor",
	Delete: "eliminar",
	All: "Todas",
	SearchInvoice: "Encuentra una factura...",
	"Delivery address": "Dirección de entrega",
	"Billing address": "Domicilio de facturación",
	"Payment terms": "Modalidades de pago",
	"Order Details": "Detalles del pedido",
	"Invoice Details": "Detalles de la factura",
	"Reject Invoice": "Rechazar",
	"Correct Extraction": "Corregir",
	Control: "Controlar",
	ToPay: "Pago",
	"Total HT": "HT Total",
	"compagny.id": "ID de la compañía",
	Discount: "Descuento",
	"VAT amount": "importe del IVA",
	"Total amount": "Cantidad total",
	"Invoice overview": "Resumen de la factura",
	History: "Historia",
	verificationDate: "Fecha de verificación",
	rejectionDate: "Fecha de rechazo",
	controlDate: "Fecha de control",
	dateOfReceipt: "Fecha de recepción",
	rejectMessage: "¿Está seguro de que desea rechazar esta factura?",
	controlMessage: "¿Está seguro de que desea controlar esta factura?",
	ErrorMessageToControl: "No puede controlar esta factura:",
	deleteMessage: "¿Está seguro de que desea eliminar esta (estas) factura (s)?",
	individualDeleteMessage: "¿Está seguro de que desea eliminar esta factura?",
	individualBillDeleteMessage: "¿Está seguro de que desea eliminar esta factura?",
	rejectionReason: "Patrón de rechazo",
	rejectionDetails: "Detalles del rechazo...",
	cancel: "anular",
	confirm: "confirmar",
	editForm: "Modificación",
	countInvoices: "Número total de facturas ",
	tobeprocessed: "a procesar ",
	None: "------------------",
	save: "Salvar",
	edit: "Editar",
	Actions: "Comportamiento",
	itemCode: "Código",
	designation: "Artículo",
	quantity: "Ctd",
	unitPrice: "Premio",
	Commands: "Líneas de facturación",
	Total: "Total",
	VAT: "Tipo de IVA",
	"Pre-Tax-Amount": "Monto antes de impuestos",
	totalHt: "HT Total",
	vat: "VAT",
	sortBy: "Ordenar por",
	createdAt: "Creado en",
	updatedAt: "Actualizado en",
	InvoicesCannotBeDeleted: "Algunas facturas no pueden ser reclamadas",
	InvoicesCannotBeRelaunched: "Algunas facturas no se pueden eliminar",
	"Integration in progress": "Integración en curso",
	"To verify": "Para verificar",
	"To control": "Al control",
	"To pay": "Pagar",
	Payed: "Pagado",
	Rejected: "Rechazado",
	Canceled: "Cancelado",
	Exception: "Excepción",
	codeArticle: "Código del artículo",
	deliveryDelay: "Retraso en la entrega",
	orderNo: "N º de pedido",
	numberOfArticle: "Recuento del artículo",
	deliveryMethod: "Método de entrega",
	InvoicesNotCreatedMessage: "No se pueden crear las siguientes facturas :",
	invoice: "factura",
	phone: "Teléfono",
	order: "orden",
	training: "Entrenando...",
	trained: "Entrenada",
	"Company Siret": "Siret",
	"VAT Number": "Número de IVA",
	"invoice Number": "Factura no.",
	"Apply Changes": "Aplicar",
	"Invoice was already paid": "La factura ya se pagó",
	"Goods or Services were never received": "Los bienes o servicios nunca se recibieron",
	"Supporting documents were not received with the invoice":
		"No se recibieron los documentos de respaldo con la factura",
	"The purchase order number is missing, is incorrect or has been canceled":
		"Falta el número de orden de compra, es incorrecto o ha sido cancelado",
	"The amount being billed does not match the agreed upon amount on the contract":
		"El monto facturado no coincide con el monto acordado en el contrato",
	Other: "Otro",
	CREATE_ACTION: "Creación de la factura",
	VERIFY_ACTION: "Comprobando la facultad",
	REJECT_ACTION: "Rechazo de la factura",
	CONTROL_ACTION: "Control de la factura",
	END_TRAINING_ACTION: "Aprendizaje completado",
	CORRECT_ACTION: "Lanzamiento de aprendizaje",
	TO_PAY_ACTION: "Pago de la factura",
	DELET_ACTION: "Eliminación de la factura",
	RELAUNCH_ACTION: "Extracción liberada",
	EXPORT_ACTION: "Exportación de la factura",
	"Apply for approval": "Aplicar aprobación",
	comment: "Comentario",
	ApplyForApproval: "Solicitud de aprobación",
	action: "Acción",
	details: "Detalles",
	totalEmptyMessageError: "El monto total de la factura no se llena",
	supplierEmptyMessageError: "El proveedor de la factura no se llena",
	incohenrenceAmountsMessageError:
		"El cálculo de las cantidades es incorrecto; El monto del IVA y el monto sin IVA es diferente del monto total",
	invoice_charge: "Fresco",
	total_extract: "Facturas totalmente extraídas",
	partial_extract: "Facturas parcialmente extraídas",
	not_extract: "Facturas no extraídas",
	"this field is mandatory": "este campo es obligatorio",
	country: "País",
	duplicated_invoice: "Factura duplicada",
	currency: "moneda",
	reason: "razón",
	"invoice.status.underProcess": "Siendo procesados",
	"invoice.status.processed": "Tratado",
	"invoice.status.InoviceProcessed": "Facturas procesadas",
	"invoice.status.InoviceUnderProcess": "Facturas en proceso",
	"invoice.duplicated": "Duplicado",
	"invoice.status.external_validation": "Aprobación pendiente",
	"invoice.status.exported": "Exportado",
	"Invoice available": "Factura disponible",
	"extraction.label.fully_extracted": "Totalmente extraído",
	"extraction.label.partially_extracted": "Extraído parcialmente",
	"extraction.label.not_extracted": "No se ha extraído",
	"extraction.label.not_invoice": "Otros que la factura",
	"tootlip.action.edit": "Editar",
	"tooltip.action.delete": "Eliminar",
	"tooltip.action.relaunch": "Relanzamiento",
	"tooltip.action.download": "Descargar",
	"tooltip.action.relaunch_extraction": "Relanzar la extracción",
	"tooltip.action.export_excel": "Exportar excel",
	"tooltip.action.export_entry_account_excel": "Entrada contable de exportación de Excel",
	"tooltip.action.export_xml": "Exportar xml",
	"supplier.add.button": "Importar proveedores",
	"supplier.export.suppliers.excel": "Exportar la lista de proveedores",
	"supplier.add2.button": "Agregar un proveedor",
	"supplier.name.exist.already": "El nombre del proveedor ya existe",
	"supplier.email.exist.already": "La dirección de correo electrónico ya existe",
	"suppliers.names.exist.already": " ya existe",
	"suppliers.empty.field.name.row.excel": "¡Hay una línea sin nombre!",
	"suppliers.add.multiple": "Añadir proveedores o ",
	"upload.supplier": "Subir proveedores",
	"downloqd.template": "descargar plantilla",
	"invoice.exported": "Facturas exportadas",
	"invoice.unexported": "Facturas no exportadas",
	"invoice.total_amoutn_to_verify": "Importe total de las facturas a verificar",
	"invoice.total_invoice": "Número de regalías",
	"add.contact": "Añadir nuevo contacto",
	Administration: "Administración",
	Logout: "Cierre de sesión",
	Profile: "Perfil",
	"invoice.detail.company_siret": "Identificación de la empresa",
	DeliverySlip: "Albarán de entrega",
	"invoice.selected.title0": "todas las facturas están seleccionadas",
	"invoice.selected.title1": "los ",
	"invoice.selected.title2": " Las facturas de esta página están todas seleccionadas.",
	"invoice.selected.title3": "Seleccionar todas las facturas",
	"supplier.add.step1": "Información general",
	"supplier.add.step2": "Información del contacto",
	"supplier.add.step3": "Informaciones financieras",
	"supplier.add.next": "Próxima",
	"supplier.add.previous": "Previa",
	"supplier.add.finish": "Finalizar",
	"supplier.add.country": "País base",
	"supplier.add.suppliername": "Nombre del proveedor",
	"supplier.add.logo": "Logo",
	"supplier.add.capital": "Capital",
	"supplier.add.siret": "Empresa SIRET",
	"supplier.add.linkedin": "LinkedIn",
	"supplier.add.email": "Correo electrónico",
	"supplier.add.companyName": "Razón social",
	"supplier.add.fax": "Fax",
	"supplier.add.civility": "Civilidad",
	"supplier.add.phone": "Teléfono",
	"supplier.add.mobile": "Número de teléfono",
	"supplier.add.vat": "Número de valor agregado",
	"supplier.add.naf": "Código NAF",
	"supplier.add.bank": "Banco",
	"supplier.add.bic": "BIC",
	"supplier.add.iban": "IBAN",
	"supplier.add.rib": "RIB",
	"supplier.add.ribAttachment": "Costilla en un accesorio",
	"supplier.add.acc": "Cuenta contable",
	"supplier.add.curr": "Moneda predeterminada",
	"supplier.add.function": "Función",
	"supplier.add.firstName": "Nombre de pila",
	"supplier.add.lastName": "Apellido",
	"supplier.add.city": "Ciudad",
	"supplier.add.street": "calle",
	"supplier.add.zipCode": "Código postal",
	"supplier.add.confirm": "¿Estás segura de que quieres guardar los cambios?",
	"supplier.delete.confirm": "¿Está seguro de que desea eliminar el proveedor?",
	"supplier.delete.success": "Proveedor eliminado correctamente",
	"supplier.delete.error": "Ocurrió un error al eliminar el proveedor",
	"suplier.action.agree": "Aceptar",
	"suplier.action.disagree": "Rechazar",
	"supplier.add.aux": "Cuenta auxiliar",
	"supplier.add.phoneFix": "Teléfono fijo",
	TO_VALIDATE_EXTERNAL_ACTION: "Solicitud de aprobación",
	EXPORT_EXCEL: "Exportar excel",
	EXPORT_EDI: "Exportar edi",
	EXPORT_XML: "Exportar xml",
	EXPORT_PDF: "Exportar pdf",
	EXPORT_EC: "Exporter EC",
	"order.absent": "Orden ausente",
	"amount.greate.thang.goods": "Monto de la factura mayor que el pedido",
	"delivery.absent": "Entrega ausente",
	delivery: "Entrega",
	"user.roles.user": "Usuario",
	"profile.update-password.current-password-required": "Se requiere contraseña actual",
	"profile.update-password.new-password-required": "Se requiere nueva contraseña",
	"profile.update-password.password-required": "se requiere contraseña",
	"profile.update-password.password-not-matched": "Las contraseñas no coinciden",
	"profile.update-password.current-password": "Contraseña actual",
	"profile.update-password.new-password": "Nueva contraseña",
	"profile.update-password.confirm-password": "Confirmar contraseña",
	"profile.update-password.reset-password": "Restablecer la contraseña",
	"profile.update-password.password-reset": "Restablecimiento de contraseña",
	"profile.update-password.password-reset-success": "Su contraseña fue cambiada exitosamente",
	"profile.update-password.password-reset-done": "Hecho",
	"profile.update-password.update-password": "Cambiar la contraseña",
	"profile.update-password.email": "Tu correo electrónico actual",
	"profile.update-password.tokenError": "Token no válido, la contraseña ya está actualizada.",
	"profile.update-password.password-email-send":
		"Un correo electronico ha sido enviado. por favor revise su correo.",
	"Zip Code": "Código postal",
	Street: "Calle",
	City: "Ciudad",
	"supplier.delete.training.error.message": "No existe formación para este proveedor.",
	"invoice.detail.reject.success": "La factura fue rechazada con éxito",
	"invoice.detail.reject.failed": "La factura no fue rechazada",
	"invoice.detail.approved.success": "La factura fue aprobada con éxito",
	"invoice.detail.approved.failed": "La factura no fue aprobada",
	"invoice.detail.export.success": "La factura se exportó correctamente",
	"invoice.detail.export.failed": "La factura no se exportó",
	"invoice.detail.message.rectify": "¿Está seguro de que desea guardar estos cambios?",
	"invoice.detail.message.confirmExport": "¿Está seguro de que desea exportar esta factura?",
	Approve: "Aprobar",
	"token.validation.noteExist": "Solicitud de cambio de contraseña ya enviada",
	"client.delete.msg": "¿Está seguro de que desea eliminar este cliente?",
	year: "Este año",
	month: "Este mes",
	week: "Esta semana",
	day: "Hoy",
	Exported: "Exportada",
	Integrated: "Integradas",
	"List des clients partenaires": "Lista de clientes socios",

	"supplier.popup.email.title": "Cambio de correo electrónico",
	"supplier.popup.email.message": "Cambio de correo electrónico",
	"supplier.popup.email.currentEmail": "Mi correo electrónico actual:",
	"supplier.popup.email.newEmail": "Mi nuevo correo electrónico",
	"supplier.popup.email.cancel": "Cancelar",
	"supplier.popup.email.confirm": "Validar",
	"supplier.popup.otp.requiredField": "OTP es obligatorio",
	"supplier.popup.email.requiredField": "El correo electrónico es obligatorio",
	"supplier.popup.email.otp": "Introduzca el código que ha recibido por teléfono:",
	"supplier.popup.phone.otp": "Introduzca el código que ha recibido por correo electrónico:",
	"supplier.popup.otp.email.success": "Su correo electrónico ha sido modificado.",
	"supplier.popup.ok": "Ok",
	"supplier.popup.phoneNumber.title": "Cambio de número de teléfono",
	"supplier.popup.phoneNumber.message": "",
	"supplier.popup.phoneNumber.currentPhoneNumber": "Mi número actual",
	"supplier.popup.phoneNumber.newPhoneNumber": "Mi nuevo número",
	"supplier.popup.phoneNumber.cancel": "Cancelar",
	"supplier.popup.phoneNumber.confirm": "Validar",
	"supplier.popup.otp.phone.success": "Su número de teléfono ha sido cambiado.",
	"supplier.popup.iban.title": "Mis datos bancarios",
	"supplier.popup.iban.message": "Cambio de IBAN",
	"supplier.popup.iban.currentIban": "Mi IBAN actual:",
	"supplier.popup.iban.newDataTitle": "Mi nueva información de contacto:",
	"supplier.popup.iban.newIban": "IBAN",
	"supplier.popup.iban.newBic": "BIC",
	"supplier.popup.iban.cancel": "Cancelar",
	"supplier.popup.iban.confirm": "Validar",
	"supplier.popup.iban.newRib": "RIB en el anexo",
	"supplier.popup.otp.iban.pending": "Su IBAN está siendo validado",
	"supplier.popup.iban.upload.placeholder": "Examinar un archivo",
	"supplier.iban.validation.message": "Solicitud pendiente de validación",
	"supplier.popup.iban.requiredFiel": "Se requiere el IBAN, el BIC y el RIB",
	"supplier.popup.phoneNumber.requiredField": "Se requiere el número de teléfono",
	"supplier.iban.refuse": "Desechos",
	"supplier.iban.accept": "Validar",
	"supplier.popup.iban.admin.confirm": "¿Está seguro de que quiere validar el IBAN?",
	"supplier.popup.iban.admin.confirm.cancel": "Cancelar",
	"supplier.popup.iban.admin.confirm.confirm": "Validar",
	"supplier.popup.iban.admin.reject": "Motivo de rechazo:",
	"supplier.popup.iban.admin.reject.cancel": "Cancelar",
	"supplier.popup.iban.admin.reject.confirm": "Validar",
	"supplier.financial.bic.notvalid": "Formato BIC inválido",
	"supplier.financial.iban.notvalid": "Formato IBAN inválido",
	"supplier.iban.validation.ibanLabel": "IBAN: ",
	"supplier.iban.validation.bicLabel": "BIC: ",
	"supplier.iban.validation.ribLabel": "Descargar Rib ",
	"supplier.popup.iban.requiredField": "Se requiere el IBAN, el BIC y el RIB",
	"company.thirdPartyAccount": "Cuenta de terceros",
	"company.companyName": "Razón social",
	libPaiement: "Texto",
	typeLot: "Tipo",
	Montant: "Importe",
	Societe: "Sociedad",
	Devise: "Divisa",
	"supplier.add.supplierUsername": "Cuenta de terceros",
	DeclarationCA: "Declaración  CA",
	"Raison sociale": "Razón social",
	Month: "Mes",
	Year: "Año",
	LOCPT: "Cuenta de terceros",
	"Code lieu": "Código de lugar",
	"Nom lieu": "Ponga su nombre",
	EOMAC: "EOMAC",
	Machine: "Máquina",
	CA: "VN",
	Designation: "Designación",
	"Total redevance": "Total tasa",
	numFacture: "Número de derechos",
	"suppliersList.headerTitle": "Sitios",
	"search.declaration": "Encuentra un número de declaración",
	"supplier.add.codeSocietee": "código de empresa",
	codeSocietee_required: "La sociedad es necesaria",
	codeSocietee_not_valid: "La sociedad debe ser un número",
	Reglement: "pago",
	Importation: "importación",
	Extourne: "reverso",
	Saisie: "entrada",
};
