import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import get from "lodash/get";
import clsx from "clsx";
import useStyles from "./style";

export default ({ content, dateSent, index, currentUser, createdBy, isSupplier, supplier }) => {
	console.log(currentUser);
	console.log(!isSupplier && !get(currentUser, "supplierIds").length === 0);
	const classes = useStyles();

	const isSender =
		(isSupplier &&
			get(currentUser, "supplierIds") &&
			get(currentUser, "supplierIds").length > 0 &&
			get(currentUser, "supplierIds").find((element) => element === get(supplier, "id"))) ||
		(!isSupplier && get(currentUser, "supplierIds").length === 0);

	return (
		<Grid container xs={12} justify={isSender ? "flex-end" : "flex-start"}>
			<Card
				index={index}
				className={clsx(classes.root, isSender ? classes.fromMessage : classes.toMessage)}
			>
				<CardContent className={classes.content}>
					<Grid container xs={12} direction="row" justify="space-between">
						<Grid item>
							<Typography variant="subtitle2" color="primary">
								{createdBy}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="secondary">
								{dateSent.substring(0, 10)}
							</Typography>
						</Grid>
					</Grid>
					<Grid container xs={12} direction="row" justify="space-between">
						<Grid item className={classes.messageContent}>
							{/* <Typography>{content}</Typography> */}
							<Typography>
								{content.split("\n").map((i, key) => (
									<div key={key}>{i}</div>
								))}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="primary">
								{dateSent.substring(11, 16)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
