import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { Button, Input, NativeSelect } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast, REGEX } from "utils";

import { createUser, fetchCivilities, fetchJobs, fetchUser, updateUser } from "../redux/actions";
import CircularLoader from "../../components/Loaders/CircularLoader";
import ConfirmDialog from "../../components/ConfirmDialog";

import useStyles from "../style";

export const Add = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const jobs = useSelector(
		({ requests }) => requests.queries.FETCH_USERS_JOBS && requests.queries.FETCH_USERS_JOBS.data
	);
	const civilities = useSelector(
		({ requests }) =>
			requests.queries.FETCH_USERS_CIVILITIES && requests.queries.FETCH_USERS_CIVILITIES.data
	)?.filter((civ) => civ.label !== "Ms"); // TODO: remove filter after 1.0 release
	const userTomodify = useSelector(
		({ requests }) => requests.queries.FETCH_USER && requests.queries.FETCH_USER.data
	);
	const { t } = useTranslation();
	const classes = useStyles();
	const { errors, handleSubmit, register, setValue } = useForm();
	const [job, setJob] = React.useState("");
	const [civility, setCivility] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [confirmObject, setConfirmObject] = React.useState({
		message: "",
		openConfirm: false,
		cancel: false,
	});
	const [userToSave, setUserToSave] = React.useState(null);

	const handleJobFieldChange = (event) => {
		const { target } = event;
		setJob(target.value);
	};
	const handleCivilityFieldChange = (event) => {
		const { target } = event;
		setCivility(target.value);
	};
	useEffect(() => {
		dispatch(fetchJobs());
		dispatch(fetchCivilities());
	}, []);
	useEffect(() => {
		if (props.match.params.idUser) dispatch(fetchUser(props.match.params.idUser));
	}, [props.match.params.idUser]);
	useEffect(() => {
		if (props.match.params.idUser && userTomodify) {
			setValue("firstName", userTomodify.firstName);
			setValue("lastName", userTomodify.lastName);
			setValue("email", userTomodify.email);
			if (get(userTomodify, "jobTitle.label")) setJob(t(userTomodify.jobTitle.label));
			if (get(userTomodify, "civility.label")) setCivility(t(userTomodify.civility.label));
		}
	}, [userTomodify]);
	const onSubmit = (data) => {
		const selectedJob = jobs.find((s) => data.jobTitle === t(s.label));
		const selectedCivility = civilities.find((s) => data.civility === t(s.label));
		data = { ...data, jobTitle: selectedJob, civility: selectedCivility };

		setUserToSave(data);
		setConfirmObject({
			message: props.match.params.idUser
				? t("admin.user.confirm.message.edit")
				: t("admin.user.confirm.message.add"),
			openConfirm: true,
		});
	};
	const onConfirm = () => {
		if (!confirmObject.cancel) {
			let data = userToSave;
			if (props.match.params.idUser) {
				// update mode
				data = { ...data, id: props.match.params.idUser };
				setIsLoading(true);
				dispatch(
					updateUser(
						props.match.params.idUser,
						data,

						() => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.success(t("invoice.user.updated.success"));
							setIsLoading(false);
							setTimeout(() => {
								history.push("/administration");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.error(error);
							setIsLoading(false);
						}
					)
				);
			} else {
				setIsLoading(true);
				dispatch(
					createUser(
						data,
						() => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.success(t("sendEmailCreationUserMessage"));
							setIsLoading(false);
							setTimeout(() => {
								history.push("/administration");
							}, 1000);
						},
						(error) => {
							setConfirmObject({ message: "", openConfirm: false });
							toast.error(error);
							setIsLoading(false);
						}
					)
				);
			}
		} else {
			setConfirmObject({ message: "", openConfirm: false, cancel: false });
			history.push("/administration");
		}
	};
	const onCancel = () => {
		setConfirmObject({ message: "", openConfirm: false });
	};

	return (
		<>
			{(props.match.params.idUser && userTomodify) || !props.match.params.idUser ? (
				<Grid container xs={12} justify="center" alignContent="center" alignItems="center">
					<Grid container item xs={12}>
						<Typography variant="caption" color="primary">
							<GroupIcon fontSize="small" /> {t("manageUsers")} {t(" > ")}{" "}
							{props.match.params.idUser && userTomodify ? t("edit_user") : t("add_user")}
						</Typography>
					</Grid>
					<Grid container item xs={12} justify="center" alignItems="center" alignContent="center">
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid
								container
								item
								xs={12}
								sm={12}
								className={classes.firstGrid}
								spacing={6}
								justify="center"
								alignItems="center"
								alignContent="center"
							>
								<Grid
									container
									item
									xs={12}
									className={classes.headerTitle}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Typography variant="h6" color="primary">
										{userTomodify && props.match.params.idUser ? t("edit_user") : t("add_user")}
									</Typography>
								</Grid>
								<Grid
									container
									item
									xs={8}
									className={classes.loginContainer}
									spacing={6}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Grid container item xs={12} spacing={6}>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("userCivility")}
												<span style={{ color: "red" }}>*</span>

												<br />
												<NativeSelect
													value={civility}
													onChange={handleCivilityFieldChange}
													inputRef={register({
														required: {
															value: true,
															message: t("civility_required"),
														},
													})}
													name="civility"
													fullWidth
												>
													{civilities &&
														civilities.map((elt, i) => (
															<option key={i} value={t(elt.label)}>
																{t(elt.label)}
															</option>
														))}
												</NativeSelect>
												<p>{errors.civility?.message}</p>
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("lastName")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<Input
													inputRef={register({
														required: {
															value: true,
															message: t("lastName_required"),
														},
														pattern: {
															value: REGEX.FORM_NAMES,
															message: t("lastName_notValid"),
														},
													})}
													defaultValue={
														props.match.params.idUser && userTomodify && userTomodify.lastName
													}
													name="lastName"
													fullWidth
												/>
												<p>{errors.lastName?.message}</p>
											</Typography>
										</Grid>
										<Grid item xs={4}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("firstName")}
												<span style={{ color: "red" }}>*</span>

												<br />
												<Input
													inputRef={register({
														required: {
															value: true,
															message: t("firstName_required"),
														},
														pattern: {
															value: REGEX.FORM_NAMES,
															message: t("firstName_notValid"),
														},
													})}
													name="firstName"
													fullWidth
												/>
											</Typography>
											<p>{errors.firstName?.message}</p>
										</Grid>
									</Grid>
									<Grid container item xs={12} spacing={6}>
										<Grid item xs={6}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("email")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<Input
													inputRef={register({
														pattern: {
															value: REGEX.EMAIL,
															message: t("email_notValid"),
														},
														required: {
															value: true,
															message: t("email_required"),
														},
													})}
													name="email"
													type="email"
													disabled={props.match.params.idUser}
													fullWidth
												/>
												<p>{errors.email?.message}</p>
											</Typography>
										</Grid>

										<Grid item xs={6}>
											<Typography variant="subtitle2" align="left" color="primary">
												{t("userFunction")}
												<span style={{ color: "red" }}>*</span>

												<br />

												<NativeSelect
													value={job}
													onChange={handleJobFieldChange}
													inputRef={register({
														required: { value: true, message: t("job_required") },
													})}
													name="jobTitle"
													fullWidth
												>
													{jobs &&
														jobs.map((elt, i) => {
															// TODO: remove "agent_purchase" from the list when order module is added
															if (!["supplier", "client", "agent_purchase"].includes(elt.label))
																return (
																	<option
																		key={i}
																		selected={
																			props.match.params.idUser &&
																			elt.label === get(userTomodify, "civility.label")
																		}
																		value={t(elt.label)}
																	>
																		{t(elt.label)}
																	</option>
																);
															return null;
														})}
												</NativeSelect>
												<p>{errors.jobTitle?.message}</p>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									item
									xs={12}
									spacing={2}
									className={classes.actions}
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Grid item>
										<Button
											color="primary"
											autoFocus
											onClick={() =>
												setConfirmObject({
													message: t("CancelActionMessage"),
													openConfirm: true,
													cancel: true,
												})
											}
										>
											{t("cancel")}
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											type="submit"
											color="secondary"
											disabled={isLoading}
										>
											{isLoading && <CircularProgress size={14} />}
											{t("confirm")}
										</Button>
									</Grid>
								</Grid>

								<ConfirmDialog
									message={confirmObject.message}
									open={confirmObject.openConfirm}
									onConfirm={onConfirm}
									onCancel={onCancel}
								/>
							</Grid>
						</form>
					</Grid>{" "}
				</Grid>
			) : (
				<CircularLoader />
			)}
		</>
	);
};

export default Add;
