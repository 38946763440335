import { FETCH_CURRENT_USER, UPDATE_PASSWORD } from "../../constants";
import {
	URL_CURRENT_USER,
	URL_UPDATE_PASSWORD,
	URL_LIST_SETTING_WITH_CODE,
	URL_LIST_SETTING,
	URL_LIST_SETTING_MODULE,
} from "../../constants/endpoint";

export const fetchCurrentUser = () => ({
	type: FETCH_CURRENT_USER,
	request: { url: `${URL_CURRENT_USER}` },
});

export const postUpdatePassword = (userCredentials, onSuccess, onError) => ({
	type: UPDATE_PASSWORD,
	request: {
		url: `${URL_UPDATE_PASSWORD}/request`,
		method: "post",
		data: userCredentials,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const fetchSettingsWithCode = (code) => ({
	type: `FETCH_SETTINGS_WITH_CODE_${code}`,
	request: {
		url: `${URL_LIST_SETTING_WITH_CODE}/${code}`,
	},
});
export const fetchSettings = () => ({
	type: "FETCH_SETTINGS",
	request: {
		url: `${URL_LIST_SETTING}`,
	},
});

export const fetchSettingsByModuleName = (module) => ({
	type: "FETCH_SETTINGS_MODULE",
	request: {
		url: `${URL_LIST_SETTING_MODULE}${module}`,
	},
});

export const updateUserPassword = (token, userCredentials, onSuccess, onError) => ({
	type: UPDATE_PASSWORD,
	request: {
		url: `${URL_UPDATE_PASSWORD}?token=${token}`,
		method: "post",
		data: userCredentials,
	},
	meta: {
		onSuccess: (response) => {
			if (onSuccess) onSuccess();
			return response;
		},
		onError: () => {
			if (onError) onError();
		},
	},
});

export const validateToken = (token) => ({
	type: UPDATE_PASSWORD,
	request: {
		url: `${URL_UPDATE_PASSWORD}/?token=${token}`,
		method: "get",
	},
});
