export default {
	countSuppliers: "إجمالي عدد عملاء الشريكين",
	searchSupplier: "العثور على المورد",
	name: "اسم",
	contact: "جهة الاتصال",
	principalContact: "جهة الاتصال الرئيسية",
	outstandingInvoices: "الفواتير المستحقة",
	addSupplier: "إضافة مورد",
	"": "",
	associatedContact: "جهات الاتصال المرتبطة",
	financialInformation: "معلومات مالية",
	return: "عودة",
	account_journal_auxiliary: "مساعد",
	account_journal_number: "حساب محاسبة",
	invoice_uploaded_error: ".لم يتم إضافة الفاتورة",
	invoice_uploaded_success: "تمت إضافة الفاتورة بنجاح.",
	"limited.liability.company": "SARL",
	"single.person.limited.liability": "EURL",
	"public.limited.company": "SA",
	"general.partnership": "SNC",
	"simplified.joint.stock.company": "SAS",
	"single.person.simplified.joint.stock.company": "SASU",
	other: "آخر",
	companySiret: "رقم SIRET",
	vatNumber: "ظريبه الشراء",
	legalForm: "استمارة قانونية",
	codeNaf: "كود NAF",
	email: "بريد الالكتروني",
	address: "العنوان",
	fax: "فاكس",
	socialCapital: "رأس المال",
	function: "موقع",
	phone: "هاتف",
	bank: "مصرف",
	iban: "IBAN",
	bic: "BIC",
	rib: "RIB",
	noPrincipalContact: "لا يوجد جهة اتصال رئيسية متاحة",
	details: "تفاصيل",
	"upload.instructions": "تعليمات",
	"upload.instructions1": "املأ ورقة Excel بمعلومات الموردين",
	"upload.instructions2": 'املأ العمود "Logo" باسم ملف الشعار. (مثال: logofilename.jpg)',
	"upload.instructions3": "تأكد من أن جميع الحقول من النوع TEXT",
	"upload.instructions4": "ضع ورقة Excel المعبأة وشعارات الموردين في نفس المجلد",
	"upload.instructions5": "ضغط هذا المجلد:",
	"upload.instructions51":
		"نظام التشغيل Windows: انقر بزر الماوس الأيمن فوق المجلد ثم اختر إرسال إلى ثم انقر فوق المجلد المضغوط",
	"upload.instructions52":
		"MAC: انقر عليه مع الضغط على مفتاح التحكم أو اضغط عليه بإصبعين ، ثم اختر ضغط من قائمة الاختصارات.",
	"upload.instructions6":
		"قم بتحميل المجلد المضغوط إلى البوابة الإلكترونية أو اسحبه وأفلته في المنطقة المخصصة",
	"upload.instructions7": "انقر فوق تحميل الموردين",
	"supplier.modify": "تحديث المورد ",
	"Nb Total des clients": "إجمالي عدد العملاء",
	"Nb Total des redevances": "إجمالي عدد الإتاوات",
	"Ajouter un commentaire": "إضافة تعليق",
	"admin reference": "المساعد Me Group",
	"post comment": "أضف تعليقا",
	"messagerie interne": "آمن المراسلة الداخلية",
	"Big.file.message": "الرجاء تحديد ملف لا يتجاوز 2 ميغابايت.",
	"missing.comment": "الرجاء إضافة تعليق.",
};
